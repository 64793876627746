import React, {Component, Fragment} from 'react';
import {
    UPDATE,
    CREATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    BooleanInput,
    FormDataConsumer,
    SelectInput
} from 'react-admin';
import {fetchIt} from "../../../../helpers/fetcher";
import {getErrorMessage} from "../../../../helpers";
import {Redirect} from 'react-router';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import {NtaSaveButton} from '../../../common';
import CurrencyInput from "../../../../components/CurrencyInput";
import {DatePicker} from '../../../../common';
import {feesTypeChoices} from "../../../../config/constants";
import {Grid} from "@material-ui/core";


export const FeesFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};

export const FeesFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};

class FeesForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;
        }
        
        // values.FromDate = values.FromDate.format('MM/DD/YYYY');
        // values.ToDate = values.ToDate.format('MM/DD/YYYY');

        fetchIt(
            requestType,
            'fees',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Fee saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        const SanitizedGrid = ({basePath, ...props}) => {
            return (
              <Grid {...props} />
            );
        };

        return (
            <Fragment>
                <Card className="cardForm">
                    <CardContent>
                            <SimpleForm
                                resource={this.props.resource}
                                record={this.props.record}
                                save={this.handleSubmit}
                                toolbar={<FeesFormToolbar isDisabled={this.state.isDisabled} />}
                            >
                                <SanitizedGrid container style={{width: '100%'}} >
                                    <Grid item xs={6}>
                                        <TextInput source="Name" className='fieldsWidthExpand' multiline validate={required('The Name field is required')} />
                                        <TextInput source="Description" className='fieldsWidthExpand' multiline />
                                        <SelectInput
                                            source='FeeType'
                                            label='Fee Type'
                                            choices={feesTypeChoices}
                                            optionText='name'
                                            validate={required('The Fee Type field is required')}
                                            className='fieldsWidthExpand'
                                        />
                                          <BooleanInput source='IsActive' defaultValue={this.props.editMode ? '' : true}/>
                                    </Grid>
                                    
                                    <Grid item xs={6}>
                                        <DatePicker source="FromDate" disableFuture={false}/>
                                        <FormDataConsumer>
                                            {
                                                ({formData}) => {
                                                    return ( 
                                                    <DatePicker 
                                                    source="ToDate"
                                                    disableFuture={false}
                                                    minDate={formData.FromDate ? formData.FromDate : undefined}
                                                    />
                                                    )
                                                }
                                            }
                                        </FormDataConsumer>
                                        <CurrencyInput source="Amount" style={{width: 300, paddingTop: 6}}/>
                                        <FormDataConsumer>
                                            {
                                                ({formData}) => {
                                                    return <FeesFormTitle title={formData.Name} />;
                                                }
                                            }
                                        </FormDataConsumer>
                                    </Grid>
                                </SanitizedGrid>
                            </SimpleForm>
                    </CardContent>
                 </Card>
            </Fragment>
        );
    }
}

export default FeesForm;
