import React, {Component, Fragment} from 'react';
import {TextField, Datagrid, ReferenceField, ListContextProvider} from 'react-admin';
import {TeacherAssignmentsListFilters} from '../'


class TeacherAssignmentsGrid extends Component {
    render() {
        const {fetchTheData, schoolYears, semesters, fetchAssignments, fetchSemesters, ...other} = this.props;

        if(!this.props.data || (Object.keys(this.props.data).length === 0 )){
            return(
                <Fragment>
                    <TeacherAssignmentsListFilters schoolYears={schoolYears} semesters={semesters} fetchAssignments={fetchAssignments} fetchSemesters={fetchSemesters}/>
                    <div style={{paddingTop: 15, paddingBottom: 15}}>
                        No data.
                    </div>
                </Fragment>
            );
        }

        return (
            <div style={{position: "relative", paddingBottom: 25}}>
                <TeacherAssignmentsListFilters
                    schoolYears={schoolYears}
                    semesters={semesters}
                    fetchAssignments={fetchAssignments}
                    fetchSemesters={fetchSemesters}
                />
                <ListContextProvider
                    value={{
                        resource: 'teachers_assignments',
                        basePath: 'teachers_assignments',
                        selectedIds: [],
                        ...other
                    }}
                >
                    <Datagrid {...other} record={this.props.record}>
                        <ReferenceField
                            source="Studio"
                            reference="studios"
                            link={(record, reference) => `/${reference}/${record.Studio}/show`}
                            sortable={false}
                        >
                            <TextField source="Name" />
                        </ReferenceField>
                        <TextField source="DayOfWeek" sortable={false} />
                        <TextField source="StartTime" sortable={false} />
                        <TextField source="ScheduleName" sortable={false} />
                    </Datagrid>
                </ListContextProvider>
            </div>
        );
    }
}

export default TeacherAssignmentsGrid;
