import React, {cloneElement, useEffect} from 'react';
import {useFormState, useForm} from 'react-final-form';
import {useTranslate} from 'react-admin';
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";
import ContentSave from "@material-ui/icons/Save";
import {makeStyles, createTheme, ThemeProvider} from "@material-ui/core/styles";
import classnames from 'classnames';


const defaultIcon = <ContentSave />;


const useStyles = makeStyles(
    theme => ({
        button: {
            position: 'relative'
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        icon: {
            fontSize: 18,
        },
    }),
    {name: 'RaSaveButton'}
);


const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#3B9287",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        red: {
            main: 'red'
        }
    },
    overrides: {

        MuiPaper: {
            rounded: {
                borderRadius: 5
            }
        },
        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#3B9287',
                boxShadow: 'none',
                borderRadius: 10
            },
        },
    }
});


const NtaSaveButton = props => {
    const {
        isDisabled,
        onCloseClick,
        showNotification,
        label,
        saving,
        icon = defaultIcon,
        color = 'primary'
    } = props;
    const translate = useTranslate();
    const displayedLabel = label ? translate(label, { _: label }) : translate('ra.action.save', { _: 'ra.action.save' });

    const formState = useFormState();
    const form = useForm();

    const classes = useStyles(props);


    useEffect(() => {
        if (props.value) {
            form.change('buttonValue', props.value);
        }
    }, []);  // eslint-disable-line


    const handleClick = e => {
        e.preventDefault();

        if (!formState.valid) {
            if (showNotification){
                showNotification(
                    'direct_message',
                    'warning',
                    { messageArgs: { _: 'The form is not valid. Please check for errors' } }
                );
            }

            return;
        }

        if (onCloseClick) {
            onCloseClick();
        }

        form.submit();
    };

    return (
        <ThemeProvider theme={theme}>
            <Button
                variant="contained"
                color={color}
                type='submit'
                value="copy"
                onClick={handleClick}
                disabled={isDisabled}
                style={props.style ? props.style : undefined}
            >
                {saving ? (
                    <CircularProgress
                        size={18}
                        thickness={2}
                        className={classes.leftIcon}
                    />
                ) : (
                    cloneElement(icon, {
                        className: classnames(classes.leftIcon, classes.icon),
                    })
                )}
                {displayedLabel}
            </Button>
        </ThemeProvider>
    );
};

export default NtaSaveButton;
