import React, {useState} from 'react';
import {fetchIt} from "../../../../../helpers/fetcher";
import {CREATE} from 'react-admin';
import {Button} from 'react-admin';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {useRefresh} from 'react-admin';
import ConfirmCancelationDialog from '../dialogs/ConfirmCancelationDialog';


const RegistrationCancelButton = props => {

    const [isOpen, setIsOpen] = useState(false);
    const refresh = useRefresh();

    const handleClick = () => {
        setIsOpen(true); 
    };

    const handleDialogClose = () => {
        setIsOpen(false);
    };

    const cancelRegistration = notice => {

        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            CREATE,
            'registration_cancel',
            {id: props.record.Id, Notes: notice}
        ).then(response => {
            switch (response.status) {
                case 200:

                    setIsOpen(false);
                    refresh(true);

                    let message = 'Registration canceled.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:
                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

    }

    return (
    <>
        <Button
            onClick={handleClick}
            label="Cancel"
            key="button"
        >
            <DeleteIcon/>
        </Button>
        <ConfirmCancelationDialog 
            isOpen={isOpen} 
            handleDialogClose={handleDialogClose} 
            cancelRegistration={cancelRegistration}
            dialogTitle='Cancel Registration'
            dialogContent='Are you sure you want to cancel the Registration?'
        />
    </>
    );
};

export default RegistrationCancelButton;