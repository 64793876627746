import React from 'react';
import {
    TextField,
    FunctionField,
    Title
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../common';
import {formatPhone} from "../../../helpers";
import LocationWorkingTimeList from './LocationWorkingTimeList';


export const LocationShow = props => {
    const fieldsToFormat = [
        {
            field: 'Phone',
            formatter: formatPhone
        },
        {
            field: 'AdditionalPhone',
            formatter: formatPhone
        },
    ];

    const options = {
        detailsTitle: 'location',
        titleField: 'LocationName',
        indexToFetch: ['locations_address', 'locations_working_time'],
    };

    return (
        <DetailsLayout {...props} options={options} fieldsToFormat={fieldsToFormat}>
            <DetailsTileWrapper
                sideTitle='Location Details'
                sideText='Add your school location here. Later you will be able
                    to distribute your classes in the locations you want.'
            >
                <Title title={options.titleField} />
                <TextField source='LocationName' />
                <TextField source='Phone' />
                <TextField source='Email' />
                <TextField source='ContactPerson' />
                <TextField source='AdditionalPhone' />
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
            </DetailsTileWrapper>
            <DetailsTileWrapper
                sideTitle='Location Address'
                sideText='This address will appear on your invoices.'
                recordObject='locations_address'
            >
                <TextField source='locations_address.StreetAddress' label='Street Address' />
                <TextField source='locations_address.USState' label='State' />
                <TextField source='locations_address.ZIP' label='Zip' />
                <TextField source='locations_address.StreetAddressLine2' label='Street Address Line 2' />
                <TextField source='locations_address.City' label='City' />
            </DetailsTileWrapper>
            <DetailsTileWrapper
                sideTitle='Location Working Time'
                sideText='Specific working time for this Location.'
                recordObject='locations_working_time'
            >
                <LocationWorkingTimeList source='locations_working_time' recordObject='locations_working_time' />
            </DetailsTileWrapper>
        </DetailsLayout>
    );
};
