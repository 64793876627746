import React, {memo} from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import NeteaSchedulerComponent from './NeteaScheduler'
import {useSelector} from "react-redux";
import "@fontsource/roboto";
import {inArray} from './helpers'

export const NeteaScheduler = memo(props => {
    const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);

    const {
        minTime,
        maxTime,
        resources,
        schedules,
        layoutColor,
        slotDuration,
        clickOnTimeSlot,
        fetchStart,
        fetchEnd,
        showNotification,
        moveSchedule,
        refresh,
        refreshRows,
        refreshSchedules,
        weekdays,
        readOnly,
        withCheckboxes,
        layoutWidth,
        excludeFromOverlapped,
        withOtherSemesters
    } = props;


    let newSchedules = {};
    let newWeekdays = weekdays;
    if (withCheckboxes) {
        let keys = Object.keys(schedules);
        if (keys.length > 0) {
            for (let day in schedules) {
                let schedulesInDay = {};
                for (let studio in schedules[day]) {
                    if (schedules[day][studio].length > 0) {
                        schedulesInDay[studio] = schedules[day][studio];
                    }
                }
                if (Object.keys(schedulesInDay).length > 0) {
                    newSchedules[day] = schedulesInDay;
                }
            }
        }

        const wds = newWeekdays;
        newWeekdays = [];
        for (let w = 0; w < wds.length; w++) {
            if (inArray(wds[w], Object.keys(newSchedules))) {
                newWeekdays.push(wds[w]);
            }
        }
    } else {
        newSchedules = schedules;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <NeteaSchedulerComponent
                minTime={minTime}
                maxTime={maxTime}
                resources={resources}
                schedules={newSchedules}
                layoutColor={layoutColor}
                slotDuration={slotDuration}
                clickOnTimeSlot={clickOnTimeSlot}
                fetchStart={fetchStart}
                fetchEnd={fetchEnd}
                showNotification={showNotification}
                moveSchedule={moveSchedule}
                refresh={refresh}
                refreshRows={refreshRows}
                refreshSchedules={refreshSchedules}
                weekdays={newWeekdays}
                readOnly={readOnly}
                withCheckboxes={withCheckboxes}
                layoutWidth={layoutWidth}
                sidebarOpen={sidebarOpen}
                excludeFromOverlapped={excludeFromOverlapped}
                withOtherSemesters={withOtherSemesters}
            />
        </DndProvider>
    )
});
