import React, {Fragment, Title} from 'react';
import LocationForms from '../containers/LocationForms';
import {BackButton} from '../../../common';


const LocationEdit = props => {

        return (
            <Fragment>
                <BackButton />
                <LocationForms
                    {...props} 
                    editMode={true}
                />
            </Fragment>
        );
}
export default LocationEdit;
