import axios from 'axios';
import * as endpoints from "../config/endpoints";
import {inArray} from './common';
import authProvider from '../authProvider';


let authTokenRequest;

const getAuthToken = () => {
    if (!authTokenRequest) {
        authTokenRequest = makeActualAuthenticationRequest();
        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }

    return authTokenRequest;
};

const resetAuthTokenRequest = () => {
    authTokenRequest = null;
};

const makeActualAuthenticationRequest = () => {
    let refreshToken = 'NoRefreshToken';
    if (localStorage.getItem('user')) {
        const userData = JSON.parse(localStorage.getItem('user'));
        refreshToken = userData.refresh_token;
    } else {
        authTokenRequest = null;
        throw new Error();
    }
    const refreshConfig = Object.assign({ refresh_token: refreshToken }, endpoints.ENDPOINTS.REFRESH.params);
    let refreshParams = [];
    // eslint-disable-next-line
    for (let param in refreshConfig) {
        if (refreshConfig.hasOwnProperty(param)) {
            refreshParams.push(encodeURIComponent(param) + '=' + encodeURIComponent(refreshConfig[param]));
        }
    }

    return axios.post(
        endpoints.BACKEND_URL + endpoints.ENDPOINTS.REFRESH.url,
        refreshParams.join('&')
    );
};


export const fetchIt = async (type, resource, data = {}, headers = false) => {
    const endpoint = endpoints.ENDPOINTS[resource];
    let accessToken = 'NoAccessToken';
    if (localStorage.getItem('user')) {
        const userData = JSON.parse(localStorage.getItem('user'));
        accessToken = userData.access_token;
    } else if (endpoint[type].isSecure){
        throw new Error('You are not logged in!');
    }

    if (typeof data.data !== 'undefined') {
        data = data.data;
    }

    if (endpoint[type].mapFiltersToParams && (typeof data.filter !== 'undefined')) {
        // eslint-disable-next-line
        for (const param in data.filter) {
            if (data.filter.hasOwnProperty(param)) {
                data[param] = data.filter[param];
            }
        }
        delete data.filter;
    }

    let config = {
        method: endpoint[type].type,
        url: endpoints.BACKEND_URL + endpoint[type].url,
        data: data
    };

    if (endpoint[type].baseUrl) {
        config.url = endpoint[type].baseUrl + endpoint[type].url;
    }

    if (endpoint[type].params) {
        config.data = Object.assign(data, endpoint[type].params);
    }

    if (
        endpoint[type].usesUrlEncodedParams ||
        (endpoint[type].type === 'GET') ||
        endpoint[type].needsId
    ) {
        if (endpoint[type].needsId && (typeof data.id !== 'undefined')) {
            config.url += '/' + data.id;
            delete data.id;

            if (data.Id === null) {
                delete data.Id;
            }

            if (endpoint[type].hasParams && (endpoint[type].type === 'GET')) {
                config.params = data;
                config.data = {};
                // eslint-disable-next-line
                for (const param in config.params) {
                    if (config.params.hasOwnProperty(param)) {
                        if (Array.isArray(config.params[param])) {
                            config.params[param] = config.params[param].join(',');
                        }
                    }
                }
            }

            if(endpoint[type].type === 'GET'){
                config.params = config.params || {};
                config.params = Object.assign(config.params, data);
                config.data = {};
            }
        } else if (endpoint[type].type === 'GET') {
            config.params = data;
            config.data = {};
            // eslint-disable-next-line
            for (const param in config.params) {
                if (config.params.hasOwnProperty(param)) {
                    if (Array.isArray(config.params[param])) {
                        config.params[param] = config.params[param].join(',');
                    }
                }
            }
        } else {
            let queryParams = [];
            // eslint-disable-next-line
            for (const param in config.data) {
                if (config.data.hasOwnProperty(param)) {
                    queryParams.push(encodeURIComponent(param) + '=' + encodeURIComponent(config.data[param]));
                }
            }

            config.data = queryParams.join('&');
        }
    }

    if (endpoint[type].headers) {
        config = Object.assign(config, { headers: endpoint[type].headers });
    }

    let newHeaders = config.headers || {};
    if (endpoint[type].headers) {
        newHeaders = config.headers || {};
        newHeaders = Object.assign(newHeaders, endpoint[type].headers);
        config = Object.assign(config, {headers: newHeaders});

    }

    if (headers) {
        newHeaders = config.headers || {};
        newHeaders = Object.assign(newHeaders, headers);
        config = Object.assign(config, {headers: newHeaders});
    }

    if (endpoint[type].isSecure) {
        const authHeader = { 'Authorization': 'Bearer '.concat(accessToken) };
        config = Object.assign(config, { headers: authHeader });

        axios.interceptors.response.use(undefined, error => {
            const originalRequest = error.config;

            if (error.response && error.response.status === 401 && originalRequest && !originalRequest._retry) {
                return getAuthToken().then(response => {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                    originalRequest._retry = true;

                    return axios(originalRequest);
                }).catch(() => {
                    return authProvider.logout().then(() => {
                        window.location.href = '/#/login';

                        return Promise.reject({response: {status: 403, data: {HasValidationErrors: false, ErrorMessage: 'Your session has ended, please reconnect.'}}});
                    });
                });
            }

            throw error;
        });
    }

    if (endpoint[type].hasOwnProperty('options')) {
        config = Object.assign(config, endpoint[type].options);
    }

    if (data.formData) {
        config.data = data.formData;
    }

    const result = axios(config).then(response => {
        let ids = [];

        if (response.data !== null) {
            if (!endpoint[type].returnsRawData) {
                if (typeof response.data.Count !== 'undefined') {
                    response.total = response.data.Count;
                }

                if (typeof response.data.Data !== 'undefined') {
                    response.data = response.data.Data;
                }

                if (typeof response.data.data !== 'undefined') {
                    response.data = response.data.data;
                }

                if (typeof response.data.Id !== 'undefined') {
                    response.data.id = response.data.Id;
                } else if (type !== 'DELETE_MANY') {
                    for (let t = 0; t < response.data.length; t++) {
                        if (response.data[t].Id && inArray(response.data[t].Id, ids)) {
                            response.data.splice(t, 1);
                            t--;
                            if (typeof response.total !== 'undefined') {
                                response.total--;
                            }
                        } else {
                            response.data[t].id = response.data[t].Id ? response.data[t].Id : t;
                            ids.push(response.data[t].Id ? response.data[t].Id : t);
                        }
                    }
                }

                if (typeof response.total === 'undefined') {
                    response.total = response.data.length;
                }
            }
        }

        return response;
    }).catch(error => {
        let response = error.response;

        if (endpoint[type].passErrors) {
            if (!response) {
                response = {
                    status: 500
                };
            } else {
                if(type === 'DELETE' && response.status === 400) {
                    response.data.id = data.id;
                }

                if (typeof response.data.Count !== 'undefined') {
                    response.total = response.data.Count;
                }

                if (typeof response.data.Data !== 'undefined') {
                    response.data = response.data.Data;
                }

                if (typeof response.data.data !== 'undefined') {
                    response.data = response.data.data;
                }
            }

            return response;
        }

        if (response && response.data && response.data.ErrorMessage) {
            return Promise.reject({
                response: {
                    status: response.status,
                    data: {
                        HasValidationErrors: false,
                        ErrorMessage: response.data.ErrorMessage
                    }
                }
            });
        } else {
            throw error;
        }
    });

    return result;
};
