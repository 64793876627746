import {connect} from 'react-redux';
import CancelScheduleStudentButton from '../components/show/CancelScheduleStudentButton';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    CancelScheduleStudentButton
);