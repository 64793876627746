import React, {useEffect, useState} from "react";
import {CardContent, Typography, CardHeader, Grid} from "@material-ui/core";
import {useForm, useFormState} from "react-final-form";
import {CheckboxGroupInput, NumberInput, TextInput, required} from "react-admin";
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';


const Discount = props => {
    const form = useForm();
    const formState = useFormState();

    const [isPercentage, setIsPercentage] = useState(false);

    const classes = props.classes;

    useEffect(() => {
        form.change('DiscountType', []);
    }, []); // eslint-disable-line

    const onDiscountChange = (event) => {
        form.change('DiscountValue', parseFloat(event.target.value));
    }

    useEffect(() => {
        if (
            formState.values.DiscountValue &&
            !isNaN(parseFloat(formState.values.DiscountValue)) &&
            (parseFloat(formState.values.DiscountValue) > 0)
        ) {
            let newTotal = formState.values.SubTotal - formState.values.DiscountValue;
            if (isPercentage) {
                newTotal = formState.values.SubTotal - ((formState.values.SubTotal * formState.values.DiscountValue) / 100);
            }

            form.change('hasDiscount', true);
            form.change('Total', newTotal);
        } else {
            form.change('hasDiscount', false);
            form.change('Total', parseFloat(formState.values.SubTotal));
        }
    }, [formState.values.SubTotal, isPercentage, formState.values.DiscountValue]); // eslint-disable-line


    const onDiscountTypeChange = data => {
        let newIsPercentage = false;
        if (data.length > 0) {
            newIsPercentage = true;
        }

        setIsPercentage(newIsPercentage);
    }

    return (
        <>
            <CardContent style={{position: 'relative', paddingLeft: 15, paddingRight: 15, paddingBottom: 15}}>
                <CardHeader titleTypographyProps={{variant: "body1"}} title="Discount" style={{padding: 0}} />
                <Grid container style={{paddingRight: 0}}>
                    <Grid item xs={10}>
                        <CheckboxGroupInput
                            label=''
                            className='checkboxPercentage'
                            source='DiscountType'
                            translateChoice={false}
                            choices={[{id: 'DiscountType', name: 'Percentage'}]}
                            onChange={onDiscountTypeChange}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {isPercentage ?
                            <Input 
                                type='number'  
                                label='Standard'
                                onChange={onDiscountChange}    
                                inputProps={{
                                    min: 0,
                                    step: 0.01
                                }}
                                endAdornment={ 
                                    <InputAdornment position='end'>%</InputAdornment>
                                }
                            />

                            :
                                <Input 
                                    type='number' 
                                    label={isPercentage ? 'Percents' : 'Amount'} 
                                    onChange={onDiscountChange}        
                                    inputProps={{
                                        min: 0,
                                        step: 0.01
                                    }}
                                    startAdornment={                                       
                                        <InputAdornment position='start'>$</InputAdornment>
                                    }
                                />
                        }
                    </Grid>
                </Grid>

                <TextInput
                    style={{margin: 0, marginTop: -35}}
                    source='DiscountNotes'
                    validate={formState.values.hasDiscount ? required('Discount Notes field is required.') : []}
                    fullWidth
                    error={
                        formState.touched.DiscountNotes &&
                        formState.values.hasDiscount &&
                        !formState.values.DiscountNotes
                    }
                    helperText={
                        formState.touched.DiscountNotes && formState.values.hasDiscount && !formState.values.DiscountNotes ?
                            'Discount Notes field is required.' :
                            ''
                    }
                    value={formState.values.DiscountNotes}
                />

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    {
                        formState.values.hasDiscount ?
                            (
                                <Typography
                                    variant='subtitle1'
                                    className={classes.selectionTitle}
                                >
                                    {
                                        'Discount: -$' +
                                        (
                                            (formState.values.SubTotal - formState.values.Total) > 0 ?
                                            (formState.values.SubTotal - formState.values.Total).toFixed(2) : '0.00'
                                        )
                                    }
                                </Typography>
                            )
                        : null
                    }

                    <Typography
                        variant='subtitle1'
                        className={classes.selectionTitle}
                    >
                        {
                            'Total: $' + (formState.values.Total ? formState.values.Total.toFixed(2) : '0.00')
                        }
                    </Typography>
                </div>
            </CardContent>
        </>
    );
}

export default Discount;
