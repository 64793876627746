import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const ScheduleActionButtons = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
         <Button
            size='small'
            variant='contained'
            color='primary'
            aria-controls='simple-menu'
            aria-haspopup='true'
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleClick}
            style={{marginRight: 10}}
        >
            Actions
        </Button>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {
                props.children.map((child, idx) => (
                    <MenuItem key={idx} onClick={handleClose}>
                        {child}
                    </MenuItem>
                ))
            }
        </Menu>
    </>
    );
}

export default ScheduleActionButtons;
