import React, {useState, useEffect} from 'react';
import {
    Filter,
    GET_LIST,
    TextInput,
    fetchEnd,
    fetchStart,
} from 'react-admin';
import SelectInput from "../../../../components/SelectInput";
import {arraySortByKey, fetchIt} from "../../../../helpers";
import {useDispatch} from 'react-redux';
import BooleanFilterInput from "../../../../components/BooleanFilterInput";
import {choices, RegistrationViewChoices} from "../../../../config/constants";


export const SemesterListFilters = props => {
    const dispatch = useDispatch();
    const [schoolYears, setSchoolYears] = useState([]);


    useEffect(() => {
        dispatch(fetchStart());

        const sort = {field: "Name", order: "DESC"};

        fetchIt(
            GET_LIST,
            'school_years',
            {sort: JSON.stringify(sort)}
        ).then(response => {
            let years = [];

            for (let t = 0; t < response.data.length; t++) {
                years.push({id: response.data[t].Name, name: response.data[t].Name});
            }

            years = arraySortByKey(years, 'id', false);

            setSchoolYears(years);
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            dispatch(fetchEnd());
        });
    }, []);  // eslint-disable-line


    return (
        <Filter {...props}>
            <TextInput label='Search by Semester name' source='search' alwaysOn style={{width: 300}}/>
            <SelectInput
                source='SchoolYear'
                label='School Year'
                choices={schoolYears}
                emptyValue={null}
                emptyText="All"
            />
            <SelectInput
                source='RegistrationView'
                label='Registration View'
                choices={RegistrationViewChoices}
                emptyValue={null}
                emptyText="All"
            />
           
            <BooleanFilterInput source='IsActive' label='Status' choices={choices} defaultValue={true} />
        </Filter>
    );
};
