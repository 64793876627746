import React, {useEffect, useState} from 'react';
import {Card} from '@material-ui/core';
import {Title} from 'react-admin';
import {CardContent} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LocationContactForm from '../containers/LocationContactForm';
import LocationAddressForm from '../containers/LocationAddressForm';
import LocationWorkTimeForm from '../containers/LocationWorkTimeForm';



const LocationForms = props => {

    const [value, setValue] = useState(0);
    const [title, setTitle] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    useEffect(() => {
        if (props.editMode) {
            setValue(1);
        }
    }, []); // eslint-disable-line

    return (
        <Card className="cardForm">
            <CardContent>
                <Title title={title} />
                <Tabs
                    value={value}
                    onChange={handleChange}
                    scrollButtons="off"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Location Contact details"  value={0}/>

                    {
                        props.editMode ?
                            <Tab label="Location Address" value={1}/>                               
                        :                                
                            null
                    }

                    {
                        props.editMode ?
                            <Tab label="Location Working Time" value={2}/>                               
                        :                                
                            null
                    }

                </Tabs>
                    {
                        value === 0 &&               
                        <LocationContactForm locationTitle={setTitle} {...props} />
                    }


                    {
                        value === 1 && props.editMode ?
                        <LocationAddressForm {...props} />
                        
                    : null
                        
                    }

                    {
                        value === 2 &&
                        <LocationWorkTimeForm {...props} />
                    }
            </CardContent>
        </Card>
    );
}

export default LocationForms;

