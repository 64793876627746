import React, {useState} from 'react';
import {
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    fetchStart,
    UPDATE,
    showNotification,
    fetchEnd,
    Title
} from 'react-admin';
import {NtaSaveButton} from "../../common";
import {Card, CardContent} from "@material-ui/core";
import {newPasswordValidation, passwordConfirmationValidation} from '../../../validators';
import {fetchIt, getErrorMessage} from "../../../helpers";
import {useDispatch} from 'react-redux';


export const ChangePasswordFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};


const ChangePassword = props => {
    const dispatch = useDispatch();

    const [isDisabled, setIsDisabled] = useState(false);

    const onSubmit = values => {
        dispatch(fetchStart());
        setIsDisabled(true);


        fetchIt(
            UPDATE,
            'change_password',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Password changed.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    dispatch(showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    ));

                    break;

                case 400:
                    let errorMessage = 'There was a problem.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    ));

                    break;

                default:
                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    ));
            }
        }).catch(() => {

        }).then(() => {
            dispatch(fetchEnd());

            setIsDisabled(false);
        });
    }

    return (
        <>
            <Card className="cardForm" style={{marginTop: 40}}>
                <CardContent>
                    <Title title='Change Password' />
                    <SimpleForm
                        save={onSubmit}
                        toolbar={<ChangePasswordFormToolbar isDisabled={isDisabled} />}
                    >
                        <TextInput
                            source='OldPassword'
                            type='password'
                            validate={required('OldPassword field is required')}
                        />
                        <TextInput
                            source='NewPassword'
                            type='password'
                            validate={newPasswordValidation}
                        />
                        <TextInput
                            label='Repeat New Password'
                            source='PasswordConfirmation'
                            type='password'
                            validate={passwordConfirmationValidation}
                        />
                    </SimpleForm>
                </CardContent>
            </Card>
        </>
    );
}

export default ChangePassword;
