import React from 'react';
import {
    ReferenceArrayInput,
    ReferenceInput,
    FormDataConsumer,
    required
} from 'react-admin';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelectInput from './NativeSelectInput';
import {Grid} from "@material-ui/core";


export const CopyScheduleFormView = ({basePath, ...props}) => {

    return (
        <Grid container spacing={2} style={{width: '100%', paddingBottom: 20, paddingLeft:0, paddingRight: 0}} {...props}>
            <Grid item xs={6}>
                <InputLabel style={{paddingBottom: 5}}>Source Semester*</InputLabel>
                <FormDataConsumer>
                    {
                        ({formData}) =>
                            <ReferenceInput
                                source='SourceSemesterId'
                                resource='semesters'
                                reference='semesters'
                                label='Source Semester'
                                validate={required('The Source Semester field is required')}
                                filter={{ExcludedID: formData.TargetSemesterIds}}
                            >
                                <NativeSelectInput />                  
                            </ReferenceInput>
                    }
                </FormDataConsumer>
            </Grid>

            <Grid item xs={6}>
                <InputLabel style={{paddingBottom: 5}}>Target Semesters*</InputLabel>
                <FormDataConsumer>
                    {
                        ({formData}) =>
                            <ReferenceArrayInput
                                source='TargetSemesterIds'
                                resource='semesters'
                                reference='semesters'
                                label='Target Semesters' 
                                filter={{ExcludedID: formData.SourceSemesterId}}
                                validate={required('The Target Semesters field is required')}
                            >
                                <NativeSelectInput />
                            </ReferenceArrayInput>
                    }
                </FormDataConsumer>
            </Grid>  
        </Grid>
    );
};



