import React from "react";
import {Typography} from "@material-ui/core";
import classnames from 'classnames';
import {useFormState} from "react-final-form";


const SelectedPayee = props => {
    const formState = useFormState();
    const classes = props.classes;

    return (
        <>
            <Typography variant='subtitle1' className={classnames(classes.selectionTitle, classes.selectionSection)}>
                Parent / Payee
            </Typography>
            {
                formState.values.selectedPayee ?
                    (
                        <Typography variant='body1'>
                            {formState.values.selectedPayee.Info}
                        </Typography>
                    )
                :
                    (
                        <Typography variant='body1' className={classes.selectionTitle}>
                            None
                        </Typography>
                    )
            }
        </>
    );
}

export default SelectedPayee;
