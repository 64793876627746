import React, {Fragment, useState, useEffect} from 'react';
import {useListContext} from 'react-admin';
import moment from 'moment';
import {DatePicker} from '../common/CustomValidators/DatePicker';


const DatesFilterInput = props => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const filterName = props.source ? props.source : 'advanced';
    const {setFilters, filterValues} = useListContext();

    const onStartDateChange = (event) => {
        setStartDate(moment.utc(event).format('YYYY-MM-DD'));
        setEndDate(moment.utc(event).add(1, 'month').format('YYYY-MM-DD'));
    }

    const onEndDateChange = (event) => {
        setEndDate(moment(event).format('YYYY-MM-DD'));
    }

    useEffect(() => {       
        let filters = filterValues;
        filters = {...filters, "EnrollmentDate":{"FromDate": startDate, "ToDate": endDate}};
        setFilters(filters);
    }, [startDate, endDate]);
    
    return (
        <Fragment>
            <DatePicker 
                onChange={onStartDateChange} 
                label="From Date" 
                source={filterName + ".FromDate"} 
                disableFuture={false}
                val={moment(startDate, 'YYYY-MM-DD')} 
                maxDate={endDate !== '' ? moment(endDate) : moment().add(10, 'years')}
                noPadding
            />
            &nbsp;&nbsp;
            <DatePicker 
                onChange={onEndDateChange} 
                label="To Date" 
                source={filterName + ".ToDate"} 
                disableFuture={false}
                val={moment(endDate, 'YYYY-MM-DD')}
                minDate={moment(startDate)}
                disabled={startDate === '' ? true : false}
                noPadding
            />
        </Fragment>
    );
};

export default DatesFilterInput;
