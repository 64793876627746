import React, {useEffect, useState} from 'react';
import {
    FormWithRedirect,
    GET_LIST,
    CREATE,
    GET_ONE,
} from 'react-admin';
import {arraySortByKey, fetchIt, getErrorMessage, getWeekdays, inArray} from '../../../../../helpers';
import {Redirect} from 'react-router';
import PropTypes from 'prop-types';
import RegistrationFormView from '../../containers/RegistrationFormView';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import {SchedulesListFilters} from "../lists/SchedulesListFilters";
import {Card, CardContent, Button, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";


const RegistrationForm = props => {
    let weekdaysArray = getWeekdays();

    let sMinTime = '05:00:00';
    let sMaxTime = '22:00:00';
    let initialWeekDays = [moment.utc().format('dddd')];


    const [errors, setErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [isDisabled, setIsDisabled] = useState(true);
    const [filters, setFilters] = useState({IsActive: true, DaysOfWeek: [parseInt(moment(initialWeekDays[0], 'dddd').format('d'))]});
    const [schoolYears, setSchoolYears] = useState([]);
    // eslint-disable-next-line
    const [currentSchoolYear, setCurrentSchoolYear] = useState();
    const [semesters, setSemesters] = useState([]);
    const [currentSemester, setCurrentSemester] = useState('0');
    const [selectedSemester, setSelectedSemester] = useState({});
    const [selectedSemesterChange, setSelectedSemesterChange] = useState({});
    const [schedulerMinTime, setSchedulerMinTime] = useState(sMinTime);
    const [schedulerMaxTime, setSchedulerMaxTime] = useState(sMaxTime);
    const [studios, setStudios] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [weekdays, setWeekdays] = useState(initialWeekDays);
    const [workDays, setWorkDays] = useState([initialWeekDays[0]]);
    const [resetForm, setResetForm] = useState(false);
    const [ages, setAges] = useState([0, 18.5]);
    const [prices, setPrices] = useState([0, 1000]);
    const [selectedDate, setSelectedDate] = useState(false);
    const [shouldSetDate, setShouldSetDate] = useState(false);


    const {fetchStart, fetchEnd, showNotification} = props;


    useEffect(() => {
        fetchSchoolYears(false);
    }, []); // eslint-disable-line


    const fetchSchoolYears = (withSemesters = true) => {
        fetchStart();

        const sort = {field: "Id", order: "DESC"};

        fetchIt(
            GET_LIST,
            'school_years',
            {sort: JSON.stringify(sort)}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let schoolYears = [];
                    let schoolYear = currentSchoolYear;

                    for (let t = 0; t < response.data.length; t++) {
                        schoolYears.push({id: response.data[t].Name, name: response.data[t].Name});
                        if (response.data[t].CurrentYear) {
                            schoolYear = response.data[t].Name;
                        }
                    }

                    setCurrentSchoolYear(schoolYear);
                    setSchoolYears(arraySortByKey(schoolYears, 'id', false));

                    if (withSemesters) {
                        fetchSemesters({"SchoolYear": schoolYear, 'DaysOfWeek': [parseInt(moment().format('d'))]});
                    }

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {}).then(() => {
            fetchEnd();
        });
    };


    const fetchSemesters = filters => {
        if (filters.SchoolYear) {
            fetchStart();

            fetchIt(
                GET_LIST,
                'semesters',
                {filter: filters}
            ).then(response => {
                switch (response.status) {
                    case 200:
                        let newSemesters = [];
                        let newCurrentSemester = '';

                        for (let t = 0; t < response.data.length; t++) {
                            if (response.data[t].IsCurrent) {
                                newCurrentSemester = response.data[t].Id + '';
                            }

                            if (response.data[t].IsActive) {
                                newSemesters.push({
                                    ...response.data[t],
                                    id: response.data[t].Id + '',
                                    name: response.data[t].Name
                                });
                            }
                        }

                        if (!newCurrentSemester.length) {
                            newCurrentSemester = newSemesters[0].id + '';
                        }

                        const newSelectedSemester = response.data.find(semester => parseInt(semester.id) === parseInt(newCurrentSemester));

                        setCurrentSemester(newCurrentSemester);
                        setSelectedSemester(newSelectedSemester);
                        setSemesters(newSemesters);

                        if (parseInt(newCurrentSemester)){
                            filters.Semester = parseInt(newCurrentSemester);
                        }

                        fetchTheData(filters);

                        break;

                    case 404:
                        let errorMessage = '';

                        if (!response.data.HasValidationErrors) {
                            errorMessage = response.data.ErrorMessage;
                        }

                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: errorMessage } }
                        );

                        break;

                    default:
                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: 'Oops, something went wrong!' } }
                        );
                }
            }).catch(() => {}).then(() => {
                fetchEnd();
            });
        }
    };


    const fetchTheData = (filter, newSemester) => {
        if (newSemester && parseInt(newSemester.RegistrationView) === 2) {
            return;
        }

        fetchStart();

        if (!filter) {
            filter = filters;
        } else {
            let stateFilters = Object.assign({}, filters);
            filter = Object.assign(stateFilters, filter);
        }

        setIsLoading(true);
        setFilters(filter);
        setWeekdays(filter.DaysOfWeek.map(dow => moment(dow, 'd').format('dddd')));

        fetchIt(
            GET_ONE,
            'common_settings',
            {filter: {Semester: filter.Semester}}
        ).then(response => {
            switch (response.status) {
                case 200:
                    fetchStart();

                    const earliestHour = response.data.EarliestHour;
                    const latestHour = response.data.LatestHour;

                    setAges([parseFloat(response.data.MinAge), parseFloat(response.data.MaxAge)]);
                    setPrices([parseFloat(response.data.MinPrice), parseFloat(response.data.MaxPrice)]);

                    let studios = [];

                    const locations = response.data.Locations;
                    let realWorkDays = [];
                    for (let l = 0; l < locations.length; l++) {
                        const location = locations[l];
                        for (let s = 0; s < location.Studios.length; s++) {
                            const studio = location.Studios[s];
                            const studioColor = studio.DisplayColor;
                            if (studioColor === '#FAFAFA') {
                                studio.DisplayColor = '#666666';
                            }

                            for (let w = 0; w < location.WorkDays.length; w++) {
                                if (!inArray(location.WorkDays[w].WorkDay, realWorkDays)) {
                                    realWorkDays.push(location.WorkDays[w].WorkDay);
                                }
                            }

                            studios.push(
                                {
                                    id: studio.Id,
                                    title: studio.Name,
                                    scheduleColor: studio.DisplayColor,
                                    color: studioColor,
                                    location: location.Name,
                                    workDays: location.WorkDays
                                }
                            )
                        }
                    }


                    let newWorkDays = [];
                    const wda = getWeekdays();
                    for (let w = 0; w < 7; w++) {
                        const day = wda[w];
                        if (inArray(day, realWorkDays)) {
                            newWorkDays.push(day);
                        }
                    }

                    setWorkDays(newWorkDays);

                    let schedules = {};
                    for (let weekday of weekdays) {
                        schedules[weekday] = {};

                        for (let studio of studios) {
                            schedules[weekday][studio.id] = [];
                        }
                    }


                    fetchIt(
                        GET_LIST,
                        'registrations_schedules',
                        {filter}
                    ).then(response => {
                        switch (response.status) {
                            case 200:
                                let myData = response.data;
                                let schMinTime = moment.utc(earliestHour, "HH:mm:ss");
                                let schMaxTime = moment.utc(latestHour, "HH:mm:ss");

                                for (let t = 0; t < myData.length; t++) {
                                    if (
                                        myData[t] &&
                                        myData[t].Id
                                    ) {
                                        if (typeof schedules[myData[t].DayOfWeek] === 'undefined') {
                                            schedules[myData[t].DayOfWeek] = {};
                                        }

                                        if (typeof schedules[myData[t].DayOfWeek][myData[t].Studio] === 'undefined') {
                                            schedules[myData[t].DayOfWeek][myData[t].Studio] = [];
                                        }

                                        if (!myData[t].IsOutsideWorkingHours) {
                                            const start = moment.utc(myData[t].StartHour, "H").add(myData[t].StartMinutes, 'minutes');
                                            const end = moment.utc(start.format('H:mm'), 'H:mm').add(myData[t].Duration, 'minutes');

                                            myData[t].top = 0;
                                            myData[t].range = moment.range(start, end);

                                            schedules[myData[t].DayOfWeek][myData[t].Studio].push(myData[t]);
                                        }
                                    }
                                }


                                setStudios(studios);
                                setSchedules(schedules);
                                setSchedulerMinTime(schMinTime.format('HH:mm:ss'));
                                setSchedulerMaxTime(schMaxTime.format('HH:mm:ss'));
                                setIsLoading(false);

                                break;

                            default:
                                showNotification(
                                    'direct_message',
                                    'warning',
                                    { messageArgs: { _: 'Oops, something went wrong!' } }
                                );
                        }
                    }).catch(error => {

                    }).then(() => {
                        // Dispatch an action letting react-admin know an API call has ended
                        fetchEnd();
                    });

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {}).then(() => {
            fetchEnd();
        });
    };


    const onSubmit = values => {
        const {fetchStart, fetchEnd, showNotification} = props;

        setIsDisabled(true);

        if (!values.selectedFamilyMember) {
            const message = 'The Student field is required.';

            showNotification(
                'direct_message',
                'warning',
                {messageArgs: {_: message}}
            );

            return false;
        }

        if (!values.hasSchedules) {
            const message = 'You should choose some schedules.';

            showNotification(
                'direct_message',
                'warning',
                {messageArgs: {_: message}}
            );

            return false;
        }

        if (values.hasDiscount && !values.DiscountNotes) {
            const message = 'Discount Notes field is required.';

            showNotification(
                'direct_message',
                'warning',
                {messageArgs: {_: message}}
            );

            return false;
        }

        let data = {
            PayeeId: values.selectedPayee.Id,
            PayeeEmail: values.selectedPayee.Email,
            PaymentMethod: 1,
            Notes: values.Notes,
            DiscountIsFlat: false,
            DiscountValue: 0,
            DiscountNotes: '',
            ScheduleStudents: []
        };

        if (values.hasDiscount) {
            const discount = {
                DiscountIsFlat: (values.DiscountType.length === 0),
                DiscountValue: values.DiscountValue,
                DiscountNotes: values.DiscountNotes,
            };

            data = Object.assign(data, discount);
        }

        let scheduleStudents = [];
        const formSchedules = values.Schedules;

        const studentName = values.selectedFamilyMemberInfo.FirstName + ' ' + values.selectedFamilyMemberInfo.LastName;

        for (const prop in formSchedules) {
            if (formSchedules.hasOwnProperty(prop)) {
                const schedule = formSchedules[prop];
                const scheduleStudent = {
                    StudentId: values.selectedFamilyMember,
                    StudentName: studentName,
                    ScheduleId: schedule.Schedule,
                    ScheduleName: (typeof schedule.Course === 'number') ? schedule.Name : [schedule.Course, schedule.DaysOfWeek, schedule.Semester].join(', '),
                    Price: parseFloat(schedule.PriceForRemainingClasses),
                    ScheduleDays: (
                        schedule.schedule_day_works &&
                        (schedule.schedule_day_works.length > 0) ?
                            schedule.schedule_day_works :
                            null
                    )
                };

                scheduleStudents.push(scheduleStudent);
            }
        }
        data.ScheduleStudents = scheduleStudents;

        fetchStart();

        fetchIt(
            CREATE,
            'registrations_submit',
            data
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Registration submited.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    fetchTheData();

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    )

                    setResetForm(true);

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {}).then(() => {
            fetchEnd();

            setIsDisabled(false);
        });

        return false;
    };

    return (
        <>
            <SchedulesListFilters
                schoolYears={schoolYears}
                schoolYear={currentSchoolYear}
                semesters={semesters}
                currentSemester={currentSemester}
                setCurrentSemester={setCurrentSemester}
                selectedSemester={selectedSemester}
                setSelectedSemester={setSelectedSemester}
                selectedSemesterChange={selectedSemesterChange}
                setSelectedSemesterChange={setSelectedSemesterChange}
                fetchTheData={fetchTheData}
                fetchSemesters={fetchSemesters}
                weekdaysArray={weekdaysArray}
                weekdays={weekdays}
                workDays={workDays}
                prices={prices}
                ages={ages}
                setShouldSetDate={setShouldSetDate}
            />

            {
                selectedSemester && !selectedSemester.IsEmpty ?
                    <FormWithRedirect
                        {...props}
                        record={{}}
                        resource='registrations_schedules'
                        mutators={{...arrayMutators}}
                        render={formProps => (
                            <RegistrationFormView
                                {...formProps}
                                onSubmit={onSubmit}
                                schoolYears={schoolYears}
                                errors={errors}
                                setErrors={setErrors}
                                weekdays={weekdays}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                minTime={schedulerMinTime}
                                maxTime={schedulerMaxTime}
                                studios={studios}
                                schedules={schedules}
                                layoutColor='#FCFCFE'
                                slotDuration={15} // This can be 5, 10, 15 or 30 minutes
                                filters={filters}
                                semesters={semesters}
                                currentSemester={currentSemester}
                                selectedSemester={selectedSemester}
                                fetchTheData={fetchTheData}
                                fetchSemesters={fetchSemesters}
                                layoutWidth={props.width}
                                resetForm={resetForm}
                                setResetForm={setResetForm}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                shouldSetDate={shouldSetDate}
                                setShouldSetDate={setShouldSetDate}
                            />
                        )}
                    />
                :
                    <Card style={{marginTop: 20, paddingTop: 20, paddingBottom: 20}}>
                        <CardContent style={{textAlign: 'center'}}>
                            <Typography variant="h6" style={{paddingBottom: 16, paddingLeft: 16}}>
                                Create and publish schedule to view and register
                            </Typography>
                            <Link to='/schedules' style={{textDecoration: 'none', display: 'block', textAlign: 'center'}}>
                                <Button
                                    variant="text"
                                    color="primary"
                                >
                                    <Typography variant="body2">
                                        Create schedule
                                    </Typography>
                                </Button>
                            </Link>
                        </CardContent>
                    </Card>
                }
        </>
    );
}

RegistrationForm.propTypes = {
    record: PropTypes.object.isRequired
};

RegistrationForm.defaultProps = {
    record: {}
};

export default RegistrationForm;
