import React, {Component} from 'react';
import {AutocompleteInput} from 'react-admin';


const defaultOptionRenderer = choice => {
    return choice ? `${choice.CourseName} ${choice.DayTimes}` : '';
};


class AdagioAutoComplete extends Component {
    _isMounted = false;
    _id = '';
    _hasChanged = true;

    constructor (props) {
        super(props);

        this.state = {
            choices: [],
            currentChoiceText: ''
        };

        this.onChange = this.onChange.bind(this);
    }


    componentDidMount () {
        this._isMounted = true;
        this._hasChanged = true;
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    componentWillReceiveProps(nextProps) {
        if (this._id !== nextProps.id) {
            if (typeof nextProps.myVal !== 'undefined') {
                this._hasChanged = false;
            }
            this._id = nextProps.id;
        }

        if (this.props.isSchedule && !this._hasChanged) {
            delete nextProps.choices[0];
            document.getElementsByName(this.props.source)[0].value = '';
        }

        if (this._isMounted) {
            this.setState({
                choices: nextProps.choices
            });
        }
    }


    onChange = value => {
        this._hasChanged = true;

        const choice = this.state.choices.find(obj => obj.Id === value);
        if (this._isMounted && choice) {
            this.setState({
                currentChoiceText: choice.FirstName + ' ' + choice.LastName
            });
        }

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    inputText = record => {
        return record ? record.Info : '';
    }

    render () {
        let optionRenderer = this.props.optionRenderer;
        if (!optionRenderer) {
            optionRenderer = defaultOptionRenderer;
        }

        let matchSuggestion = this.props.matchSuggestion;
        if (!matchSuggestion) {
            matchSuggestion = (filter, choice) => (choice.name.toLowerCase().search(filter.toLowerCase()) > -1);
        }

        if (this.props.renderOptionsAsComponent) {
            return (
                <AutocompleteInput
                    isRequired={this.props.noRequired ? false : true}
                    label={this.props.label}
                    optionText={optionRenderer}
                    inputText={this.inputText}
                    matchSuggestion={matchSuggestion}
                    onChange={this.onChange}
                    choices={this.state.choices}
                    source={this.props.source}
                    className={this.props.fullwidth ? 'scheduleAutocompleteFullwidth' : 'scheduleAutocomplete'}
                    // validate={this.props.validate}
                    options={{suggestionsContainerProps: {style: {zIndex: 2000}, transition: true}}}
                    translateChoice={false}
                    shouldRenderSuggestions={val => (val.trim().length > 1)}
                    suggestionLimit={30}
                    setFilter={this.props.setFilter}
                    helperText={this.props.helperText || ''}
                />
            );
        }

        return (
            <AutocompleteInput
                isRequired={this.props.noRequired ? false : true}
                optionText={optionRenderer}
                onChange={this.onChange}
                choices={this.state.choices}
                source={this.props.source}
                className={this.props.fullwidth ? 'scheduleAutocompleteFullwidth' : 'scheduleAutocomplete'}
                // validate={this.props.validate}
                options={{suggestionsContainerProps: {style: {zIndex: 2000}, transition: true}}}
                translateChoice={false}
                shouldRenderSuggestions={val => (val.trim().length > 1)}
                suggestionLimit={30}
            />
        );
    }
}

export default AdagioAutoComplete;
