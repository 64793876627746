import React, {useState, useEffect} from 'react';
import {
    Filter,
    TextInput,
    fetchStart,
    GET_LIST,
    fetchEnd
} from 'react-admin';
import SelectInput from "../../../../../components/SelectInput";
import {
    registrationTypeChoices, 
    registrationStatusChoices, 
    paymentStatusChoices,
    registrationClassTypeChice
} from "../../../../../config/constants";
import {arraySortByKey, fetchIt} from "../../../../../helpers";
import {useDispatch} from 'react-redux';
import DatesFilterInput from '../../../../../components/DatesFilterInput';


export const RegistrationsListFilters = props => {
    const dispatch = useDispatch();
    const [semesters, setSemesters] = useState([]);
    const [courses, setCourses] = useState([]);


    useEffect(() => {
        dispatch(fetchStart());

        const sort = {field: "Name", order: "DESC"};

        fetchIt(
            GET_LIST,
            'semesters',
            {sort: JSON.stringify(sort), filter: {"IsActive": true}}
        ).then(response => {
            let semesters = [];

            for (let t = 0; t < response.data.length; t++) {
                semesters.push({id: response.data[t].Id, name: response.data[t].Name});
            }

            semesters = arraySortByKey(semesters, 'id', false);

            setSemesters(semesters);
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            dispatch(fetchEnd());
        });


        fetchIt(
            GET_LIST,
            'courses',
            {sort: JSON.stringify(sort), filter: {"IsActive": true}}
        ).then(response => {
            let courses = [];

            for (let t = 0; t < response.data.length; t++) {
                courses.push({id: response.data[t].Id, name: response.data[t].Name});
            }

            courses = arraySortByKey(courses, 'id', false);

            setCourses(courses);
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            dispatch(fetchEnd());
        });
    }, []);  // eslint-disable-line



    return(
        <Filter {...props}>
            <TextInput label='Search by Id, Payee and Email' source='search' />
            <SelectInput
                source='PaymentReceived'
                label='Payment status'
                choices={paymentStatusChoices}
                optionText='paymentStatusName'
                allowEmpty
                emptyValue={null}
                emptyText="All"
            />
            <SelectInput
                source='IsPartial'
                label='Type'
                choices={registrationTypeChoices}
                optionText='typeName'
                allowEmpty
                emptyValue={null}
                emptyText="All"
            />
            <SelectInput
                source='Semester'
                label='Semester'
                choices={semesters}
                emptyValue={null}
                emptyText="All"
            />
            <SelectInput
                source='Course'
                label='Course'
                choices={courses}
                emptyValue={null}
                emptyText="All"
            />
            <SelectInput
                source='IsActive'
                label='Status'
                choices={registrationStatusChoices}
                defaultValue={true}
                optionText='statusName'
                allowEmpty={false}
            />
            <SelectInput
                source='HasSemester'
                label='Class Type'
                choices={registrationClassTypeChice}
                optionText='classTypeName'
                allowEmpty={false}
            />
            <DatesFilterInput source='EnrollmentDate' label='Date'/>
        </Filter>
    );
};
