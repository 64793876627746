import React, {useEffect, useState} from 'react';
import {
    Button,
    GET_ONE,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    fetchEnd
} from 'react-admin';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import {NtaSaveButton} from '../../common';
import {fetchIt, getWeekdays, inArray} from '../../../helpers';
import {ScheduleTimePicker} from '../../../common';
import {useForm} from "react-final-form";
import {Grid} from "@material-ui/core";


const FormActions = props => {
    const {basePath, onCloseClick, isDisabled, showNotification, ...rest} = props;

    return (
        <DialogActions {...rest} className="EmailChangeButtons">
            <NtaSaveButton label="Save"  showNotification={showNotification} isDisabled={isDisabled} />
            <Button label="Cancel" onClick={onCloseClick}>
                <IconCancel />
            </Button>
        </DialogActions>
    );
};


const EditScheduleFormView = props => {
    const [workDays, setWorkDays] = useState([]);
    const form = useForm();

    useEffect(() => {
        form.change('Schedule', parseInt(props.scheduleToEdit.parentSchedule));

        fetchIt(
            GET_ONE,
            'common_settings',
            {}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let realWorkDays = [];
                    const locations = response.data.Locations;
                    for (let l = 0; l < locations.length; l++) {
                        const location = locations[l];
                        for (let s = 0; s < location.Studios.length; s++) {
                            for (let w = 0; w < location.WorkDays.length; w++) {
                                if (!inArray(location.WorkDays[w].WorkDay, realWorkDays)) {
                                    realWorkDays.push(location.WorkDays[w].WorkDay);
                                }
                            }
                        }

                        const newWorkDays = getWeekdays().map(weekDay => {
                            if (inArray(weekDay, realWorkDays)){
                                return {id: weekDay, name: weekDay};
                            }

                            return {id: weekDay, name: weekDay, disabled: true};
                        });


                        setWorkDays(newWorkDays);
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {}).then(() => {
            fetchEnd();
        });
    }, []); // eslint-disable-line


    const onStudioChange = event => {
        event.persist();

        form.change('Studio', event.target.value);
    }


    const onStartTimeChange = data => {
        form.change('StartTime', data);
    }


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <SelectInput
                        required
                        source='DayOfWeek'
                        choices={workDays}
                        translateChoice={false}
                        label='Day Of Week'
                        className='fieldsWidthExpand'
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <ScheduleTimePicker
                        isRequired
                        label='Start Time *'
                        source="StartTime"
                        useInitialWidth={true}
                        onChange={onStartTimeChange}
                        className='fieldsWidthExpand'
                        editShedule
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <NumberInput
                        label='Duration'
                        source='Duration'
                        inputProps={{
                            min: 0
                        }}
                        format={v => parseInt(v) >= 0 ? parseInt(v) : ' '}
                        //parse={v => parseInt(v)}
                        validate={required('The Duration field is required')}
                        className='fieldsWidthExpand'
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <ReferenceInput
                        required
                        label='Studio'
                        source='Studio'
                        resource='studios'
                        reference='studios'
                        filter={{IsActive: true}}
                        perPage={1000}
                        onChange={onStudioChange}
                        className='fieldsWidthExpand'
                        fullWidth
                    >
                        <SelectInput
                            optionText='Name'
                            style={{width: '100%'}}
                            fullWidth
                        />
                    </ReferenceInput>
                </Grid>
            </Grid>
            <FormActions
                style={{backgroundColor: '#f5f5f5', justifyContent: 'flex-start' }}
                onCloseClick={props.handleCloseClick}
            />

        </>
    );
}

export default EditScheduleFormView;
