import React, {Component} from 'react';
import SendIcon from '@material-ui/icons/Send';
import {fetchIt, getErrorMessage} from "../../../../helpers";
import {Button} from "@material-ui/core";


class ResetPasswordButton extends Component {
    _isMounted = false;

    state = {
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSendClick = () => {
        const {showNotification} = this.props;
        this.props.fetchStart();

        fetchIt(
            "RESET_PASSWORD",
            'tenant_users',
            {id: this.props.record.Id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Email is sent.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:
                    let errorMessage = 'There was a problem with sending reset password email.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            this.props.fetchEnd();
        });
    };

    render() {
        return (
            <Button
                variant="contained"
                onClick={this.handleSendClick}
                color="primary"
                disabled={this.state.isDisabled}
                style={{marginTop: 5}}
            >
                <SendIcon style={{fontSize: 20, marginRight: 5}} /> Reset Password
            </Button>
        );
    }
}

export default ResetPasswordButton;
