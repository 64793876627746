import React, {Component, Fragment} from 'react';
import {
    UPDATE,
    required,
    TextInput,
    Toolbar,
    FormDataConsumer,
    TabbedForm,
    FormTab,
    GET_LIST,
    SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {NtaSaveButton} from '../../common';
import {fetchIt, getErrorMessage, formatPhone} from "../../../helpers";
import {Redirect} from 'react-router';
import {emailValidation} from '../../../validators';
import {PhoneMaskedInput, ZipCodeMaskedInput, UsStates} from '../../../common';
import {phoneValidation, zipCodeValidation} from '../../../validators';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import {TimeZones} from "../../../config/constants";


export const SchoolEditToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};


export const SchoolTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};


export const AddressHeading = () => (
    <h2>
        Address
    </h2>
);


class SchoolEditForm extends Component {
    _isMounted = false;


    state = {
        error: false,
        redirectTo: null,
        value: 1
    };


    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        if (values.Phone) {
            values.Phone = values.Phone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }
        if (values.AdditionalPhone) {
            values.AdditionalPhone = values.AdditionalPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        values.id = this.props.id;

        fetchIt(
            UPDATE,
            'schools',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'School saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    fetchIt(
                        GET_LIST,
                        'schools'
                    ).then(response => {
                        switch (response.status) {
                            case 200:
                                localStorage.setItem('school', JSON.stringify(response.data[0]));

                                break;

                            case 401:
                            case 403:
                                localStorage.setItem('school', JSON.stringify({Id: 0}));

                                break;

                            default:
                                showNotification(
                                    'direct_message',
                                    'warning',
                                    { messageArgs: { _: 'Oops, something went wrong!' } }
                                );
                        }
                    }).catch(error => {

                    }).then(() => {
                        // Dispatch an action letting react-admin know an API call has ended
                        fetchEnd();
                    });

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:
                    values.Phone = formatPhone(values.Phone);
                    values.AdditionalPhone = formatPhone(values.AdditionalPhone);

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    values.Phone = formatPhone(values.Phone);
                    values.AdditionalPhone = formatPhone(values.AdditionalPhone);

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Fragment>
                <Card className="cardForm">
                    <CardContent>
                        <TabbedForm
                            record={this.props.record}
                            save={this.handleSubmit}
                            toolbar={<SchoolEditToolbar />}
                        >
                            <FormTab label="name">
                                <TextInput source="SchoolName" className='fieldsWidthExpand' validate={required('The School Name field is required')} />
                                <FormDataConsumer>
                                    {
                                        ({formData}) => {
                                            return <SchoolTitle title={formData.SchoolName} />;
                                        }
                                    }
                                </FormDataConsumer>

                                <SelectInput
                                    source='TimeZone'
                                    label='Time Zone'
                                    choices={TimeZones}
                                    optionText='name'
                                    className='fieldsWidthExpand'
                                    validate={required('The Time Zone field is required')}
                                    helperText='If you change Time Zone settings you will be logged out'
                                />

                                <SelectInput
                                    source='FirstDayOfWeek'
                                    label='Week starts from'
                                    choices={[{id: 'Monday', name: 'Monday'}, {id: 'Sunday', name: 'Sunday'}]}
                                    optionText='name'
                                    className='fieldsWidthExpand'
                                    validate={required('The Week starts from field is required')}
                                />

                                {
                                    ! (
                                        Object.keys(this.props.record).length === 0 &&
                                        this.props.record.constructor === Object
                                    ) ?
                                        <RichTextInput source="Description" />
                                    : null
                                }
                            </FormTab>

                            <FormTab label="contacts">
                                <PhoneMaskedInput
                                    required
                                    source='Phone'
                                    validate={phoneValidation}
                                    className='fieldsWidthExpand'
                                />
                                <PhoneMaskedInput
                                    required
                                    source='AdditionalPhone'
                                    validate={phoneValidation}
                                    className='fieldsWidthExpand'
                                />
                                <TextInput type='email' source='Email' className='fieldsWidthExpand' validate={emailValidation} />
                                <TextInput source='ContactPerson' className='fieldsWidthExpand' validate={required('The Contact Person field is required')} />
                                <TextInput source='Director' className='fieldsWidthExpand' validate={required('The Director field is required')} />
                                <TextInput label='URL' source='URL' className='fieldsWidthExpand' />
                            </FormTab>

                            <FormTab label="Address">
                                <TextInput
                                    source='Address.City'
                                    label='City' className='fieldsWidthExpand'
                                    validate={required('The City field is required')}
                                />
                                <UsStates source='Address.USState' className='fieldsWidthExpand' validate={required('The State field is required')}/>
                                <ZipCodeMaskedInput
                                    required
                                    source='Address.ZIP'
                                    label='ZIP Code'
                                    validate={zipCodeValidation}
                                />
                                <TextInput
                                    multiline
                                    source='Address.StreetAddress'
                                    label='Street Address'
                                    validate={required('The Street Address field is required')}
                                    className='fieldsWidthExpand'
                                />
                                <TextInput source='Address.StreetAddressLine2' className='fieldsWidthExpand' label='Street Address Line 2' />
                            </FormTab>
                        </TabbedForm>
                    </CardContent>
                 </Card>
            </Fragment>
        );
    }
}

SchoolEditForm.propTypes = {
    record: PropTypes.object.isRequired
};

SchoolEditForm.defaultProps = {
    record: {}
};

export default SchoolEditForm;
