import React from 'react';
import {
    Filter,
    TextInput
} from 'react-admin';
import moment from 'moment';
import {calendarYearsStart} from '../../../config/settings';
import SelectInput from '../../../components/SelectInput';
import {arraySortByKey} from "../../../helpers";

let yearChoices = [];

for (let i = calendarYearsStart; i <= parseInt(moment().format("YYYY")); i++ ){
    yearChoices.push({id: i, name: (i + "")});
}
yearChoices = arraySortByKey(yearChoices, 'id', false);

export const HolidaysListFilters = props => (
    <Filter {...props}>
        <TextInput label='Search by name' source='search' alwaysOn style={{width: 300}}/>
        <SelectInput
            source='CalendarYear'
            label='Years'
            choices={yearChoices}
            emptyValue={null}
            emptyText="All"
        />
    </Filter>
);
