import React from 'react';
import {Filter, TextInput} from 'react-admin';
import AdvancedFilterInput from '../../../../components/AdvancedFilterInput';
import BooleanFilterInput from '../../../../components/BooleanFilterInput';
import {choices, roles} from "../../../../config/constants";
import SelectInput from "../../../../components/SelectInput";


export const UsersListFilters = props => (
    <Filter {...props}>
        <TextInput label='Search by User Full Name' source='search' style={{width: 300}}/>
        <AdvancedFilterInput source='FirstName' label='User First Name' defaultValue={{field: 'FirstName'}} />
        <AdvancedFilterInput source='LastName' label='User Last Name' defaultValue={{field: 'LastName'}} />
        <AdvancedFilterInput source='Email' label='Email' defaultValue={{field: 'Email'}} />
        <AdvancedFilterInput source='CellPhone' label='Cell Phone' defaultValue={{field: 'CellPhone'}} />
        {/* <BooleanFilterInput source='IsActive' label='Status' choices={choices} defaultValue={true} /> */}
        {/* <BooleanFilterInput source='AreNotificationsEnabled' label='Are Notifications Enabled' defaultValue={false} /> */}
        <BooleanFilterInput source='IsDeleted' label='Deleted' defaultValue={true} />
        <SelectInput
            source='Role'
            label='Role'
            choices={roles}
            emptyValue={null}
            emptyText="All"
        />
    </Filter>
);
