import {connect} from 'react-redux';
import CopyScheduleDialog from '../components/CopyScheduleDialog';
import {
    fetchEnd,
    fetchStart,
    showNotification,
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};


export default connect(null, mapDispatchToProps)(
    CopyScheduleDialog
);