import React, {Component} from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import {fetchIt, getErrorMessage, inArray} from "../../../helpers";
import {
    GET_LIST,
    UPDATE,
    DELETE,
    GET_ONE,
    Title,
    FormWithRedirect,
    AutocompleteArrayInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
    FormDataConsumer,
    NumberInput,
} from 'react-admin';
import {Menu, Item, contextMenu, theme, animation} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import moment from "moment";
import Tooltip from './Tooltip';
import {Grid, Portal, Typography} from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import {withStyles} from "@material-ui/core/styles";
import CalendarCheckbox from "./CalendarCheckbox";
import {NewEventDialog} from './NewEventDialog';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import IconEdit from '@material-ui/icons/Edit';
import IconEditAttributes from '@material-ui/icons/EditAttributes';
import IconCancel from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteWholeIcon from "@material-ui/icons/DeleteSweep";
import SaveIcon from "@material-ui/icons/Save";
import TeachersIcon from "@material-ui/icons/People";
import arrayMutators from "final-form-arrays";
import {DatePicker, TimePicker} from "../../../common";
import {endsAtValidation, startsAtValidation} from "../../../validators/startEndTimeValidation";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CalendarAutoComplete from "../../../components/CalendarAutoComplete";
import {requiredNonNegativeNumberValidation} from "../../../validators";
import CurrencyInput from "../../../components/CurrencyInput";
import RichTextInput from "ra-input-rich-text";



const dialogTitleStyles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(dialogTitleStyles)(props => {
    const {children, classes, onClose, ...other} = props;

    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h6">{children}</Typography>

            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
    );
});


const styles = ({
    tooltip: {
        zIndex: '500000000 !important',
        padding: '5px !important',
        opacity: '1 !important'
    },
});


class Calendar extends Component {
    _isMounted = false;
    _isJustMounted = true;
    calendarRef = React.createRef();


    constructor (props) {
        super(props);

        let minTime = '05:00:00';
        let maxTime = '22:00:00';

        const hiddenDays = [];
        let school = localStorage.getItem('school');
        let firstDay = 1;

        if (school) {
            school = JSON.parse(school);
            if (school.FirstDayOfWeek === 'Sunday') {
                firstDay = 0;
            }
        }

        this.state = {
            defaultDate: moment().format('YYYY-MM-DD'),
            defaultView: 'resourceTimeGridDay',
            studios: [],
            events: [],
            showDialog: false,
            isDisabled: false,
            tooltip: false,
            event: {},
            menuShown: false,
            slotWidth: 20,
            semesters: [],
            teachers: [],
            courses: [],
            minTime,
            maxTime,
            hiddenDays,
            firstDay,
            selectedSemester: props.selectedSemester ? props.selectedSemester : null,
            isFiltered: true,
            filterBySchedule: props.filterBySchedule ? props.filterBySchedule : false,
            showNewDialog: false,
            viewType: '',
            activeStart: '',
            activeEnd: '',
            semesterStartDate: moment().format('YYYY-MM-DD'),
            semesterEndDate: '2100-01-01',
            openDeleteEvent: false,
            openDeleteWholeSchedule: false,
            isTeachersOpened: false,
            eventTeachers: [],
            showEditEventDialog: false,
            showEditScheduleDialog: false,
        };
    }


    componentDidMount() {
        this._isMounted = true;
        const me = this;

        const {fetchStart, fetchEnd} = this.props;

        fetchStart();

        fetchIt(
            GET_LIST,
            'semesters'
        ).then(response => {
            if (this._isMounted) {
                this.setState({semesters: response.data});
            }

            fetchEnd();

            let semesterStartDate = moment().format('YYYY-MM-DD');

            let semester = response.data.find(sem => {
                return parseInt(sem.Id) === parseInt(me.state.selectedSemester);
            });

            if (!!semester) {
                semesterStartDate = moment(semester.FromDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
            }

            if (this.props.selectedDate) {
                semesterStartDate = moment(this.props.selectedDate).format('YYYY-MM-DD');

                this.props.setSelectedDate(false);
            }

            me.goToDate(semesterStartDate);
        });

        fetchIt(
            GET_LIST,
            'teachers'
        ).then(response => {
            if (this._isMounted) {
                this.setState({teachers: response.data});
            }

            fetchEnd();
        });

        fetchIt(
            GET_LIST,
            'courses'
        ).then(response => {
            if (this._isMounted) {
                this.setState({courses: response.data});
            }

            fetchEnd();
        });
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.shouldSetDate && this.props.setShouldSetDate && this.props.setSelectedDate) {
            this.props.setShouldSetDate(false);
            this.props.setSelectedDate(this.calendarRef.current.getApi().getDate());
        }


        if (!!nextProps.selectedSemester) {
            let semesterStartDate = moment().format('YYYY-MM-DD');
            let semesterEndDate = '2100-01-01';

            if (nextProps.selectedSemester !== this.state.selectedSemester) {
                const semester = this.state.semesters.find(sem => {
                    return parseInt(sem.id) === parseInt(nextProps.selectedSemester);
                });

                if (!!semester) {
                    semesterStartDate = moment(semester.FromDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
                    semesterEndDate = moment(semester.ToDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
                }
            } else {
                semesterStartDate = moment(this.calendarRef.current.getApi().view.activeStart).format('YYYY-MM-DD');
                semesterEndDate = moment(this.calendarRef.current.getApi().view.activeEnd).format('YYYY-MM-DD');
            }

            if (this._isMounted) {
                this.setState({
                    selectedSemester: nextProps.selectedSemester
                });
            }

            this.fetchTheData(semesterStartDate, semesterEndDate, nextProps.selectedSemester);

            this.goToDate(moment(semesterStartDate).format('YYYY-MM-DD'));
        }


        if (
            (!!nextProps.filterBySchedule || (nextProps.filterBySchedule === false)) &&
            (nextProps.filterBySchedule !== this.state.filterBySchedule)
        ) {
            if (this._isMounted) {
                this.setState({
                    filterBySchedule: nextProps.filterBySchedule
                });

                this.fetchTheData();
            }
        }
    }


    refetch = data => {
        const eventDate = moment(data.StartDate).format('YYYY-MM-DD');
        const eventDateObject = moment(eventDate);
        let start = eventDateObject.format('YYYY-MM-DD');
        let end = eventDateObject.add(1, 'day').format('YYYY-MM-DD');

        const activeStart = moment(this.state.activeStart).format('YYYY-MM-DD');
        const activeEnd = moment(this.state.activeEnd).format('YYYY-MM-DD');

        if (this.state.viewType === 'timeGridWeek') {
            start = eventDateObject.day('Sunday').format('YYYY-MM-DD');
            end = eventDateObject.day('Sunday').add(7, 'days').format('YYYY-MM-DD');
        }

        if (this.state.viewType === 'dayGridMonth') {
            start = activeStart;
            end = activeEnd;
        }

        this.fetchTheData(start, end);

        this.goToDate(eventDate);
    }


    updateEvent = (dataToSend, eventDropInfo, closeDialog) => {
        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            UPDATE,
            'calendar',
            dataToSend
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Event saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (this._isMounted && closeDialog) {
                        this.setState(closeDialog);
                    }

                    this.refetch(dataToSend);

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    if (eventDropInfo) {
                        eventDropInfo.revert();
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {}).then(() => {
            fetchEnd();

            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    }


    eventDrop = eventDropInfo => {
        let event = this.state.events.find(event => {
            return parseInt(event.id) === parseInt(eventDropInfo.event.id);
        });

        if (eventDropInfo.newResource) {
            event.Studio = parseInt(eventDropInfo.newResource.id);
            event.resourceId = parseInt(eventDropInfo.newResource.id);
        }

        const dataToSend = {
            id: event.Id,
            Name: event.Name,
            Course: event.Course,
            Places: event.Places,
            Studio: event.Studio,
            StartDate: moment.utc(eventDropInfo.event.start).format('MM/DD/YYYY'),
            StartTime: moment(eventDropInfo.event.start).format('HH:mm'),
            EndTime: moment(eventDropInfo.event.end).format('HH:mm'),
        }

        const viewType = this.calendarRef.current.getApi().view.type;
        const activeStart = this.calendarRef.current.getApi().view.activeStart;
        const activeEnd = this.calendarRef.current.getApi().view.activeEnd;


        if (this._isMounted) {
            this.setState({event, viewType, activeStart, activeEnd});
        }

        this.updateEvent(dataToSend, eventDropInfo);
    };


    datesSet = info => {
        const start = moment(info.start).format('YYYY-MM-DD');
        const end = moment(info.end).format('YYYY-MM-DD');
        const view = info.view.type;

        if (
            (start !== this.state.defaultDate) ||
            (view !== this.state.defaultView) ||
            this._isJustMounted &&
            !this.state.onRegistration
        ) {
            if (this._isMounted) {
                this.setState({defaultDate: start, defaultView: view});
            }
            this._isJustMounted = false;

            this.fetchTheData(start, end);
        }
    }


    eventDidMount = info => {
        if (info.event.extendedProps.IsEvent && !this.props.onRegistration) {
            info.el.oncontextmenu = e => {
                e.preventDefault();

                if (this._isMounted) {
                    this.setState({
                        menuShown: true,
                        event: info.event.extendedProps,
                    });
                }

                contextMenu.show({
                    id: 'TheItemContextMenu',
                    event: e,
                    props: info
                });
            };
        }
    };


    eventContent = arg => {
        const {classes} = this.props;
        let flag = '';
        if (arg.event.extendedProps.HasSubmittedAttendanceFully) {
            flag = <>&#128681;</>;
        } else if (arg.event.extendedProps.HasSubmittedAttendance) {
            flag = <>&#127987;</>;
        }

        return (
            <>
                <div
                    data-tip
                    data-for={'tt-' + arg.event.id}
                    style={{height: '100%', paddingTop: 1, overflow: 'hidden'}}
                >
                    <label htmlFor={'sch-' + arg.event.id} style={{display: 'block', cursor: 'pointer', height: '100%'}}>
                        <div style={{
                            color: '#ffffff',
                            backgroundColor: arg.event.backgroundColor,
                            padding: '1px 1px 0px',
                            fontSize: 'var(--fc-small-font-size, .85em)',
                            borderRadius: 3,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}>
                            {
                                this.props.onRegistration ?
                                    <CalendarCheckbox
                                        id={'sch-' + arg.event.id}
                                        event={arg.event}
                                        teachers={this.state.teachers}
                                    />
                                : ''
                            } {flag} {arg.event.title}
                        </div>
                    </label>
                </div>

                <Portal>
                    <ReactTooltip
                        id={'tt-' + arg.event.id}
                        type='light'
                        className={classes.tooltip}
                    >
                        <Tooltip
                            event={arg.event}
                            resources={this.state.studios}
                            teachers={this.state.teachers}
                            courses={this.state.courses}
                        />
                    </ReactTooltip>
                </Portal>
            </>
        );
    }


    fetchTheData = (Start, End, Semester) => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        fetchStart();

        let filter = {};
        if (Start && End) {
            filter = {
                Start,
                End
            };
        } else {
            let semesterStartDate = moment().format('YYYY-MM-DD');
            let semesterEndDate = '2100-01-01';

            const semester = this.state.semesters.find(sem => {
                return parseInt(sem.id) === parseInt(this.state.selectedSemester);
            });

            if (!!semester) {
                semesterStartDate = moment(semester.FromDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
                semesterEndDate = moment(semester.ToDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
            }

            filter = {
                Start: semesterStartDate,
                End: semesterEndDate
            };

        }


        if (Semester) {
            filter.Semester = Semester;
        } else if (this.state.selectedSemester) {
            filter.Semester = this.state.selectedSemester;
        }


        if (this.props.filters) {
            filter = Object.assign(this.props.filters, filter);
        }

        fetchIt(
            GET_ONE,
            'common_settings',
            {filter: {"IsActive": true}}
        ).then(response => {
            switch (response.status) {
                case 200:
                    const earliestHour = response.data.EarliestHour;
                    const latestHour = response.data.LatestHour;

                    if (me._isMounted) {
                        me.setState({
                            minTime: earliestHour,
                            maxTime: latestHour
                        });
                    }

                    let studios = [];

                    const locations = response.data.Locations;
                    let weekdays = [];
                    for (let l = 0; l < locations.length; l++) {
                        const location = locations[l];
                        for (let s = 0; s < location.Studios.length; s++) {
                            const studio = location.Studios[s];
                            const studioColor = studio.DisplayColor;
                            if (studioColor === '#FAFAFA') {
                                studio.DisplayColor = '#666666';
                            }

                            for (let w = 0; w < location.WorkDays.length; w++) {
                                if (!inArray(location.WorkDays[w].WorkDay, weekdays)) {
                                    weekdays.push(location.WorkDays[w].WorkDay);
                                }
                            }

                            studios.push(
                                {
                                    id: studio.Id,
                                    title: studio.Name,
                                    scheduleColor: studio.DisplayColor,
                                    location: location.Name,
                                    workDays: location.WorkDays,
                                    // eventColor: studio.DisplayColor,
                                    studioColor: studioColor
                                }
                            )
                        }
                    }

                    let hiddenDays = [];
                    for (let t = 0; t < 7; t++){
                        if (!inArray(moment(t, 'd').format('dddd'), weekdays)) {
                            hiddenDays.push(t);
                        }
                    }

                    if (me._isMounted) {
                        me.setState({hiddenDays});
                    }

                    fetchStart();

                    if (this.state.isFiltered) {
                        filter.EventsOnly = true;
                    }

                    fetchIt(
                        GET_LIST,
                        'calendar',
                        {filter}
                    ).then(response => {
                        switch (response.status) {
                            case 200:
                                let events = [];

                                for (let t = 0; t < response.data.length; t++) {
                                    if (
                                        response.data[t] &&
                                        response.data[t].id &&
                                        response.data[t].StartDateTime &&
                                        response.data[t].EndDateTime
                                    ) {
                                        let event = response.data[t];

                                        event.resourceEditable = event.IsEvent;
                                        event.editable = event.IsEvent;
                                        event.resourceId = event.Studio;
                                        event.title = moment(event.StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mmA') + ' ' + event.Name;
                                        event.start = moment(event.StartDateTime, 'M/D/YYYY h:mm:ss A').format();
                                        event.end = moment(event.EndDateTime, 'M/D/YYYY h:mm:ss A').format();
                                        event.startHour = moment(event.StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mmA');
                                        event.endHour = moment(event.EndDateTime, 'M/D/YYYY h:mm:ss A').format('h:mmA');
                                        let studio = studios.find(studio => {
                                            return studio.id === parseInt(event.Studio);
                                        });

                                        if (studio) {
                                            event.backgroundColor = studio.scheduleColor;
                                            events.push(event);
                                        }
                                    }
                                }

                                if (me.state.filterBySchedule) {
                                    events = events.filter(event => parseInt(event.Schedule) === me.state.filterBySchedule);

                                    if (me.props.selectedEvent) {
                                        const eventDate = moment(me.props.selectedEvent.StartDateTime, 'M/D/YYYY h:mm:ss A').format('YYYY-MM-DD');

                                        me.goToDate(eventDate);
                                    }
                                }

                                if (me._isMounted) {
                                    me.setState({
                                        studios,
                                        events
                                    });
                                }

                                break;

                            default:
                                showNotification(
                                    'direct_message',
                                    'warning',
                                    { messageArgs: { _: 'Oops, something went wrong!' } }
                                );
                        }
                    }).catch(error => {

                    }).then(() => {
                        // Dispatch an action letting react-admin know an API call has ended
                        fetchEnd();
                    });

                    if (me._isMounted) {
                        me.setState({
                            studios
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {}).then(() => {
            fetchEnd();
        });
    };


    datesRender = info => {
        let slotWidth = 20;
        if (info.view.type === 'resourceTimelineWeek') {
            slotWidth = 50;
        }

        if (this._isMounted) {
            this.setState({slotWidth});
        }
    };


    onEditScheduleClick = e => {
        const viewType = this.calendarRef.current.getApi().view.type;
        const activeStart = this.calendarRef.current.getApi().view.activeStart;
        const activeEnd = this.calendarRef.current.getApi().view.activeEnd;

        const event = this.state.events.find(event => {
            return parseInt(event.id) === parseInt(e.props.event.id);
        });

        if (this._isMounted) {
            this.setState({
                showEditScheduleDialog: true,
                event,
                viewType,
                activeStart,
                activeEnd
            });
        }
    };


    onEditScheduleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            this.setState({showEditScheduleDialog: false});
        }
    };


    onEditScheduleCancel = () => {
        if (this._isMounted) {
            this.setState({showEditScheduleDialog: false});
        }
    };


    onEditScheduleSubmit = (e, data) => {
        e.persist();
        e.preventDefault();

        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();


        const viewType = this.calendarRef.current.getApi().view.type;
        const activeStart = this.calendarRef.current.getApi().view.activeStart;
        const activeEnd = this.calendarRef.current.getApi().view.activeEnd;

        let event = this.state.events.find(event => {
            return parseInt(event.id) === parseInt(data.Id);
        });

        if (this._isMounted) {
            this.setState({event, viewType, activeStart, activeEnd});
        }

        const dataToSend = {
            id: data.Id,
            Course: data.Course,
            Name: data.Name,
            Description: data.Description,
            Places: data.Places,
            Price: data.Price,
            StartDate: moment.utc(event.start).format('MM/DD/YYYY'),
            StartTime: moment(event.start).format('HH:mm'),
            EndTime: moment(event.end).format('HH:mm'),
        }


        fetchIt(
            UPDATE,
            'calendar_common',
            dataToSend
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Schedule saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    this.onEditScheduleCancel();

                    this.refetch(dataToSend);

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {}).then(() => {
            fetchEnd();
        });

        return false;
    }


    onEditEventClick = e => {
        const viewType = this.calendarRef.current.getApi().view.type;
        const activeStart = this.calendarRef.current.getApi().view.activeStart;
        const activeEnd = this.calendarRef.current.getApi().view.activeEnd;

        const event = this.state.events.find(event => {
            return parseInt(event.id) === parseInt(e.props.event.id);
        });

        let semesterStartDate = moment().format('YYYY-MM-DD');
        let semesterEndDate = '2100-01-01';

        const semester = this.state.semesters.find(sem => {
            return parseInt(sem.id) === parseInt(event.Semester);
        });

        if (!!semester) {
            semesterStartDate = moment(semester.FromDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
            semesterEndDate = moment(semester.ToDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
        }

        if (this._isMounted) {
            this.setState({
                showEditEventDialog: true,
                event,
                viewType,
                activeStart,
                activeEnd,
                semesterStartDate,
                semesterEndDate,
            });
        }
    };

    onEditEventClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            this.setState({showEditEventDialog: false});
        }
    };


    onEditEventCancel = () => {
        if (this._isMounted) {
            this.setState({showEditEventDialog: false});
        }
    };


    onEditEventSubmit = () => {
        const event = this.state.event;
        const dataToSend = {
            id: event.Id,
            Name: event.Name,
            Course: event.Course,
            Places: event.Places,
            Studio: event.Studio,
            StartDate: moment.utc(event.StartDateTime, 'M/D/YYYY h:mm:ss A').format('MM/DD/YYYY'),
            StartTime: moment(event.StartDateTime, 'M/D/YYYY h:mm:ss A').format('HH:mm'),
            EndTime: moment(event.EndDateTime, 'M/D/YYYY h:mm:ss A').format('HH:mm'),
        }

        this.updateEvent(dataToSend, null, {showEditEventDialog: false});
    };


    onEventStudioChange = e => {
        e.persist();

        let event = Object.assign({}, this.state.event);
        event.Studio = e.target.value;

        if (this._isMounted) {
            this.setState({event});
        }
    };


    onEventStartDateChange = newDate => {
        if (moment.isMoment(newDate) && newDate.isValid()) {
            let event = Object.assign({}, this.state.event);
            let oldStartTime = moment(this.state.event.StartDateTime, 'M/D/YYYY h:mm:ss A').format(' h:mm:ss A');
            let newStartDate = newDate.format('M/D/YYYY');
            event.StartDateTime = newStartDate + oldStartTime;

            if (this._isMounted) {
                this.setState({event});
            }
        }
    };


    onEventStartTimeChange = newTime => {
        if (moment.isMoment(newTime) && newTime.isValid()) {
            let event = Object.assign({}, this.state.event);
            let oldStartDate = moment(this.state.event.StartDateTime, 'M/D/YYYY h:mm:ss A').format('M/D/YYYY ');
            let newStartTime = newTime.format('h:mm:ss A');
            event.StartDateTime = oldStartDate + newStartTime;

            if (this._isMounted) {
                this.setState({event});
            }
        }
    };


    onEventEndTimeChange = newTime => {
        if (moment.isMoment(newTime) && newTime.isValid()) {
            let event = Object.assign({}, this.state.event);
            let oldEndDate = moment(this.state.event.EndDateTime, 'M/D/YYYY h:mm:ss A').format('M/D/YYYY ');
            let newEndTime = newTime.format('h:mm:ss A');
            event.EndDateTime = oldEndDate + newEndTime;

            if (this._isMounted) {
                this.setState({event});
            }
        }
    };


    onCloseClick = (event, reason) => {
        if (reason !== 'backdropClick') {
            this.setState({showDialog: false});
        }
    };


    onNewEventClick = e => {
        const viewType = this.calendarRef.current.getApi().view.type;
        const activeStart = this.calendarRef.current.getApi().view.activeStart;
        const activeEnd = this.calendarRef.current.getApi().view.activeEnd;

        this.setState({event: {}, showDialog: true, viewType, activeStart, activeEnd});
    };


    onDeleteEventClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    onDeleteEventCancel = () => {
        if (this._isMounted) {
            this.setState({openDeleteEvent: false});
        }
    };


    openDeleteEvent = e => {
        e.event.persist();
        const event = e.props.event.extendedProps;

        if (this._isMounted) {
            this.setState({openDeleteEvent: true, event});
        }
    };


    onEventDelete = () => {
        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            DELETE,
            'calendar',
            {id: this.state.event.Id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Event deleted.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    let events = this.state.events;
                    events = events.filter(event => ((event.id + '') !== this.state.event.Id));

                    this.setState({events});

                    this.refetch({StartDate: moment(this.state.event.StartDateTime, 'M/D/YYYY h:mm:ss A').format('YYYY-MM-DD')});

                    break;

                case 400:
                    let errorMessage = 'Event can not be deleted.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {}).then(() => {
            fetchEnd();

            if (me._isMounted) {
                me.setState({
                    isDisabled: false,
                    openDeleteEvent: false
                });
            }
        });
    };


    onDeleteScheduleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    openDeleteSchedule = e => {
        e.event.persist();
        const event = e.props.event.extendedProps;

        if (this._isMounted) {
            this.setState({openDeleteWholeSchedule: true, event});
        }
    };


    onScheduleCancel = () => {
        if (this._isMounted) {
            this.setState({openDeleteWholeSchedule: false});
        }
    };


    onScheduleDelete = () => {
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            DELETE,
            'schedules',
            {id: this.state.event.Schedule}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Schedule deleted!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    this.refetch({StartDate: moment(this.state.event.StartDateTime, 'M/D/YYYY h:mm:ss A').format('YYYY-MM-DD')});

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            this.onScheduleCancel();

            fetchEnd();
        });
    };


    onTeachersClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    onTeachersCancelClick = () => {
        if (this._isMounted) {
            this.setState({isTeachersOpened: false});
        }
    };


    onTeachersChange = value => {
        let event = Object.assign({}, this.state.event);
        event.Teachers = value;

        if (this._isMounted) {
            this.setState({event});
        }
    };


    onTeachersClick = e => {
        e.event.persist();
        const event = e.props.event.extendedProps;

        if (this._isMounted) {
            this.setState({isTeachersOpened: true, event});
        }
    };


    onTeachersSubmit = event => {
        event.preventDefault();

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();


        const viewType = this.calendarRef.current.getApi().view.type;
        const activeStart = this.calendarRef.current.getApi().view.activeStart;
        const activeEnd = this.calendarRef.current.getApi().view.activeEnd;

        if (this._isMounted) {
            this.setState({viewType, activeStart, activeEnd});
        }


        let teachers = [];
        for (let t = 0; t < me.state.event.Teachers.length; t++) {
            teachers.push(
                {
                    Id: me.state.event.Teachers[t],
                    IsPrimary: true
                }
            )
        }

        fetchIt(
            UPDATE,
            'calendar_teachers',
            {
                id: me.state.event.Id,
                Teachers: teachers
            }
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Event Teachers changed!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    this.onTeachersCancelClick();

                    this.refetch({StartDate: moment(this.state.event.StartDateTime, 'M/D/YYYY h:mm:ss A').format('YYYY-MM-DD')});

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

        return false;
    };


    goToDate = newDate => {
        if (this.calendarRef.current) {
            let calendarApi = this.calendarRef.current.getApi();
            calendarApi.gotoDate(newDate);
        }
    };


    resourceRender = info => {
        let style = {color: info.resource.extendedProps.studioColor};
        if (info.resource.extendedProps.studioColor === '#FAFAFA') {
            style.backgroundColor = '#666666';
        }

        return (<span style={style}>{info.resource.title}</span>);
    };


    onMenuShown = () => {
        if (this._isMounted && !this.state.menuShown) {
            this.setState({
                menuShown: false
            });
        }
    }


    onMenuHidden = () => {
        if (this._isMounted && this.state.menuShown) {
            this.setState({
                menuShown: false
            });
        }
    }


    onFilterClick = (event, el) => {
        if (this.state.isFiltered) {
            el.className = el.className + ' fc-button-active';
        } else {
            el.className = el.className.replace(' fc-button-active','');
        }

        if (this._isMounted) {
            this.setState({
                isFiltered: !this.state.isFiltered
            });

            if (this.state.activeStart !== "") {
                const activeStart = moment(this.state.activeStart).format('YYYY-MM-DD');
                const activeEnd = moment(this.state.activeEnd).format('YYYY-MM-DD');

                this.fetchTheData(activeStart, activeEnd);

                this.goToDate(moment(activeStart).format('YYYY-MM-DD'));
            } else {
                this.fetchTheData();
            }
        }
    }


    render() {
        return (
            <div className="wrapperContent calendarWrapper">
                {
                    !this.props.onRegistration ?
                        <Title title="Calendar" />
                    : null
                }

                <FullCalendar
                    ref={this.calendarRef}
                    customButtons={
                        {
                            newEvent: {
                                text: 'Add New Event',
                                click: this.onNewEventClick
                            },
                            filterEvents: {
                                text: 'Include weekly classes',
                                click: this.onFilterClick
                            }
                        }
                    }
                    views={{
                        resourceTimelineWeek: { // name of view
                            titleFormat: {year: 'numeric', month: '2-digit', day: '2-digit'}
                        }
                    }}
                    initialView={!this.props.onRegistration ? 'resourceTimeGridDay' : 'dayGridMonth'}
                    plugins={
                        !this.props.onRegistration ?
                        [interaction, resourceTimeGridPlugin, timeGridPlugin, dayGridPlugin] :
                        [dayGridPlugin]
                    }
                    headerToolbar={{
                        left:  (!this.props.onRegistration ? 'resourceTimeGridDay,timeGridWeek,dayGridMonth newEvent,filterEvents' : ''),
                        center: 'title',
                        right: 'prev,today,next',
                    }}
                    editable={!this.props.onRegistration}
                    resourceAreaHeaderContent='Studios'
                    resources={this.state.studios}
                    events={this.state.events}
                    slotMinTime={this.state.minTime}
                    slotMaxTime={this.state.maxTime}
                    height='auto'
                    resourceAreaWidth={170}
                    slotDuration='00:05:00'
                    slotWidth={this.state.slotWidth}
                    schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                    eventDidMount={this.eventDidMount}
                    eventContent={this.eventContent}
                    datesRender={this.datesRender}
                    resourceLabelContent={this.resourceRender}
                    eventDrop={this.eventDrop}
                    eventResize={this.eventDrop}
                    datesSet={this.datesSet}
                    hiddenDays={this.state.hiddenDays}
                    firstDay={this.state.firstDay}
                    handleWindowResize={true}
                />

                <NewEventDialog
                    resource={this.props.resource}
                    record={this.state.event}
                    onClose={this.onCloseClick}
                    open={this.state.showDialog}
                    goToDate={this.goToDate}
                    fetchTheData={this.fetchTheData}
                    handleSubmit={this.handleSubmit}
                    viewType={this.state.viewType}
                    activeStart={this.state.activeStart}
                    activeEnd={this.state.activeEnd}
                    semesters={this.state.semesters}
                />


                <Dialog
                    onClose={this.onDeleteEventClose}
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.openDeleteEvent}
                >
                    <DialogTitle id="confirmation-dialog-title" onClose={this.onDeleteEventCancel}>Delete Single Event</DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.event.Registrations > 0 ?
                                <p style={{color: 'red', marginTop: 0}}>This event has registrations!</p>
                            : null
                        }

                        Are you sure you want to delete this Event?
                    </DialogContent>
                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                        <Button
                            onClick={this.onDeleteEventCancel}
                            color="primary"
                        >
                            <IconCancel /> Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.onEventDelete}
                        >
                            <DoneIcon /> Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    onClose={this.onDeleteScheduleClose}
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.openDeleteWholeSchedule}
                >
                    <DialogTitle id="confirmation-dialog-title" onClose={this.onScheduleCancel}>Delete Whole Schedule</DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.event.Registrations > 0 ?
                                <p style={{color: 'red', marginTop: 0}}>This schedule has registrations!</p>
                            : null
                        }

                        Are you sure you want to delete this Whole Schedule?
                    </DialogContent>
                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                        <Button
                            onClick={this.onScheduleCancel}
                            color="primary"
                        >
                            <IconCancel /> Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.onScheduleDelete}
                        >
                            <DoneIcon /> Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    onClose={this.onTeachersClose}
                    maxWidth="sm"
                    fullWidth={true}
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.isTeachersOpened}
                >
                    <DialogTitle id="confirmation-dialog-title" onClose={this.onTeachersCancelClick}>
                        Manage Teachers for the specific day
                    </DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.event.Registrations ?
                                <p style={{color: 'red', marginTop: 0}}>This event has registrations!</p>
                            : null
                        }

                        <FormWithRedirect
                            initialValues={{Teachers: this.state.event.Teachers}}
                            resource='calendar'
                            mutators={{...arrayMutators}}
                            render={() => (
                                <form>
                                    <AutocompleteArrayInput
                                        source='Teachers'
                                        choices={this.state.teachers}
                                        value={this.state.event.Teachers}
                                        optionText='Name'
                                        optionValue='Id'
                                        options={{fullWidth: true}}
                                        onChange={this.onTeachersChange}
                                    />
                                </form>
                            )}
                        />
                    </DialogContent>

                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                        <Button
                            color="primary"
                            startIcon={<IconCancel />}
                            onClick={this.onTeachersCancelClick}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={this.onTeachersSubmit}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.showEditScheduleDialog}
                    onClose={this.onEditScheduleClose}
                    maxWidth='xl'
                    style={{paddingTop: 0}}
                >
                    <DialogTitle style={{paddingBottom: 0}} onClose={this.onEditScheduleClose}>
                        Edit Schedule Common Attributes
                    </DialogTitle>

                    <FormWithRedirect
                        {...this.props}
                        record={this.state.event}
                        resource='calendar'
                        mutators={{...arrayMutators}}
                        render={() => (
                            <form className={this.props.classes.scheduleInnerForm}>
                                <DialogContent style={{paddingTop: 0}}>
                                    {
                                        this.state.event.Registrations ?
                                            <p style={{color: 'red', marginTop: 0, paddingLeft: 25, paddingTop: 16}}>This event has registrations!</p>
                                            : null
                                    }

                                    <Grid container>
                                        <Grid item xs={6} style={{paddingTop: 0, paddingBottom:0}}>
                                            <TextInput source="Name" multiline validate={required('The Name field is required')}/>
                                            <FormDataConsumer>
                                                {
                                                    ({formData}) => {
                                                        return (
                                                            <ReferenceInput
                                                                label='Course'
                                                                source='Course'
                                                                reference='courses'
                                                                filter={{IsActive: true}}
                                                                perPage={1000}
                                                                validate={required('The Course field is required')}
                                                            >
                                                                <CalendarAutoComplete
                                                                    source='Course'
                                                                    id='Course'
                                                                    myVal={formData.Course}
                                                                    optionRenderer={choice => choice ? `${choice.Name}` : ''}
                                                                    validate={required('The Course field is required')}
                                                                />
                                                            </ReferenceInput>
                                                        );
                                                    }
                                                }
                                            </FormDataConsumer>
                                        </Grid>

                                        <Grid item xs={6} style={{paddingBottom: 0,  paddingLeft: '7%'}}>
                                            <NumberInput
                                                source='Places'
                                                validate={requiredNonNegativeNumberValidation}
                                                className='fieldsWidthExpand'
                                            />
                                            <CurrencyInput source='Price' validate={requiredNonNegativeNumberValidation} />
                                        </Grid>
                                        <RichTextInput source='Description' className="fieldsWidthExpand"/>
                                    </Grid>
                                </DialogContent>

                                <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                                    <Button
                                        color="primary"
                                        startIcon={<IconCancel />}
                                        onClick={this.onEditScheduleCancel}
                                    >
                                        Cancel
                                    </Button>

                                    <FormDataConsumer>
                                        {
                                            ({formData}) => {
                                                return (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<SaveIcon />}
                                                        onClick={event => this.onEditScheduleSubmit(event, formData)}
                                                    >
                                                        Save
                                                    </Button>
                                                );
                                            }
                                        }
                                    </FormDataConsumer>
                                </DialogActions>
                            </form>
                        )}
                    />
                </Dialog>


                <Dialog
                    open={this.state.showEditEventDialog}
                    onClose={this.onEditEventClose}
                    maxWidth='xl'
                    style={{paddingTop: 0}}
                >
                    <DialogTitle style={{paddingBottom: 0}} onClose={this.onEditEventClose}>
                        Edit Event
                    </DialogTitle>

                    <DialogContent style={{paddingTop: 0}}>
                        {
                            this.state.event.Registrations ?
                                <p style={{color: 'red', marginTop: 0, paddingLeft: 25, paddingTop: 16}}>This event has registrations!</p>
                                : null
                        }

                        <FormWithRedirect
                            {...this.props}
                            record={this.state.event}
                            resource='calendar'
                            mutators={{...arrayMutators}}
                            render={() => (
                                <form className={this.props.classes.scheduleInnerForm}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <ReferenceInput
                                                label='Studio'
                                                source='Studio'
                                                resource='studios'
                                                reference='studios'
                                                filter={{IsActive: true}}
                                                perPage={1000}
                                                validate={required('The Studio field is required')}
                                                onChange={this.onEventStudioChange}
                                            >
                                                <SelectInput optionText='Name' value={this.state.event.Studio} />
                                            </ReferenceInput>
                                            <DatePicker
                                                source='StartDate'
                                                label='Date'
                                                dateFormat='MM/DD/YYYY'
                                                validate={required('The Date field is required')}
                                                disableFuture={false}
                                                onChange={this.onEventStartDateChange}
                                                val={moment(this.state.event.StartDateTime, 'M/D/YYYY h:mm:ss A')}
                                                minDate={this.state.semesterStartDate}
                                                maxDate={this.state.semesterEndDate}
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{paddingBottom: 0,  textAlign: 'end'}}>
                                            <TimePicker
                                                source={'StartTime'}
                                                label='Starts'
                                                val={moment(this.state.event.StartDateTime, 'M/D/YYYY h:mm:ss A')}
                                                onChange={this.onEventStartTimeChange}
                                                validate={startsAtValidation}
                                            />

                                            <TimePicker
                                                source={'EndTime'}
                                                label='Ends'
                                                val={moment(this.state.event.EndDateTime, 'M/D/YYYY h:mm:ss A')}
                                                onChange={this.onEventEndTimeChange}
                                                validate={endsAtValidation}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        />
                    </DialogContent>

                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                        <Button
                            color="primary"
                            startIcon={<IconCancel />}
                            onClick={this.onEditEventCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={this.onEditEventSubmit}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>


                <Menu
                    id='TheItemContextMenu'
                    theme={theme.light}
                    animation={animation.flip}
                    onHidden={this.onMenuHidden}
                    style={{position: 'fixed', zIndex: 111}}
                >
                    <Item onClick={this.onTeachersClick}>
                        <TeachersIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Teachers (Manage Teachers for the specific event)
                        </div>
                    </Item>
                    <Item onClick={this.onEditEventClick}>
                        <IconEdit />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Edit Single Event
                        </div>
                    </Item>
                    <Item onClick={this.onEditScheduleClick}>
                        <IconEditAttributes />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Edit Schedule Common Attributes
                        </div>
                    </Item>
                    {
                        this.state.event.Registrations === 0 ?
                            <>
                                <Item onClick={this.openDeleteEvent}>
                                    <DeleteIcon />
                                    <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                                        Delete (Single Event)
                                    </div>
                                </Item>
                                <Item onClick={this.openDeleteSchedule}>
                                    <DeleteWholeIcon />
                                    <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                                        Delete Schedule (Delete whole schedule)
                                    </div>
                                </Item>
                            </>
                        : null
                    }
                    {/*<Item onClick={this.openDeleteEvent}>*/}
                    {/*    <DeleteIcon />*/}
                    {/*    <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>*/}
                    {/*        Delete (Single Event)*/}
                    {/*    </div>*/}
                    {/*</Item>*/}
                    {/*<Item onClick={this.openDeleteSchedule}>*/}
                    {/*    <DeleteWholeIcon />*/}
                    {/*    <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>*/}
                    {/*        Delete Schedule (Delete whole schedule)*/}
                    {/*    </div>*/}
                    {/*</Item>*/}
                </Menu>
            </div>
        );
    }
}

export default withStyles(styles)(Calendar);
