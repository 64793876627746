import React, { Component } from "react";
// import * as endpoints from '../config/endpoints';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

const styles = {
    mainWrapper: {
        marginTop: 100,
        marginBottom: 100
    },

    lightBlackButtonWithBorder: {
        borderRadius: '50px',
        border: '1px solid #000000',
        color: '#000000',
        letterSpacing: 2,
        fontWeight: 600,
        fontSize: 14,
        padding: '10px 35px',
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: "#000000",
            borderColor: "#000000"
        },
    },

    theOrWord: {
        fontSize: 25,
        fontWeight: 100,
        marginTop: 12,
        marginBottom: 20
    },

    headingText: {
        fontFamily: 'proxima-nova,sans-serif',
        fontWeight: 100,
        color: '#000000',
        fontSize: 48,
        lineHeight: 1.1,
        marginTop: 20,
        marginBottom: 10
    },

    secondHeadingText: {
        fontSize: 30,
        fontWeight: 100,
        lineHeight: 1.1,
        marginTop: 20,
        marginBottom: 10
    },

    titlesRow: {
        marginBottom: 60
    },

};

class FourOFour extends Component {
    render() {
        const {classes} = this.props;

        return (
            <Card style={{marginTop: 20}}>
                <Title title="Not Found" />
                <CardContent>
                    <Grid container spacing={4} justifyContent='center' className={classes.mainWrapper}>
                        <Grid item sm={10} lg={6} xs={12} align='center'>
                            <Grid container spacing={4} justifyContent='center'>
                                <Grid item xs={12} align='center' className={classes.titlesRow}>
                                    <Typography component="h1" variant="h2" color="inherit" align='center'  className={classes.headingText}>
                                        OOPS!
                                    </Typography>
                                    <Typography component="h2" variant="h3" color="inherit" align='center'  className={classes.secondHeadingText}>
                                        Looks like this page doesn't exists.
                                    </Typography>
                                </Grid>
                                {/*<Grid item sm={5} xs={12} align='center'>*/}
                                {/*    <a href={endpoints.FRONTEND_URL}>*/}
                                {/*        <Button*/}
                                {/*            variant='text'*/}
                                {/*            className={classes.lightBlackButtonWithBorder}*/}
                                {/*            component="span"*/}
                                {/*        >*/}
                                {/*            Go to homepage*/}
                                {/*        </Button>*/}
                                {/*    </a>*/}
                                {/*</Grid>*/}
                                {/*<Grid item sm={2} xs={12} align='center'>*/}
                                {/*    <Typography variant="h4" component="span" color="inherit" align='center' className={classes.theOrWord}>*/}
                                {/*        OR*/}
                                {/*    </Typography>*/}
                                {/*</Grid>*/}
                                {/*<Grid item sm={5} xs={12} align='center'>*/}
                                {/*    <a href={endpoints.FRONTEND_URL + endpoints.FRONTEND_SCHEDULE_PAGE_NAME}>*/}
                                {/*        <Button*/}
                                {/*            variant='text'*/}
                                {/*            className={classes.lightBlackButtonWithBorder}*/}
                                {/*            component="span"*/}
                                {/*        >*/}
                                {/*            Browse schedule*/}
                                {/*        </Button>*/}
                                {/*    </a>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
};


FourOFour.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FourOFour);
