import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Notification from '../../layout/Notification';
import {
    SimpleForm,
    TextInput,
    fetchEnd,
    fetchStart,
    showNotification,
} from "react-admin";
import {NtaSaveButton} from "../../resources/common";
import {emailValidation} from "../../validators";
import {useDispatch} from 'react-redux';
import {fetchIt, getErrorMessage} from "../../helpers";
import EmailIcon from "@material-ui/icons/Email";



const styles = ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

    formPaper: {
        padding: "25px 0"
    },

    inputPadding: {
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    }
});


const ForgottenPassword = props => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);

    const {classes, staticContext, ...other} = props;


    const onSubmit = values => {
        setIsDisabled(true);

        // Dispatch an action letting react-admin know an API call is ongoing
        dispatch(fetchStart());

        const data = {
            Email: values.Email
        }

        fetchIt(
            'post',
            'forgotten_password',
            data
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Password Reset Link sent.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    dispatch(showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    ));

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    ));

                    setIsDisabled(false);

                    break;

                default:
                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    ));
            }
        }).catch(error => {
            setIsDisabled(false);
        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            dispatch(fetchEnd());
        });
    };


    return (
        <div className={classes.main}>
            <Grid container>
                <Grid item xs={12} md={4}> </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container>
                        <Grid item xs={12} md={2}> </Grid>
                        <Grid item xs={12} md={9}>
                            <Paper className={classes.formPaper}>
                                <Typography component="h1" variant="h5" align="center">Reset Password</Typography>
                                <SimpleForm
                                    {...other}
                                    save={onSubmit}
                                    toolbar={<NtaSaveButton icon={<EmailIcon />} label='Send Password Reset Link' isDisabled={isDisabled} style={{marginTop: 15}} />}
                                    style={{textAlign: 'center'}}
                                >
                                    <TextInput
                                        source='Email'
                                        type='email'
                                        validate={emailValidation}
                                        fullWidth
                                        variant="standard"
                                    />
                                </SimpleForm>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Notification />
        </div>
    );
}


export default withStyles(styles)(ForgottenPassword);
