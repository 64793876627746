import {paymentMethodChoices} from "../../../../config/constants";
import {inArray} from "../../../../helpers";
import ExcelJS from "exceljs";
import {saveAs} from 'file-saver';


const exporter = records => {
    const headers = [{
        Id: 'Id',
        EnrollmentDate: 'Enrollment Date',
        PayeeId: 'Payee Id',
        PayeeName: 'Payee Name',
        PaymentMethod:'Payment Method',
        StudentName: 'Student Name'
    }];


    let data = records.map(record => ({
        Id: record.Id,
        EnrollmentDate: record.EnrollmentDate,
        PayeeId: record.PayeeId,
        PayeeName: record.PayeeName,
        PaymentMethod: paymentMethodChoices.find(
            choice => (parseInt(choice.id) === parseInt(record.PaymentMethod))
        ).name,
        StudentName: (record.Students ? record.Students.map(student => student.Name).join(', ') : '')
    }));


    data = headers.concat(data);


    let objectMaxLength = [];
    for (let t = 0; t < data.length; t++) {
        for (let key in data[t]) {
            if (data[t].hasOwnProperty(key)) {
                objectMaxLength[key] = objectMaxLength[key] >= ((data[t][key] + '').length + 3) ?
                    objectMaxLength[key] :
                    ((data[t][key] + '').length + 3);
            }
        }
    }

    data.shift();

    const rightAligned = ['Id', 'EnrollmentDate', 'PayeeId'];

    let newHeaders = [];
    for (let key in headers[0]) {
        if (headers[0].hasOwnProperty(key)) {
            newHeaders.push({
                key: key,
                header: headers[0][key],
                width: objectMaxLength[key],
                style: (inArray(key, rightAligned) ? {alignment: {vertical: 'middle', horizontal: 'right'}} : {})
            });
        }
    }


    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Registrations');
    sheet.columns = newHeaders;
    for (let t = 0; t < data.length; t++) {
        sheet.addRow(data[t]);
    }

    let row = sheet.getRow(1);
    row.font = {bold: true};


    workbook.xlsx.writeBuffer()
    .then(buffer => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const blob = new Blob([buffer], {type: fileType});

        return saveAs(blob, 'Registrations.xlsx')
    })
    .catch(err => console.log('Error writing excel export', err));
};

export default exporter;
