import React from 'react';
import {useFormState, useForm} from 'react-final-form';
import {NtaSaveButton} from "../../resources/common";

const RegisterButton = props => {
    const {isDisabled, ...otherProps} = props;

    const formState = useFormState();
    const form = useForm();
    const handleClick = e => {
        e.preventDefault();

        if (!formState.valid) {
            return;
        }

        form.submit();
    };

    return (
        <NtaSaveButton
            label="Register"
            {...otherProps}
            handleSubmitWithRedirect={handleClick}
            disabled={isDisabled}
            style={{marginTop: 15}}
        />
    );
};

export default RegisterButton;
