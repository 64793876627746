import React from 'react';
import {Create} from 'react-admin';
import {BackButton} from '../../../common';
import LocationContactForm from '../containers/LocationContactForm';
import LocationForms from './LocationForms';


const LocationCreate = props => {
    return (
        <>
            <BackButton />
            <Create {...props} title="Create Location">
                <LocationForms />
            </Create>
        </>
    );
};

export default LocationCreate;
