import React, {useState, useEffect} from 'react';
import {
    GET_ONE,
    UPDATE,
    Toolbar,
    SimpleForm,
    ArrayInput, 
    SimpleFormIterator
} from 'react-admin';
import {fetchIt} from "../../../helpers/fetcher";
import {getErrorMessage} from "../../../helpers";
import {NtaSaveButton} from '../../common';
import LocationWorkDaysFormView from './LocationWorkDaysFormView';
import moment from 'moment';

export const LocationFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} label={props.label}/>
        </Toolbar>
    );
};

export const LocationFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};

const LocationWorkTimeForm = props => {

    const [record, setRecord] = useState({});
    const [isAddDisabled, setIsAddDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        
        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_ONE,
            'locations_working_time',
            {id: props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let record = response.data;

                    for (let w = 0; w < record.length; w++) {
                        record[w].WorkStartTime = moment(record[w].WorkStartTime, 'HH:mm:ss');
                        record[w].WorkEndTime = moment(record[w].WorkEndTime, 'HH:mm:ss'); 
                    }
                    
                    setRecord({Data: record});
                    setIsLoading(false);

                    break;

                case 404:
                    let errorMessage = '';
                    
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
       
    }, []); // eslint-disable-line


    const handleSubmitWorkingTime = values => {
        const {fetchStart, fetchEnd, showNotification} = props;

        for (let w = 0; w < values.Data.length; w++) {
            values.Data[w].WorkStartTime = values.Data[w].WorkStartTime ? values.Data[w].WorkStartTime.format('HH:mm:ss') : moment().format('HH:mm:ss'); 
            values.Data[w].WorkEndTime = values.Data[w].WorkEndTime ? values.Data[w].WorkEndTime.format('HH:mm:ss') : moment().format('HH:mm:ss'); 
        }
        const data = {id: props.id, WorkDays: values.Data};

        fetchStart();

        fetchIt(
            UPDATE,
            'locations_working_time',
            data
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Location Working Time saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );


                    break;

                case 400:

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    return (
        <>
        {!isLoading ?
            <SimpleForm
                resource={props.resource}
                record={record}
                save={handleSubmitWorkingTime}
                toolbar={<LocationFormToolbar label='Save Working Time' />}
            >
                <ArrayInput source='Data' label=' '>
                    <SimpleFormIterator disableAdd={isAddDisabled}> 
                        <LocationWorkDaysFormView 
                            setIsAddDisabled={setIsAddDisabled}
                            record={record} 
                            resource={props.resource} 
                            save={handleSubmitWorkingTime}
                        />                                        
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        : null
        }
        </>
    );
}

export default LocationWorkTimeForm;