import React, {Fragment} from 'react';
import {TextField, FunctionField} from 'react-admin';
import {formatPhone} from "../../../../helpers";
import {DetailsLayout, DetailsTileWrapper} from '../../../common';
import TenantUserFamilyList from '../containers/TenantUserFamilyList';
import moment from "moment";
import SendActivationEmailButton  from '../containers/SendActivationEmailButton';
import ResetPasswordButton  from '../containers/ResetPasswordButton';
import FormLabel from '@material-ui/core/FormLabel';


export const UserActionButton = props => {
    let isActive = false;

    if(props.record && props.record.IsActive){
        isActive = true;
    }

    return (
        <>
            {
                props.record.IsDeleted ?
                    null
                :
                isActive ?
                    <Fragment>
                        <FormLabel component="legend" style={{marginTop: 8}}>Actions</FormLabel>
                        <ResetPasswordButton record={props.record} />
                    </Fragment>
                :
                    <Fragment>
                        <FormLabel component="legend" style={{marginTop: 8}}>Actions</FormLabel>
                        <SendActivationEmailButton record={props.record} />
                    </Fragment>
            }
        </>
    );
}

export const UserShow = props => {
    const fieldsToFormat = [
        {
            field: 'CellPhone',
            formatter: formatPhone
        },

        {
            field: 'HomePhone',
            formatter: formatPhone
        }
    ];

    const options = {
        detailsTitle: 'User',
        titleField: 'FirstName',
        lastNameField: 'LastName',
        removeDeleteButton: true,
        UserActionButton: false,
        indexToFetch: ['users_emergency_contact', 'tenant_users_address'],
        fetchedFieldsToFormat: [
            [
                {
                    field: 'CellPhone',
                    formatter: formatPhone
                }
            ]
        ]
    };

    return (
        <Fragment>
            <DetailsLayout {...props} options={options} fieldsToFormat={fieldsToFormat}>
                <DetailsTileWrapper
                    sideTitle='User Details'
                >
                    <FunctionField source='FirstName' label="Name" render={record => `${record.FirstName} ${record.LastName}`} />
                    <TextField source='Role' />
                    <TextField source='Email' />
                    <TextField label='Cell Phone' source='CellPhone' />
                    <TextField source='Gender' />
                    <FunctionField
                        source='BirthDate'
                        render={record => record.BirthDate !== 'Not specified' ? moment(record.BirthDate, 'MM/DD/YYYY').format('MMMM D, YYYY') : 'Not specified'}
                    />
                    <TextField source='Age' />
                    {/* <FunctionField
                        label='Status'
                        source='IsActive'
                        render={
                            record => {
                                return record.IsDeleted ?
                                    "Deleted"
                                    :
                                    record.IsActive ? 'Active' : 'Inactive'
                            }
                        }
                    /> */}
                    <TextField label='Home Phone' source='HomePhone' />
                    {/* <UserActionButton source='IsActive' /> */}
                </DetailsTileWrapper>

                <DetailsTileWrapper
                    sideTitle='User Address'
                    recordObject='tenant_users_address'
                >
                    <TextField source='tenant_users_address.StreetAddress' label='Street Address' />
                    <TextField source='tenant_users_address.City' label='City' />
                    <TextField source='tenant_users_address.ZIP' label='Zip' />
                    <TextField source='tenant_users_address.StreetAddressLine2' label='Street Address Line 2' />
                    <TextField source='tenant_users_address.USState' label='State' />
                </DetailsTileWrapper>

                <DetailsTileWrapper
                    needsTheFetcher={true}
                    sideTitle='Family Members List'
                >
                    <TenantUserFamilyList source="Id" />
                </DetailsTileWrapper>

                <DetailsTileWrapper
                    sideTitle='Emergency Contact Details'
                    recordObject='users_emergency_contact'
                >
                    <TextField source='users_emergency_contact.FirstName' label="First Name"/>
                    <TextField source='users_emergency_contact.Email' label="Email"/>
                    <TextField source='users_emergency_contact.CellPhone' label="Cell Phone"/>
                    <TextField source='users_emergency_contact.OtherEmergencyContactRelationship' label="Other Emergency Contact Relationship"/>
                    <TextField source='users_emergency_contact.LastName' label="Last Name"/>
                    <TextField source='users_emergency_contact.BirthDate' label="Birth Date"/>
                    <TextField source='users_emergency_contact.Relationship' label="Relationship"/>
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};
