import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Title} from 'react-admin';


const Dashboard = () => (
    <div className="wrapperContent">
        <Card style={{marginTop: 20}}>
            <Title title="Welcome to the administration" />
            <CardContent style={{padding: 20}}>This is the Dashboard</CardContent>
        </Card>
    </div>
);

export default Dashboard;
