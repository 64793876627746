import {required} from "react-admin";
import moment from 'moment';

export const startTimeValidation = (message = 'Start Hour should be before End Hour') =>
    (value, allValues, field) => {
        let result = undefined;

        const start = value;
        let path = field.name.split('.');

        const endTimeIndex = path[1].replace('StartTime', '');

        let end = allValues[path[0]]['EndTime' + endTimeIndex];

        if (end && typeof end === 'object') {
           if (start && (typeof end.isAfter === 'function') && !end.isAfter(start)) {
               result = message;
           }
        }

        return result;

};

export const endTimeValidation = (message = 'Start Hour should be before End Hour') =>
    (value, allValues, field) => {
        let result = undefined;

        const end = value;
        let path = field.name.split('.');

        const startTimeIndex = path[1].replace('EndTime', '');

        let start = allValues[path[0]]['StartTime' + startTimeIndex];

        if (start && typeof start === 'object') {
           if (end && (typeof end.isAfter === 'function') && !end.isAfter(start)) {
               result = message;
           }
        }

        return result;

};

export const startsAtValidation = [
    required('The Starts at field is required'),
    startTimeValidation()
];

export const endsAtValidation = [
    required('The Ends at field is required'),
    endTimeValidation()
];

