import React from 'react';
import {TextField, FunctionField, RichTextField, SingleFieldList, ChipField, Title} from 'react-admin';
import {formatPhone, formatWorkHour} from "../../../helpers";
import {DetailsLayout, DetailsTileWrapper} from '../../common';


export const SchoolShow = props => {
    const fieldsToFormat = [
        {
            field: 'Phone',
            formatter: formatPhone
        },

        {
            field: 'AdditionalPhone',
            formatter: formatPhone
        },

        {
            field: 'WorkStartTime',
            formatter: formatWorkHour
        }
    ];

    const options = {
        detailsTitle: 'school',
        removeDeleteButton: true,
        removeListButton: true
    };

    return (
        <>
            <Title title='School' />
            <DetailsLayout {...props} options={options} fieldsToFormat={fieldsToFormat}>
                <DetailsTileWrapper
                    sideTitle='School Details'
                    sideText='Add your school here.'
                >
                    <TextField source='SchoolName' />
                    <TextField source='Phone' />
                    <TextField source='Email' />
                    <TextField source='ContactPerson' />
                    <TextField source='Director' />
                    <TextField source='AdditionalPhone' />
                    <TextField source='URL' label="Url" />
                    <RichTextField source='Description'/>
                    <TextField source='FirstDayOfWeek' label="Week starts from" />
                </DetailsTileWrapper>
                <DetailsTileWrapper
                    sideTitle='School Address'
                    sideText='This address will appear on your invoices.'
                    recordObject='Address'
                >
                    <TextField source='Address.StreetAddress' label='Street Address' />
                    <TextField source='Address.USState' label='State' />
                    <TextField source='Address.ZIP' label='Zip' />
                    <TextField source='Address.StreetAddressLine2' label='Street Address Line 2' />
                    <TextField source='Address.City' label='City' />
                </DetailsTileWrapper>
            </DetailsLayout>
        </>
    );
};
