import {connect} from 'react-redux';
import CreateNewStudentDialog from '../components/dialogs/CreateNewStudentDialog';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};


export default connect(null, mapDispatchToProps)(
    CreateNewStudentDialog
);
