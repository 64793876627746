import React from 'react';
import moment from 'moment';

export const inArray = (needle = '', haystack = []) => {
    return (Array.isArray(haystack) && (haystack.indexOf(needle) > -1));
};

export const hexToRgb = hex => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}


export const getErrorMessage = data => {
    let errorMessage = 'Oops, something went wrong';
    if (typeof data.HasValidationErrors !== 'undefined') {
        if (data.HasValidationErrors) {
            errorMessage = data.Errors.map((message, key) => {
                return (
                    <p key={key}>{message.Message}</p>
                );
            });
            errorMessage = data.Errors[0].Message;
        } else {
            errorMessage = data.ErrorMessage;
        }
    } else if (data.error_description !== 'undefined') {
        errorMessage = data.error_description;
    }

    return errorMessage;
};


export const getWeekdays = () => {
    let firstDayOfWeek = 1;
    let dayOfWeekFormat = 'E';
    let school = localStorage.getItem('school');
    if (school) {
        school = JSON.parse(school);
        if (school.FirstDayOfWeek === 'Sunday') {
            firstDayOfWeek = 0;
            dayOfWeekFormat = 'd';
        }
    }

    let weekdays = [];
    for (let t = firstDayOfWeek; t < (firstDayOfWeek + 7); t++) {
        weekdays.push(moment(t, dayOfWeekFormat).format('dddd'));
    }

    return weekdays;
};


export const getWeekdayChoices = () => getWeekdays().map(weekDay => ({id: weekDay, name: weekDay}));
