import React from 'react';
import './App.css'
import {Admin, Resource} from 'react-admin';
import PeopleIcon from '@material-ui/icons/People';
// import NotificationIcon from '@material-ui/icons/NotificationsActiveRounded';
import SchoolIcon from '@material-ui/icons/School';
import MyAccountIcon from '@material-ui/icons/AccountCircleRounded';
import EventIcon from "@material-ui/icons/Event";
// import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import {createTheme} from '@material-ui/core/styles';
import JssProvider from 'react-jss/lib/JssProvider';
import {generateClassName} from './generateClassName';
// import {NotificationCreate, NotificationsList} from './resources/notifications';
import {LocationsList, LocationShow, LocationEdit,  LocationCreate} from './resources/locations';
import {SchoolShow, SchoolEdit} from './resources/schools';
import FourOFour from './components/FourOFour';
import {Login} from './authorization';
import Routes from './Routes';
import {LayoutWithTheme, Dashboard} from './layout';
import {StudiosList, StudioShow, StudioCreate, StudioEdit} from './resources/studios';
import {MyAccountShow, MyAccountEdit} from './resources/account';
import {TeachersList, TeacherShow, TeacherCreate, TeacherEdit} from './resources/teachers';
import Teachers from '@material-ui/icons/People';
import {HolidaysList, HolidayCreate, HolidayEdit, HolidayShow} from './resources/holidays';
import HolidayIcon from '@material-ui/icons/WbSunny';
import {
    UsersList,
    UserShow,
    UserEdit,
    UserCreate,
    RegistrationsList,
    RegistrationCreate,
    RegistrationShow
} from './resources/tenants';
import Calendar from "./resources/calendar/containers/Calendar";
import {SchedulesList} from "./resources/schedules";
import {StaffList, StaffShow, StaffEdit, StaffCreate} from './resources/settings/Staff'
import {
    CourseShow,
    CoursesList,
    CourseCreate,
    CourseEdit
} from './resources/courses';
import {FeesList, FeesShow, FeesEdit, FeesCreate} from './resources/settings/fees';
import {SemestersList, SemesterShow, SemesterEdit, SemesterCreate} from './resources/settings/semesters';
import CoursesIcon from "@material-ui/icons/Class";

import {inArray} from './helpers';
import {approvedRoles} from './config/constants';
import {createBrowserHistory as createHistory} from 'history';
import ChangePassword from "./resources/account/components/ChangePassword";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const history = createHistory();


const App = () => (
    <JssProvider generateClassName={generateClassName}>
        <Admin
            history={history}
            theme={THEME}
            layout={LayoutWithTheme}
            catchAll={FourOFour}
            authProvider={authProvider}
            dataProvider={dataProvider}
            loginPage={Login}
            dashboard={Dashboard}
            customRoutes={Routes}
            disableTelemetry
        >
            {permissions => [
                <Resource
                    name="account"
                    show={MyAccountShow}
                    edit={MyAccountEdit}
                    icon={MyAccountIcon}
                    options={{label: 'My Account'}}
                />,

                <Resource
                    name="change_password"
                    edit={ChangePassword}
                    options={{label: 'Change Password'}}
                />,

                // <Resource
                //     name="notifications"
                //     list={NotificationsList}
                //     create={NotificationCreate}
                //     icon={NotificationIcon}
                //     options={{label: 'Notifications'}}
                // />,

                <Resource
                    name="schools"
                    show={SchoolShow}
                    edit={inArray(permissions, approvedRoles) ? SchoolEdit : null}
                    icon={SchoolIcon}
                    options={{label: 'School'}}
                />,

                <Resource
                    name="locations"
                    show={LocationShow}
                    list={LocationsList}
                    create={LocationCreate}
                    edit={LocationEdit}
                />,

                <Resource
                    name="studios"
                    show={StudioShow}
                    list={StudiosList}
                    create={StudioCreate}
                    edit={StudioEdit}
                />,

                <Resource
                    name="teachers"
                    show={TeacherShow}
                    list={TeachersList}
                    create={TeacherCreate}
                    edit={TeacherEdit}
                    icon={Teachers}
                    options={{ label: 'Teachers' }}
                />,

                <Resource
                    name="holidays"
                    show={HolidayShow}
                    list={HolidaysList}
                    create={HolidayCreate}
                    edit={HolidayEdit}
                    icon={HolidayIcon}
                    options={{label: 'Holidays'}}
                />,

                <Resource
                    name="tenant_users"
                    show={UserShow}
                    edit={UserEdit}
                    list={UsersList}
                    create={UserCreate}
                    icon={PeopleIcon}
                    options={{label: 'Customers'}}
                />,

                <Resource
                    name="registrations"
                    show={RegistrationShow}
                    // edit={RegistrationEdit}
                    list={RegistrationsList}
                    create={RegistrationCreate}
                    options={{label: 'Registrations'}}
                />,

                <Resource
                    name="calendar"
                    list={Calendar}
                    icon={EventIcon}
                    options={{label: 'Calendar View'}}
                />,

                <Resource
                    name='schedules'
                    list={SchedulesList}
                    options={{label: 'Weekly View'}}
                />,

                <Resource
                    name='courses'
                    show={CourseShow}
                    list={CoursesList}
                    create={CourseCreate}
                    edit={CourseEdit}
                    options={{label: 'Courses'}}
                    icon={CoursesIcon}
                />,

                // <Resource
                //     name="fees"
                //     list={FeesList}
                //     show={FeesShow}
                //     edit={FeesEdit}
                //     create={FeesCreate}
                // />,

                <Resource
                    name='semesters'
                    list={SemestersList}
                    show={SemesterShow}
                    create={SemesterCreate}
                    edit={SemesterEdit}
                />,


                permissions === 'School Manager' ?
                    (
                        <Resource
                            name='staff'
                            list={StaffList}
                            show={StaffShow}
                            create={StaffCreate}
                            edit={StaffEdit}
                        />
                    )
                    : null,



                <Resource name="registrations_students" />,
                <Resource name="schedule_day_works" />,
                <Resource name="home_address" />,
                <Resource name="notifications_locations" />,
                <Resource name="notifications_sessions" />,
                <Resource name="notifications_schedules" />,
                <Resource name="notifications_created_by" />,
                <Resource name="users_family_members" create={UserCreate} />,
                <Resource name="school_years" />
            ]}

        </Admin>
    </JssProvider>
);

export default App;



// Colors
// const appBarBackgroundBlue = "#2196f3";
const appBarBackgroundGreen = "#3B9287";


const customBreakpointValues = {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1600,
    },
  }

const breakpoints = createBreakpoints({ ...customBreakpointValues });


const THEME = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: appBarBackgroundGreen,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        red: {
            main: 'red'
        },
        background: {
            default: '#fcfcfe',
        },
    },

    breakpoints: {
        ...customBreakpointValues,
    },

    shape: {
        borderRadius: 10,
    },

    typography: {
        useNextVariants: true,
        "fontFamily": "\"Roboto\"",
    },

    overrides: {
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #3B9287',
            },
        },


        MuiAppBar: {
            root: {
                background: appBarBackgroundGreen + ' !important'
            },
        },

        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
        },

        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#3B9287',
                boxShadow: 'none',
                borderRadius: 10
            },
        },

        MuiFormLabel: {
            root: {
                fontWeight: 500,
                color: '#9f9f9f',
                fontSize: 15,
                lineHeight: 1.57
            }
        },

        MuiInputBase: {
            input: {
                background: 'transparent',
            }
        },

        MuiFilledInput: {
            root: {
                backgroundColor: 'transparent',

                '&:hover': {
                    backgroundColor: 'transparent',
                }
            },

            input: {
                padding: '27px 0px 10px',
            },

            multiline: {
                paddingLeft: 0
            }
        },

        MuiFormControl: {
            marginDense: {
                marginTop: 5,
                marginBottom: 0,
            },
            marginNormal: {
                marginTop: 4,
                marginBottom: 0,
            },
            root: {
                width: 300,
                [breakpoints.up('xl')]: {
                    width: 410,
                },
            }
        },

        RaFormInput : {
            input: {
                width: 300,
                [breakpoints.up('xl')]: {
                    width: 410,
                },
            }
        },

        MuiFormHelperText: {
            contained: {
                marginLeft: 0
            }
        },

        MuiMenuItem: {
            dense: {
                fontSize: '1rem'
            },
            root: {
                paddingLeft: 12
            }
        },

        MuiTableCell: {
            head: {
                lineHeight: '2rem',
                color: "#000",
                textTransform: "uppercase",
                fontWeight: 400,
                fontSize: 11
            },

            root: {
                padding: 0,
                fontFamily: 'Roboto',
                fontWeight: 400,

                '&:last-child': {
                    paddingRight: 0
                }
            },

            body: {
                fontSize: "1rem"
            },

            sizeSmall: {
                padding: '0px  16px',
            },

        },

        MuiPrivateNotchedOutline: {
            legend: {
                borderColor: "transparent"
            }
        },

        MuiListItem: {
            root: {
                boxSizing: 'content-box'
            }
        },

        MuiDrawer: {
            docked: {
                marginRight: 15,
                marginTop: '2em'
            }
        },

        MuiTypography: {
            body2: {
                fontFamily: 'Roboto',
                fontWeight: 400,
            },
        },

        MuiTablePagination: {
            toolbar: {
                minHeight: 30,
                marginBottom: -12,
                paddingRight: 23
            }
        },

        RaListToolbar: {
            actions: {
                paddingTop: 34
            },
            toolbar: {
                marginTop: -10
            }
        },

        RaCreate: {
            card: {
                border: 'none'
            }
        },

        RaAppBar: {
            menuButton: {
                marginLeft: 7,
                marginRight: 3,
            }
        },

        MuiCardContent: {
            root: {
                paddingLeft: 0,
                paddingRight: 0,
                '&:last-child': {
                    paddingBottom: 0
                }
            }
        },

        RaCardContentInner: {
            root: {
                paddingLeft: 25,
                paddingRight: 25,
                '&:last-child': {
                    paddingBottom: 0
                }
            }
        },

        RaTabbedForm: {
            content: {
                paddingLeft: 25,
                paddingRight: 25
            }
        },

        MuiDialogContent: {
            root: {
                paddingLeft: 0,
                paddingRight: 0,
                '&:last-child': {
                    paddingBottom: 0
                }
            }
        },

        MuiGrid: {
            container: {
                paddingLeft: 25,
                paddingRight: 25
            }
        },

        MuiDialogActions: {
            root: {
                paddingLeft: 25,
                paddingRight: 25,
                background: '#f5f5f5'
            }
        },

        MuiTabs: {
            root: {
                paddingLeft: 15,
                paddingRight: 15
            }
        },

        RaList: {
            content: {
                paddingBottom: 15
            }
        },

        MuiDialog: {
            paperWidthXl: {
                maxWidth: 850,
                [breakpoints.up('xl')]: {
                    maxWidth: 1000,
                },
            },

            paperWidthSm: {
                maxWidth: 375,
                [breakpoints.up('xl')]: {
                    maxWidth: 460,
                },
            }
        },

        MuiSelect: {
            root: {
                width: 300,
                [breakpoints.up('xl')]: {
                    width: 410,
                },
            }
        },

        MuiDialogContentText: {
            root: {
                paddingLeft: 25,
                paddingRight: 25
            }
        },

        RaFilterForm: {
            form: {
                marginTop: 0
            }
        }
    },

    RaAutocompleteArrayInput: {
        suggestionsContainer: {
            zIndex: 2000,
        }
    },

    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
});
