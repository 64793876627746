import React, {useState} from 'react';
import {Button} from "@material-ui/core";
import {fetchIt, getErrorMessage} from "../../../helpers"
import SuspendIcon from '@material-ui/icons/Close';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    refreshView
} from 'react-admin';
import {useDispatch} from 'react-redux';


const ChangeActiveStatusButton = props => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);


    const onClick = () => {
        setIsDisabled(true);

        dispatch(fetchStart());

        fetchIt(
            "CHANGE_ACTIVE_STATUS",
            'teachers',
            {id: props.record.Id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Teacher status is updated.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    dispatch(showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    ));

                    dispatch(refreshView());

                    break;

                case 400:
                    let errorMessage = 'There was a problem with status change.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    ));

                    break;

                default:
                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    ));
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            dispatch(fetchEnd());

            setIsDisabled(false);
        });
    };

    return (
        <Button
            onClick={onClick}
            color="primary"
            disabled={isDisabled}
            size="small"
        >
            <SuspendIcon style={{fontSize: 20, marginRight: 5}} /> {props.label}
        </Button>
    );
}

export default ChangeActiveStatusButton;
