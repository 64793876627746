import React, {Component, Fragment} from 'react';
import {GET_ONE, Title} from 'react-admin';
import CourseForm from '../containers/CourseForm';
import {fetchIt} from "../../../helpers";
import {BackButton} from '../../../common';
import {Redirect} from 'react-router';
import {classTypeChoices} from '../../../config/constants';


class CourseEdit extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            record: {Categories: [], ClassType: classTypeChoices[0].id},
            title: '',
            redirectTo: null
        };
    }


    updateTitle = title => {
        if (this._isMounted) {
            this.setState({
                title
            })
        }
    };


    componentDidMount() {
        this._isMounted = true;
        const { fetchStart, fetchEnd, showNotification } = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_ONE,
            'courses',
            {id: this.props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    if (!response.data.MinAge) {
                        response.data.MinAge = 0;
                    }

                    let months = parseInt(response.data.MinAge);
                    let years = months / 12;
                    let theHalf = false;
                    if (Math.floor(years) < years) {
                        theHalf = true;
                    }
                    years = Math.floor(years);

                    response.data.MinAge = {};
                    response.data.MinAge.Years = years;
                    response.data.MinAge.TheHalf = theHalf;

                    months = parseInt(response.data.MaxAge);
                    years = months / 12;
                    theHalf = false;
                    if (Math.floor(years) < years) {
                        theHalf = true;
                    }
                    years = Math.floor(years);

                    response.data.MaxAge = {};
                    response.data.MaxAge.Years = years;
                    response.data.MaxAge.TheHalf = theHalf;
    
                    if (me._isMounted) {
                        me.setState({
                            record: {...response.data}
                        });
                    }

                    break;

                case 404:
                    let errorMessage = '';
                    
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Fragment>
                <BackButton />
                {/* <h1>{this.state.title}</h1>
                 */}
                <Title title={this.state.record.Name} />
                <CourseForm editMode={true} record={this.state.record} updateTitle={this.updateTitle} {...this.props}/>
            </Fragment>
        );
    }
}
export default CourseEdit;
