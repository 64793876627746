import React from 'react';
import {addField} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import {useForm, useFormState} from 'react-final-form';


const styles = {
    numberInput: {
        '& input': {paddingLeft: 15}
    },
};


const NativeSelectInput = withStyles(styles)(({input, meta, source, record, id, label, classes, inDialog, val, ...props}) => {
    const form = useForm();
    const formState = useFormState();
   
    const onChange = event => {
        event.persist();

        const value = parseInt(event.target.value);

        if (!props.multiple) {
            form.change(source, value);
        } else {
            form.change(source, Array.from(event.target.selectedOptions, option => parseInt(option.value)));
        }
    }

    return (
        
        <select value={formState.values[source]} multiple={!!props.multiple} size={5} onChange={onChange} style={{height: 'auto'}}>
            {props.choices.map(choice => (
                <option key={choice.Id} value={choice.Id}>
                    {choice.Name}
                </option>
            ))}
        </select>
);
});

export default addField(NativeSelectInput); // decorate with react-final-form's <Field>
