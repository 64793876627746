import React from 'react';
import RegistrationForm from '../../containers/RegistrationForm';
import {withResizeDetector} from 'react-resize-detector';
import {Title} from 'react-admin';


const RegistrationCreate = props => {
    return (
        <>
            <Title title="Registration Create" />
            <RegistrationForm record={{}} {...props}/>
        </>
    );
}
export default withResizeDetector(RegistrationCreate);
