import React, {useEffect, useState} from 'react';
import {Card, CardContent, CardHeader, Typography} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {
    fetchEnd,
    fetchStart, GET_LIST,
    showNotification
} from 'react-admin';
import {fetchIt, getErrorMessage} from "../../../../../../helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {useForm, useFormState} from "react-final-form";


const ParentPayeeInformation = props => {
    const dispatch = useDispatch();

    const [selectedPayee, setSelectedPayee] = useState(0);
    const [payees, setPayees] = useState([]);

    const form = useForm();
    const formState = useFormState();


    useEffect(() => {
        if (formState.values.selectedFamilyMember) {
            setPayees([]);
            form.change('selectedPayee', null);


            fetchIt(
                GET_LIST,
                'registration_payees_list',
                {id: formState.values.selectedFamilyMember}
            ).then(response => {
                switch (response.status) {
                    case 200:
                        setPayees(response.data);
                        if (response.data.length > 0) {
                            setSelectedPayee(parseInt(response.data[0].Id));
                            form.change('selectedPayee', response.data[0]);
                        } else {
                            form.change('selectedPayee', null);
                        }

                        break;

                    case 400:
                        setPayees([]);
                        form.change('selectedPayee', null);

                        let errorMessage = 'There was a problem.';
                        if (typeof response.data.HasValidationErrors !== 'undefined') {
                            errorMessage = getErrorMessage(response.data);
                        }

                        dispatch(showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: errorMessage } }
                        ));

                        break;

                    default:
                        setPayees([]);
                        form.change('selectedPayee', null);

                        dispatch(showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: 'Oops, something went wrong!' } }
                        ));
                }
            }).catch(() => {}).then(() => {
                dispatch(fetchEnd());
            });
        } else {
            setSelectedPayee(0);
            setPayees([]);
        }
    }, [formState.values.selectedFamilyMember]); // eslint-disable-line


    const onChange = event => {
        event.persist();

        form.change('selectedPayee', payees.find(payee => payee.Id === parseInt(event.target.value)));
        setSelectedPayee(parseInt(event.target.value));
    }


    return (
        <Card className={props.classes.card} style={{paddingBottom: 15}}>
            <CardHeader titleTypographyProps={{variant: "body1"}} title='2. Parent / Payee information' />
            <CardContent style={{paddingLeft: 15, paddingRight: 15}}>
                {
                    payees.length > 0 ?
                        <RadioGroup value={selectedPayee} onChange={onChange} >
                            {
                                payees.map(payee => {
                                    return (
                                        <FormControlLabel
                                            key={payee.Id}
                                            value={payee.Id}
                                            control={<Radio color='primary' />}
                                            label={payee.Info}
                                        />
                                    );
                                })
                            }
                        </RadioGroup>
                        : <Typography variant='body1'>No Payee information</Typography>
                }
            </CardContent>
        </Card>
    );
}

export default ParentPayeeInformation;
