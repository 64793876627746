import React, {useState, useEffect, useRef} from 'react';
import {FormHelperText, Grid} from '@material-ui/core';
import {
    SelectInput,
    AutocompleteArrayInput,
    ReferenceInput,
    required,
    fetchEnd,
    fetchStart,
    showNotification,
    GET_ONE, ReferenceArrayInput, SelectArrayInput
} from 'react-admin';
import {TimePicker, DatePicker} from '../../../common';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import moment from "moment";
import {useForm} from 'react-final-form';
import Divider from '@material-ui/core/Divider';
import {startsAtValidation, endsAtValidation} from '../../../validators/startEndTimeValidation';
import {fetchIt} from "../../../helpers";


const EventsDatesInput = props => {
    const baseScheduleDayOfWeek = {
        Studio: undefined,
        StartDate: undefined,
        StartTime: undefined,
        EndTime: undefined,
        Teachers: [],
    };

    const form = useForm();
    const formState = form.getState();
    const [scheduleDaysOfWeek, setScheduleDaysOfWeek] = useState([baseScheduleDayOfWeek]);
    const [semesterStartDate, setSemesterStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [semesterEndDate, setSemesterEndDate] = useState('2100-01-01');
    const [refresh, setRefresh] = useState(false);
    const bottom = useRef(null);
    const scheduleDaysOfWeekLength = scheduleDaysOfWeek.length;


    const scrollToBottom = () => {
        if (bottom.current) {
            bottom.current.scrollIntoView({behavior: "smooth"})
        }
    };


    const errorsLength = Object.keys(formState.errors).length;

    useEffect(() => {
        if (props.activeTab === 1) {
            if (errorsLength > 0) {
                props.disallowStep(2);
                props.setSsdErrors(formState.errors.MonthlyEventsInput);
            } else {
                props.allowStep(2);
            }
        }
    }, [errorsLength]);


    useEffect(scrollToBottom, [scheduleDaysOfWeekLength]);


    useEffect(() => {
        form.change('MonthlyEventsInput.Studio0', baseScheduleDayOfWeek.Studio);
        form.change('MonthlyEventsInput.StartDate0', baseScheduleDayOfWeek.StartDate);
        form.change('MonthlyEventsInput.StartTime0', baseScheduleDayOfWeek.StartTime);
        form.change('MonthlyEventsInput.EndTime0', baseScheduleDayOfWeek.EndTime);
        form.change('MonthlyEvents', scheduleDaysOfWeek);

        setRefresh(!refresh);
    }, []);


    useEffect(() => {
        let newSemesterStartDate = moment().format('YYYY-MM-DD');
        let newSemesterEndDate = '2100-01-01';

        if (formState.values.Semester !== undefined) {
            const semester = props.semesters.find(sem => {
                return parseInt(sem.id) === parseInt(formState.values.Semester);
            });

            if (!!semester) {
                newSemesterStartDate = moment(semester.FromDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
                newSemesterEndDate = moment(semester.ToDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
            }
        }

        setSemesterStartDate(newSemesterStartDate);
        setSemesterEndDate(newSemesterEndDate);
    }, [formState.values.Semester]);


    const addDayOfWeek = () => {
        props.setSsdErrors({});

        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        let previousIndex = 0;
        if (formState.values.MonthlyEvents) {
            previousIndex = formState.values.MonthlyEvents.length - 1;
        }
        let index = formState.values.MonthlyEvents.length;
        const previousDay  = formState.values.MonthlyEvents[previousIndex];
        const newDay = {
            Studio: previousDay.Studio,
            StartDate: moment(previousDay.StartDate).add(1, 'day').format('MM/DD/YYYY'),
            EndTime: previousDay.EndTime,
            StartTime: previousDay.StartTime,
            Teachers: previousDay.Teachers,
        }

        newScheduleDaysOfWeek.push(Object.assign({}, newDay));

        form.change('MonthlyEventsInput.Studio' + index, newDay.Studio);
        form.change('MonthlyEventsInput.StartDate' + index, newDay.StartDate);
        form.change('MonthlyEventsInput.StartTime' + index, moment(previousDay.StartTime, 'h:mmA'));
        form.change('MonthlyEventsInput.EndTime' + index, moment(previousDay.EndTime, 'h:mmA'));
        form.change('MonthlyEventsInput.Teachers' + index, newDay.Teachers);
        form.change('MonthlyEvents', newScheduleDaysOfWeek);
    }


    const removeDayOfWeek = index => {
        props.setSsdErrors({});
        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        newScheduleDaysOfWeek.splice(index,1);

        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setRefresh(!refresh);
    };


    const onStudioChange = (event, index) => {
        event.persist();
        props.setSsdErrors({});

        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        newScheduleDaysOfWeek[index].Studio = event.target.value;

        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setRefresh(!refresh);
        form.change('MonthlyEvents', newScheduleDaysOfWeek);
    }


    const onCourseDateChange = (value, index) => {
        props.setSsdErrors({});

        let newScheduleDaysOfWeek = scheduleDaysOfWeek;

        let newDate = '';
        if (value) {
            newDate = value.format('MM/DD/YYYY');
        }

        newScheduleDaysOfWeek[index].StartDate = newDate;
        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setRefresh(!refresh);
        form.change('MonthlyEvents', newScheduleDaysOfWeek);
        form.change('MonthlyEventsInput.StartDate' + index, value);
    }


    const onStartTimeChange = (value, index) => {
        props.setSsdErrors({});

        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        let newStartTime = '';

        if (value) {
            newStartTime = value.format('h:mmA');
        }

        newScheduleDaysOfWeek[index].StartTime = newStartTime;
        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setRefresh(!refresh);

        form.change('MonthlyEvents', newScheduleDaysOfWeek);
        form.change('MonthlyEventsInput.StartTime' + index, value);
    };


    const onEndTimeChange = (value, index) => {
        props.setSsdErrors({});

        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        let newEndTime = '';

        if (value) {
            newEndTime = value.format('h:mmA');
        }

        newScheduleDaysOfWeek[index].EndTime = newEndTime;
        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setRefresh(!refresh);

        form.change('MonthlyEvents', newScheduleDaysOfWeek);
        form.change('MonthlyEventsInput.EndTime' + index, value);
    };


    const onTeacherChange = (value, index) => {
        props.setSsdErrors({});

        let newScheduleDaysOfWeek = scheduleDaysOfWeek;
        newScheduleDaysOfWeek[index].Teachers = value;
        setScheduleDaysOfWeek(newScheduleDaysOfWeek);
        setRefresh(!refresh);

        form.change('MonthlyEvents', newScheduleDaysOfWeek);
        form.change('MonthlyEventsInput.Teachers' + index, value);
    };


    return (
        <>
            {
                scheduleDaysOfWeek.map((sdow, index) => {
                    let hasError = false;
                    if ((errorsLength > 0) && (typeof formState.errors.MonthlyEventsInput !== 'undefined')) {
                        if (
                            (typeof formState.errors.MonthlyEventsInput['Studio' + index] !== 'undefined') ||
                            (typeof formState.errors.MonthlyEventsInput['StartDate' + index] !== 'undefined') ||
                            (typeof formState.errors.MonthlyEventsInput['StartTime' + index] !== 'undefined') ||
                            (typeof formState.errors.MonthlyEventsInput['EndTime' + index] !== 'undefined')
                        ) {
                            hasError = true;
                        }
                    }

                    return (
                        <div key={index} style={{marginTop: 20}}>
                            <Grid container style={{border: (hasError ? '1px solid red' : 'none')}}>
                                <Grid item xs={6}>
                                    <ReferenceInput
                                        label='Studio'
                                        source={'MonthlyEventsInput.Studio' + index}
                                        resource='studios'
                                        reference='studios'
                                        filter={{IsActive: true}}
                                        perPage={1000}
                                        validate={required('The Studio field is required')}
                                        onChange={event => onStudioChange(event, index)}
                                    >
                                        <SelectInput optionText='Name' value={sdow.Studio} />
                                    </ReferenceInput>
                                    <DatePicker
                                        source={'MonthlyEventsInput.StartDate' + index}
                                        label='Date'
                                        dateFormat='MM/DD/YYYY'
                                        validate={required('The Date field is required')}
                                        disableFuture={false}
                                        onChange={value => onCourseDateChange(value, index)}
                                        val={moment(sdow.StartDate, 'MM/DD/YYYY')}
                                        minDate={semesterStartDate}
                                        maxDate={semesterEndDate}
                                    />

                                    {
                                        !!props.ssdErrors['StartDate' + index] ?
                                            <FormHelperText error style={{marginTop: -20}}>
                                                {props.ssdErrors['StartDate' + index]}
                                            </FormHelperText>
                                            : null
                                    }
                                </Grid>
                                <Grid item xs={6} style={{paddingBottom: 0,  textAlign: 'end'}}>
                                    <TimePicker
                                        source={'MonthlyEventsInput.StartTime' + index}
                                        label='Starts'
                                        onChange={value => onStartTimeChange(value, index)}
                                        validate={startsAtValidation}
                                    />

                                    <TimePicker
                                        source={'MonthlyEventsInput.EndTime' + index}
                                        label='Ends'
                                        onChange={value => onEndTimeChange(value, index)}
                                        validate={endsAtValidation}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <ReferenceArrayInput
                                        source={'MonthlyEventsInput.Teachers' + index}
                                        label='Teachers'
                                        resource='teachers'
                                        reference='teachers'
                                        filter={{IsActive: true}}
                                        perPage={1000}
                                        onChange={event => onTeacherChange(event, index)}
                                        fullWidth
                                    >
                                        <AutocompleteArrayInput
                                            optionText='Name'
                                            optionValue='Id'
                                            options={{fullWidth: true, suggestionsContainerProps: {style: {zIndex: 2000}, transition: true}}}
                                        />
                                    </ReferenceArrayInput>
                                    <FormHelperText style={{marginTop: -10}}>You can choose more than one teacher</FormHelperText>
                                </Grid>
                            </Grid>

                            {
                                scheduleDaysOfWeekLength > 1 ?
                                    <Grid container justifyContent="center" spacing={5}>
                                        <Grid item xs={12} sm={6} md={3} lg={2} xl={1} style={{paddingLeft: 0}} >
                                            <Button
                                                variant="contained"
                                                component="span"
                                                color='secondary'
                                                startIcon={<RemoveIcon />}
                                                style={{
                                                    borderRadius: 20,
                                                    textTransform: 'unset'
                                                }}
                                                onClick={() => removeDayOfWeek(index)}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    </Grid>
                                : null
                            }
                            <Divider style={{marginTop: 20}} />
                        </div>
                    );
                })
            }

            <Grid container justifyContent="center" spacing={5}>
                <Grid item xs={5}>
                    <Button
                        variant="outlined"
                        component="span"
                        startIcon={<AddIcon />}
                        style={{
                            borderRadius: 20,
                            textTransform: 'unset',
                            width: '100%',
                            marginTop: 20
                        }}
                        onClick={addDayOfWeek}
                        ref={bottom}
                    >
                        Add more days for this course
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default EventsDatesInput;
