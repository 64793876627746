import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import moment from 'moment';


const LocationWorkingTimeList = props => {
    
    const createData = (day, startTime, endTime) => {
        return {day, startTime, endTime};
    }

    const rows = [];   
    for (let t = 0; t < props.record.locations_working_time.length; t++) { 
        rows.push(createData(
            props.record.locations_working_time[t].WorkDay, 
            moment.utc(props.record.locations_working_time[t].WorkStartTime, 'HH:mm:ss').format('h:mm A'),
            moment.utc(props.record.locations_working_time[t].WorkEndTime, 'HH:mm:ss').format('h:mm A'), 
        ));
    }

    return props.record.locations_working_time.length ? (
        <TableContainer style={{paddingBottom: 25}}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Day</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, key) => (                  
                        <TableRow key={key}>
                            <TableCell>{row.day}</TableCell>
                            <TableCell>{row.startTime}</TableCell>
                            <TableCell>{row.endTime}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>     
    ) : null;
}

export default LocationWorkingTimeList;
