import React from 'react';
import {DetailsLayout, DetailsTileWrapper} from "../resources/common";
import {FunctionField, ImageField, NumberField, RichTextField, TextField} from "react-admin";
import {renderYearsFromMonths} from "../helpers";
import {Grid} from "@material-ui/core";


const CourseDetails = props => {
    const options = {
        detailsTitle: 'course',
        removeListButton: true,
        removeActions: true,
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={8}>
                <div style={{margin: 16}}>
                    <DetailsLayout {...props} resource='courses' id={props.match.params.course} options={options}>
                        <DetailsTileWrapper
                            sideTitle='Course Details'
                            sideText='Course details here.'
                        >
                            <TextField source='Name' label='Course Name' />
                            <RichTextField source='Description' />
                            <FunctionField source='MinAge' render={record => renderYearsFromMonths(record, 'MinAge') + ' yrs.'} />
                            <FunctionField source='MaxAge' render={record => renderYearsFromMonths(record, 'MaxAge') + ' yrs.'} />
                            <NumberField source="Duration" label="Duration (in minutes)" />
                            <TextField source="Places" />
                            <FunctionField source='SingleClassPrice' render={record => '$' + record.SingleClassPrice} />
                            <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                        </DetailsTileWrapper>

                        <DetailsTileWrapper
                            sideTitle='Course Image'
                            sideText='Course Image here.'
                            passComponent={true}
                        >
                            <ImageField
                                source='ImageUrl'
                                style={{marginLeft: 'auto', marginRight: 'auto', display: 'table'}}
                            />
                        </DetailsTileWrapper>
                    </DetailsLayout>
                </div>
            </Grid>
        </Grid>
    );
}

export default CourseDetails;
