import React, {Fragment, useState, useEffect} from 'react';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {useForm, useFormState} from 'react-final-form';
import {hexToRgb} from './helpers'
import Tooltip from '@material-ui/core/Tooltip';
import {Link} from 'react-router-dom';


const useStylesSchedule = makeStyles(theme => ({
    courseDetails: {
        color: theme.palette.common.black,
        textDecoration: 'none',
        '&:visited': {
            color: theme.palette.common.black,
            textDecoration: 'none',
        }
    },
}));


const useStylesTooltip = makeStyles(theme => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        maxWidth: 300,
        fontSize: 14,
        backgroundColor: theme.palette.common.black,
    },
    tooltipPlacementTop: {
        margin: '9px 0px'
    },
}));


const CustomTooltip = props => {
    const classes = useStylesTooltip();

    return <Tooltip arrow classes={classes} {...props} />;
}


const useStyles = makeStyles(() => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        padding: 0,
        paddingRight: 4,
        marginTop: -1,
    },
    icon: {
        borderRadius: 3,
        width: 10,
        height: 10,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '1px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 10,
            height: 10,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));



const StyledCheckbox = props => {
    const classes = useStyles();
    const {IsDraft, ...other} = props;

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={classnames(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{'aria-label': 'decorative checkbox', 'data-schedule': props['data-schedule'], 'disabled': IsDraft}}
            {...other}
        />
    );
}



const getStyles = (resource, schedule, innerSlot, targetSlots) => {
    const scheduleLeft = schedule.StartMinutes - (innerSlot * (60 / targetSlots));

    return {
        position: 'absolute',
        top: schedule.top,
        left: (180 / 60) * scheduleLeft,
        width: (180 / 60) * schedule.Duration - 1,
        backgroundColor: '#ffffff',
        borderLeft: '3px solid ' + (schedule.BelongsToAnotherSemester || schedule.IsDraft ? '#666666' : resource.color),
        boxSizing: 'border-box',
        zIndex: 4,
        opacity: 1,
        height: (schedule.BelongsToAnotherSemester ? 58 : 57),
        cursor: 'pointer',
        overflow: 'hidden',
        userSelect: 'none',
    }
}



const CheckboxedSchedule = props => {
    const form = useForm();
    const formState = useFormState();

    const {resource, schedule, timeSlot, innerSlot, targetSlots, backgroundColor, refresh, shouldRefresh} = props;
    const [forceUpdate, setForceUpdate] = useState(false);
    const [checked, setChecked] = useState(false);

    const classes = useStylesSchedule();


    const rgbColor = hexToRgb('#666666');
    const scheduleBackgroundColor = schedule.IsDraft ?
        'rgba(' + rgbColor.r + ', ' + rgbColor.g + ', ' + rgbColor.b + ', 0.15)' :
        backgroundColor;

    useEffect(() => {
        if (formState && formState.values.Schedules && formState.values.Schedules.hasOwnProperty('sch-' + schedule.Schedule)) {
            setChecked(true);
        }
    }, []);


    useEffect(() => {
        if (formState && formState.values.Schedules && formState.values.Schedules.hasOwnProperty('sch-' + schedule.Schedule)) {
            setChecked(true);
        } else if (checked) {
            setChecked(false);
        }
    }, [formState.values.SchedulesChanged]); // eslint-disable-line


    useEffect(() => {
        if (refresh && (shouldRefresh !== forceUpdate)) {
            setForceUpdate(shouldRefresh);
        }
    }, [refresh, shouldRefresh]); // eslint-disable-line


    const containerStyle = getStyles(resource, schedule, innerSlot, targetSlots, props.top);

    const start = moment.utc(schedule.StartHour + ':' + schedule.StartMinutes, 'H:m');
    const end = moment.utc(start.format('H:m'), 'H:m').add(schedule.Duration, 'minutes');

    const toggleChecked = event => {
        event.persist();

        setChecked(event.target.checked);

        let schedules = {};
        if (formState.values.Schedules) {
            schedules = formState.values.Schedules;
        }

        if (event.target.checked) {
            schedules['sch-' + schedule.Schedule] = JSON.parse(event.target.dataset.schedule);
        } else {
            delete schedules['sch-' + schedule.Schedule];
        }

        form.change('Schedules', schedules);
        form.change('SchedulesChanged', !formState.values.SchedulesChanged);
    };


    const renderSchedule = () => {
        return (
            <div
                style={containerStyle}
                data-get-parent={true}
            >
                <label htmlFor={'sch-' + schedule.Id} style={{cursor: 'pointer'}}>
                    <div
                        style={{
                            width: '100%',
                            height: 58,
                            backgroundColor: scheduleBackgroundColor,
                            fontSize: 10,
                            lineHeight: '10px',
                            padding: 5,
                            boxSizing: 'border-box'
                        }}
                        data-target-slots={targetSlots}
                        data-time-slot={timeSlot}
                        data-time-subslot={innerSlot}
                        data-weekday={schedule.DayOfWeek}
                        data-resource={schedule.Studio}
                        data-schedule={schedule.Id}
                        data-parent-schedule={schedule.Schedule}
                        data-get-parent={true}
                    >
                        <div
                            data-get-parent={true}
                            style={{
                                paddingBottom: 3,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <StyledCheckbox
                                id={'sch-' + schedule.Id}
                                data-schedule={JSON.stringify(schedule)}
                                checked={checked}
                                onChange={toggleChecked}
                                IsDraft={schedule.IsDraft}
                            />
                            <Link to={'/course-details/' + schedule.Course} target="_blank" className={classes.courseDetails}>
                                {schedule.CourseName}
                            </Link>
                        </div>

                        <div
                            data-get-parent={true}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                paddingBottom: 3,
                            }}
                        >
                            {start.format('h:mmA')} - {end.format('h:mmA')}
                        </div>

                        <div
                            data-get-parent={true}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                paddingBottom: 3,
                            }}
                        >
                            Ages: {schedule.AgeTitle}
                        </div>

                        {
                            schedule.Places ?
                                <div
                                    data-get-parent={true}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                   Free Places: {schedule.FreePlaces}/{schedule.Places}
                                </div>
                            : null
                        }
                    </div>
                </label>
            </div>
        );
    }

    if (schedule.IsDraft && schedule.DraftNotes) {
        const draftNotesTitle = (
            <Fragment>
                Starts at: {schedule.StartDate}<br />
                Ends at: {schedule.EndDate}<br />
                Price per visit: ${schedule.SingleEventPrice}<br />
                Number of classes per semester: {schedule.TotalClasses}<br /><br />
                {schedule.DraftNotes}
            </Fragment>
        );

        return (
            <CustomTooltip title={draftNotesTitle} placement="top">
                {renderSchedule()}
            </CustomTooltip>
        )
    } else {
        let teachers = 'There are no teachers assigned.';
        if (schedule.Teachers.length > 0) {
            teachers = 'Teachers: ' + schedule.Teachers.map(teacher => teacher.Name).join(', ');
        }

        const title = (
            <Fragment>
                Starts at: {schedule.StartDate}<br />
                Ends at: {schedule.EndDate}<br />
                Price per visit: ${schedule.SingleEventPrice}<br />
                Number of classes per semester: {schedule.TotalClasses}<br />
                {teachers}
            </Fragment>
        );

        return (
            <CustomTooltip
                title={title}
                placement="top"
            >
                {renderSchedule()}
            </CustomTooltip>
        )
    }
};

export default CheckboxedSchedule;
