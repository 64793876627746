import React, {useState} from 'react';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import OverlappedSchedulesList from './OverlappedSchedulesList';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';


const OverlappedSchedules = props => {

    const styles = theme => ({
        root: {
            margin: 0,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });
    
    const DialogTitle = withStyles(styles)(props => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });
    
    const [showDialog, setShowDialog] = useState(false);

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    }

    return ( 
        <>
            <Chip
                icon={<ErrorIcon style={{color: 'white'}}/>}
                label={'Overlapped Schedules: ' + props.overlappedSchedules.length}
                style={{fontWeight: 700, marginRight: 10}}
                color='secondary'
                onClick={handleClick}
            />
            
            <Dialog
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Overlapped Schedules"
                maxWidth='xl'
            >
                <DialogTitle onClose={handleCloseClick} style={{paddingBottom: 0}}>
                    Overlapped Schedules
                </DialogTitle>
                <DialogContent style={{paddingLeft: 25, paddingRight: 25}}>
                    <OverlappedSchedulesList overlappedSchedules={props.overlappedSchedules} />
                </DialogContent>
            </Dialog>
        </>
    );  
};


export default OverlappedSchedules;
