import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {CreateButton, useListContext} from 'react-admin';
import InboxIcon from '@material-ui/icons/Inbox';

const EmptyList = (props) => {
    const {basePath, resource} = useListContext();
    return (
        <Box textAlign="center" m={1}>
            <InboxIcon style={{color: '#7E7E80', width: '9em', height: '9em'}} />
            <Typography style={{color: '#7E7E80'}} variant="h4" paragraph>
                {props.emptyTitle}
            </Typography>
            <Typography style={{color: '#7E7E80'}} variant="body1">
                Do you want to add one?
            </Typography>
            <CreateButton variant='contained' basePath={basePath} style={{marginTop: '2em'}} />
        </Box>
    );
};

export default EmptyList;