import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Notification from '../../layout/Notification';
import {
    SimpleForm,
    TextInput,
    fetchEnd,
    fetchStart,
    showNotification,
    userLogin,
} from "react-admin";
import {passwordValidation, repeatPasswordValidation} from "../../validators";
import {useDispatch} from 'react-redux';
import {fetchIt, getErrorMessage} from "../../helpers";
import {Redirect} from "react-router";
import {NtaSaveButton} from "../../resources/common";



const styles = ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        height: '1px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

    formPaper: {
        padding: "25px 0"
    }
});


const TeacherPassword = props => {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const hash = props.match.params.hash;
    const tenant = props.match.params.tenant;

    if (!hash || hash.length === 0 || !tenant || tenant.length === 0) {
        dispatch(showNotification(
            'direct_message',
            'warning',
            { messageArgs: { _: 'No hash provided!' } }
        ));

        return (
            <Redirect to='/login' />
        );
    }

    const {classes, staticContext, ...other} = props;


    const onSubmit = values => {
        setIsDisabled(true);

        // Dispatch an action letting react-admin know an API call is ongoing
        dispatch(fetchStart());

        const data = {
            Hash: hash,
            Password: values.Password
        }

        fetchIt(
            'post',
            'users_activate',
            data,
            {'x-lms-tnt': tenant}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'New password set.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    dispatch(showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    ));

                    const auth = {
                        username: response.data.Email,
                        password: values.Password
                    };

                    dispatch(userLogin(auth, ''));

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    ));

                    break;

                default:
                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    ));
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            dispatch(fetchEnd());

            setIsDisabled(false);
        });
    };


    return (
        <div className={classes.main}>
            <Grid container>
                <Grid item xs={12} md={4}> </Grid>
                <Grid item xs={12} md={4}>
                    <Grid container>
                        <Grid item xs={12} md={2}> </Grid>
                        <Grid item xs={12} md={9}>
                            <Paper className={classes.formPaper}>
                                <Typography component="h1" variant="h5" align="center">Set new password</Typography>
                                <SimpleForm
                                    {...other}
                                    save={onSubmit}
                                    toolbar={<NtaSaveButton isDisabled={isDisabled} style={{marginTop: 15}} />}
                                    align="center"
                                    style={{textAlign: 'center'}}
                                >
                                    <TextInput
                                        source='Password'
                                        type='password'
                                        validate={passwordValidation}
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source='RepeatPassword'
                                        type='password'
                                        validate={repeatPasswordValidation}
                                        fullWidth
                                        variant="standard"
                                    />
                                </SimpleForm>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Notification />
        </div>
    );
}


export default withStyles(styles)(TeacherPassword);
