import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Confirm, useNotify, useRedirect, useRefresh, DELETE} from 'react-admin';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {withStyles, createStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {alpha} from '@material-ui/core/styles/colorManipulator';
import {fetchIt, getErrorMessage} from "../../helpers";


const sanitizeRestProps = ({
                               basePath,
                               classes,
                               dispatch,
                               filterValues,
                               handleSubmit,
                               handleSubmitWithRedirect,
                               invalid,
                               label,
                               pristine,
                               resource,
                               saving,
                               selectedIds,
                               submitOnEnter,
                               redirect,
                               onClick,
                               dataProvider,
                               successMessage,
                               errorMessage,
                               bulkSuccessMessage,
                               bulkErrorMessage,
                               confirmTitle,
                               confirmContent,
                               ...rest
                           }) => rest;

const styles = theme => createStyles({
    deleteButton: {
        // color: theme.palette.error.main,
        color: '#f44336 !important',
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
});


const DeleteButton = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const deleteIt = () => {
        const {
            basePath,
            successMessage,
        } = props;

        setLoading(true);

        fetchIt(
            DELETE,
            basePath.replace(/[/]/g,''),
            {id: (props.record ? props.record.id : 0)}
        ).then(response => {
            switch (response.status) {
                case 200:
                    redirect(basePath);
                    refresh(true);
                    notify('direct_message', 'info', {_: successMessage}, false);

                    break;

                case 400:
                    let message = getErrorMessage(response.data);

                    notify('direct_message', 'warning', {_: message});

                    break;

                default:
                    notify('direct_message', 'warning', {_: 'Oops, something went wrong!'});
                }
        }).catch(error => {

        }).then(() => {
            setLoading(false);
        });
    }


    const handleClick = e => {
        e.stopPropagation();

        setIsOpen(true);
    };


    const handleDialogClose = () => {
        setIsOpen(false);
    };


    const handleDelete = () => {
        handleDialogClose();

        if (props.selectedIds) {
            // deleteThem();
        } else if (props.record) {
            try {
                deleteIt();
            } catch (e) {
                console.log(e);
            }
        }
    };


    return (
        <Fragment>
            <Button
                onClick={handleClick}
                label="Delete"
                className={classnames(
                    'nta-delete-button',
                    props.classes.deleteButton,
                    props.className
                )}
                key="button"
                disabled={loading}
                {...sanitizeRestProps(props)}
            >
                <DeleteIcon/>
            </Button>
            <Confirm
                isOpen={isOpen}
                title={props.confirmTitle}
                content={props.confirmContent}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

DeleteButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    record: PropTypes.object,
    resource: PropTypes.string.isRequired,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    bulkSuccessMessage: PropTypes.string,
    bulkErrorMessage: PropTypes.string,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string
};

DeleteButton.defaultProps = {
    successMessage: 'Element deleted',
    errorMessage: 'Element NOT deleted',
    bulkSuccessMessage: 'Elements deleted',
    bulkErrorMessage: 'Elements NOT deleted',
    confirmTitle: 'Delete element',
    confirmContent: 'Are you sure you want to delete this element?'
};

export default withStyles(styles)(DeleteButton);
