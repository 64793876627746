import React from 'react';
import {
    TextField,
    RichTextField,
    FunctionField,
    NumberField
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../common';
import CourseImage from '../containers/CourseImage';
import {renderYearsFromMonths} from '../../../helpers';


export const CourseShow = props => {
    const options = {
        detailsTitle: 'course',
    };

    return (
        <>
            <DetailsLayout {...props} options={options}>
                <DetailsTileWrapper
                    sideTitle='Course Details'
                    sideText='Add your Course here.'
                >
                    <TextField source='Name' label='Course Name' />
                    <RichTextField source='Description' />
                    <FunctionField source='MinAge' render={record => renderYearsFromMonths(record, 'MinAge') + ' yrs.'} />
                    <FunctionField source='MaxAge' render={record => renderYearsFromMonths(record, 'MaxAge') + ' yrs.'} />
                    <NumberField source="Duration" label="Duration (in minutes)" />
                    <TextField source="Places" />
                    <FunctionField source='SingleClassPrice' render={record => '$' + record.SingleClassPrice} />
                    <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                </DetailsTileWrapper>

                <DetailsTileWrapper
                    sideTitle='Course Image'
                    sideText='Add Course Image here.'
                    passComponent={true}
                >
                    <CourseImage source='course_image.Image' />
                </DetailsTileWrapper>
            </DetailsLayout>
        </>
    );
};
