import React from 'react';
import {
    List,
    Datagrid,
    FunctionField,
    TextField
} from 'react-admin';
import {RegistrationsListFilters} from "./RegistrationsListFilters";
import exporter from "../../helpers/exporter";
import RegistrationSplitButton from '../../containers/RegistrationSplitButton';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';


export const isCanceledRowStyle = (record) => ({
    backgroundColor: record.Canceled ? '#eaeaea' : 'white',
});

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -14,
      top: 10,
    },
}))(Badge);

const StatusField = record => {
    if (record.Canceled) {
        return (<>Canceled</>);
    } else {
        if (record.IsPartlyCanceled) {
            return (
                <Tooltip title="Has Canceled Schedule Student">
                    <StyledBadge badgeContent={'!'} color='secondary'>Active</StyledBadge>
                </Tooltip>
            )
        } else {
            return (<>Active</>);
        }
    }
}


export const RegistrationsList = props => (
    <div className="wrapperContent">
        <List
            {...props}
            filters={<RegistrationsListFilters />}
            exporter={exporter}
            title='Registrations List'
            bulkActionButtons={false}
            sort={{field: 'id', order: 'DESC'}}
            perPage={25}
        >
            <Datagrid rowStyle={isCanceledRowStyle} isRowSelectable={record => record.Canceled}>
                <TextField source='Id' />
                <TextField label='Date' source='EnrollmentDate' />
                <FunctionField
                    label='Student'
                    source='Students'
                    render={record => record.Students ? record.Students.map(student => student.Name).join(', ') : ''} 
                />
                <TextField source='PayeeName' label='Payee' />
                <FunctionField
                    label='Type'
                    source='IsPartial'
                    render={record => record.IsPartial ? 'Partial' : 'Full'} 
                />                
                <FunctionField label='Payment Status' source='PaymentReceived' render={record => record.PaymentReceived ? 'Paid' : 'Not paid'} />
                <FunctionField label='Status' source='Canceled' render={StatusField}   />
                <RegistrationSplitButton />
            </Datagrid>
        </List>
    </div>
);
