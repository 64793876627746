import React, {Fragment} from 'react';
import {Create} from 'react-admin';
import {HolidayForm} from '../';
import {BackButton} from '../../../common';


const HolidayCreate = props => {
    return (
        <Fragment>
            <BackButton />
            <Create {...props} title="Create Holiday">
                <HolidayForm style={{marginTop: 0}} />
            </Create>
        </Fragment>
    );
};

export default HolidayCreate;
