import React, {Fragment} from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
} from 'react-admin';
import {UsersListFilters} from '../../';
import {DeleteButton} from '../../../../common';
import {formatPhone, inArray} from '../../../../helpers';
import ExcelJS from "exceljs";
import {saveAs} from 'file-saver';
import SplitButton from '../../../../common/components/SplitButton';
import EmptyList from '../../../../components/EmptyList';


export const UsersBulkActionButtons = props => (
    <Fragment>
        <DeleteButton
            {...props}
            bulkSuccessMessage='Users deleted'
            bulkErrorMessage='Users NOT deleted'
            confirmTitle='Delete selected users'
            confirmContent='Are you sure you want to delete the selected users?'
        />
    </Fragment>
);


export const exporter = records => {
    const headers = [{
        Id: 'Id',
        FirstName: 'First Name',
        LastName: 'Last Name',
        Email: 'Email',
        CellPhone: 'Cell Phone',
        //Status: 'Status',
        //AreNotificationsEnabled: 'Are Notifications Enabled'
    }];


    const rightAligned = ['Id'];


    let data = records.map(record => {
        return ({
            Id: record.Id !== null ? record.Id : '',
            FirstName: record.FirstName !== null ? record.FirstName : '',
            LastName: record.LastName !== null ? record.LastName : '',
            Email: record.Email !== null ? record.Email : '',
            CellPhone: record.CellPhone !== null ? formatPhone(record.CellPhone) : '',
            //Status: record.IsActive ? 'Active' : 'Inactive',
            //AreNotificationsEnabled: record.AreNotificationsEnabled ? 'Yes' : 'No',
        });
    });


    data = headers.concat(data);


    let objectMaxLength = [];
    for (let t = 0; t < data.length; t++) {
        for (let key in data[t]) {
            if (data[t].hasOwnProperty(key)) {
                objectMaxLength[key] = objectMaxLength[key] >= ((data[t][key] + '').length + 2) ?
                    objectMaxLength[key] :
                    ((data[t][key] + '').length + 2);
            }
        }
    }

    data.shift();

    let newHeaders = [];
    for (let key in headers[0]) {
        if (headers[0].hasOwnProperty(key)) {
            newHeaders.push({
                key: key,
                header: headers[0][key],
                width: objectMaxLength[key],
                style: (inArray(key, rightAligned) ? {alignment: {vertical: 'middle', horizontal: 'right'}} : {})
            });
        }
    }


    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Customers');
    sheet.columns = newHeaders;
    for (let t = 0; t < data.length; t++) {
        sheet.addRow(data[t]);
    }

    let row = sheet.getRow(1);
    row.font = {bold: true};


    workbook.xlsx.writeBuffer()
    .then(buffer => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const blob = new Blob([buffer], {type: fileType});

        return saveAs(blob, 'Customers.xlsx')
    })
    .catch(err => console.log('Error writing excel export', err));
};



export const isDeletedRowStyle = (record) => ({
    backgroundColor: record.IsDeleted ? '#eaeaea' : 'white',
});


export const UsersList = props => (
    <div className="wrapperContent">
        <List
            empty={<EmptyList emptyTitle='No customers' />}
            filters={<UsersListFilters />}
            {...props}
            title='Customers List'
            bulkActionButtons={false}
            exporter={exporter}
            sort={{field: 'id', order: 'DESC'}}
            perPage={25}
        >
            <Datagrid rowStyle={isDeletedRowStyle}>
                <FunctionField
                    label='Name'
                    render={record => `${record.FirstName} ${record.LastName}`}
                    sortBy="FirstName"
                />
                <TextField source='Email' />
                <FunctionField
                    label='Phone'
                    render={record => {return `${record.CellPhone}` === 'null' ? '' : formatPhone(`${record.CellPhone}`)}}
                />
                <FunctionField label='Status' source='IsActive' render={record => record.IsDeleted ? 'Deleted' : 'Active'} />
                {/* <FunctionField
                    label='Status'
                    source='IsActive'
                    render={
                        record => {
                            return record.IsDeleted ?
                                "Deleted"
                                :
                                record.IsActive ? 'Active' : 'Inactive'
                        }
                    }
                /> */}
                <TextField source='Role' />
                <SplitButton 
                    cellClassName='splitButtonTdSmall' 
                    smallTd 
                    successMessage='Customer deleted'
                    errorMessage='Customer NOT deleted'
                    confirmTitle='Delete the customer'
                    confirmContent='Are you sure you want to delete customer?'
                />
            </Datagrid>
        </List>
    </div>
);
