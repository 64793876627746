import React, {Fragment, Component} from 'react';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {ShowButton} from 'react-admin';
import RegistrationCancelButton from '../../containers/RegistrationCancelButton';
import PaymentIcon from '@material-ui/icons/Payment';
import {CREATE} from 'react-admin';
import {fetchIt} from '../../../../../helpers';



class RegistrationSplitButton extends Component {
    _isMounted = false;
    anchorRef = null;
    options = [];

    constructor(props) {
        super(props);

        this.anchorRef = React.createRef();
    }

    state = {
        open: false,
        selectedIndex: 0,
        record: {}
    };

    onPaymentResend = (registrationId) => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
 
         // Dispatch an action letting react-admin know an API call is ongoing
         fetchStart();
 
        fetchIt(
            CREATE,
            'registration_resend_payment_link',
            {id: registrationId}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Schedule Student canceled.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:
                default:
                showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        }); 
    }

    componentDidMount() {
        this._isMounted = true;

        this.setState({
            record: this.props.record
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.record && this._isMounted) {
            this.setState({
                record: nextProps.record
            });
        }
    }

    handleMenuItemClick = (event, index) => {
        this.setState({
            open: false,
            selectedIndex: index
        });
    };

    handleToggle = (event, index) => {
        this.setState({ open: !this.state.open });
    };

    handleClose = (event, index) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({ open: false});
    };

    menuItems = () => {
        if (
            this.state.record && 
            this.state.record.PaymentReceived && 
            (this.state.record.Canceled || this.state.record.IsPartlyCanceled)
        ) 
        {
            return (
                <MenuItem>
                    <Button 
                        href={this.props.record.PaymentUrl}
                        target="_blank" 
                        color="primary"
                        startIcon={<PaymentIcon />}
                    >
                        Issue a refund
                    </Button>
                </MenuItem>
            );
        }

        if (this.state.record && !this.state.record.Canceled) {
            return (
                <MenuItem>
                    <RegistrationCancelButton record={this.props.record} />
                </MenuItem>
            );
        }
    }
    
    render() {

        return (
            <Fragment>
                <div ref={this.anchorRef} style={{textAlignLast: 'right', minWidth: 140}}>
                    <Button
                        color="primary"
                        size="small"
                        aria-owns={this.state.open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleToggle}
                        style={{minWidth: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                    >
                        Actions
                        <ArrowDropDownIcon />
                    </Button>
                </div>
                <Popper
                    open={this.state.open}
                    anchorEl={this.anchorRef.current}
                    transition
                    placement='bottom-start'
                    style={{zIndex: 999}}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper id="menu-list-grow">
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        <MenuItem>
                                            <ShowButton
                                                label="Details"
                                                record={this.props.record}
                                                basePath={this.props.basePath}
                                            />
                                        </MenuItem>

                                            {this.menuItems()}     

                                        {
                                            this.state.record && !this.state.record.PaymentReceived && !this.state.record.Canceled ?
                                            <MenuItem>
                                                <Button
                                                    onClick={() => this.onPaymentResend(this.props.record.Id)}
                                                    style={{color: '#3B9287'}}
                                                    startIcon={<PaymentIcon />}
                                                >
                                                    Resend Payment Link
                                            </Button>
                                        </MenuItem>
                                        :
                                            null
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Fragment>
        );
    }
}

export default RegistrationSplitButton;


