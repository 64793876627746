import React, {useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
    FormWithRedirect,
    Button,
    CREATE,
    showNotification
} from 'react-admin';
import arrayMutators from 'final-form-arrays';
import {Typography} from "@material-ui/core";
import ArrowPrevIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowNextIcon from "@material-ui/icons/KeyboardArrowRight";
import moment from "moment";
import {fetchIt} from "../../../helpers";
import EventFormView from '../containers/EventFormView';
import {useDispatch} from 'react-redux';


const StyledTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);


const StyledTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        }
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);


const useStyles = makeStyles(theme => ({
    padding: {
        padding: theme.spacing(2),
        borderBottom: '1px solid #e8e8e8',
    },
    scheduleForm: {
        backgroundColor: theme.palette.background.paper,
    },
}));


const EventForm = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [value, setValue] = useState(0);
    const [form, setForm] = useState({});
    const [forbiddenStep, setForbiddenStep] = useState(1);
    const [ssdErrors, setSsdErrors] = useState({});


    const handleChange = (event, newValue) => {
        if (value === 1) {
            const formState = form.getState();

            if (Object.keys(formState.errors).length > 0) {
                let errorMessage = 'There are errors in the Start Dates Tab';
                dispatch(showNotification(
                    'direct_message',
                    'warning',
                    { messageArgs: { _: errorMessage } }
                ));
                disallowStep(2);
                setValue(newValue);
                setSsdErrors(formState.errors.MonthlyEventsInput);
            } else {
                setValue(newValue);
            }
        } else {
            setValue(newValue);
        }
    };


    const allowStep = step => {
        setForbiddenStep(step + 1);
    };


    const disallowStep = step => {
        if (forbiddenStep > step) {
            setForbiddenStep(step);
        }
    };


    const onFormPrev = () => {
        setValue(value - 1);
    };


    const onFormNext = () => {
        if (value === 1) {
            const formState = form.getState();
            if (Object.keys(formState.errors).length > 0) {
                let errorMessage = 'There are errors in the Start Dates Tab';

                dispatch(showNotification(
                    'direct_message',
                    'warning',
                    { messageArgs: { _: errorMessage } }
                ));

                setSsdErrors(formState.errors);
            } else {
                setValue(value + 1);
            }
        } else {
            setValue(value + 1);
        }
    };


    const handleSubmit = () => {
        const formState = form.getState();
        const {fetchStart, fetchEnd, showNotification} = props;
        let data = {};
        let requestType = CREATE;
        let t = 0;
        const monthlyEvents = formState.values.MonthlyEvents.map(monthlyEvent => {
            return {
                Idx: t++,
                Studio: monthlyEvent.Studio,
                StartDate: moment.utc(monthlyEvent.StartDate, 'MM/DD/YYYY', true).format(),
                StartTime: moment.utc(monthlyEvent.StartTime, 'h:mmA', true).format('HH:mm'),
                EndTime: moment.utc(monthlyEvent.EndTime, 'h:mmA', true).format('HH:mm'),
                Teachers: monthlyEvent.Teachers,
            };
        });

        data = {
            Course: formState.values.Course,
            Semester: formState.values.Semester,
            Name: formState.values.Name,
            Description: formState.values.Description,
            Places: formState.values.Places,
            Price: formState.values.Price,
            MonthlyEvents: monthlyEvents,
        };

        fetchStart();

        fetchIt(
            requestType,
            'calendar',
            data
        ).then(response => {
            let errorMessage = '';
            switch (response.status) {
                case 200:
                    let message = 'Event saved!';
                    let eventDate = '';

                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    eventDate = moment(data.MonthlyEvents[0].StartDate).format('YYYY-MM-DD');

                    const eventDateObject = moment(eventDate);
                    let start = eventDateObject.format('YYYY-MM-DD');
                    let end = eventDateObject.add(1, 'day').format('YYYY-MM-DD');

                    let activeStart = moment(props.activeStart).format('YYYY-MM-DD');
                    let activeEnd = moment(props.activeEnd).format('YYYY-MM-DD');

                    if (props.viewType === 'timeGridWeek') {
                        start = eventDateObject.day('Sunday').format('YYYY-MM-DD');
                        end = eventDateObject.day('Sunday').add(7, 'days').format('YYYY-MM-DD');
                    }

                    if (props.viewType === 'dayGridMonth') {
                        start = activeStart;
                        end = activeEnd;
                    }

                    props.fetchTheData(start, end);

                    props.goToDate(eventDate);
                    props.closeDialog();


                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    break;

                case 400:
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );


                    break;

                case 404:
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

    };

    return (
        <div className={classes.scheduleForm}>
            <StyledTabs value={value} onChange={handleChange} aria-label="Schedule Form">
                <StyledTab
                    label="COURSE & SESSION"
                />
                <StyledTab
                    label="START DATES"
                    disabled={forbiddenStep < 2}
                    style={Object.keys(ssdErrors).length > 0 ? {color: 'red'} : {}}
                />
                <StyledTab
                    label="REVIEW"
                    disabled={forbiddenStep < 3}
                />
            </StyledTabs>

            <FormWithRedirect
                {...props}
                record={props.record}
                resource='calendar'
                mutators={{...arrayMutators}}
                render={formProps => (
                    <EventFormView
                        {...formProps}
                        activeTab={value}
                        eventInitialData={props.eventInitialData}
                        allowStep={allowStep}
                        disallowStep={disallowStep}
                        setForm={setForm}
                        setActiveTab={setValue}
                        ssdErrors={ssdErrors}
                        setSsdErrors={setSsdErrors}
                        semesters={props.semesters}
                    />
                )}
            />

            <Typography className={classes.padding} />

            <div
                style={{
                    display: 'flex',
                    flex: '0 0 auto',
                    justifyContent: 'center',
                    padding: 8,
                    alignItems: 'center',
                    background: '#F5F5F5'
                }}
            >
                <Button
                    label="PREV"
                    onClick={onFormPrev}
                    startIcon={<ArrowPrevIcon />}
                    variant='contained'
                    disabled={value < 1}
                />

                {
                    value < 2 ?
                        <Button
                            label="NEXT"
                            onClick={onFormNext}
                            endIcon={<ArrowNextIcon />}
                            variant='contained'
                            style={{marginLeft: 8}}
                            disabled={(forbiddenStep === (value + 1))}
                        />
                    : null
                }

                {
                    value === 2 ?
                        <Button
                            label="FINISH"
                            onClick={handleSubmit}
                            variant='contained'
                            color='primary'
                            style={{marginLeft: 8}}
                        />
                    : null
                }
            </div>
        </div>
    );
};

export default EventForm;
