import React, {useEffect, useState} from 'react';
import {
    UPDATE,
    CREATE,
    required,
    TextInput,
    Toolbar,
    BooleanInput,
    FormDataConsumer,
    SimpleForm,
    GET_ONE
} from 'react-admin';
import {fetchIt} from "../../../helpers/fetcher";
import {getErrorMessage} from "../../../helpers";
import {PhoneMaskedInput} from '../../../common';
import {emailValidation} from '../../../validators';
import {phoneValidation} from '../../../validators';
import {formatPhone} from "../../../helpers";
import {NtaSaveButton} from '../../common';
import {Redirect} from "react-router";



export const LocationFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} label={props.label}/>
        </Toolbar>
    );
};

export const LocationFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};


const LocationContactForm = props => {

    const [record, setRecord] = useState({});
    const [redirectTo, setRedirectTo] = useState(null);

    useEffect(() => {
        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_ONE,
            'locations',
            {id: props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let record = Object.assign({}, response.data);
                    record.Phone = formatPhone(response.data.Phone);
                    record.AdditionalPhone = formatPhone(response.data.AdditionalPhone);
                    
                    setRecord(record);
                    props.locationTitle(record.LocationName);

                    break;

                case 404:
                    let errorMessage = '';
                    
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }, []); // eslint-disable-line


    const handleSubmitContactDetails = values => {

        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (props.editMode) {
            requestType = UPDATE;
            values.id = props.id;
        }
        if (values.Phone) {
            values.Phone = values.Phone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }
        
        if (values.AdditionalPhone) {
            values.AdditionalPhone = values.AdditionalPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10); 
        }
        

        fetchIt(
            requestType,
            'locations',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Location saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    setRedirectTo(props.basePath + '/' + response.data.Id + '/edit');

                    break;

                case 400:
                    values.Phone = formatPhone(values.Phone);
                    values.AdditionalPhone = formatPhone(values.AdditionalPhone);

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    values.Phone = formatPhone(values.Phone);
                    values.AdditionalPhone = formatPhone(values.AdditionalPhone);

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    if (redirectTo && !props.editMode) {
        return <Redirect to={redirectTo}/>
    }

    
    return (
        <SimpleForm
            resource={props.resource}
            record={record}
            save={handleSubmitContactDetails}
            toolbar={<LocationFormToolbar label='Save Contact Details' />}
        >
            <TextInput source="LocationName" className='fieldsWidthExpand' validate={required('The Location Name field is required')} />
            <FormDataConsumer>
                {
                    ({formData}) => {
                        return <LocationFormTitle title={formData.LocationName} />;
                    }
                }
            </FormDataConsumer>
            <PhoneMaskedInput
                required
                source='Phone'
                validate={phoneValidation}
                className='fieldsWidthExpand'
            />
            <PhoneMaskedInput
                required
                label='Additional Phone'
                source='AdditionalPhone'
                validate={phoneValidation}
                className='fieldsWidthExpand'
            />
            <TextInput type='email' source='Email' className='fieldsWidthExpand' validate={emailValidation} />
            <TextInput source='ContactPerson' className='fieldsWidthExpand' validate={required('The Contact Person field is required')} />
            <BooleanInput source='IsActive'  defaultValue={props.editMode ? '' : true} />
        </SimpleForm>                  
    );
}

export default LocationContactForm;
