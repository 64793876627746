import React, {Fragment} from 'react';
import {
    TextField,
    RichTextField,
    FunctionField,
    ReferenceField,
} from 'react-admin';
import {ColorField} from 'react-admin-color-input';
import {DetailsLayout, DetailsTileWrapper} from '../../common';


export const StudioShow = props => {

    const options = {
        detailsTitle: 'location',
    };

    return (
        <Fragment>
            <DetailsLayout  {...props} options={options}>
                <DetailsTileWrapper
                    sideTitle='Studio Details'
                    sideText='Add your studio here. Later you will be able
                        to distribute your classes in the studios you want.'
                >
                    <TextField source='Name' label='Studio Name' />
                    <ReferenceField label='Location' source='SchoolLocationId' reference='locations'>
                        <TextField source='LocationName' />
                    </ReferenceField>
                    <RichTextField source='Description' />
                    <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                    <ColorField source='DisplayColor' className="colorField" style={{borderRadius: 50}}/>
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};
