import React from 'react';
import moment from "moment";


export const inArray = (needle = '', haystack = []) => {
    return (Array.isArray(haystack) && (haystack.indexOf(needle) > -1));
};


export const getErrorMessage = data => {
    let errorMessage = 'Oops, something went wrong';
    if (typeof data.HasValidationErrors !== 'undefined') {
        if (data.HasValidationErrors) {
            errorMessage = data.Errors.map((message, key) => {
                return (
                    <p key={key}>{message.Message}</p>
                );
            });
            errorMessage = data.Errors[0].Message;
        } else {
            errorMessage = data.ErrorMessage;
        }
    } else if (data.error_description !== 'undefined') {
        errorMessage = data.error_description;
    }

    return errorMessage;
};


export const arraySortByKey = (array, key, asc = true) => {
    return array.sort((a, b) => {
        const x = a[key];
        const y = b[key];

        if (asc) {
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        }

        return ((y < x) ? -1 : ((y > x) ? 1 : 0));
    });
}


export const formatPhone = phoneNumber => {
    if(!phoneNumber || phoneNumber === ""){
        return "";
    }

    phoneNumber = phoneNumber.replace(/\(|\)|\s|-/gi, "");

    const firstPart = phoneNumber.substring(0, 3);
    const secondPart = phoneNumber.substring(3, 6);
    const thirdPart = phoneNumber.substring(6);

    return "(" + firstPart + ") " + secondPart + "-" + thirdPart;
};



export const capitalize = s => {
    if (typeof s !== 'string') {
        return '';
    }

    return s.charAt(0).toUpperCase() + s.slice(1);
};


export const getUserName = () => new Promise((resolve, reject) => {
    if (localStorage.getItem('account')) {
        const account = JSON.parse(localStorage.getItem('account'));

        resolve(account.FirstName + ' ' + account.LastName);
    }

    let counter = 0;

    let interval = setInterval(() => {
        counter++;
        if (counter > 100) {
            clearInterval(interval);

            reject(new Error("Whoops!"));
        } else if (localStorage.getItem('account')) {
            clearInterval(interval);

            const account = JSON.parse(localStorage.getItem('account'));

            resolve(account.FirstName + ' ' + account.LastName);
        }
    }, 200);
});


export const getPermissions = () => new Promise((resolve, reject) => {
    if (localStorage.getItem('account')) {
        const account = JSON.parse(localStorage.getItem('account'));

        resolve(account.Role);
    }

    if(localStorage.getItem('user') === null) {
        resolve("unlogged");
    }

    let counter = 0;

    let interval = setInterval(() => {
        counter++;
        if (counter > 100) {
            clearInterval(interval);

            reject(new Error("No account data!"));
        } else if (localStorage.getItem('account')) {
            clearInterval(interval);
            const account = JSON.parse(localStorage.getItem('account'));

            resolve(account.Role);
        }
    }, 200);
});


export const formatWorkHour = time => {
    if(!time || time === ""){
        return "";
    }

    return moment(time, 'HH:mm:ss').format('h:mm A');
};


export const getWeekdays = () => {
    let firstDayOfWeek = 1;
    let dayOfWeekFormat = 'E';
    let school = localStorage.getItem('school');
    if (school) {
        school = JSON.parse(school);
        if (school.FirstDayOfWeek === 'Sunday') {
            firstDayOfWeek = 0;
            dayOfWeekFormat = 'd';
        }
    }

    let weekdays = [];
    for (let t = firstDayOfWeek; t < (firstDayOfWeek + 7); t++) {
        weekdays.push(moment(t, dayOfWeekFormat).format('dddd'));
    }

    return weekdays;
};


export const getWeekdayChoices = () => getWeekdays().map(weekDay => ({id: weekDay, name: weekDay}));
