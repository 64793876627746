import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import TextField from '@material-ui/core/TextField';
import IconCancel from '@material-ui/icons/Cancel';


const ConfirmCancelationDialog = props => {
    
    const [notice, setNotice] = useState('');

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.handleDialogClose}
            maxWidth='md'
        >
            <DialogTitle>
                {props.dialogTitle}
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    {props.dialogContent}
                </DialogContentText>
                <TextField 
                    value={notice} 
                    onChange={event => setNotice(event.target.value)} 
                    label='Cancellation notes' 
                    style={{width: '87%', paddingBottom: 40, marginLeft: 24, marginRight: 24}}
                />
            </DialogContent>
            <DialogActions style={{backgroundColor: '#F5F5F5'}}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => props.cancelRegistration(notice)}
                >
                    <DoneIcon /> Confirm
                </Button>
                <Button 
                    onClick={props.handleDialogClose} 
                    color="primary"
                >
                     <IconCancel /> Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmCancelationDialog;
