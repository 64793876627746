import React, {Component, Fragment} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {SimpleShowLayout} from 'react-admin';


const styles = ({
    lightGrey: {
        color: "#9f9f9f"
    },

    leftColumnTitle: {
        marginTop: 17
    },

    noShadow: {
        //boxShadow: 'none',
        border: 'none'
    },

    gutterBottom: {
        marginBottom: 16
    }
});


class DetailsContent extends Component {
    _isMounted = false;


    constructor (props) {
        super(props);

        this.state = {
            record: {},
            isLoading: true
        };
    }


    componentDidMount() {
        this._isMounted = true;
    }


    componentWillReceiveProps(nextProps) {
        if(nextProps.record && this._isMounted){
            this.setState({
                record: nextProps.record,
                isLoading: nextProps.isLoading
            });
        }
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    renderChildren() {
        if (!Array.isArray(this.props.children)) {
            let key = 0;


            if(typeof this.props.fetchTheData === "function"){
                return (
                    <Grid item xs={12}>
                        <SimpleShowLayout basePath={this.props.basePath} record={this.state.record}>
                            {React.cloneElement(this.props.children, {
                                addLabel: false,
                                key: key,
                                fetchTheData: this.props.fetchTheData
                            })}
                        </SimpleShowLayout>
                    </Grid>
                );
            } else {
                return (
                    <Grid item xs={12}>
                        <SimpleShowLayout basePath={this.props.basePath} record={this.state.record}>
                            {React.cloneElement(this.props.children, {
                                addLabel: false,
                                key: key
                            })}
                        </SimpleShowLayout>
                    </Grid>
                );
            }
        }

        let children = this.props.children.filter(child => child !== null);
        let childrenCount = children.length;

        const even = childrenCount % 2;
        const leftCount = (childrenCount - even) / 2 + even;
        let left = [];
        let right = [];

        for (let t = 0; t < childrenCount; t++) {
            let record = this.state.record;
            let source = children[t].props.source;

            if (source && source.includes('.')) {
                const sourcePath = source.split('.');
                record = record[sourcePath[0]];
                source = sourcePath[1];
            }

            if (source && record) {
                if (!record[source] && (record[source] !== false) && (record[source] !== 0)) {
                    record[source] = 'Not specified';
                }

                let currenChild = children[t];

                if(typeof this.props.fetchTheData === "function"){
                    currenChild = React.cloneElement(children[t], {
                        key: t,
                        fetchTheData: this.props.fetchTheData
                    });
                }

                if (t < leftCount) {
                    left.push(currenChild);
                } else {
                    right.push(currenChild);
                }
            }
        }

        return (
            <Fragment>
                <Grid item xs={6}>
                    <SimpleShowLayout basePath={this.props.basePath} record={this.state.record} className='showSections'>
                        {left}
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={6}>
                    <SimpleShowLayout basePath={this.props.basePath} record={this.state.record} className='showSections'>
                        {right}
                    </SimpleShowLayout>
                </Grid>
            </Fragment>
        );
    }


    renderServiceInfo() {
        const {classes} = this.props;

        if (
            this.state.record.CreatedTime ||
            this.state.record.CreatedBy ||
            this.state.record.ModifiedTime ||
            this.state.record.ModifiedBy
        ) {
            return (
                <Grid item xs={12}>
                    <Card className={classes.noShadow}>
                        <CardContent className='showSections'>
                            {
                                this.state.record.CreatedTime ?
                                    <Fragment>
                                        <Divider className={classes.gutterBottom} />
                                        <Typography className={classes.lightGrey} gutterBottom style={{fontSize: '0.85rem'}}>
                                            Created: {this.state.record.CreatedTime},
                                            by {this.state.record.CreatedBy}
                                        </Typography>
                                    </Fragment>
                                : null
                            }
                            {
                                this.state.record.ModifiedTime ?
                                    <Typography className={classes.lightGrey} style={{fontSize: '0.85rem'}}>
                                        Modified: {this.state.record.ModifiedTime},
                                        by {this.state.record.ModifiedBy}
                                    </Typography>
                                : null
                            }
                        </CardContent>
                    </Card>
                </Grid>
            );
        }

        return null;
    }


    render () {
        const {classes} = this.props;

        return (
            <Fragment>
                { !this.state.isLoading ?
                    <Fragment>
                        <Grid item xs={2}>
                            <Typography variant="h6" component="div" className={classes.leftColumnTitle} gutterBottom>
                                {this.props.sideTitle}
                            </Typography>
                            <Typography variant="subtitle2" component="div" gutterBottom className={classes.lightGrey}>
                                {this.props.sideText}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Card style={{minHeight: 85}}>
                                <Grid container>

                                    {this.renderChildren()}

                                    {this.renderServiceInfo()}

                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Fragment>
                    :null
                }
            </Fragment>
        );
    }
}

export default withStyles(styles)(DetailsContent);
