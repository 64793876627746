import {required} from "react-admin";


export const phoneRegExValidation = (message = 'The Phone Number is invalid') =>
    value => {
        const phoneRegEx = /^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/;

        return !value || phoneRegEx.test(value.substring(0, 14)) ? undefined : message;
    }

export const phoneValidation = [
    required('The Phone field is required'),
    phoneRegExValidation('The Phone Number is invalid')
];

export const optionalPhoneValidation = [
    phoneRegExValidation('The Phone Number is invalid')
];


export const cellPhoneValidation = [
    required('The Cell Phone field is required'),
    phoneRegExValidation('The Cell Phone Number is invalid')
];

export const homePhoneValidation = [
    phoneRegExValidation('The Home Phone Number is invalid')
];

export const workPhoneValidation = [
    phoneRegExValidation('The Home Phone Number is invalid')
];

export const purePhoneRegExValidation = (message = 'The Phone Number is invalid') =>
    value => {
        const phoneRegEx = /^\d{10}$/;
        const formatedPhoneRegEx = /^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/;
        
        return !value || phoneRegEx.test(value.substring(0, 14)) || formatedPhoneRegEx.test(value.substring(0, 14)) ? undefined : message;
    }


export const purePhoneValidation = [
    required('The Phone field is required'),
    purePhoneRegExValidation('The Phone Number is invalid')
];

export const pureOptionalPhoneValidation = [
    purePhoneRegExValidation('The Phone Number is invalid')
];