import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {Grid, Typography} from "@material-ui/core";


const InfoCard = props => {
    const {record, classes} = props;

    return (
        <Grid item xs={12}>
            <Card className={classes.noShadow} style={{border: 'none'}}>
                <CardContent style={{padding: 16, paddingTop: 0}}>
                    <Typography className={classes.lightGrey} style={{fontSize: '0.85rem'}}>
                        Created: {record.RegistrationDate},
                        by {record.CreatedBy}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default InfoCard;
