import React, {Fragment} from 'react';
import {Typography} from "@material-ui/core";
import {Labeled} from 'react-admin';


const TypographyField = props => {
    return (
        <Fragment>
            {
                props.label ?
                    <Labeled
                        record={props.record}
                        label={props.label}
                        source={props.source}
                        disabled={false}
                    >
                        <Typography
                            component={props.component ? props.component : 'div'}
                            variant={props.variant ? props.variant : "subtitle2"}
                            style={{marginBottom: 0}}
                        >
                            {(props.index ? props.index + '. ' : '') + props.record[props.source]}
                        </Typography>
                    </Labeled>
                :
                    <Typography
                        component={props.component ? props.component : 'div'}
                        variant={props.variant ? props.variant : "subtitle2"}
                        style={{marginBottom: 0, paddingLeft: 25}}
                    >
                        {(props.index ? props.index + '. ' : '') + props.record[props.source]}
                    </Typography>
            }
        </Fragment>
    );
};

export default TypographyField;
