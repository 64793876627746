import {connect} from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import LocationContactForm from '../components/LocationContactForm';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    LocationContactForm
);