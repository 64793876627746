import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import IconCancel from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';


class MoveScheduleConfirmDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        };

        this.promiseInfo = {};
    }

    show = async () => {
        return new Promise((resolve, reject) => {
            this.promiseInfo = {
                resolve,
                reject
            };

            this.setState({
                show: true
            });
        });
    };

    hide = () => {
        this.setState({
          show: false
        });
    };


    render() {
        const {show, hide} = this.state;
        const {resolve, reject} = this.promiseInfo;

        return (
            <Dialog
                open={show}
                onClose={hide}
                id='myDialog'
            >
                <DialogTitle style={{paddingBottom: 0}}>
                    Move schedule
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        This schedule has registrations. Аre you sure you want to move it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                    <Button 
                        onClick={() => {
                            this.hide();
                            reject();

                            this.setState({
                                show: false
                            });
                        }}  
                        color="primary"
                    >
                        <IconCancel /> Cancel
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            this.hide();
                            resolve();
                        }}
                    >
                        <DoneIcon /> Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    getResolve() {
        const {resolve = () => {} } = this.promiseInfo || {};
        return result => {
            resolve(result);
            this.hide();
        };
    }
    

    getReject() {
        const {reject = () => {} } = this.promiseInfo || {};
        return err => {
            reject(err);
            this.hide();
        };
    }
}

export default MoveScheduleConfirmDialog;
