import {required} from "react-admin";

export const nameRegExValidation = (message = 'The Name is invalid') =>
    value => {
        const nameRegEx = /^([a-zA-Z0-9]|'|\.|\/|\(|\)|\{|\}|\[|\]|!|\?|,|;|:|&|_|-|\s|\u002D|\u002E|\u0020|\u0027)+$/;

        return !value || nameRegEx.test(value.substring(0, 25)) ? undefined : message;
    }

export const semesterNameValidation = [
    required('The Name field is required'),
    nameRegExValidation('The Name is invalid')
];