import {connect} from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import RegistrationFormView from '../components/create/RegistrationFormView';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    RegistrationFormView
);
