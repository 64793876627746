import React, {useState, useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox} from "@material-ui/core";
import classnames from 'classnames';
import {useForm, useFormState} from 'react-final-form';
import {inArray} from "../../../helpers";


const cbStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        padding: 0,
        paddingRight: 4,
        marginTop: -2,
    },
    icon: {
        borderRadius: 3,
        width: 12,
        height: 12,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '1px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 12,
            height: 12,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
}));



const StyledCheckbox = props => {
    const classes = cbStyles();
    const {IsDraft, ...other} = props;

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={classnames(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{'aria-label': 'decorative checkbox', 'data-schedule': props['data-schedule'], 'disabled': IsDraft}}
            {...other}
        />
    );
}


const CalendarCheckbox = props => {
    const form = useForm();
    const formState = useFormState();

    const {refresh, shouldRefresh} = props;
    const [forceUpdate, setForceUpdate] = useState(false);
    const [checked, setChecked] = useState(false);


    useEffect(() => {
        if (formState && formState.values.Schedules && formState.values.Schedules.hasOwnProperty('sch-' + props.event.extendedProps.Schedule)) {
            setChecked(true);
        }
    }, []);


    useEffect(() => {
        if (formState && formState.values.Schedules && formState.values.Schedules.hasOwnProperty('sch-' + props.event.extendedProps.Schedule)) {
            setChecked(true);
        } else if (checked) {
            setChecked(false);
        }
    }, [formState.values.SchedulesChanged]); // eslint-disable-line


    useEffect(() => {
        if (refresh && (shouldRefresh !== forceUpdate)) {
            setForceUpdate(shouldRefresh);
        }
    }, [refresh, shouldRefresh]); // eslint-disable-line


    const toggleChecked = event => {
        event.persist();

        setChecked(event.target.checked);

        let schedules = {};
        if (formState.values.Schedules) {
            schedules = formState.values.Schedules;
        }

        let eventProps = Object.assign({}, props.event.extendedProps);
        let teachers = [];
        for (let t = 0; t < props.teachers.length; t++) {
            if (inArray(parseInt(props.teachers[t].Id), eventProps.Teachers)) {
                teachers.push(props.teachers[t]);
            }
        }
        eventProps.Teachers = teachers;

        if (event.target.checked) {
            schedules['sch-' + props.event.extendedProps.Schedule] = eventProps;
        } else {
            delete schedules['sch-' + props.event.extendedProps.Schedule];
        }

        form.change('Schedules', schedules);
        form.change('SchedulesChanged', !formState.values.SchedulesChanged);
    };

    return (
        <StyledCheckbox
            id={'sch-' + props.event.id}
            data-schedule={JSON.stringify(props.event)}
            checked={checked}
            onChange={toggleChecked}
        />
    );
};

export default CalendarCheckbox;
