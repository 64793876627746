import {required, regex} from "react-admin";

export const nonNegativeNumberValidation = (message = 'This field should be a non-negative number') =>
    (value, allValues) => value >= 0 ? undefined : message;

export const positiveNumberValidation = (message = 'This field should be a positive number') =>
    (value, allValues) => value > 0 ? undefined : message;

export const maximum720Validation = (message = 'This field can not exceed 720') =>
    (value, allValues) => parseInt(value) <= 720 ? undefined : message;

export const min5Validation = (message = 'Min duration is 5 minutes') =>
    (value, allValues) => parseInt(value) >= 5 ? undefined : message;

export const requiredNonNegativeNumberValidation = [
    required('This field is required'),
    regex(/^[-+]?[0-9]*\.?[0-9]+$/, 'This field should be a number'),
    nonNegativeNumberValidation()
];

export const requiredPositiveNumberValidation = [
    required('This field is required'),
    regex(/^[-+]?[0-9]*\.?[0-9]+$/, 'This field should be a number'),
    positiveNumberValidation()
];

export const durationValidation = [
    required('The Duration field is required'),
    regex(/^\d+$/, 'The Duration field should be a number'),
    positiveNumberValidation('The Duration field should be a positive number'),
    maximum720Validation('Duration must be between 5 and 720 minutes'),
    min5Validation("Duration must be between 5 and 720 minutes")
];

