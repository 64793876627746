import React, {Fragment} from 'react';
import {Create} from 'react-admin';
import {StudioForm} from '../';
import {BackButton} from '../../../common';


const StudioCreate = props => {
    return (
        <Fragment>
            <BackButton />
            <Create {...props} title="Create Studio">
                <StudioForm />
            </Create>
        </Fragment>
    );
};

export default StudioCreate;
