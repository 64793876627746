import React, {useEffect, useState} from 'react';
import {
    SelectInput,
    required
} from 'react-admin';
import {Grid} from "@material-ui/core";
import {ScheduleTimePicker} from '../../../common';
import {useFormState} from "react-final-form";
import {getWeekdayChoices, inArray} from '../../../helpers';


const LocationWorkDaysFormView = props => {
    const formState = useFormState();
    const [weekdays, setWeekdays] = useState(getWeekdayChoices());


    useEffect(() => {
        if (formState.values.Data && formState.values.Data.length) {
            let newWorkDays = [];
            for (let w = 0; w < formState.values.Data.length; w++) {
                if (formState.values.Data[w]) {
                    newWorkDays.push(formState.values.Data[w].WorkDay);
                }
            }

            let newWeekDays = weekdays;
            for (let w = 0; w < weekdays.length; w++) {
                newWeekDays[w].disabled = inArray(weekdays[w].id, newWorkDays);
            }
            setWeekdays(newWeekDays);
            props.setIsAddDisabled(formState.values.Data.length > 6);
        } else {
            props.setIsAddDisabled(false);
        }

    }, [formState.values.Data]); // eslint-disable-line

    return(
        <Grid container key={props.id}>
            <Grid item xs={4}>
                <SelectInput
                    record={props.record}
                    source={props.id + '.WorkDay'}
                    label='Day'
                    choices={weekdays}
                    optionText='name'
                    validate={required('The Work Day field is required')}
                    style={{marginTop: 4}}
                />
            </Grid>
            <Grid item xs={4}>
                <ScheduleTimePicker
                    isRequired
                    label='Start Time *'
                    source={props.id + '.WorkStartTime'}
                />
            </Grid>
            <Grid item xs={4}>
                <ScheduleTimePicker
                    isRequired
                    label='End Time *'
                    source={props.id + '.WorkEndTime'}
                />
            </Grid>
        </Grid>
    );

}

export default LocationWorkDaysFormView;
