import React, {useEffect, useState} from "react";
import {useForm, useFormState} from "react-final-form";
import {
    CheckboxGroupInput,
    GET_LIST,
    fetchStart,
    fetchEnd,
    showNotification
} from "react-admin";
import {fetchIt, getErrorMessage} from "../../../../../../helpers";
import {useDispatch} from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';



const SelfFetchCheckboxInput = props => {
    const dispatch = useDispatch();

    const form = useForm();
    const formState = useFormState();


    const [isLoading, setIsLoading] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const [record, setRecord] = useState(formState.values);

    useEffect(() => {
        setRecord(formState.values);
        setRefresh(!refresh);
        // eslint-disable-next-line
    }, [props.refreshClasses]); // eslint-disable-next-line

    useEffect(() => {
        if (
            formState.values.Schedules['sch-' + props.classSchedule] &&
            formState.values.Schedules['sch-' + props.classSchedule].sdv_choices &&
            (formState.values.Schedules['sch-' + props.classSchedule].sdv_choices.length > 0) &&
            formState.values.Schedules['sch-' + props.classSchedule].schedule_day_works
        ) {
            let newValues = record;
            newValues.Schedules['sch-' + props.classSchedule].schedule_day_works = formState.values.Schedules['sch-' + props.classSchedule].schedule_day_works;
            setRecord(newValues);
            setRefresh(!refresh);

            return;
        }

        let newChoices = record.Schedules['sch-' + props.classSchedule].schedule_day_works;

        dispatch(fetchStart());
        setIsLoading(true);

        fetchIt(
            GET_LIST,
            'schedule_day_works',
            {id: props.classSchedule}
        ).then(response => {
            switch (response.status) {
                case 200:
                    form.change('Schedules.sch-' + props.classSchedule + '.sdv_choices', response.data);

                    if (!newChoices || (newChoices.length === 0)) {
                        newChoices = [];
                        for (let t = 0; t < response.data.length; t++) {
                            if (!response.data[t].HasEnded) {
                                newChoices.push(response.data[t].Id);
                            }
                        }

                        let newValues = record;
                        newValues.Schedules['sch-' + props.classSchedule].schedule_day_works = newChoices;
                        setRecord(newValues);
                        setRefresh(!refresh);

                        form.change('Schedules.sch-' + props.classSchedule + '.schedule_day_works', newChoices);
                    }

                    break;

                case 400:
                    let errorMessage = 'There was a problem.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    ));

                    break;

                default:
                    dispatch(showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    ));
            }
        }).catch(error => {

        }).then(() => {
            dispatch(fetchEnd());

            setIsLoading(false);
        });
    }, []); // eslint-disable-line


    const onSdwChange = data => {
        let newValues = record;
        newValues.Schedules['sch-' + props.classSchedule].schedule_day_works = data;
        setRecord(newValues);
        setRefresh(!refresh);
        form.change('Schedules.sch-' + props.classSchedule + '.schedule_day_works', data);
    }


    return (
        <>
            {
                isLoading ?
                    <LinearProgress />
                :
                    <CheckboxGroupInput
                        record={record}
                        label=''
                        source={'Schedules.sch-' + props.classSchedule + '.schedule_day_works'}
                        translateChoice={false}
                        choices={formState.values.Schedules['sch-' + props.classSchedule].sdv_choices || []}
                        onChange={onSdwChange}
                        style={{
                            display: 'inline-block',
                            width: 'auto',
                            marginTop: 10,
                            marginBottom: 0,
                            marginLeft: 30,
                            marginRight:30
                        }}
                        optionValue='Id'
                        optionText={record => (record.StartDateTime + ' - ' + record.EndDateTime)}
                        className="CheckboxGroup"
                        helperText={false}
                    />
            }
        </>
    );
}

export default SelfFetchCheckboxInput;
