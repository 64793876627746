import React, {Component, Fragment} from 'react';
import {
    Button,
    SimpleForm,
    TextInput,
    CREATE,
    RadioButtonGroupInput,
    required
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {emailValidation} from '../../../../../validators';
import {fetchIt, getErrorMessage} from '../../../../../helpers';
import {NtaSaveButton} from '../../../../common';
import {Grid} from "@material-ui/core";
import {PhoneMaskedInput, DatePicker} from '../../../../../common';
import {phoneValidation} from '../../../../../validators';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';



const FormActions = props => {
    const {basePath, onCloseClick, isDisabled, showNotification, ...rest} = props;

    return (
        <DialogActions {...rest}>
            <NtaSaveButton  label="Create" showNotification={showNotification} isDisabled={isDisabled} />
        </DialogActions>
    );
};

const styles = theme => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class CreateNewFamilyDialog extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };


    handleSubmit = values => {

        const {fetchStart, fetchEnd, showNotification} = this.props;

        if (values.CellPhone) {
            values.CellPhone = values.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        fetchIt(
            CREATE,
            'tenant_users',
            { data: values }
        ).then(response => {
            switch (response.status) {
                case 200:

                    this.setState({showDialog: false});

                    this.props.fetchFamilyMembers(response.data.Id);

                    let message = 'New Family is saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (this.props.handleRefresh) {
                        this.props.handleRefresh();
                    }

                    break;

                case 400:
                    let errorMessage = 'There was a problem.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    render() {
        // const {showMessage} = this.state;
        const SanitizedGrid = ({basePath, ...props}) => {
            return (
              <Grid {...props} />
            );
          };

        return (
            <Fragment>
                <Button
                    label="Create Family"
                    onClick={this.handleClick}
                    style= {{background: "white"}}
                >
                </Button>
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Create New Family"
                    maxWidth='xl'
                >
                    <DialogTitle onClose={this.handleCloseClick} style={{paddingBottom: 0}}>
                        Create New Family
                    </DialogTitle>
                    <DialogContent style={{overflow: 'hidden', paddingTop: 0}}>
                        <SimpleForm
                            resource='tenant_users'
                            save={this.handleSubmit}
                            toolbar={null}
                            style={{paddingTop: 0, marginTop: -25}}
                        >
                            <SanitizedGrid container style={{width: '100%', paddingLeft: 0, paddingRight:0}} >
                                <Grid item xs={6} style={{paddingTop: 0, paddingBottom:0}}>
                                    <TextInput
                                        label='Organizer First Name'
                                        className='fieldsWidthExpand'
                                        source='FirstName'
                                        validate={required('The Organizer First Name field is required')}
                                    />
                                    <TextInput
                                        label='Organizer Last Name'
                                        className='fieldsWidthExpand'
                                        source='LastName'
                                        validate={required('The Organizer First Name field is required')}
                                    />
                                    <DatePicker source='BirthDate'/>
                                    <TextInput
                                        className='fieldsWidthExpand'
                                        source='Email'
                                        validate={emailValidation}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{paddingBottom:0, paddingLeft: '7%'}}>
                                    <PhoneMaskedInput
                                        label='Mobile Phone'
                                        source='CellPhone'
                                        validate={phoneValidation}
                                        required={true}
                                    />
                                    <br/>
                                    <RadioButtonGroupInput
                                        source='Gender'
                                        label=''
                                        row={false}
                                        choices={[
                                            {id: 'Male', name: 'Male'},
                                            {id: 'Female', name: 'Female'},
                                        ]}
                                    />
                                </Grid>

                                <FormActions
                                    style={{
                                        backgroundColor: '#f5f5f5', 
                                        justifyContent: 'flex-start', 
                                        width: '100%',
                                        marginLeft: -25
                                    }}
                                    onCloseClick={this.handleCloseClick}
                                    showNotification={this.props.showNotification}
                                    isDisabled={this.state.isDisabled}
                                />
                            </SanitizedGrid>
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

export default CreateNewFamilyDialog;
