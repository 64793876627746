import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Button, DialogActions, Typography} from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';

const ShowIssuesDialog = (props) => {
    let registrationIssues = [];

    if (props.registrationIssues && props.registrationIssues.length > 0) {
        registrationIssues = props.registrationIssues;
    }


    return (   
        <Dialog
            open={props.isRegisrationIssueDialogOpen}
            onClose={props.registrationIssueDialogClose}
            aria-label="Issues"
            maxWidth='xl'
        >
            <DialogTitle style={{paddingBottom: 0}}>
                Publishing issues
            </DialogTitle>
            <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 20}}>
                {
                    registrationIssues.map((issue, index) => (
                        <Typography key={index}>{index + 1 + ". " + issue}</Typography>
                    ))
                }
            </DialogContent>
            <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                <Button color='primary' onClick={props.registrationIssueDialogClose}>
                    <IconCancel />
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
} 

export default ShowIssuesDialog;
