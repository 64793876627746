import {GET_ONE} from 'react-admin';
import {fetchIt, getPermissions} from "./helpers";
import Cookies from 'universal-cookie';
import {FRONTEND_URL, hostname} from "./config/endpoints";


const authProvider = {
    login: ({ username, password }) =>  {
        localStorage.removeItem('user');
        localStorage.removeItem('school');
        localStorage.removeItem('account');

        return fetchIt(
            'post',
            'login',
            {username, password}
        ).then(response => {
            switch (response.status) {
                case 200:
                    localStorage.setItem('user', JSON.stringify(response.data));

                    fetchIt(
                        GET_ONE,
                        'account',
                        {id: 1}
                    ).then(accountResponse => {
                        if (accountResponse.status === 200) {
                            localStorage.setItem('account', JSON.stringify(accountResponse.data));

                            return Promise.resolve();
                        }

                        return Promise.reject();
                    });

                    break;

                case 400:
                    throw new Error('The email and/or password are not correct.');

                default:
                    throw new Error('Oops, something went wrong!');
            }
        });
    },


    logout: () => {
        const cookies = new Cookies();
        cookies.set(
            'lms_logging_out',
            1,
            {
                path: '/',
                domain: '.' + FRONTEND_URL,
            }
        );

        localStorage.removeItem('school');
        localStorage.removeItem('account');

        if (localStorage.getItem('user') !== null) {
            const refreshToken = JSON.parse(window.localStorage.getItem('user')).refresh_token;
            localStorage.removeItem('user');

            if (hostname === 'localhost') {
                return fetchIt(
                    'post',
                    'logout',
                    {token: refreshToken}
                ).then(response => {
                    return;
                }).catch(error => {
                    return Promise.resolve();
                });
            }
        }

        return Promise.resolve();
    },

    checkAuth: () => localStorage.getItem('user')
        ? Promise.resolve()
        : Promise.reject(),

    getPermissions: () => {
        return getPermissions();
    },

    checkError: error => {
        const status  = error.status;

        if (status === 403) {
            localStorage.removeItem('user');
            localStorage.removeItem('school');
            localStorage.removeItem('account');

            return Promise.reject();
        } else if (status === 400){
            return Promise.resolve();
        }
        return Promise.resolve();
    },
};

export default authProvider;
