import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField
} from 'react-admin';
import {formatPhone} from "../../../../helpers";
import SplitButton  from './SplitButton';
import {StaffListFilter} from './StaffListFilter';
import EmptyList from '../../../../components/EmptyList';


export const StaffList = props => (
    <div className="wrapperContent">
        <List 
            {...props} 
            empty={<EmptyList emptyTitle='No Staff' />}
            filters={<StaffListFilter />} 
            perPage={25} 
            title='Staff List' 
            sort={{field: 'id', order: 'DESC'}} 
            bulkActionButtons={false}
        >
            <Datagrid>
                <FunctionField source='FirstName' label="Name" render={record => `${record.FirstName} ${record.LastName}`} />
                <FunctionField label="Phone" render={record => {return `${record.CellPhone}` === "null" ? '' : formatPhone(`${record.CellPhone}`)}} />
                <TextField source='Email' />
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                <TextField source='Role' cellClassName='tightTd' />
                <SplitButton cellClassName='splitButtonTd' smallTd/>
            </Datagrid>
        </List>
    </div>
);