import React from "react";
import MaskedInput from 'react-text-mask';
import {addField} from "react-admin";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import {FormHelperText} from "@material-ui/core";


const TextMaskCustom = props => {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            // showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};


const PhoneMaskedInput = props => {
    return (
        <FormControl style={{marginBottom: 22, marginTop: 4}} error={!!(props.meta.modified && props.meta.error)}>
            <InputLabel htmlFor={props.source}>{props.label || props.source}{props.required ? ' *' : null}</InputLabel>
            <Input
                value={props.input.value}
                onChange={props.input.onChange}
                inputComponent={TextMaskCustom}
                validate={props.validate}
                id={props.source}
                className='fieldsWidthExpand'
            />
            {
                !!(props.meta.modified && props.meta.error) ? <FormHelperText>{props.meta.error}</FormHelperText> : null
            }
        </FormControl>
    );
}

export default addField(PhoneMaskedInput);
