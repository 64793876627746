import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import {connect} from "react-redux";
import NtaSaveButton from '../components/NtaSaveButton';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    NtaSaveButton
);
