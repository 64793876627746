export const styles = () => ({
    neteaScheduler: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        transition: '195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },

    mainTable: {
        borderCollapse: 'collapse',
        borderSpacing: 0,
        zIndex: 2
    },

    headRow: {
        height: 28,
        display: 'flex'
    },

    stickyHeadRow: {
        height: 28,
    },

    stickyHeadRowSticked: {
        height: 28,
        display: 'block',
        position: 'fixed',
        transform: 'translate3d(0px, 0px, 0px)',
        transition: 'all 0.2s ease-in-out 0s',
        zIndex: 4
    },

    stickyHeadRowStickedAndTranslated: {
        height: 28,
        display: 'block',
        position: 'fixed',
        transform: 'translate3d(0px, 48px, 0px)',
        transition: 'all 0.2s ease-in-out 0s',
        zIndex: 4
    },

    headCell: {
        borderCollapse: 'collapse',
        padding: 0
    },

    stickyLabel: {
        display: 'none'
    },

    stickyLabelSticked: {
        borderBottom: '1px solid #969696',
        boxSizing: 'border-box',
        height: 28,
        lineHeight: '28px',
        display: 'block',
        textAlign: 'left',
        paddingLeft: 10,
        backgroundColor: '#DBDBDB',

        position: 'fixed',
        top: 0,
        transform: 'translate3d(0px, 0px, 0px)',
        transition: 'all 0.2s ease-in-out 0s',
        zIndex: 4
    },

    stickyLabelStickedAndTranslated: {
        borderBottom: '1px solid #969696',
        boxSizing: 'border-box',
        height: 28,
        lineHeight: '28px',
        display: 'block',
        textAlign: 'left',
        paddingLeft: 10,
        backgroundColor: '#DBDBDB',

        position: 'fixed',
        top: 0,
        transform: 'translate3d(0px, 48px, 0px)',
        transition: 'all 0.2s ease-in-out 0s',
        zIndex: 4
    },

    timeslot: {
        flexShrink: 0,
        border: '1px solid #969696',
        borderTop: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
        height: 28,
        lineHeight: '28px',
        display: 'inline-block',
        width: 180,
        textAlign: 'center'
    },

    timeSubSlot: {
        border: '1px dotted #DBDBDB',
        borderTop: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
        height: '100%',
        display: 'inline-block',
        padding: 0
    },

    weekdayRow: {
        height: 28,
        display: 'flex',
        backgroundColor: '#DBDBDB'
    },

    resourceRow: {
        minHeight: 58,
        display: 'flex'
    },

    contentTimeslot: {
        border: '1px solid #969696',
        borderTop: 'none',
        borderRight: 'none',
        boxSizing: 'border-box',
        height: '100%',
        display: 'inline-block',
        position: 'relative'
    },

    contentResource: {
        border: 'none',
        height: '100%',
        display: 'table',
        width: 140
    },

    scrollingCell: {
        display: 'flex',
        padding: 0,
        overflow: 'hidden',
        margin: '0px 0px -15px'
    },

    scrollerWrapper: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        height: 15,
        position: 'fixed',
        bottom: 0,
        zIndex: 3
    },
});
