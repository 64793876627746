export const choices = [
    {
        value: true,
        name: 'Active'
    },
    {
        value: false,
        name: 'Inactive'
    }
];

export const paymentStatusChoices = [
    {
        id: true,
        paymentStatusName: 'Paid'
    },
    {
        id: false,
        paymentStatusName: 'Not paid'
    }
];

export const paymentMethodChoices = [
    {
        id: 1,
        name: 'Stripe'
    },
];

export const classTypeChoices = [
    {
        id: 1,
        name: 'Session Classes'
    },
    {
        id: 2,
        name: 'Independent Classes'
    },
    // {
    //     id: 3,
    //     name: 'Special Events'
    // },
    // {
    //     id: 4,
    //     name: 'Group Events'
    // },
];

export const feesTypeChoices = [
    {
        id: 'Paid Once',
        name: 'Paid Once'
    },
    {
        id: 'Paid Multiple Times',
        name: 'Paid Multiple Times'
    },
    {
        id: 'Paid Once Per Student',
        name: 'Paid Once Per Student'
    }
];

export const approvedRoles = [
    'Administrator',
    'School Manager'
];

export const weekdays = [
    {
        id: 'Monday',
        name: 'Monday'
    },
    {
        id: 'Tuesday',
        name: 'Tuesday'
    },
    {
        id: 'Wednesday',
        name: 'Wednesday'
    },
    {
        id: 'Thursday',
        name: 'Thursday'
    },
    {
        id: 'Friday',
        name: 'Friday'
    },
    {
        id: 'Saturday',
        name: 'Saturday'
    },
    {
        id: 'Sunday',
        name: 'Sunday'
    },
];

export const roles = [
    {
        id: 'Organizer',
        name: 'Organizer'
    },
    {
        id: 'Parent/Guardian',
        name: 'Parent/Guardian'
    },
    {
        id: 'Student',
        name: 'Student'
    },
];

export const RegistrationViewChoices = [
    {
        id: 1,
        name: 'Weekly View'
    },
    {
        id: 2,
        name: 'Calendar View'
    }
];

export const TimeZones = [
    {
        id: "Hawaiian Standard Time",
        name: "(UTC-10:00) Hawaii"
    },
    {
        id: "Alaskan Standard Time",
        name: "(UTC-09:00) Alaska"
    },
    {
        id: "Pacific Standard Time",
        name: "(UTC-08:00) Pacific Time (US & Canada)"
    },
    {
        id: "Mountain Standard Time",
        name: "(UTC-07:00) Mountain Time (US & Canada)"
    },
    {
        id: "Central Standard Time",
        name: "(UTC-06:00) Central Time (US & Canada)"
    },
    {
        id: "Eastern Standard Time",
        name: "(UTC-05:00) Eastern Time (US & Canada)"
    },
    {
        id: "FLE Standard Time",
        name: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
    }
];

export const registrationTypeChoices = [
    {
        id: true,
        typeName: 'Partial'
    },
    {
        id: false,
        typeName: 'Full'
    }
];

export const registrationStatusChoices = [
    {
        id: true,
        statusName: 'Active'
    },
    {
        id: false,
        statusName: 'Canceled'
    }
];

export const registrationClassTypeChice = [
    {
        id: true,
        classTypeName: 'In semester'
    },
    {
        id: false,
        classTypeName: 'Independent Classes'
    }
];