import React, { Fragment } from 'react';
import {
    TextField,
    FunctionField,
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../common';
import {formatPhone} from "../../../helpers";
import TeachersAssignmentsList from '../containers/TeachersAssignmentsList';


export const TeacherShow = props => {
    const fieldsToFormat = [
        {
            field: 'CellPhone',
            formatter: formatPhone
        }
    ];

    const options = {
        detailsTitle: 'teacher',
        titleField: 'FirstName',
        lastNameField: 'LastName',
        removeDeleteButton: true,
    };

    return (
        <Fragment>
            <DetailsLayout {...props} options={options} fieldsToFormat={fieldsToFormat}>
                <DetailsTileWrapper
                    sideTitle='Teacher Details'
                    sideText='Add your data here.'
                >
                    <FunctionField source='FirstName' label="Name" render={record => `${record.FirstName} ${record.LastName}`} />
                    <TextField source='Email' />
                    <TextField source='CellPhone' />
                    <TextField source='WorkPhone' />
                    <TextField source='DisplayName' />
                    <TextField source='EmailAlternate' />
                    <TextField source='HomePhone' />
                    <FunctionField label='Status' source='Active' render={record => record.Active ? 'Active' : 'Inactive'} />
                </DetailsTileWrapper>

                <DetailsTileWrapper
                    needsTheFetcher={true}
                    sideTitle='Teacher Assignments'
                >
                    <TeachersAssignmentsList source="Id" />
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};
