import React, {useState, useEffect} from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import moment from "moment";
import {inArray} from "../../../../../helpers";
import {Slider, Tooltip} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";


const NtaSlider = withStyles({
    root: {
        width: 300,
        marginBottom: -30
    },
    active: {},
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markLabel: {
        // top: -8,
        fontSize: '0.75rem',
        color: '#9f9f9f',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);


const NtaTooltip = withStyles({
    tooltip: {
        background: 'rgba(97, 97, 97, 0.5)'
    },
    arrow: {
        color: 'rgba(97, 97, 97, 0.5)',
        marginBottom: -7,
    },
})(Tooltip);


export const SchedulesListFilters = props => {
    const [schoolYear, setSchoolYear] = useState('');
    const [semester, setSemester] = useState('');
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [ages, setAges] = useState([0, 18.5]);
    const [prices, setPrices] = useState([0, 1000]);
    const [minAge, setMinAge] = useState(0.00);
    const [maxAge, setMaxAge] = useState(1000.00);
    const [minPrice, setMinPrice] = useState(0.00);
    const [maxPrice, setMaxPrice] = useState(1000.00);
    const [theTimeout, setTheTimeout] = useState(0);


    useEffect(() => {
        if (!schoolYear) {
            setSchoolYear(moment.utc().format('YYYY'));
        }
    }, []); // eslint-disable-line


    useEffect(() => {
        if (props.ages.length === 2) {
            setMinAge(props.ages[0]);
            setMaxAge(props.ages[1]);
            setAges([props.ages[0], props.ages[1]]);
        }
    }, [props.prices[0], props.prices[1]]); // eslint-disable-line


    useEffect(() => {
        if (props.prices.length === 2) {
            setMinPrice(props.prices[0]);
            setMaxPrice(props.prices[1]);
            setPrices([props.prices[0], props.prices[1]]);
        }
    }, [props.prices[0], props.prices[1]]); // eslint-disable-line


    useEffect(() => {
        if (props.schoolYear !== schoolYear) {
            setSchoolYear(props.schoolYear + '');

            props.fetchSemesters({'SchoolYear': props.schoolYear, 'DaysOfWeek': daysOfWeek});
        }
    }, [props.schoolYear]); // eslint-disable-line


    useEffect(() => {
        setSemester(props.currentSemester);
    }, [props.currentSemester]); // eslint-disable-line


    const onSchoolYearChange = event => {
        event.persist();

        setSchoolYear(event.target.value);

        let thePriceRange = {
            Min: 0,
            Max: 18.5,
        };
        if (prices.length === 2) {
            thePriceRange = {
                Min: prices[0],
                Max: prices[1],
            };
        }

        let theAgeRange = {
            Min: 0,
            Max: 18.5,
        };
        if (ages.length === 2) {
            theAgeRange = {
                Min: ages[0],
                Max: ages[1],
            };
        }

        props.fetchSemesters({'SchoolYear': event.target.value, 'DaysOfWeek': daysOfWeek, 'AgeRange': theAgeRange, 'PriceRange': thePriceRange});
    };


    const onSemesterChange = event => {
        event.persist();

        setSemester(event.target.value);

        let filters = {};
        if (parseInt(event.target.value)) {
            filters.Semester = parseInt(event.target.value);
        }

        if (schoolYear) {
            filters.SchoolYear = schoolYear;
        }

        if (prices.length === 2) {
            filters.PriceRange = {
                Min: prices[0],
                Max: prices[1],
            };
        }

        if (ages.length === 2) {
            filters.AgeRange = {
                Min: ages[0],
                Max: ages[1],
            };
        }

        filters.DaysOfWeek = daysOfWeek;

        let selectedSemester = props.semesters.find(semester => parseInt(semester.id) === filters.Semester);
        if (selectedSemester && props.setSelectedSemester) {
            props.setSelectedSemester(selectedSemester);
            props.setSelectedSemesterChange(!props.selectedSemesterChange);
        }

        if (selectedSemester && props.setCurrentSemester) {
            props.setCurrentSemester(event.target.value + '');
        }

        props.fetchTheData(filters, selectedSemester);
    };


    const onDaysOfWeekChange = event => {
        event.persist();

        let newDaysOfWeek = event.target.value.sort();
        let school = localStorage.getItem('school');
        if (school) {
            school = JSON.parse(school);
            if ((school.FirstDayOfWeek !== 'Sunday') && (newDaysOfWeek[0] === 0)) {
                newDaysOfWeek = [];
                for (let t = 1; t < event.target.value.length; t++) {
                    newDaysOfWeek.push(event.target.value[t]);
                }
                newDaysOfWeek.push(0);
            }
        }

        setDaysOfWeek(newDaysOfWeek);

        let filters = {};
        filters.DaysOfWeek = newDaysOfWeek;

        if (parseInt(semester)) {
            filters.Semester = parseInt(semester);
        }

        if (schoolYear) {
            filters.SchoolYear = schoolYear;
        }

        if (prices.length === 2) {
            filters.PriceRange = {
                Min: prices[0],
                Max: prices[1],
            };
        }

        if (ages.length === 2) {
            filters.AgeRange = {
                Min: ages[0],
                Max: ages[1],
            };
        }

        props.fetchTheData(filters);
    };


    const onAgesChange = (event, value) => {
        let filters = {};

        filters.DaysOfWeek = daysOfWeek;

        if (parseInt(semester)) {
            filters.Semester = parseInt(semester);
        }

        if (schoolYear) {
            filters.SchoolYear = schoolYear;
        }

        if (prices.length === 2) {
            filters.PriceRange = {
                Min: prices[0],
                Max: prices[1],
            };
        }

        if (value.length === 2) {
            filters.AgeRange = {
                Min: value[0],
                Max: value[1],
            };
        }

        window.clearTimeout(theTimeout);
        const timeout = window.setTimeout(
            () => {
                props.setShouldSetDate(true);

                props.fetchTheData(filters);
            }, 2000
        );

        setTheTimeout(timeout);
        setAges(value);
    }

    const onPricesChange = (event, value) => {
        let filters = {};

        filters.DaysOfWeek = daysOfWeek;

        if (parseInt(semester)) {
            filters.Semester = parseInt(semester);
        }

        if (schoolYear) {
            filters.SchoolYear = schoolYear;
        }

        if (ages.length === 2) {
            filters.AgeRange = {
                Min: ages[0],
                Max: ages[1],
            };
        }

        if (value.length === 2) {
            filters.PriceRange = {
                Min: value[0],
                Max: value[1],
            };
        }

        window.clearTimeout(theTimeout);
        const timeout = window.setTimeout(
            () => {
                props.setShouldSetDate(true);

                props.fetchTheData(filters);
            }, 2000
        );

        setTheTimeout(timeout);
        setPrices(value);
    }


    const valueText = value => `${value}yrs`;


    const valuePriceText = value => '$' + value.toFixed(2);


    const marks = [
        {
            value: minAge,
            label: minAge,
        },
        {
            value: maxAge / 4,
            label: Math.round(maxAge / 4),
        },
        {
            value: maxAge / 4 * 2,
            label: Math.round(maxAge / 4 * 2),
        },
        {
            value: maxAge / 4 * 3,
            label: Math.round(maxAge / 4 * 3),
        },
        {
            value: maxAge,
            label: maxAge,
        },
    ];


    const priceMarks = [
        {
            value: minPrice,
            label: minPrice,
        },
        {
            value: maxPrice / 4,
            label: Math.round(maxPrice / 4),
        },
        {
            value: maxPrice / 4 * 2,
            label: Math.round(maxPrice / 4 * 2),
        },
        {
            value: maxPrice / 4 * 3,
            label: Math.round(maxPrice / 4 * 3),
        },
        {
            value: maxPrice,
            label: maxPrice,
        },
    ];


    const ValueLabelComponent = myprops => {
        const {children, open, value} = myprops;

        return (
            <NtaTooltip arrow open={open} enterTouchDelay={0} placement="top" title={value} style={{marginTop: 10}}>
                {children}
            </NtaTooltip>
        );
    }


    return (
        <div style={{margin: '5px 10px 25px 0px', display: 'inline-block', float: 'right'}}>
            <div style={{float: 'right', marginLeft: 50}}>
                <InputLabel id="PriceRange">Price Range</InputLabel>
                <NtaSlider
                    value={prices}
                    onChange={onPricesChange}
                    valueLabelDisplay="on"
                    valueLabelFormat={valuePriceText}
                    ValueLabelComponent={ValueLabelComponent}
                    aria-labelledby="range-slider"
                    getAriaValueText={valuePriceText}
                    min={minPrice}
                    max={maxPrice}
                    step={0.05}
                    marks={priceMarks}
                />
            </div>

            <div style={{float: 'right', marginLeft: 50}}>
                <InputLabel id="Ages">Ages (in years)</InputLabel>
                <NtaSlider
                    value={ages}
                    onChange={onAgesChange}
                    valueLabelDisplay="on"
                    valueLabelFormat={valueText}
                    ValueLabelComponent={ValueLabelComponent}
                    aria-labelledby="range-slider"
                    getAriaValueText={valueText}
                    min={minAge}
                    max={maxAge}
                    step={0.5}
                    marks={marks}
                />
            </div>

            {
                parseInt(props.selectedSemester.RegistrationView) !== 2 ?
                    <div style={{float: 'right'}}>
                        <InputLabel id="day-of-week">Days of week</InputLabel>
                        <Select
                            onChange={onDaysOfWeekChange}
                            value={daysOfWeek}
                            style={{width: 120}}
                            multiple
                        >
                            {
                                props.weekdaysArray.map(
                                    weekday => (
                                        <MenuItem
                                            key={weekday}
                                            value={parseInt(moment(weekday, 'dddd').format('d'))}
                                            disabled={!inArray(weekday, props.workDays)}
                                        >
                                            {weekday}
                                        </MenuItem>
                                    )
                                )
                            }
                        </Select>
                    </div>
                : null
            }

            <div style={{float: 'right', marginRight: 50}}>
                <InputLabel id="semester">Semester</InputLabel>
                <Select
                    onChange={onSemesterChange}
                    value={semester}
                    style={{width: 250}}
                >
                    {
                        props.semesters.map(
                            semesterObject => (
                                <MenuItem key={semesterObject.id} value={semesterObject.id}>
                                    {semesterObject.name}
                                </MenuItem>
                            )
                        )
                    }
                </Select>
            </div>
            <div style={{float: 'right', marginRight: 50}}>
                <InputLabel id="School-Years">School Year</InputLabel>
                <Select
                    onChange={onSchoolYearChange}
                    value={schoolYear}
                    style={{width: 180}}
                >
                    {
                        props.schoolYears.map(
                            (schoolYear1, idx)  => (
                                <MenuItem key={idx} value={schoolYear1.id}>
                                    {schoolYear1.name}
                                </MenuItem>
                            )
                        )
                    }
                </Select>
            </div>
        </div>
    )
};
