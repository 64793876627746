import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


export const FamilyMembersSimpleList = props => {

    return props.familyMembers.length ? (
        <List>
            <h4 style={{color: '#8e8c8c', fontWeight: 400, marginBottom: 10}}>Family Details</h4>
            {
                props.familyMembers.map(familyMember => {
                    
                    return ( 
                        <ListItem className='familyDetails' key={familyMember.Id}>
                            <ListItemText 
                                primary={familyMember.FirstName + ' ' + familyMember.LastName + (familyMember.Email ? ', ' + familyMember.Email : '')} 
                                secondary={familyMember.Role} 
                            />
                        </ListItem>
                    );
                })
            }      
        </List>     
    ) : null;
};
