import React, {useEffect, useState} from 'react';
import {
    ReferenceInput,
    BooleanInput,
    GET_LIST,
    TextInput,
    FormDataConsumer,
} from 'react-admin';
import {Redirect} from 'react-router';
import PropTypes from 'prop-types';
import AdagioAutoComplete from "../../../../../components/AdagioAutoComplete";
import {NeteaScheduler} from "netea-scheduler";
import {useForm, useFormState} from "react-final-form";
import StudentOptionRenderer from './input_components/StudentOptionRenderer';
import {Grid, Card, CardContent, CardHeader, CardActions, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CreateNewFamilyDialog from '../../containers/CreateNewFamilyDialog';
import CreateNewStudentDialog from '../../containers/CreateNewStudentDialog';
import {fetchIt, getErrorMessage} from '../../../../../helpers';
import {FamilyMembersList} from '../lists/FamilyMembersList';
import ParentPayeeInformation from "./input_components/ParentPayeeInformation";
import {Typography} from '@material-ui/core';
import classnames from 'classnames';
import SelectedClasses from "./info_components/SelectedClasses";
import SelectedStudent from "./info_components/SelectedStudent";
import SelectedPayee from "./info_components/SelectedPayee";
import Discount from "./info_components/Discount";
import ShowClassesDialog from '../../containers/ShowClassesDialog';
import ContentSave from "@material-ui/icons/Save";
import {Calendar} from "../../../../calendar";
// import PaymentCardDetails from "./PaymentCardDetails";
// import SelectedCard from "./SelectedCard";


const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        maxWidth: '100%',
        padding: '5px 0px 5px 0px',
        display: 'block',
        boxSizing: 'border-box',
        marginBottom: 30,
        borderRadius: 0
    },
    innerDiv: {
        width: '100%',
        maxWidth: '100%',
        boxSizing: 'border-box',
        marginBottom: 40
    },
    card: {
        marginTop: 30,
    },
    actions: {
        justifyContent: 'center',
        marginTop: 25
    },
    grid: {
        flexBasis: 'calc(50% - 15px)',
    },
    gridLeft: {
        marginRight: 30,
    },
    selectionSection: {
        paddingTop: 30,
    },
    selectionTitle: {
        color: '#838383',
    },
    percentage: {
        display: 'inline-block',
        position: 'absolute',
        bottom: 24,
        marginRight: -12
    },
    amount: {
        display: 'inline-block',
        position: 'absolute',
        bottom: 30,
        marginLeft: -12
    },
    hidden: {
        display: 'none',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

        root: {
            width: '100%'
        }

}));


const RegistrationFormView = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    // eslint-disable-next-line
    const [redirectTo, setRedirectTo] = useState(null);
    // eslint-disable-next-line
    const [isDisabled, setIsDisabled] = useState(false);
    const [withParents, setWithParents] = useState(false);
    const [familyMembers, setFamilyMembers] = useState([]);
    const [selectedFamilyMember, setSelectedFamilyMember] = useState(null);
    const [selectedFamilyMemberInfo, setSelectedFamilyMemberInfo] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [memberToEdit, setMemberToEdit] = useState(0);
    // eslint-disable-next-line
    const [showClassesDialog, setShowClassesDialog] = useState(false);
    const [classSchedule, setClassSchedule] = useState(0);
    // eslint-disable-next-line
    const [selectedEvent, setSelectedEvent] = useState(false);
    const [selectedSemester, setSelectedSemester] = useState(props.selectedSemester ? parseInt(props.selectedSemester.Id) : null);
    const [filterBySchedule, setFilterBySchedule] = useState(false);
    const [schedules, setSchedules] = useState(props.schedules);

    const formState = useFormState();
    const form = useForm();


    const classes = useStyles();


    useEffect(() => {
        if (!loaded && !props.isLoading) {
            setLoaded(true);
        }

        setIsLoading(props.isLoading);
    }, [props.isLoading]); // eslint-disable-line


    useEffect(() => {
        if (!props.isLoading) {
            const {fetchStart, fetchEnd} = props;


            let newSchedules = Object.assign({}, props.schedules);
            if (filterBySchedule) {
                fetchStart();
                setIsLoading(true);

                let keys = Object.keys(props.schedules);
                if (keys.length > 0) {
                    for (let day in props.schedules) {
                        for (let studio in props.schedules[day]) {
                            newSchedules[day][studio] = props.schedules[day][studio].filter(
                                sch => parseInt(sch.Schedule) === filterBySchedule
                            );

                            if (newSchedules[day][studio].length < 1) {
                                delete newSchedules[day][studio];
                            }
                        }
                    }
                }

                window.setTimeout(
                    () => {
                        setIsLoading(false);

                        fetchEnd();
                    },
                    100
                );
            }

            setSchedules(newSchedules);
        }
    }, [filterBySchedule, props.isLoading]); // eslint-disable-line


    useEffect(() => {
        let shouldFilterBySchedule = false;
        if (formState && formState.values && formState.values.Schedules) {
            const keys = Object.keys(formState.values.Schedules);
            if (keys.length > 0) {
                const firstProp = keys[0];
                shouldFilterBySchedule = parseInt(formState.values.Schedules[firstProp].Schedule);
                setSelectedEvent(formState.values.Schedules[firstProp]);
            } else if (!props.selectedSemester || (props.selectedSemester.RegistrationView !== 2)) {
                props.fetchTheData();
                setSelectedEvent(false);
            } else {
                setSelectedEvent(false);
            }
        }

        setFilterBySchedule(shouldFilterBySchedule);
    }, [formState.values.SchedulesChanged]); // eslint-disable-line


    useEffect(() => {
        if(formState.values.Student) {
            fetchFamilyMembers(formState.values.Student);
        }
    }, [formState.values.Student]); // eslint-disable-line


    useEffect(() => {
        if (props.selectedSemester) {
            setSelectedSemester(parseInt(props.selectedSemester.Id));
        }
    }, [props.selectedSemester.Id]); // eslint-disable-line


    const fetchFamilyMembers = familyMemberId => {
        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        setSelectedFamilyMember(null);
        setSelectedFamilyMemberInfo(null);

        fetchIt(
            GET_LIST,
            'registration_family_members_list',
            {id: familyMemberId}
        ).then(response => {
            switch (response.status) {
                case 200:
                    form.change('selectedFamilyMember', parseInt(familyMemberId));
                    setFamilyMembers(response.data);
                    setSelectedFamilyMember(familyMemberId);
                    setSelectedFamilyMemberInfo(response.data.find(familyMember => familyMember.Id === familyMemberId));
                    form.change('selectedFamilyMemberInfo', response.data.find(familyMember => familyMember.Id === familyMemberId));
                    break;

                case 400:
                    let errorMessage = 'There was a problem.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {

        }).then(() => {fetchEnd();});
    }


    if (redirectTo) {
        return <Redirect to={redirectTo}/>
    }


    const onWithParentsChange = value => {
        setWithParents(value);
    }


    const onSubmit = event => {
        event.preventDefault();

        props.onSubmit(formState.values);

        return false;
    }

    useEffect(() => {
        if(props.resetForm === true) {
            setFamilyMembers([]);
            setSelectedFamilyMember(null);
            setSelectedFamilyMemberInfo(null);
            setClassSchedule(0);
            form.change('Student', 0)
            form.change('Notes', '');
            form.change('DiscountNotes', '');
            form.change('DiscountType', []);
            form.change('DiscountValue', '');
            form.restart();
            props.setResetForm(!props.resetForm);
        }
    }, [props.resetForm]); // eslint-disable-line


    const closeClassesDialog = () => {
        // form.change('Schedules.sch-' + classSchedule + '.schedule_day_works', null);
        setShowClassesDialog(false);
    }


    const loadClassDates = schedule => {
        setClassSchedule(schedule);
        setShowClassesDialog(true);
    }


    return (
        <form onSubmit={onSubmit}>
            <Grid container style={{paddingLeft: 0, paddingRight: 0}}>
                <Grid item xs={12}>
                    {
                        !isLoading ?
                            parseInt(props.selectedSemester.RegistrationView) !== 2 ?
                                <div className={classes.innerDiv}>
                                    <NeteaScheduler
                                        minTime={props.minTime}
                                        maxTime={props.maxTime}
                                        resources={props.studios}
                                        schedules={schedules}
                                        layoutColor='#FCFCFE'
                                        slotDuration={15} // This can be 5, 10, 15 or 30 minutes
                                        fetchStart={props.fetchStart}
                                        fetchEnd={props.fetchEnd}
                                        showNotification={props.showNotification}
                                        weekdays={props.weekdays}
                                        form={form}
                                        formState={formState}
                                        layoutWidth={props.layoutWidth}
                                        readOnly
                                        withCheckboxes
                                    />
                                </div>
                            :
                                <div style={{paddingBottom: 30}}>
                                    <Calendar
                                        onRegistration
                                        selectedSemester={selectedSemester}
                                        filterBySchedule={filterBySchedule}
                                        selectedEvent={selectedEvent}
                                        selectedDate={props.selectedDate}
                                        setSelectedDate={props.setSelectedDate}
                                        shouldSetDate={props.shouldSetDate}
                                        setShouldSetDate={props.setShouldSetDate}
                                        filters={props.filters}
                                    />
                                </div>
                        : null
                    }
                </Grid>


                <Grid container
                    style={{
                        border: '1px solid #E0E0E3',
                        borderRadius: 10,
                        backgroundColor: '#fff'
                    }}
                >
                    <Grid id='regStyle' item sm={5} xs={12} >
                        <ReferenceInput
                            label="Select a Student"
                            source="Student"
                            reference="registrations_students"
                            sort={{field: 'Name', order: 'ASC'}}
                            filter={{WithParents: withParents}}
                            perPage={30}
                            filterToQuery={
                                searchText => (
                                    {
                                        search: (searchText.length > 0 ? searchText : 'RETURN_EMPTY_RESULT'),
                                        WithParents: withParents
                                    }
                                )
                            }
                        >
                            <AdagioAutoComplete
                                noRequired
                                optionRenderer={<StudentOptionRenderer />}
                                matchSuggestion={() => true}
                                renderOptionsAsComponent
                                style={{display: 'inline-block'}}
                                helperText='You can search for student name, email, parent'
                            />
                        </ReferenceInput>

                        <BooleanInput
                            source='WithParents'
                            label='Search for Parent / Guardians'
                            defaultValue={false}
                            onChange={onWithParentsChange}
                        />
                    </Grid>

                    <Grid item sm={2} xs={12} style={{textAlign: 'center'}}>
                        <Typography variant="h6" style={{paddingTop: 50, color: '#8e8c8c'}} >
                            OR
                        </Typography>
                    </Grid>

                    <Grid item sm={5} xs={12} style={{textAlign: 'center'}}>
                        <Typography style={{paddingBottom: 15, paddingTop: 30}} variant='body1'>New family account</Typography>
                        <CreateNewFamilyDialog fetchFamilyMembers={fetchFamilyMembers} />
                    </Grid>
                </Grid>


                <Grid item sm={6} xs={12} className={classnames(classes.grid, classes.gridLeft)}>
                    <Typography
                        variant="h6"
                        align="center"
                        style={{color: '#8e8c8c', paddingTop: 20, paddingBottom: 20}}
                    >
                        Student registration process
                    </Typography>

                    <Card>
                        <CardHeader titleTypographyProps={{variant: "body1"}} title="1. Choose a student" />
                        <CardContent id='regStyle' style={{paddingTop: 0, paddingLeft: 15, paddingRight: 15}}>
                            {
                                selectedFamilyMember != null ?
                                    <FamilyMembersList
                                        familyMembers={familyMembers}
                                        setSelectedFamilyMember={setSelectedFamilyMember}
                                        setSelectedFamilyMemberInfo={setSelectedFamilyMemberInfo}
                                        setIsEdit={setIsEdit}
                                        setShowDialog={setShowDialog}
                                        setMemberToEdit={setMemberToEdit}
                                    />
                                : <Typography style={{paddingTop: 15, paddingBottom: 15}} variant='body1'>No Family information</Typography>
                            }

                            {
                                selectedFamilyMember != null ?
                                    <CreateNewStudentDialog
                                        familyMembers={familyMembers}
                                        fetchFamilyMembers={fetchFamilyMembers}
                                        isEdit={isEdit}
                                        setIsEdit={setIsEdit}
                                        showDialog={showDialog}
                                        setShowDialog={setShowDialog}
                                        memberToEdit={memberToEdit}
                                    />
                                : null
                            }
                        </CardContent>
                    </Card>

                    <ParentPayeeInformation classes={classes} resetForm={props.resetForm} />

                    {/*<PaymentCardDetails classes={classes} />*/}
                </Grid>

                <Grid item sm={6} xs={12} className={classes.grid}>
                    <Typography
                        variant="h6"
                        align="center"
                        style={{color: '#8e8c8c', paddingTop: 20, paddingBottom: 20}}
                    >
                        Your selection
                    </Typography>

                    <Card>
                        <CardHeader titleTypographyProps={{variant: "body1"}} title="Selected Classes" style={{paddingBottom: 0}} />
                        <CardContent style={{position: 'relative', paddingLeft: 15, paddingRight: 15, paddingBottom: 15}}>
                            <SelectedClasses classes={classes} loadClassDates={loadClassDates} resetForm={props.resetForm} />

                            <FormDataConsumer>
                                {
                                    ({formData}) => {
                                        return (
                                            formData.hasSchedules ?
                                                (
                                                    <Typography
                                                        variant='subtitle1'
                                                        className={classes.selectionTitle}
                                                        style={{position: 'absolute', bottom: 15, right: 16}}
                                                    >
                                                        {'Sub total: $' + (formData.SubTotal ? parseFloat(formData.SubTotal).toFixed(2) : '0.00')}
                                                    </Typography>
                                                )
                                            : null
                                        );
                                    }
                                }
                            </FormDataConsumer>
                        </CardContent>
                    </Card>

                    <FormDataConsumer>
                        {
                            ({formData}) => {
                                return (
                                    formData.hasSchedules ?
                                        <Card style={{marginTop: 30}}>
                                            <Discount classes={classes} />
                                        </Card>
                                    : null
                                    );
                            }
                        }
                    </FormDataConsumer>

                    <Card style={{marginTop: 30}}>
                        <CardHeader titleTypographyProps={{variant: "body1"}} title="Selected Student" style={{paddingBottom: 0}} />
                        <CardContent style={{paddingLeft: 15, paddingRight: 15, paddingBottom: 15}}>
                            <SelectedStudent classes={classes} selectedFamilyMemberInfo={selectedFamilyMemberInfo} />

                            <SelectedPayee classes={classes} />

                            {/*<SelectedCard classes={classes} />*/}
                        </CardContent>
                    </Card>


                    <Card style={{marginTop: 30, paddingLeft: 15, paddingRight: 15}}>
                        <TextInput
                            source='Notes'
                            label='Registration notes'
                            style={{width: '100%'}}
                        />
                    </Card>

                    <CardActions className={classes.actions}>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            disabled={isDisabled}
                        >
                            <ContentSave style={{marginRight: 5, fontSize: 18}} /> REGISTER
                        </Button>
                    </CardActions>
                </Grid>
            </Grid>


            <ShowClassesDialog
                open={showClassesDialog}
                aria-label="Classes dates"
                maxWidth='md'
                closeClassesDialog={closeClassesDialog}
                classes={classes}
                classSchedule={classSchedule}
                setShowClassesDialog={setShowClassesDialog}
            />
        </form>
    );
}

RegistrationFormView.propTypes = {
    record: PropTypes.object.isRequired
};

RegistrationFormView.defaultProps = {
    record: {}
};

export default RegistrationFormView;
