import React, {Component} from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {addField, FieldTitle} from "react-admin";

class TimePicker extends Component {
    renderLabel = (date) => {
        if (date.isValid()) {
          return date.format(this.props.dateFormat ? this.props.dateFormat : 'h:mm A');
        } else {
          return '';
        }
    };

    render() {
        const {
            label,
            source,
            resource,
            isRequired,
            meta,
            disablePast,
            useInitialWidth
        } = this.props;

        const {modified, error} = meta;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <KeyboardTimePicker
                    margin="normal"
                    className={useInitialWidth ? '' : 'fieldsWidthExpand'}
                    id={"time_picker_" + source}
                    label={
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    }
                    value={this.props.val ? this.props.val : this.props.input.value}
                    onChange={this.props.onChange ? value => this.props.onChange(value, 'Picker') : this.props.input.onChange}
                    format={this.props.dateFormat ? this.props.dateFormat : 'h:mm A'}
                    style={(this.props.editMode && !!(error)) || (!this.props.editMode && !!(modified && error)) ? {} : {paddingBottom: 23}}
                    error={this.props.editMode ? !!(error) : !!(modified && error)}
                    helperText={this.props.editMode ? error : modified && error}
                    disablePast={disablePast}
                    labelFunc={this.renderLabel}
                    minDate={this.props.minDate}
                />
            </MuiPickersUtilsProvider>
        );
    }
}
export default addField(TimePicker);
