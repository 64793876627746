import React from 'react';
import {useSelector} from "react-redux";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import {Portal} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: '#000',
    },
    root: {
        position: 'relative',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 500 : 700],
    },
    top: {
        color: '#257166',
        animationDuration: '750ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));


const LoadingIndicator = props => {
    const loading = useSelector(state => state.admin.loading > 0);

    const classes = useStyles();

    return (
        <Portal>
            <Backdrop className={classes.backdrop} open={loading}>
                <div className={classes.root}>
                    <CircularProgress
                        variant="determinate"
                        className={classes.bottom}
                        size={60}
                        thickness={6}
                        {...props}
                        value={100}
                    />
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        className={classes.top}
                        classes={{
                            circle: classes.circle,
                        }}
                        size={60}
                        thickness={6}
                        {...props}
                    />
                </div>
            </Backdrop>
        </Portal>
    );
}

export default LoadingIndicator;
