import React, {Component, Fragment} from 'react';
import moment from 'moment';
import compose from 'recompose/compose';
import {withStyles} from '@material-ui/core/styles';
import {withResizeDetector} from 'react-resize-detector';
import {styles} from './styles';
import {inArray, getErrorMessage, hexToRgb} from './helpers'
import TimeSlot from './TimeSlot'
import DroppableTable from './DroppableTable'
import {itemTypes} from './config/constants'
import VerticalSplitOutlinedIcon from '@material-ui/icons/VerticalSplitOutlined';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import MoveScheduleConfirmDialog from './MoveScheduleConfirmDialog';
import {getWeekdays} from './helpers/common'

const myRef = React.createRef();

class NeteaSchedulerComponent extends Component {
    _isMounted = false;
    _layoutWidth = 0;
    _initialWidth = 0;
    _parentWidthWithSidebarClosed = 0;
    _oldWindowScroll = null;
    _slotDuration = null;

    constructor(props) {
        super(props);

        this.neteaSchedulerNode = React.createRef();
        this.scrollingCell = React.createRef();
        this.stickyHeadRow = React.createRef();
        this.stickyLabel = React.createRef();
        this.scrollingCellTbody = React.createRef();
        this.positioner = React.createRef();
        this.scrollerWrapper = React.createRef();
        this.references = {};

        if (this.props.slotDuration) {
            if (inArray(this.props.slotDuration, [5, 10, 15, 30, 60])) {
                this._slotDuration = this.props.slotDuration;
            }
        }

        let resources = [];
        if (resources) {
            resources = this.props.resources;
        }

        let weekdays = getWeekdays();
        if (this.props.weekdays && (this.props.weekdays.length > 0)) {
            weekdays = this.props.weekdays;
        }

        for (let t = 0; t < weekdays.length; t++) {
            this.references[weekdays[t]] = React.createRef();
        }


        let schedules = [];
        if (this.props.schedules) {
            schedules = this.props.schedules;
        }

        this.state = {
            isFolded: false,
            resources,
            weekdays,
            schedules: schedules,
            rowsHeight: {},
            refresh: [],
            refreshRows: [],
            refreshSchedules: [],
            isLoading: true,
            showDialog: false,
            // tooltip: false,
            // schedule: {},
            // tooltipPosition: {
            //     top: -500,
            //     left: -1000
            // },
            // tooltipWidth: 0,
            // tooltipHeight: 0,
            width: 100,
            scrollerPosition: 0,
            scrollerWidth: 0,
            scrollingCellTbodyMarginTop: 0,
            stickyLabel: ''
        };
    }


    updateDimensions = sidebarOpen => {
        let sideBarOpen = sidebarOpen;
        if (typeof sidebarOpen === 'undefined') {
            sideBarOpen = this.props.sidebarOpen;
        }

        if (this._isMounted) {

            let parentWidth = this.neteaSchedulerNode.current.parentNode.offsetWidth - (this.props.readOnly ? 0 : 35);
            let neededContainerWith = 0;

            if (this._parentWidthWithSidebarClosed === 0) {
                if (sideBarOpen) {
                    this._parentWidthWithSidebarClosed = parentWidth + 160;
                } else {
                    this._parentWidthWithSidebarClosed = parentWidth;
                }
            }

            if (sideBarOpen) {
                neededContainerWith = this._parentWidthWithSidebarClosed - 160;
            } else {
                neededContainerWith = this._parentWidthWithSidebarClosed;
            }

            let newState = {
                ...this.state,
                width: neededContainerWith,
                scrollerPosition: this.scrollingCell.current ? this.scrollingCell.current.getBoundingClientRect().x : 0
            };

            if (this.state.scrollerWidth === 0) {
                newState.scrollerWidth = this.scrollingCell.current ? this.scrollingCell.current.offsetWidth : 0;
            }

            const position = this.positioner.current.getBoundingClientRect().y;
            if (window.innerHeight < position + 15) {
                this.scrollerWrapper.current.style.position = 'fixed';
                this.scrollerWrapper.current.style.left = (newState.scrollerPosition + 2) + 'px';
            } else {
                this.scrollerWrapper.current.style.position = 'relative';
                this.scrollerWrapper.current.style.left = !this.state.isFolded ? '142px' : '73px';
            }

            this.setState(newState);
        }
    };


    moveSchedule = async (id, hour, minutes, oldWeekday, newWeekday, oldStudio, newStudio, item) => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        let schedules = Object.assign({}, this.state.schedules);

        const scheduleIndex = schedules[oldWeekday][oldStudio].findIndex(schedule => schedule && (schedule.Id === id));

        if (scheduleIndex >= 0) {

            let oldSchedule = Object.assign({}, schedules[oldWeekday][oldStudio][scheduleIndex]);

            oldSchedule.StartHour = hour;
            oldSchedule.StartMinutes = minutes;
            oldSchedule.DayOfWeek = newWeekday;
            oldSchedule.Studio = newStudio;

            let start = moment.utc(oldSchedule.StartHour, "H").add(oldSchedule.StartMinutes, 'minutes');
            let end = moment.utc(start.format('H:mm'), 'H:mm').add(oldSchedule.Duration, 'minutes');
            oldSchedule.top = 0;
            oldSchedule.range = moment.range(start, end);


            if (this.props.moveSchedule) {
                const data = {
                    id,
                    Studio: newStudio,
                    DayOfWeek: newWeekday,
                    StartTime: moment.utc(hour + ':' + minutes, 'H:m').format('HH:mm:ss'),
                    Duration: schedules[oldWeekday][oldStudio][scheduleIndex].Duration,
                    Schedule: item.schedule.Schedule,
                    Registrations: schedules[oldWeekday][oldStudio][scheduleIndex].Registrations
                }

                let isMoveActionConfirmed = true;
                if (data.Registrations > 0 ) {
                    try {
                        const modal = myRef.current;
                        const result = await modal.show();
                    } catch (err) {
                        isMoveActionConfirmed = false;
                    }
                }

                if (isMoveActionConfirmed === false) {
                    return;
                }

                fetchStart();

                this.props.moveSchedule(data).then(response => {
                    switch (response.status) {
                        case 200:
                            schedules[oldWeekday][oldStudio].splice(scheduleIndex, 1);
                            oldSchedule.IsOverlapped = false;
                            schedules[newWeekday][newStudio].push(oldSchedule);

                            schedules = this.recalculateRowsHeight(schedules, oldWeekday, oldStudio);
                            schedules = this.recalculateRowsHeight(schedules, newWeekday, newStudio);

                            if (this._isMounted) {
                                this.setState({schedules, refresh: [oldWeekday, newWeekday], refreshRows: [oldWeekday + '_' + oldStudio, newWeekday + '_' + newStudio]});
                            }

                            if (this.props.excludeFromOverlapped) {
                                this.props.excludeFromOverlapped(oldSchedule);
                            }

                            showNotification(
                                'direct_message',
                                'info',
                                {messageArgs: {_: response.data.Message}}
                            );

                            break;

                        case 400:
                        case 403:
                            let errorMessage = 'Oops, something went wrong!';
                            if (typeof response.data.HasValidationErrors !== 'undefined') {
                                errorMessage = getErrorMessage(response.data);
                            }

                            showNotification(
                                'direct_message',
                                'warning',
                                {messageArgs: {_: errorMessage}}
                            );

                            if (this._isMounted) {
                                this.setState({schedules, refresh: [oldWeekday, newWeekday], refreshRows: [oldWeekday + '_' + oldStudio, newWeekday + '_' + newStudio]});
                            }

                            break;

                        default:
                            showNotification(
                                'direct_message',
                                'warning',
                                { messageArgs: { _: 'Oops, something went wrong!' } }
                            );
                    }
                }).catch(error => {
                    let errorMessage = 'Oops, something went wrong!';
                    if (
                        (typeof error.response !== 'undefined') &&
                        (typeof error.response.data !== 'undefined') &&
                        (typeof error.response.data.HasValidationErrors !== 'undefined')
                    ) {
                        errorMessage = getErrorMessage(error.response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    if (this._isMounted) {
                        this.setState({schedules, refresh: [oldWeekday, newWeekday], refreshRows: [oldWeekday + '_' + oldStudio, newWeekday + '_' + newStudio]});
                    }
                }).then(() => {
                    // Dispatch an action letting react-admin know an API call has ended
                    fetchEnd();
                });
            }
        }
    }


    recalculateRowsHeight = (schedules, weekday, studio) => {
        let rowsHeight = this.state.rowsHeight;

        delete rowsHeight[weekday + '_' + studio];

        let allSchedules = schedules[weekday][studio];

        const timeSlots = this.getTimeSlots();
        const timeSlotsCount = timeSlots.length;
        let targetSlots = 1;
        let slotDuration = 0;
        if (this._slotDuration) {
            slotDuration = this._slotDuration;
            targetSlots = 60 / slotDuration;
        }

        for (let outer = 0; outer < timeSlotsCount; outer++) {
            for (let inner = 0; inner < targetSlots; inner++) {
                const startMinutes = (60 / targetSlots) * inner;
                const startHour = parseInt(moment(timeSlots[outer], "h a").format("H"));

                let filteredSchedules = [];
                if (typeof allSchedules !== 'undefined') {
                    filteredSchedules = allSchedules.filter(
                        schedule => {
                            if (this.props.withOtherSemesters || this.props.withCheckboxes){
                                return (
                                    schedule.StartHour === startHour &&
                                    schedule.StartMinutes >= startMinutes &&
                                    schedule.StartMinutes < (startMinutes + (60 / targetSlots))
                                );
                            }

                            return (
                                !schedule.BelongsToAnotherSemester &&
                                schedule.StartHour === startHour &&
                                schedule.StartMinutes >= startMinutes &&
                                schedule.StartMinutes < (startMinutes + (60 / targetSlots))
                            );
                        }
                    );
                }

                let myHeight = 0;
                let tops = {};
                for (let schedule of filteredSchedules) {
                    let prevOverlaps = schedules[weekday][studio].filter(theSchedule => {
                        const scheduleStart = moment.utc(schedule.StartHour + ':' + schedule.StartMinutes, 'H:m');
                        const theScheduleStart = moment.utc(theSchedule.StartHour + ':' + theSchedule.StartMinutes, 'H:m');

                        if (
                            !this.props.withOtherSemesters &&
                            theSchedule.BelongsToAnotherSemester &&
                            !this.props.withCheckboxes
                        ) {
                            return false;
                        }

                        return (
                            theScheduleStart.isBefore(scheduleStart) &&
                            schedule.range.overlaps(theSchedule.range, {adjacent: false})
                        );
                    });

                    const rowMinHeight = (this.props.withCheckboxes ? 58 : 80);
                    const scheduleMinHeight = (this.props.withCheckboxes ? 59 : 60);
                    if (prevOverlaps.length > 0) {
                        prevOverlaps.sort((a, b) => (a.top < b.top ? 1 : -1));

                        myHeight = prevOverlaps[0].top + (prevOverlaps[0].BelongsToAnotherSemester || this.props.withCheckboxes ? scheduleMinHeight : 80);
                        if (tops['sh' + schedule.StartHour] && tops['sh' + schedule.StartHour].top > prevOverlaps[0].top) {
                            myHeight = tops['sh' + schedule.StartHour].top + (tops['sh' + schedule.StartHour].BelongsToAnotherSemester || this.props.withCheckboxes ? scheduleMinHeight : 80);
                        }
                    }

                    if (tops['sh' + schedule.StartHour]) {
                        myHeight = tops['sh' + schedule.StartHour].top + (tops['sh' + schedule.StartHour].BelongsToAnotherSemester || this.props.withCheckboxes ? scheduleMinHeight : 80);
                    }

                    tops['sh' + schedule.StartHour] = {};
                    tops['sh' + schedule.StartHour].top = myHeight;
                    tops['sh' + schedule.StartHour].BelongsToAnotherSemester = schedule.BelongsToAnotherSemester;


                    const top = myHeight;
                    myHeight += (schedule.BelongsToAnotherSemester || this.props.withCheckboxes ? scheduleMinHeight : 81);

                    const scheduleIndex = schedules[weekday][studio].findIndex(theSchedule => theSchedule.Id === schedule.Id);
                    schedules[weekday][studio][scheduleIndex].top = top;

                    if (!rowsHeight[weekday + '_' + studio]) {
                        rowsHeight[weekday + '_' + studio] = rowMinHeight;
                    }

                    if (
                        (myHeight > rowMinHeight + 5) &&
                        (rowsHeight[weekday + '_' + studio] < myHeight)
                    ) {
                        rowsHeight[weekday + '_' + studio] = myHeight;
                    }

                }
            }
        }

        if (this._isMounted) {
            this.setState({rowsHeight});
        }

        return schedules;
    }


    stopRefreshing = refresh => {
        if (this._isMounted) {
            this.setState({refresh});
        }
    }


    componentDidMount() {
        this._isMounted = true;

        let schedules = [];
        if (this.state.schedules) {
            schedules = this.state.schedules;

            for (let weekday in schedules) {
                for (let studio in schedules[weekday]) {
                    schedules = this.recalculateRowsHeight(schedules, weekday, studio);
                }
            }

            if (this._isMounted) {
                this.setState({schedules});
            }
        }

        this.updateDimensions();

        window.addEventListener('scroll', this.onWindowScroll);
        window.addEventListener('resize', this.onWindowResize);
    }


    onWindowResize = () => {
        this._initialWidth = 0;
    }


    componentDidUpdate(prevProps) {
        const {layoutWidth, sidebarOpen} = this.props;

        if (sidebarOpen !== prevProps.sidebarOpen) {
            this.updateDimensions(sidebarOpen);
        }

        if (layoutWidth !== prevProps.layoutWidth) {
            this.updateDimensions(sidebarOpen);
        }
    }


    onWindowScroll = e => {
        if (this._oldWindowScroll === null) {
            this._oldWindowScroll = parseInt(e.currentTarget.scrollY);
        }

        const position = this.positioner.current.getBoundingClientRect().y
        let scrollingCellTbodyMarginTop = 0;
        let tableTop = 0;
        if (
            !!this.references[this.state.weekdays[0]] &&
            this.references[this.state.weekdays[0]].current
        ) {
            tableTop = this.references[this.state.weekdays[0]].current.getBoundingClientRect().y;
        }

        let stickyHeadRowClass = this.props.classes.stickyHeadRow;
        let stickyLabelClass = this.props.classes.stickyLabel;

        if (this._oldWindowScroll > parseInt(e.currentTarget.scrollY)) {
            if ((parseInt(e.currentTarget.scrollY) > 72) && position > 72) {
                stickyHeadRowClass = this.props.classes.stickyHeadRowStickedAndTranslated;
                stickyLabelClass = this.props.classes.stickyLabelStickedAndTranslated;
                scrollingCellTbodyMarginTop = 28;
            } else {
                stickyHeadRowClass = this.props.classes.stickyHeadRow;
                stickyLabelClass = this.props.classes.stickyLabel;
                scrollingCellTbodyMarginTop = 0;
            }

            if (this.stickyHeadRow.current.getBoundingClientRect().y <= tableTop - 29) {
                stickyHeadRowClass = this.props.classes.stickyHeadRow;
                scrollingCellTbodyMarginTop = 0;
            }

            if (window.innerHeight < position + 15) {
                this.scrollerWrapper.current.style.position = 'fixed';
                this.scrollerWrapper.current.style.left = (this.state.scrollerPosition + 3) + 'px';
            }
        } else {
            if (tableTop <= 28) {
                stickyHeadRowClass = this.props.classes.stickyHeadRowSticked

                scrollingCellTbodyMarginTop = 28
            }

            if (tableTop <= 0) {
                stickyLabelClass = this.props.classes.stickyLabelSticked
            }

            if (position < 28) {
                scrollingCellTbodyMarginTop = 0;
                stickyHeadRowClass = this.props.classes.stickyHeadRow;
                stickyLabelClass = this.props.classes.stickyLabel;
            }

            if (window.innerHeight > position + 15) {
                this.scrollerWrapper.current.style.position = 'relative';
                this.scrollerWrapper.current.style.left = !this.state.isFolded ? '142px' : '71px';
            }
        }

        this.stickyHeadRow.current.scrollLeft = this.scrollingCell.current.scrollLeft;
        this._oldWindowScroll = parseInt(e.currentTarget.scrollY);

        let stickyLabel = '';
        for (let t = 0; t < this.state.weekdays.length; t++) {
            if (
                !!this.references[this.state.weekdays[t]] &&
                this.references[this.state.weekdays[t]].current &&
                (this.references[this.state.weekdays[t]].current.getBoundingClientRect().y <= this.stickyLabel.current.getBoundingClientRect().y)
            ) {
                stickyLabel = this.state.isFolded ? moment(this.state.weekdays[t], 'dddd').format('ddd') : this.state.weekdays[t];
            }
        }
        if (stickyLabel === '') {
            stickyLabelClass = this.props.classes.stickyLabel;
        }

        if (this._isMounted) {
            this.setState({scrollingCellTbodyMarginTop, stickyLabel});
        }

        this.stickyHeadRow.current.className = stickyHeadRowClass;
        this.stickyLabel.current.className = stickyLabelClass;
    };


    componentWillUnmount() {
        this._isMounted = false;

        window.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('resize', this.onWindowResize);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.width && (nextProps.width !== this._layoutWidth)) {
            this._layoutWidth = nextProps.width;
            this.updateDimensions();
        }

        if (typeof nextProps.withOtherSemesters !== 'undefined') {
            this.setState({refresh: this.state.weekdays});
        }

        if (nextProps.refresh) {
            let refreshRows = [];
            let refreshSchedules = [];

            if (nextProps.refreshRows) {
                refreshRows = nextProps.refreshRows;

                if (nextProps.refreshSchedules) {
                    refreshSchedules = nextProps.refreshSchedules;
                }
            }

            if (this._isMounted) {
                this.setState({refresh: nextProps.refresh, refreshRows, refreshSchedules});
            }
        }
    }

    onScroll = e => {
        e.persist();

        this.scrollingCell.current.scrollLeft = e.target.scrollLeft;
        this.stickyHeadRow.current.scrollLeft = e.target.scrollLeft;
    }


    getTimeSlots = () => {
        let slotTime = moment(this.props.minTime ? this.props.minTime : '00:00', 'HH:mm');
        let endTime = moment(this.props.minTime ? this.props.minTime : '00:00', 'HH:mm');
        endTime = this.props.maxTime ? moment(this.props.maxTime, 'HH:mm') : endTime.add(1, 'days').subtract(1, 'seconds');

        let timeSlots = [];
        while (slotTime < endTime) {
            timeSlots.push(slotTime.format("h a"));
            slotTime = slotTime.add(60, 'minutes');
        }

        return timeSlots;
    }


    timeSubSlotsRender = (weekday, resource) => {
        let isResourceOutside = false;
        let workDay = resource.workDays.find(day => day.WorkDay === weekday);
        let minTime = {};
        let maxTime = {};
        if (!workDay) {
            isResourceOutside = true;

            minTime = moment.utc(this.props.minTime ? this.props.minTime : '00:00', 'HH:mm');
            maxTime = moment.utc(this.props.minTime ? this.props.minTime : '00:00', 'HH:mm');
            maxTime = this.props.maxTime ? moment.utc(this.props.maxTime, 'HH:mm') : maxTime.add(1, 'days').subtract(1, 'seconds');
        } else {
            minTime = moment.utc(workDay.WorkStartTime ? workDay.WorkStartTime : '00:00:00', 'HH:mm:ss');
            maxTime = moment.utc(minTime.format('HH:mm:ss'), 'HH:mm:ss');
            maxTime = workDay.WorkEndTime ? moment.utc(workDay.WorkEndTime, 'HH:mm:ss') : maxTime.add(1, 'days').subtract(1, 'seconds');
        }

        const timeSlots = this.getTimeSlots();
        const timeSlotsCount = timeSlots.length;
        let targetSlots = 1;
        let slotDuration = 0;
        if (this._slotDuration) {
            slotDuration = this._slotDuration;
            targetSlots = 60 / slotDuration;
        }

        let timeSubSlots = [];
        for (let outer = 0; outer < timeSlotsCount; outer++) {
            for (let inner = 0; inner < targetSlots; inner++) {
                let borderStyle = {
                    borderStyle: 'dotted',
                    borderColor: '#DBDBDB',
                    borderWidth: '0px 0px 1px 1px',
                    width:  180 / targetSlots,
                    borderBottomStyle: 'solid',
                    borderBottomColor: '#969696',
                };
                if (inner === 0) {
                    borderStyle = {
                        borderStyle: 'solid',
                        borderColor: '#969696',
                        borderWidth: '0px 0px 1px 1px',
                        padding: 0,
                        width:  180 / targetSlots,
                    };
                }

                const startMinutes = (60 / parseInt(targetSlots)) * parseInt(inner);
                const startHour = parseInt(moment.utc(timeSlots[outer], "h a").format("H"));

                let isOutsideWorkingHours = true;
                if (!isResourceOutside) {
                    const startTime = moment.utc(startHour + '|' + startMinutes, 'H|m');
                    const endTime = moment.utc(startHour + '|' + startMinutes, 'H|m').add(slotDuration, 'minutes');

                    if (startTime.isSameOrAfter(minTime) && endTime.isSameOrBefore(maxTime)) {
                        isOutsideWorkingHours = false;
                    }
                }

                if (isOutsideWorkingHours) {
                    // borderStyle.backgroundImage = `url(${pattern})`;
                    borderStyle.backgroundColor = `rgb(102, 102, 102, 0.1`;
                    borderStyle.cursor = 'not-allowed';
                }

                const hasSchedules = (
                    !isOutsideWorkingHours &&
                    this.state &&
                    this.state.schedules &&
                    this.state.schedules[weekday] &&
                    (typeof this.state.schedules[weekday][resource.id] !== 'undefined')
                );

                let filteredSchedules = [];
                if (hasSchedules) {
                    filteredSchedules = this.state.schedules[weekday][resource.id].filter(
                        schedule => schedule.StartHour === startHour &&
                            schedule.StartMinutes >= startMinutes &&
                            schedule.StartMinutes < (startMinutes + (60 / targetSlots)));
                }

                const timeSubSlot = (
                    <TimeSlot
                        key={'dts_' + outer + '_' + inner}
                        id={'ts_' + weekday + '_' + resource.id + '_' + outer + '_' + inner}
                        weekday={weekday}
                        resource={resource}
                        timeSlot={timeSlots[outer]}
                        targetSlots={targetSlots}
                        schedules={filteredSchedules}
                        refreshSchedules={this.state.refreshSchedules}
                        timeSlotClick={this.timeSlotClick}
                        inner={inner}
                        accept={itemTypes.SCHEDULE}
                        borderStyle={borderStyle}
                        className={this.props.classes.contentTimeslot}
                        readOnly={this.props.readOnly}
                        withCheckboxes={this.props.withCheckboxes}
                        withOtherSemesters={this.props.withOtherSemesters}
                        isOutsideWorkingHours={isOutsideWorkingHours}
                        minTime={minTime}
                        maxTime={maxTime}
                    />
                );

                timeSubSlots.push(timeSubSlot);
            }
        }

        return timeSubSlots;
    }


    resourceRender = resource => {
        let resourceColor = resource.color;
        if (resource.color === '#FAFAFA') {
            resourceColor = '#666666'
        }

        const initialArr = resource.title.split(" ").map((n)=>n[0]);
        const init = (initialArr.length > 1)? `${initialArr[0]}${initialArr[initialArr.length - 1]}` : initialArr[0];
        const initials = init.toUpperCase();

        return (
            <div style={{
                display: 'table-cell',
                verticalAlign: 'middle',
                maxWidth: 140,
            }}>

                {
                    this.state.isFolded ?
                        <Tooltip title={resource.title + ', ' + resource.location} placement='right-start'>
                            <Avatar style={{backgroundColor: resourceColor}}>{initials}</Avatar>
                        </Tooltip>
                :
                    <div style={{
                            borderLeft: '3px solid' + resourceColor,
                            paddingLeft: 10,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {!this.state.isFolded ? resource.title : null}
                    </div>
                }
                <div style={{
                    color: '#9f9f9f',
                    marginLeft: 13,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}>
                    {!this.state.isFolded ? resource.location : null}
                </div>
            </div>
        );
    };


    timeSlotClick = event => {
        event.preventDefault();

        event.persist();

        const dataSet = event.target.dataset;

        const baseTime = moment(dataSet.timeSlot, "h a");
        const targetSlots = parseInt(dataSet.targetSlots);
        const timeSubslot = parseInt(dataSet.timeSubslot);
        const slotDuration = 60 / targetSlots;
        const addMinutesToBase = timeSubslot * slotDuration;
        const newTime = baseTime.add(addMinutesToBase, 'minutes');

        if (this.props.clickOnTimeSlot) {
            if (dataSet.getParent) {
                let scheduleDomObject = event.target;
                if (scheduleDomObject.dataset.getParent && !scheduleDomObject.dataset.schedule) {
                    while (scheduleDomObject.dataset.getParent && !scheduleDomObject.dataset.schedule) {
                        scheduleDomObject = scheduleDomObject.parentElement;
                    }
                }

                const schedule = this.state.schedules[scheduleDomObject.dataset.weekday][parseInt(scheduleDomObject.dataset.resource)].find(
                    theSchedule => parseInt(theSchedule.id) === parseInt(scheduleDomObject.dataset.schedule)
                );

                if (schedule && !schedule.BelongsToAnotherSemester) {
                    dataSet.Duration = schedule.Duration;
                    dataSet.Teachers = JSON.stringify(schedule.Teachers ? schedule.Teachers : []);
                    dataSet.Places = schedule.Places;
                    dataSet.Price = schedule.Price;
                    dataSet.IsActive = schedule.IsActive;
                    dataSet.Registrations = schedule.Registrations;

                    this.props.clickOnTimeSlot(event, newTime, false, dataSet);
                }
            } else {
                this.props.clickOnTimeSlot(event, newTime, true, dataSet);
            }

        }
    };


    foldIt = () => {
        let newScrollerPosition = (
            this.state.isFolded ?
                this.scrollingCell.current.getBoundingClientRect().x + 69 :
                this.scrollingCell.current.getBoundingClientRect().x - 71
        );

        if (this.scrollerWrapper.current.style.position === 'relative') {
            newScrollerPosition = (
                this.state.isFolded ?
                    139 :
                    69
            );
        }

        if (this._isMounted) {
            this.setState({
                isFolded: !this.state.isFolded,
                scrollerPosition: newScrollerPosition
            });
        }
    }


    render() {
        const {showNotification} = this.props;
        let layoutColor = 'transparent';
        if (this.props.layoutColor) {
            layoutColor = this.props.layoutColor;
        }

        return (
            <div className={this.props.classes.neteaScheduler} ref={this.neteaSchedulerNode} style={{width: this.state.width}}>
                <table className={this.props.classes.mainTable}>
                    <tbody>
                    <tr>
                        <td style={{width: (this.state.isFolded ? 70 : 140), padding: 0, verticalAlign: 'top', transition: 'all 0.2s ease-in-out 0s'}}>
                            <table className={this.props.classes.mainTable}>
                                <tbody>
                                    <tr className={this.props.classes.headRow}>
                                        <td
                                            className={this.props.classes.headCell}
                                            style={{
                                                width: (this.state.isFolded ? 70 : 140),
                                                alignItems: 'center',
                                                transition: 'all 0.2s ease-in-out 0s',
                                                borderBottom: '1px solid grey'
                                            }}
                                        >
                                            <div
                                                onClick={this.foldIt}
                                                className={this.props.classes.timeslot}
                                                style={{
                                                    cursor: 'pointer',
                                                    border: 0,
                                                    width: '95%',
                                                    padding: 4,
                                                    height: 26,
                                                    marginLeft: (this.state.isFolded ? 0 : 4),
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <VerticalSplitOutlinedIcon
                                                    style={{
                                                        height: 20,
                                                        marginTop: -1,
                                                        verticalAlign: 'top'
                                                    }}
                                                />
                                                {
                                                    !this.state.isFolded ?
                                                        <div
                                                            style={{
                                                                verticalAlign: 'top',
                                                                display: 'inline-block',
                                                                lineHeight: '22px',
                                                                marginTop: -2
                                                            }}
                                                        >Studios</div>
                                                    : null
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: 0}}>
                                            <div
                                                ref={this.stickyLabel}
                                                className={this.props.classes.stickyLabel}
                                                style={{width: (this.state.isFolded ? 70 : 140), transition: 'all 0.2s ease-in-out 0s'}}
                                            >
                                                {this.state.stickyLabel}
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        this.state.weekdays.map((weekday, key) => (
                                            <Fragment key={'wd_' + key}>
                                                <tr
                                                    ref={this.references[weekday]}
                                                    className={this.props.classes.weekdayRow}
                                                >
                                                    <td style={{padding: 0}}>
                                                        <div
                                                            className={this.props.classes.timeslot}
                                                            style={{
                                                                width: (this.state.isFolded ? 70 : 140),
                                                                borderLeft: 'none',
                                                                textAlign: 'left',
                                                                paddingLeft: 10,
                                                                transition: 'all 0.2s ease-in-out 0s',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis'
                                                            }}
                                                        >
                                                            {this.state.isFolded ? moment(weekday, 'dddd').format('ddd') : weekday}
                                                        </div>
                                                    </td>
                                                </tr>
                                                {
                                                    this.state.resources.map((resource, resKey) => {
                                                        if (
                                                            !this.state.schedules[weekday] ||
                                                            !this.state.schedules[weekday][resource.id]
                                                        ) {
                                                            return null;
                                                        }

                                                        return (
                                                            <tr
                                                                className={this.props.classes.resourceRow}
                                                                key={'res_' + resKey}
                                                                style={{height: (this.state.rowsHeight[weekday + '_' + resource.id] ? this.state.rowsHeight[weekday + '_' + resource.id] : (this.props.withCheckboxes ? 58 : 80))}}
                                                            >
                                                                <td style={{padding: 0}}>
                                                                    <div
                                                                        className={this.props.classes.contentResource}
                                                                        style={
                                                                            resKey === this.state.resources.length - 1 ?
                                                                                {
                                                                                    borderBottom: '1px solid #969696',
                                                                                    width: (this.state.isFolded ? 70 : 140),
                                                                                    transition: 'all 0.2s ease-in-out 0s'
                                                                                } :
                                                                                {
                                                                                    width: (this.state.isFolded ? 70 : 140),
                                                                                    transition: 'all 0.2s ease-in-out 0s'
                                                                                }
                                                                        }
                                                                    >
                                                                        {this.resourceRender(resource)}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </Fragment>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </td>
                        <td className={this.props.classes.scrollingCell} ref={this.scrollingCell} style={{verticalAlign: 'top', width: this.state.width - (this.state.isFolded ? 60 : 130)}}>
                            <table className={this.props.classes.mainTable}>
                                <thead>
                                <tr
                                    className={this.props.classes.stickyHeadRow}
                                    ref={this.stickyHeadRow}
                                    style={{width: (this.state.isFolded ? this.state.width - 71 : this.state.width - 141) , overflow: 'hidden', top: 0, backgroundColor: layoutColor}}
                                >
                                    <td className={this.props.classes.headCell} style={{display: 'flex', width: 'fit-content'}}>
                                        {
                                            this.getTimeSlots().map((timeSlot, key)=> (
                                                <div className={this.props.classes.timeslot} key={key}>
                                                    {timeSlot}
                                                </div>
                                            ))
                                        }
                                    </td>
                                </tr>
                                </thead>
                                <tbody id='timeSlotsBody'>
                                {
                                    this.state.weekdays.map((weekday, key) => (
                                        <Fragment key={'wdcont_' + key}>
                                            <tr
                                                className={this.props.classes.weekdayRow}
                                                ref={key === 0 ? this.scrollingCellTbody : null}
                                                style={key === 0 ? {marginTop: this.state.scrollingCellTbodyMarginTop} : {}}
                                            >
                                                <td style={{display: 'flex', padding: 0}}>
                                                    {
                                                        this.getTimeSlots().map((timeSlot, key)=> (
                                                            <div className={this.props.classes.timeslot} key={key}>

                                                            </div>
                                                        ))
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: 0}}>
                                                    {
                                                        <DroppableTable
                                                            id={weekday}
                                                            accept={itemTypes.SCHEDULE}
                                                            resources={this.state.resources}
                                                            timeSubSlotsRender={this.timeSubSlotsRender}
                                                            weekday={weekday}
                                                            schedules={this.state.schedules}
                                                            moveSchedule={this.moveSchedule}
                                                            stopRefreshing={this.stopRefreshing}
                                                            refresh={this.state.refresh}
                                                            refreshRows={this.state.refreshRows}
                                                            showNotification={showNotification}
                                                            rowsHeight={this.state.rowsHeight}
                                                            withCheckboxes={this.props.withCheckboxes}
                                                            readOnly={this.props.readOnly}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        </Fragment>
                                    ))
                                }
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div id="positioner" ref={this.positioner}> </div>
                <div
                    className={this.props.classes.scrollerWrapper}
                    ref={this.scrollerWrapper}
                    onScroll={this.onScroll}
                    style={{
                        left: this.state.scrollerPosition + 3,
                        width: this.state.width - (this.state.isFolded ? 72 : 142)
                    }}
                >
                    <div style={{width: this.state.scrollerWidth - 12, overflowX: 'scroll', overflowY: 'hidden'}}> </div>
                </div>
                <MoveScheduleConfirmDialog ref={myRef} />
            </div>
        )
    }
}

export default compose(
    withResizeDetector,
    withStyles(styles)
)(NeteaSchedulerComponent);
