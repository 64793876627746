import React, {useEffect, useState} from 'react';
import {CREATE} from "react-admin";
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import SelfFetchCheckboxInput from '../create/input_components/SelfFetchCheckboxInput';
import DialogActions from '@material-ui/core/DialogActions';
import {Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ContentSave from "@material-ui/icons/Save";
import {useForm, useFormState} from "react-final-form";
import {fetchIt} from "../../../../../helpers";


const ShowClassesDialog = props => {
    const {
        classes,
        closeClassesDialog,
        classSchedule,
        refreshClasses,
        setShowClassesDialog,
        fetchStart,
        fetchEnd,
        showNotification,
        ...rest
    } = props;
    const form = useForm();
    const formState = useFormState();
    const [refresh, setRefresh] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);


    useEffect(() => {
        setRefresh(refreshClasses);
    }, [refreshClasses]); // eslint-disable-next-line


    const handleSubmit = () => {
        setIsDisabled(true);

        //Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let discountIsFlat = true;
        let discountValue = 0;
        let student = 0;

        if (formState.values.Student) {
            student = formState.values.Student;
        }

        const mySchedules = formState.values.Schedules;
        if (formState.values.hasDiscount) {
            discountIsFlat = !formState.values.DiscountType.length;
            discountValue = formState.values.DiscountValue;
        }

        let scheduleStudents = [];
        for (const mySchedule in mySchedules) {
            if (mySchedules.hasOwnProperty(mySchedule)) {
                const scheduleId = mySchedule.split('-');
                const scheduleStudent = {
                    StudentId: student,
                    ScheduleId: scheduleId[1],
                    ScheduleDays: mySchedules[mySchedule].schedule_day_works
                };
                scheduleStudents.push(scheduleStudent);
            }
        }

        const data = {
            DiscountIsFlat: discountIsFlat,
            DiscountValue: discountValue,
            ScheduleStudents: scheduleStudents,
        };

        fetchIt(
            CREATE,
            'registration_price',
            data
        ).then(response => {
            let errorMessage = '';
            switch (response.status) {
                case 200:
                    response.data.Prices.map(object => {
                        form.change('Schedules.sch-' + object.ScheduleId + '.PriceForRemainingClasses', object.Price);

                        return object;
                    });

                    form.change('SchedulesChanged', !formState.values.SchedulesChanged);
                    form.change('SubTotal', response.data.Subtotal);
                    form.change('Total', response.data.TotalPrice);

                    let message = 'The price is updated!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }
                    setShowClassesDialog(false);
                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    break;

                case 400:
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                case 404:
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            setIsDisabled(false);
        });

    };

    return (
        <Dialog
            onClose={setShowClassesDialog}
            {...rest}
        >
            <DialogTitle style={{paddingBottom: 0, paddingLeft: 30}}>
                Classes dates
                <IconButton aria-label='close' className={classes.closeButton} onClick={closeClassesDialog}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent style={{paddingTop: 0, paddingBottom: 0}}>
                <SelfFetchCheckboxInput classSchedule={classSchedule} handleSubmit={handleSubmit} refreshClasses={refresh}/>
            </DialogContent>

            <DialogActions className="EmailChangeButtons" style={{backgroundColor: '#f5f5f5'}}>
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={isDisabled}
                    onClick={handleSubmit}
                >
                    <ContentSave style={{marginRight: 5, fontSize: 18}} /> Save
                </Button>
                <Button onClick={closeClassesDialog} color='primary'>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShowClassesDialog;
