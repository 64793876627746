import React, {Component} from "react";
import {addField} from "react-admin";
import * as usStates from '../../config/usStates';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

class UsStates extends Component {
    constructor(props) {
	    super(props);

	    this.state = {
            value: props.input.value,
            hasError: false
		};
	}

    componentWillReceiveProps(nextProps){

        let newState = this.state;

        if(nextProps.input.value){
           newState.value = nextProps.input.value;
        }

        if(newState.value) {
            newState.hasError = false
        }

        if(nextProps.meta.submitFailed && nextProps.meta.invalid) {
            newState.hasError = true
        }

        this.setState(newState);
    }

    render() {
        return this.props.record.Address || !this.props.editMode ? (
            <FormControl error={this.state.hasError} style={{marginLeft: this.props.userTabbedForm ? 25 : 0}}>
                <InputLabel htmlFor={this.props.source}>State *</InputLabel>
                <Select
                    native
                    value={this.state.value}
                    onChange={this.props.input.onChange}
                    id={this.props.source}
                    className='fieldsWidthExpand'
                >
                    {
                        !this.state.value ? <option value="" /> : null
                    }
                    {
                        usStates.usStates.map(function(usState) {
                            return <option key={usState}
                            value={usState}>{usState}</option>;
                        })
                    }
                </Select>
                {this.state.hasError && <FormHelperText>{this.props.meta.error}</FormHelperText>}
            </FormControl>
        ) :null ;

    }
}

export default addField(UsStates);