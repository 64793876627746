import React, {Component} from 'react';
import {TextField, FunctionField, Datagrid, ShowButton, ListContextProvider} from 'react-admin';
import {UserFamilyDetails} from './UserFamilyDetails';
import CreateFamilyMemberButton from '../containers/CreateFamilyMemberButton';
import moment from "moment";


export const FamilyMemberShowButton = props => {
    const {handleRefresh, fetchTheData, ...other} = props;
    let fetchTheDataHandler = fetchTheData;

    if(typeof fetchTheDataHandler !== 'function') {
        fetchTheDataHandler = () => {};
    }

    return(
        <ShowButton
            {...other}
            onClick={() => {fetchTheDataHandler(props.record.id); handleRefresh(null, props.record.id);}}
            label="User Details"
            basePath='/tenant_users'
        />
    );
};

class TenantUserFamilyGrid extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            showDialog: false,
            tenantFamilyMemberData: {}
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // onDialogOpen = (e, record) => {
    //     e.preventDefault();
    //
    //     if (record.CellPhone) {
    //         record.CellPhone = formatPhone(record.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10));
    //     }
    //
    //     if (record.HomePhone) {
    //         record.HomePhone = formatPhone(record.HomePhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10));
    //     }
    //
    //     if (this._isMounted) {
    //         this.setState({showDialog: true, tenantFamilyMemberData: record});
    //     }
    // };

    // onDialogClose = () => {
    //     if (this._isMounted) {
    //         this.setState({showDialog: false});
    //     }
    // };

    render() {
        const {handleRefresh, fetchTheData, ...other} = this.props;

        const dataGridProps = Object.assign({}, other);
        dataGridProps.resource = 'tenant_users';

        if(!this.props.data || (Object.keys(this.props.data).length === 0 && this.props.data.constructor === Object)){
            return(
                <div style={{position: "relative"}}>
                    <div style={{position: 'absolute', right: 15, top: -5, zIndex: 10}}>
                        <CreateFamilyMemberButton
                            handleRefresh={handleRefresh}
                            record={this.props.record}
                            isDeleted={this.props.isDeleted}
                        />
                    </div>
                    <div className="NoDataLabel">
                        No data.
                    </div>
                </div>
            );
        }

        return (
            <div style={{position: "relative", paddingBottom: 25}}>
                <div style={{position: 'absolute', right: 15, top: -5, zIndex: 10}}>
                    <CreateFamilyMemberButton
                        handleRefresh={handleRefresh}
                        record={this.props.record}
                        isDeleted={this.props.isDeleted}
                    />
                </div>
                <ListContextProvider
                    value={{
                        resource: 'users_family_members',
                        basePath: 'users_family_members',
                        selectedIds: [],
                        ...dataGridProps
                    }}
                >
                    <Datagrid expand={<UserFamilyDetails {...other} />} record={this.props.record}>
                        <FunctionField
                            source='FirstName'
                            label="Name"
                            render={record => `${record.FirstName} ${record.LastName}`}
                            sortable={false}
                        />
                        <TextField source='Role' sortable={false} />
                        <FunctionField
                            label='BirthDate'
                            render={record => record.BirthDate ? moment(record.BirthDate, 'MM/DD/YYYY').format('MMMM D, YYYY') : 'Not specified'}
                        />
                        {/* <FunctionField
                            label='Status'
                            source='IsActive'
                            render={record => record.IsActive ? 'Active' : 'Inactive'}
                            sortable={false}
                        /> */}

                        <FamilyMemberShowButton style={{float: 'right'}} fetchTheData={fetchTheData} handleRefresh={handleRefresh}/>
                    </Datagrid>
                </ListContextProvider>
            </div>
        );
    }
}

export default TenantUserFamilyGrid;
