import React from 'react';
import CardContent from "@material-ui/core/CardContent";
import {Grid, Typography} from "@material-ui/core";


const Totals = props => {
    const {record, classes} = props;

    return (
        <>
            <Grid item xs={8}>
                <CardContent style={{padding: 0}}>
                    {
                        record.Discount ? (
                            <Typography variant="body1" style={{textAlign: 'end'}} component="div" className={classes.lightGrey} gutterBottom>
                                Discount:
                            </Typography>
                        ) : null
                    }
                    {
                        record.Total ? (
                            <Typography variant="body1" style={{textAlign: 'end'}} component="div" gutterBottom>
                                Total:
                            </Typography>
                        ) : null
                    }
                </CardContent>
            </Grid>

            <Grid item xs={2}>
                <CardContent style={{paddingRight: 9, paddingTop: 0}}>
                    {
                        record.Discount ? (
                            <Typography variant="body1" style={{textAlign: 'end'}} component="div" gutterBottom>
                                ${record.Discount.toFixed(2)}
                            </Typography>
                        ) : null
                    }
                    {
                        record.Total ? (
                            <Typography variant="body1" style={{textAlign: 'end'}} component="div" gutterBottom>
                                ${record.Total.toFixed(2)}
                            </Typography>
                        ) : null
                    }
                </CardContent>
            </Grid>
        </>
    );
}

export default Totals;
