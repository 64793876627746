import React, {Component, Fragment} from 'react';
import {
    CREATE,
    UPDATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    TextField,
    BooleanInput,
    NumberInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {NtaSaveButton} from '../../common';
import {getErrorMessage, fetchIt} from "../../../helpers";
import CurrencyInput from "../../../components/CurrencyInput";
import {Redirect} from 'react-router';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import {requiredPositiveNumberValidation, durationValidation} from "../../../validators";
import MonthsToYearsInput from '../../../components/MonthsToYearsInput';
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";



export const CourseFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} />
        </Toolbar>
    );
};


class CourseForm extends Component {
    _isMounted = false;

    state = {
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    nameOnChange = e => {
        this.updateTitle(e.currentTarget.value);
    };

    updateTitle = newTitle => {
        if (this.props.updateTitle) {
            let title = '';
            if (newTitle) {
                title = newTitle;
            }
            this.props.updateTitle(title);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.updateTitle(nextProps.record.Name);
        }
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;

        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;

            if (values.Places === null) {
                delete values.Places;
            } 
        }

        let data = Object.assign({}, values);
        
        let MinAge = 0;
        if (values.MinAge && values.MinAge.Years) {
            MinAge = values.MinAge.Years * 12;
        }
        if (values.MinAge && values.MinAge.TheHalf) {
            MinAge += 6;
        }
        data.MinAge = MinAge;

        let MaxAge = 0;
        if (values.MaxAge && values.MaxAge.Years) {
            MaxAge = values.MaxAge.Years * 12;
        }
        if (values.MaxAge && values.MaxAge.TheHalf) {
            MaxAge += 6;
        }
        data.MaxAge = MaxAge;

        fetchIt(
            requestType,
            'courses',
            data
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Course saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.location = this.props.location;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        const SanitizedGrid = ({basePath, ...props}) => {
            return (
              <Grid {...props} />
            );
        };

        return (
            <Card className="cardForm">
                <CardContent>
                    <SimpleForm
                        record={this.props.record}
                        resource="courses"
                        save={this.handleSubmit}
                        toolbar={<CourseFormToolbar isDisabled={this.state.isDisabled} />}
                    >
                        <SanitizedGrid container style={{width: '100%', maxWidth: 1640}} >
                            <Grid item xs={6}>
                                <TextInput
                                    source="Name"
                                    label='Course Name'
                                    className='fieldsWidthExpand'
                                    onChange={this.nameOnChange}
                                    validate={required('The Course Name field is required')}
                                    multiline
                                />
                                <NumberInput
                                    source="Duration"
                                    label="Duration (in minutes (max. 720)"
                                    defaultValue={45}
                                    inputProps={{
                                        min: 0,
                                        max: 720
                                    }}
                                    format={v => parseInt(v) >= 0 ? parseInt(v) : ' '}
                                    parse={v => parseInt(v)}
                                    validate={durationValidation}
                                    className='fieldsWidthExpand'
                                />

                                <NumberInput 
                                    source="Places" 
                                    inputProps={{
                                        min: 0
                                    }}
                                    format={v => parseInt(v) >= 0 ? parseInt(v) : ' '}
                                    parse={v => parseInt(v)}
                                    className='fieldsWidthExpand'
                                />

                                <CurrencyInput
                                    source='SingleClassPrice'
                                    validate={requiredPositiveNumberValidation}
                                />

                                <BooleanInput source='IsActive' defaultValue={this.props.editMode ? '' : true} />
                            </Grid>

                            <Grid item xs={6}>
                               
                                <MonthsToYearsInput source='MinAge' label="Min Age*" />
                                <Typography style={{paddingTop: 18}}></Typography>
                                <MonthsToYearsInput source='MaxAge' label="Max Age*" />        
                                <Typography style={{paddingTop: 18}}></Typography>
                                 {
                                    ! this.props.editMode ||
                                    ! (
                                        Object.keys(this.props.record).length === 0 &&
                                        this.props.record.constructor === Object
                                    ) ?
                                        <RichTextInput source="Description" />
                                    : null
                                }     

                                {
                                    this.props.editMode ?
                                        <Fragment>
                                            <TextField source='CreatedBy' />
                                            <TextField source='ModifiedBy' />
                                            <TextField source='CreatedTime' />
                                            <TextField source='ModifiedTime' />
                                        </Fragment>
                                    : null
                                }
                            </Grid>
                        </SanitizedGrid>
                        
                        
                    </SimpleForm>
                </CardContent>
             </Card>
        );
    }
}

export default CourseForm;
