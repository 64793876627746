import React, {Component, Fragment} from 'react';
import {BooleanInput, addField, Labeled} from 'react-admin';
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

let choices = [];
for (let t = 0; t < 19; t++) {
    const object = {id: t, name: t};
    choices.push(object);
}

const YearsInputInner = ({input, meta: {touched, error}, source, record, id, ...props}) => {
    return (
        <Fragment>
            <Select
                native
                style={{minWidth: 121}}
                source={source + '.Years'}
                record={record}
                value={input.value.Years}
                onChange={event => input.onChange({...input.value, Years: event.target.value})}
            >
                {
                    !input.value ? <option value={0}>Select years</option> : null
                }
                {
                    choices ?
                        choices.map(choice => {
                            return <option key={choice.id} value={choice.id}>{choice.name}</option>;
                        }) : null
                }
            </Select>
        </Fragment>
    );
};

export const YearsInput = addField(YearsInputInner); // decorate with react-final-form's <Field>


class MonthsToYearsInput extends Component {
    _isMounted = false;
    _hasChanged = true;

    constructor (props) {
        super(props);

        this.state = {
            choices: [],
            value: 0
        };
    }


    componentDidMount () {
        this._isMounted = true;
        this._hasChanged = true;
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render () {
        return (
            <Labeled source={this.props.source} label={this.props.label}>
                <FormControl style={{minWidth: 256, flexDirection: "row", marginTop: -22}} error={this.props.meta.touched && this.props.meta.error}>
                    <YearsInput {...this.props} source={this.props.source + '.Years'} />
                    <BooleanInput
                        source={this.props.source + '.TheHalf'}
                        record={this.props.record}
                        label='+ 6 months'
                        style={{paddingLeft: 24, marginBottom: -21}}
                    />
                    {this.props.meta.touched && this.props.meta.error && <FormHelperText>{this.props.meta.error}</FormHelperText>}
                </FormControl>
            </Labeled>
        );
    }
}

export default addField(MonthsToYearsInput);
