import React, {useState, useEffect} from 'react';
import {
    Grid,
    Divider,
    Typography,
    Button
} from "@material-ui/core";
import {ListButton, GET_ONE, CREATE, Title} from "react-admin";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import {createTheme, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import {fetchIt} from '../../../../../helpers';
import moment from "moment";
import Card from "@material-ui/core/Card";
import InfoCard from "./InfoCard";
import Totals from "./Totals";
import ScheduleStudents from "./ScheduleStudents";
import PayeeCard from "./PayeeCard";


const innerTheme = createTheme({
    typography: {
        useNextVariants: true,
        "fontFamily": "\"proxima-nova\", sans-serif",
    },
    overrides: {
        MuiFormLabel: {
            root: {
                fontWeight: 500,
                color: '#9f9f9f',
                fontSize: 14,
                lineHeight: 1.57
            },

        },

        MuiInputLabel: {
            shrink: {
                transform: 'none'
            }
        },

        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
            rounded: {
                borderRadius: 10
            }
        },

        MuiDialogContent: {
            root: {
                paddingLeft: 0,
                paddingRight: 0,
                '&:last-child': {
                    paddingBottom: 0
                }
            }
        },

        MuiDialogContentText: {
            root: {
                paddingLeft: 25,
                paddingRight: 25
            }

        },

        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#3B9287',
                boxShadow: 'none',
                borderRadius: 10
            },
        },
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#3B9287",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        red: {
            main: 'red'
        },
        background: {
            default: '#fcfcfe',
        },
    },
});


const styles = ({
    lightGrey: {
        color: "#9f9f9f"
    },

    detailsTopContainer: {
        display: "flex",
        justifyContent: "space-between"
    },

    leftColumnTitle: {
        marginTop: 25
    },

    backToList: {
        textTransform: "capitalize !important",
        fontSize: "16px !important",
        color: "#9f9f9f !important"
    },

    noShadow: {
        boxShadow: 'none'
    },

    gutterBottom: {
        marginBottom: 16
    },

});



const RegistrationShow = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState({});

    useEffect(() => {
        fetchTheData();
    }, []); // eslint-disable-line

    const fetchTheData = () => {
        const {fetchStart, fetchEnd, showNotification} = props;

        fetchStart();

        setIsLoading(true);

        fetchIt(
            GET_ONE,
            'registrations',
            {id: props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    setRecord(response.data);

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {
            //
        }).then(() => {
            fetchEnd();

            setIsLoading(false);
        });
    }

    const onPaymentResend = () => {
        const {fetchStart, fetchEnd, showNotification} = props;

         // Dispatch an action letting react-admin know an API call is ongoing
         fetchStart();

        fetchIt(
            CREATE,
            'registration_resend_payment_link',
            {id: record.Id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Schedule Student canceled.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:
                default:
                showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }

    const {classes} = props;
    const regDate = 'Registartion Date: ' + moment.utc(record.RegistrationDate, 'M/D/YYYY h:mm A').format('D MMMM YYYY');

    return (
        <>
            {
                !isLoading ?
                    (
                        <MuiThemeProvider theme={innerTheme}>
                            <div className="wrapperContent" style={{marginTop: 8}}>
                            <Title title={regDate}/>
                                <Grid container spacing={2} className='labeled-value'>
                                    <Grid item xs={4}>
                                        <ListButton
                                            basePath='/registrations'
                                            icon={<ArrowBack style={{fontSize: 16, marginRight: '-0.5em'}} />}
                                            label='Back'
                                            className={classes.backToList}
                                        />
                                    </Grid>

                                    <Grid item xs={8}>
                                        <div className={classes.detailsTopContainer}>
                                            <Typography variant="h4" component="h1" style={{marginBottom: '0.1em'}}>
                                                {/* Enrollment Date: {moment.utc(record.RegistrationDate, 'M/D/YYYY h:mm A').format('D MMMM YYYY')} */}
                                            </Typography>
                                            {
                                                !record.PaymentReceived && !record.Canceled ?
                                                    <Button style={{color: '#3B9287'}} onClick={onPaymentResend}>Resend Payment Link</Button>
                                                : null
                                            }
                                            {
                                                record.PaymentReceived && (record.Canceled || record.IsPartlyCanceled) ?
                                                    <Button
                                                        href={record.PaymentUrl}
                                                        target="_blank"
                                                        color="primary"
                                                    >
                                                        Issue a refund
                                                    </Button>
                                                : null
                                            }


                                        </div>

                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop: 0}}><Divider /></Grid>

                                    <Grid item xs={2}>
                                        <Typography variant="h6" component="div" className={classes.leftColumnTitle} gutterBottom>
                                            Courses
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Card style={{minHeight: 85}}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {
                                                        record.ScheduleStudents ? record.ScheduleStudents.map(scheduleStudent => (
                                                            <ScheduleStudents
                                                                key={scheduleStudent.StudentId}
                                                                scheduleStudent={scheduleStudent}
                                                                classes={classes}
                                                                fetchTheData={fetchTheData}
                                                            />
                                                        )) : null
                                                    }
                                                </Grid>

                                                <Totals record={record} classes={classes} />

                                                <InfoCard record={record} classes={classes} />
                                            </Grid>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <PayeeCard record={record} classes={classes} />
                                </Grid>
                            </div>
                        </MuiThemeProvider>
                    ) : null
            }
        </>
    );
};

export default withStyles(styles)(RegistrationShow);
