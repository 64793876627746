import React, {Fragment} from 'react';
import {
    TextField,
    FunctionField,
    RichTextField,
    SelectField
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../../common';
import {RegistrationViewChoices} from "../../../../config/constants";


export const SemesterShow = props => {
    const options = {
        detailsTitle: 'semester',
        titleField: 'SemesterName',
    };

    return (
        <Fragment >
            <DetailsLayout {...props} options={options}>
                <DetailsTileWrapper
                    sideTitle='Semester Details'
                >
                    <TextField source='Name' />
                    <TextField source='FromDate' />
                    <TextField source='ToDate' />
                    <TextField source='SchoolYear' />
                    <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                    <SelectField source='RegistrationView' choices={RegistrationViewChoices} />
                    <RichTextField source='Description' />
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};
