import React, {Component} from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {addField, FieldTitle} from "react-admin";

class DatePicker extends Component {

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    render() {
        const {
            label,
            source,
            resource,
            isRequired,
            meta,
        } = this.props;

        const {touched, error} = meta;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <KeyboardDatePicker
                    className='fieldsWidthExpand'
                    label={
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    }
                    margin="normal"
                    format="MM/DD/YYYY"
                    value={this.props.val ? this.props.val : this.props.input.value}
                    onChange={this.props.onChange ? value => this.props.onChange(value, 'Picker') : this.props.input.onChange}
                    disableFuture={this.props.disableFuture !== false}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={{
                        marginBottom: !this.props.noPadding ? 21 : 4
                    }}
                    labelFunc={this.renderLabel}
                    helperText={touched && error}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    inputProps={{
                        readOnly: true
                    }}
                    error={this.props.error ? this.props.error : !!(touched && error)}
                    disabled={this.props.disabled}
                />
            </MuiPickersUtilsProvider>
        );
    }
}
export default addField(DatePicker);
