import {inArray} from "./common";
import ExcelJS from "exceljs";
import {saveAs} from 'file-saver';


export const labelFromKey = key => {
    let label = '';
    if (key && typeof key === 'string') {
        label = key.replace(/([A-Z])/g, " $1")
        label = label.charAt(0).toUpperCase() + label.slice(1);
    }

    return label;
}


const exporter = records => {
    let headers = {};
    const rightAligned = [];


    let data = records.map(record => {
        let newRecord = {};

        for (const field in record) {
            if (record.hasOwnProperty(field)) {
                headers[field] = labelFromKey(field);
                const object = record[field];

                if (typeof object === "object") {
                    let text = [];

                    for (const prop in object) {
                        if (object.hasOwnProperty(prop)) {
                            text.push(prop + ": " + object[prop]);
                        }
                    }

                    newRecord[field] = text.join(", ");
                } else if (field !== "id") {
                    newRecord[field] = object;
                }
            }
        }

        return newRecord;
    });


    headers = [headers];


    data = headers.concat(data);


    let objectMaxLength = [];
    for (let t = 0; t < data.length; t++) {
        for (let key in data[t]) {
            if (data[t].hasOwnProperty(key)) {
                objectMaxLength[key] = objectMaxLength[key] >= ((data[t][key] + '').length + 3) ?
                    objectMaxLength[key] :
                    ((data[t][key] + '').length + 3);
            }
        }
    }

    data.shift();

    let newHeaders = [];
    for (let key in headers[0]) {
        if (headers[0].hasOwnProperty(key)) {
            newHeaders.push({
                key: key,
                header: headers[0][key],
                width: objectMaxLength[key],
                style: (inArray(key, rightAligned) ? {alignment: {vertical: 'middle', horizontal: 'right'}} : {})
            });
        }
    }


    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Export');
    sheet.columns = newHeaders;
    for (let t = 0; t < data.length; t++) {
        sheet.addRow(data[t]);
    }

    let row = sheet.getRow(1);
    row.font = {bold: true};


    workbook.xlsx.writeBuffer()
    .then(buffer => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const blob = new Blob([buffer], {type: fileType});

        return saveAs(blob, 'Export.xlsx')
    })
    .catch(err => console.log('Error writing excel export', err));
};

export default exporter;
