import React, {Component} from "react";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// import MuiDatePicker from 'material-ui-pickers/DatePicker';
// import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {addField} from "react-admin";


class DatePickerHoliday extends Component {

    isFromSet = false
    isToSet = false
    isFromClicked = false

    componentWillReceiveProps(nextProps) {

        const inputProps = nextProps.input;
        const inputValue = inputProps.value;
        const inputName = inputProps.name;

        if(inputValue !== "") {

            if(inputName === "From" && !this.isFromSet) {
                this.isFromSet = true

                this.props.context.setState({ 
                    fromDate: inputValue,
                    fromDateValue: inputValue
                });
            }

            if(inputName === "To" && !this.isToSet) {
                this.isToSet = true

                this.props.context.setState({
                    toDateValue: inputValue
                });
            }
        }
    }

    renderLabel = (date) => {

        if (date.isValid()) {
          return date.format('MM/DD/YYYY');
        } else {
          return '';
        }
    }

    onPickerChange = (selectedDate) => {

        this.props.context.setState({ 
            fromDate: selectedDate._d
        }, () => {
            this.props.context.setState({ 
                fromDate: this.props.context.state.fromDateValue
            })
        });

        if(this.props.source === 'From'){
            this.props.context.setState({ 
                fromDate: selectedDate._d,
                fromDateValue: selectedDate._d,
                errorFromRequired: false
            });
        } else {
            this.props.context.setState({ 
                toDate: selectedDate._d,
                toDateValue: selectedDate._d,
                errorToRequired: false
            });
        }
    }


    render() {
        const state = this.props.context.state;
        const source = this.props.source;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
                <KeyboardDatePicker
                    required
                    error={source === 'From' ? state.errorFromRequired : state.errorToRequired}
                    helperText={source === 'From' ? (state.errorFromRequired ? "The From field is required" : null) : (state.errorToRequired ? "The To field is required" : null) }
                    label={source}
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    onChange={this.onPickerChange}
                    value={source === 'To' ? moment(state.toDateValue) : moment(state.fromDateValue)}
                    disabled ={source === 'To' ? state.fromDateValue === "" : false}
                    format="MM/DD/YYYY"
                    style={{paddingBottom: 22}}
                    minDate={source === 'To' ? moment(state.fromDate) : new Date()}
                    labelFunc={this.renderLabel}
                    inputProps={{ readOnly: true }}
                    className='fieldsWidthExpand'
                />
            </MuiPickersUtilsProvider>
        );
    }
}

export default addField(DatePickerHoliday);