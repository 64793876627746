import React, {Component} from 'react'
import {inArray} from './helpers'


class Row extends Component {
    _isMounted = false;

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            !this.state ||
            (
                nextProps &&
                nextProps.refresh &&
                inArray(this.props.id, nextProps.refresh)
            )
        );
    }


    componentDidMount() {
        this._isMounted = true;
        this.setState({loaded: true});
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        return (
            <tr style={{
                minHeight: (this.props.rowsHeight[this.props.weekday + '_' + this.props.resource.id] ? this.props.rowsHeight[this.props.weekday + '_' + this.props.resource.id] : (this.props.withCheckboxes ? 58 : 80)),
                display: 'flex'
            }}>
                <td style={{display: 'flex', padding: 0, zIndex: 2, border: 'none'}}>
                    {
                        this.props.timeSubSlotsRender(this.props.weekday, this.props.resource)
                    }
                </td>
            </tr>
        )
    }

}

export default Row;
