import React, {useState, Fragment, useEffect} from 'react';
import {
    Button,
    FormWithRedirect,
    TextInput,
    UPDATE,
    GET_ONE,
    required
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {emailValidation} from '../../../validators';
import {fetchIt, getErrorMessage} from "../../../helpers";
import IconCancel from '@material-ui/icons/Cancel';
import {NtaSaveButton} from '../../common';
import EditIcon from '@material-ui/icons/Edit';
import DialogContentText from '@material-ui/core/DialogContentText';

const FormActions = props => {
    const {basePath, onCloseClick, isDisabled, showNotification, ...rest} = props;

    return (
        <DialogActions {...rest} className="EmailChangeButtons">
            <NtaSaveButton label="Save" onCloseClick={onCloseClick} showNotification={showNotification} isDisabled={isDisabled} />
            <Button label="Cancel" onClick={onCloseClick}>
                <IconCancel />
            </Button>
        </DialogActions>
    );
};

const ChangeEmailDialog = props => {

    const [showDialog, setShowDialog] = useState(false);
    const [record, setRecord] = useState(false);

    useEffect(() => {

        if(showDialog) {
            const {fetchStart, fetchEnd, showNotification} = props;

            fetchStart();

            fetchIt(
                GET_ONE,
                'account',
                {id: props.memberToEdit}
            ).then(response => {
                switch (response.status) {
                    case 200:

                        setRecord({Email: response.data.Email});

                        break;

                    case 400:

                        let errorMessage = 'Oops, something went wrong!';
                        if (typeof response.data.HasValidationErrors !== 'undefined') {
                            errorMessage = getErrorMessage(response.data);
                        }

                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: errorMessage } }
                        );

                        break;

                    default:
                        showNotification(
                            'direct_message',
                            'warning',
                            { messageArgs: { _: 'Oops, something went wrong!' } }
                        );
                }
            }).catch(error => {

            }).finally(() => {
                // Dispatch an action letting react-admin know a API call has ended
                fetchEnd();
            });
        }

        if(!props.showDialog){
            setRecord(false);
        }

    }, [showDialog]); // eslint-disable-line

    const handleClick = (values) => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };


    const handleSubmit = values => {;

        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        fetchIt(
            UPDATE,
            'change_account_email',
            {data: values}
        ).then(response => {
            switch (response.status) {
                case 200:

                    let message = 'Email updated.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    setShowDialog(false);

                    break;

                case 400:
                    setShowDialog(true);
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };

        return (
            <Fragment>
                <Button
                    onClick={handleClick}
                    lable="Update Email"
                    id='changeEmailButton'
                >
                    <EditIcon />
                </Button>
                <Dialog
                    open={showDialog}
                    onClose={handleCloseClick}
                    aria-label="Update email"
                    maxWidth='sm'
                >
                    <DialogTitle>
                        Update email
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            *Note: Email is also used as login user name!
                        </DialogContentText>

                        <FormWithRedirect
                            {...props}
                            record={record}
                            resource='tenant_users_email'
                            save={handleSubmit}
                            render={formData => (
                                <form style={{paddingLeft: 25, paddingRight: 25}}>
                                    <TextInput
                                        source='Email'
                                        validate={emailValidation}
                                        validate={required('The Email field is required')}
                                    />

                                    <TextInput
                                        source='CurrentPassword'
                                        type='password'
                                        validate={required('The Password field is required')}
                                    />
                                    <FormActions
                                        onCloseClick={handleCloseClick}
                                        showNotification={props.showNotification}
                                        style={{
                                            backgroundColor: '#f5f5f5', 
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                            marginLeft: -25
                                        }} 
                                    />
                                </form>
                            )}
                        />
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
}

export default ChangeEmailDialog;
