import React, {Component, Fragment} from 'react';
import {Create} from 'react-admin';
import CourseForm from '../containers/CourseForm';
import {BackButton} from '../../../common';


class CourseCreate extends Component {
    render() {
        return (
            <Fragment>
                <BackButton />
                <Create {...this.props} title="Create Course">
                    <CourseForm />
                </Create>
            </Fragment>
        );
    }
}
export default CourseCreate;
