import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import {connect} from "react-redux";
import TenantUserFamilyList from '../components/TenantUserFamilyList';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    TenantUserFamilyList
);
