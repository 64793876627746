import React, {Component} from 'react';
import {
    UPDATE,
    CREATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    FormDataConsumer,
    BooleanInput,
    SelectInput
} from 'react-admin';
import {fetchIt, getErrorMessage} from "../../../../helpers";
import {Redirect} from 'react-router';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import {DatePicker} from '../../../../common';
import {NtaSaveButton} from '../../../common';
import RichTextInput from 'ra-input-rich-text';
import {RegistrationViewChoices} from "../../../../config/constants";
import {semesterNameValidation} from '../../../../validators';
import {Grid} from "@material-ui/core";
import moment from "moment";


export const SemesterFormToolbar = props => {
    const {isDisabled, context, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} context={context}/>
        </Toolbar>
    );
};

export const SemesterFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};

class SemesterForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null,
        isDisabled: false
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;

        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;
        }

        values["FromDate"] = values["FromDate"].format('MM/DD/YYYY');
        values["ToDate"] = values["ToDate"].format('MM/DD/YYYY');
        values["RegistrationStartDate"] =  moment.utc().format('MM/DD/YYYY');


        fetchIt(
            requestType,
            'semesters',
            values
        ).then(response => {
            let errorMessage = '';
            switch (response.status) {
                case 200:
                    let message = 'Semester saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;

                case 400:
                    errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    errorMessage = 'Oops, something went wrong!';
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;
        props.semester = this.props.semester;

        const SanitizedGrid = ({basePath, ...props}) => {
            return (
              <Grid {...props} />
            );
        };


        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Card className="cardForm">
                <CardContent>
                    <SimpleForm
                        resource={this.props.resource}
                        record={this.props.record}
                        save={this.handleSubmit}
                        toolbar={<SemesterFormToolbar isDisabled={this.state.isDisabled} context={this}/>}
                    >
                        <SanitizedGrid container style={{width: '100%', maxWidth: 1640}} >
                            <Grid item xs={6}>
                                <TextInput
                                    source="Name"
                                    className='fieldsWidthExpand'
                                    validate={semesterNameValidation}
                                    multiline
                                />

                                <FormDataConsumer>
                                    {
                                        ({formData}) => {
                                            return <SemesterFormTitle title={formData.SemesterName} />;
                                        }
                                    }
                                </FormDataConsumer>


                                <TextInput
                                    source="SchoolYear"
                                    className='fieldsWidthExpand'
                                    validate={required('The School year field is required')}
                                />

                                <DatePicker
                                    source='FromDate'
                                    disableFuture={false}
                                    validate={required('The From Date field is required')}
                                />

                                <FormDataConsumer>
                                    {
                                        ({formData}) => {
                                            return (
                                                <DatePicker
                                                    source="ToDate"
                                                    disableFuture={false}
                                                    validate={required('The To Date field is required')}
                                                    minDate={formData.FromDate ? formData.FromDate : undefined}
                                                />
                                            )
                                        }
                                    }
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={6} >
                                <SelectInput
                                    source='RegistrationView'
                                    label='Registration View'
                                    choices={RegistrationViewChoices}
                                    optionText='name'
                                    className='fieldsWidthExpand'
                                    validate={required('The Registration View field is required')}
                                />
                                <RichTextInput source='Description' />

                                <BooleanInput source='IsActive' />
                            </Grid>
                        </SanitizedGrid>
                    </SimpleForm>
                </CardContent>
            </Card>
        );
    }
}

export default SemesterForm;
