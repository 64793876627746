import {required} from "react-admin";

const passwordFieldValidation = (message = 'The two password fields should match') =>
    (value, allValues) => value === allValues.RepeatPassword ? undefined : message;

const repeatPasswordFieldValidation = (message = 'The two password fields should match') =>
    (value, allValues) => (!allValues.Password || (value === allValues.Password)) ? undefined : message;

const newPasswordFieldValidation = (message = 'The New Password and Repeat New Password fields should match') =>
    (value, allValues) => value === allValues.PasswordConfirmation ? undefined : message;

const repeatNewPasswordFieldValidation = (message = 'The New Password and Repeat New Password fields should match') =>
    (value, allValues) => (!allValues.NewPassword || (value === allValues.NewPassword)) ? undefined : message;

export const passwordValidation = [required('The Password field is required'), passwordFieldValidation()];
export const repeatPasswordValidation = [repeatPasswordFieldValidation(), required('The Repeat Password field is required')];

export const newPasswordValidation = [required('The New Password field is required'), newPasswordFieldValidation()];
export const passwordConfirmationValidation = [repeatNewPasswordFieldValidation(), required('The Repeat New Password field is required')];
