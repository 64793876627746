import React, {Component} from 'react';
import {GET_LIST} from 'react-admin';
import {fetchIt} from "../../../helpers";
import TeacherAssignmentsGrid from "./TeacherAssignmentsGrid";
import moment from "moment";


class TeachersAssignmentsList extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            data: {},
            ids: [],
            currentSort: {field: "id", order: "DESC"},
            schoolYears: [],
            semesters: []
        };
    }

    fetchAssignments = filters => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        let sort = "";

        if (!sort) {
            sort = {field: "id", order: "DESC"};
        }

        if(!filters){
            filters = {}
        }

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_LIST,
            'teachers_assignments',
            {id: this.props.record.id, filter: filters}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let data = {};
                    let ids = [];
                    for (let t = 0; t < response.data.length; t++) {
                        data[response.data[t].Id + ''] = response.data[t];
                        ids.push(response.data[t].Id);
                    }

                    if (me._isMounted) {
                        me.setState({
                            data,
                            ids,
                            currentSort: sort
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };

    fetchSemesters = (SchoolYear) => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_LIST,
            'semesters',
            {filter: SchoolYear}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let semesters = [];

                    for (let t = 0; t < response.data.length; t++) {
                        semesters.push({id: response.data[t].Id, name: response.data[t].Name});
                    }

                    if (me._isMounted) {
                        me.setState({
                            semesters
                        });
                    }

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }

    fetchSchoolYears = () => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_LIST,
            'school_years',
        ).then(response => {
            switch (response.status) {
                case 200:

                    let schoolYears = [];

                    for (let t = 0; t < response.data.length; t++) {
                        schoolYears.push({id: response.data[t].Name, name: response.data[t].Name, currentYear: response.data[t].CurrentYear});
                    }

                    if (me._isMounted) {
                        me.setState({
                            schoolYears
                        });
                    }

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }

    componentDidMount() {
        this._isMounted = true;

        const currentSchoolYear = moment().format('YYYY');


        this.fetchSchoolYears();
        this.fetchAssignments({"SchoolYear":currentSchoolYear});
        this.fetchSemesters({"SchoolYear":currentSchoolYear});
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <TeacherAssignmentsGrid
                data={this.state.data}
                ids={this.state.ids}
                record={{Id: this.props.record[this.props.source]}}
                currentSort={this.state.currentSort}
                basePath={this.props.basePath}
                fetchTheData={this.props.fetchTheData}
                schoolYears={this.state.schoolYears}
                semesters={this.state.semesters}
                fetchAssignments={this.fetchAssignments}
                fetchSemesters={this.fetchSemesters}
            />
        );
    }
}

export default TeachersAssignmentsList;
