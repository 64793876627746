import React, {Fragment} from 'react';
import CardContent from "@material-ui/core/CardContent";
import {Divider, Grid, Typography} from "@material-ui/core";
import Dates from './Dates';
import Badge from '@material-ui/core/Badge';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CancelScheduleStudentButton from '../../containers/CancelScheduleStudentButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';


const ScheduleStudents = props => {
    const {scheduleStudent, classes} = props;

    const StyledBadge = withStyles(() => ({
        badge: {
          right: 21,
          top: 11,
        },
    }))(Badge);

    
    return (
        <CardContent className='showSections'>
            <Typography variant="body1" style={{fontWeight: 700}} component="div" className={classes.gutterBottom}>
                Student Name: {scheduleStudent.StudentName}
            </Typography>

            <Grid container>
                <Grid item xs={3}>
                    <Typography variant="body2" className={classes.lightGrey} component="div">
                        Course Name
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" className={classes.lightGrey} component="div">
                        Semester
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2" className={classes.lightGrey} component="div">
                        Date & Time
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="body2" className={classes.lightGrey} component="div">
                        Num.of Classes
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="body2" style={{textAlign: 'end'}} className={classes.lightGrey} component="div">
                        Price
                    </Typography>
                </Grid>
                <Grid item xs={2} >
                    <Typography variant="body2" style={{textAlign: 'end', paddingRight: 5}} className={classes.lightGrey} component="div">
                        Action
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.gutterBottom} />
                </Grid>

                {
                    scheduleStudent.Schedules.map((schedule, idx) => (
                        <Fragment key={idx}>
                            <Grid item xs={3}>
                            {
                                schedule.IsPartial ?
                                <Tooltip title="Partial Registration" placement="right-start">
                                    <StyledBadge 
                                        badgeContent={'P'} 
                                        color="secondary" 
                                    >
                                        <Typography variant="body1" style={{paddingRight: 33}} component="div" className={classes.gutterBottom}>
                                            {schedule.Course}
                                        </Typography>
                                    </StyledBadge>
                                </Tooltip>
                                
                            :

                                <Typography variant="body1" component="div" className={classes.gutterBottom}>
                                    {schedule.Course}
                                </Typography>
                            }
                            
                                
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" component="div" className={classes.gutterBottom}>
                                    {schedule.Semester}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body1" component="div" className={classes.gutterBottom}>
                                    {schedule.DayTimes.join(', ')}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                {
                                    schedule.Dates ?
                                        <Typography variant="body1" component="div" className={classes.gutterBottom}>
                                            {schedule.Dates.length}
                                        </Typography>
                                    : 
                                        <Typography variant="body1" component="div" className={classes.gutterBottom}>
                                            All
                                        </Typography>
                                }
                            </Grid>

                            <Grid item xs={1}>
                                <Typography variant="body1" style={{textAlign: 'end'}} component="div" className={classes.gutterBottom}>
                                    ${schedule.Price.toFixed(2)}
                                </Typography>
                            </Grid>
                            {
                                !schedule.Canceled ?
                                    <Grid item xs={2}  style={{textAlign: 'end'}}>
                                        <CancelScheduleStudentButton fetchTheData={props.fetchTheData} scheduleStudent={schedule} />
                                    </Grid>
                            :
                                <Grid item xs={2}  style={{textAlign: 'center', paddingLeft: 20}}>
                                    <Typography variant="body1" style={{textAlign: 'end'}} component="div">
                                        Canceled
                                    </Typography>
                                   
                                </Grid>
                            }
                            
                            {
                                schedule.Dates ?
                                    <Grid item xs={12} style={{paddingBottom: 20}}>
                                        <Dates record={schedule.Dates}/>
                                    </Grid>
                                : null
                            }                           
                        </Fragment>
                    ))
                }

                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        </CardContent>
    );
}

export default ScheduleStudents;
