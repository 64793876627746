import React, {Fragment} from "react";
import ChildCareOutlinedIcon from "@material-ui/icons/ChildCareOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import HowToRegIcon from '@material-ui/icons/HowToReg';
import {withStyles, createStyles} from "@material-ui/core/styles";
import classnames from 'classnames';


const styles = createStyles(theme => ({
    optionContainer: {
        width: 707,
        maxWidth: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        borderBottom: '1px solid grey',
    },
    optionCommon: {
        margin: 0,
        boxSizing: 'border-box',
    },
    optionIcon: {
        maxWidth: 29,
        paddingRight: 5,
        flexBasis: 29,
    },
    optionItem: {
        width: 'calc(100% - 29px)',
        maxWidth: 'calc(100% - 29px)',
    },
    optionIconThree: {
        maxWidth: 40,
        paddingRight: 5,
        flexBasis: 40,
    },
    optionItemThree: {
        width: 'calc(100% - 74px)',
        maxWidth: 'calc(100% - 74px)',
        whiteSpace: "normal !important",
        wordWrap: "normal !important"
    },
}));


export const getPersonIcon = role => (
    role === 'Organizer' ?
        <HowToRegIcon /> :
        role === 'Student' ?
            <ChildCareOutlinedIcon /> :
            <AccountCircleOutlinedIcon />
);


const StudentOptionRenderer = props => {
    const classes = props.classes;

    return (
        <div className={classes.optionContainer}>
            <div className={classnames(classes.optionCommon, classes.optionIcon)}>
                {getPersonIcon(props.record ? props.record.Role : 'Student')}
            </div>
            <div className={classnames(classes.optionCommon, classes.optionItem)}>
                {props.record.Info}
            </div>
            {
                props.record.Parents.map(parent => {
                    return (
                        <Fragment key={parent.Id}>
                            <div className={classnames(classes.optionCommon, classes.optionIcon)}>
                                {getPersonIcon(parent.Role)}
                            </div>
                            <div className={classnames(classes.optionCommon, classes.optionItem)}>
                                {parent.Info}
                            </div>
                        </Fragment>
                    )
                })
            }
        </div>
    );
}

export default withStyles(styles)(StudentOptionRenderer);
