import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import classnames from 'classnames';
import Portal from '@material-ui/core/Portal';

import {
    hideNotification,
    getNotification,
    translate,
    undo,
    complete,
    undoableEventEmitter
} from 'react-admin';

const styles = theme => createStyles({
    confirm: {
        backgroundColor: theme.palette.background.default,
        border: 'none'
    },
    warning: {
        backgroundColor: theme.palette.error.light,
        border: 'none'
    },
    undo: {
        color: theme.palette.primary.light,
        border: 'none'
    },
});

class Notification extends React.Component {
    state = {
        open: false,
    };
    componentWillMount = () => {
        this.setOpenState(this.props);
    };
    componentWillReceiveProps = nextProps => {
        this.setOpenState(nextProps);
    };

    setOpenState = ({ notification }) => {
        this.setState({
            open: !!notification,
        });
    };

    handleRequestClose = () => {
        this.setState({
            open: false,
        });
    };

    handleExited = () => {
        const { notification, hideNotification, complete } = this.props;
        if (notification && notification.undoable) {
            complete();
            undoableEventEmitter.emit('end', { isUndo: false });
        }
        hideNotification();
    };

    handleUndo = () => {
        const { undo } = this.props;
        undo();
        undoableEventEmitter.emit('end', { isUndo: true });
    };

    render() {
        const {
            undo,
            complete,
            classes,
            className,
            type,
            translate,
            notification,
            autoHideDuration,
            hideNotification,
            ...rest
        } = this.props;
        const {
            warning,
            confirm,
            undo: undoClass, // Rename classes.undo to undoClass in this scope to avoid name conflicts
            ...snackbarClasses
        } = classes;
        return (
            <Portal>
                <Snackbar
                    open={this.state.open}
                    message={
                        notification &&
                        notification.message &&
                        translate(notification.message, notification.messageArgs)
                    }
                    autoHideDuration={
                        (notification && notification.autoHideDuration) ||
                        autoHideDuration
                    }
                    disableWindowBlurListener={
                        notification && notification.undoable
                    }
                    onClose={this.handleRequestClose}
                    TransitionProps={{
                        onExited: this.handleExited
                    }}
                    ContentProps={{
                        className: classnames(
                            classes[(notification && notification.type) || type],
                            className
                        ),
                    }}
                    action={
                        notification && notification.undoable ? (
                            <Button
                                color="primary"
                                className={undoClass}
                                size="small"
                                onClick={this.handleUndo}
                            >
                                {translate('ra.action.undo')}
                            </Button>
                        ) : null
                    }
                    classes={snackbarClasses}
                    {...rest}
                />
            </Portal>
        );
    }
}

Notification.propTypes = {
    complete: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
    notification: PropTypes.shape({
        message: PropTypes.string,
        type: PropTypes.string,
        autoHideDuration: PropTypes.number,
        messageArgs: PropTypes.object,
    }),
    type: PropTypes.string,
    hideNotification: PropTypes.func.isRequired,
    autoHideDuration: PropTypes.number,
    translate: PropTypes.func.isRequired,
    undo: PropTypes.func,
};

Notification.defaultProps = {
    type: 'info',
    autoHideDuration: 4000,
};

const mapStateToProps = state => ({
    notification: getNotification(state),
});

export default compose(
    translate,
    withStyles(styles),
    connect(
        mapStateToProps,
        {
            complete,
            hideNotification,
            undo,
        }
    )
)(Notification);
