import React from 'react';
import {useForm} from 'react-final-form';
import {Grid, Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import NameField from './NameField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';


export const Review = (props) => {

    const form = useForm();
    const formState = form.getState();
    const montlyEvents = formState.values.MonthlyEvents;

    return (
            <>
                {
                    montlyEvents && montlyEvents.length ? 
                    <>
                        <Accordion style={{borderRadius: 10, marginBottom: 15}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='body1'> {formState.values.Name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{paddingLeft: 0, paddingRight: 0}}>
                                    <Grid item xs={6}>
                                        <NameField 
                                            record={formState.values.Semester} 
                                            endpointToFetch='semesters'
                                            activeTab={props.activeTab}
                                            label='Semester'
                                        />
                                        <NameField 
                                            record={formState.values.Course} 
                                            endpointToFetch='courses'
                                            activeTab={props.activeTab}
                                            label='Course'
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
                                            <span style={{fontWeight: 'bold'}}>Places:</span> {formState.values.Places}
                                        </Typography>  
                                        <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
                                            <span style={{fontWeight: 'bold'}}>Price:</span> ${formState.values.Price}
                                        </Typography>                                      
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <TableContainer style={{marginBottom: 15}} component={Paper}>                           
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Course Date</TableCell>
                                        <TableCell>Start Time</TableCell>
                                        <TableCell>End Time</TableCell>
                                        <TableCell>Studio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {montlyEvents.map((row, index) => (                
                                        <TableRow key={index}>
                                            <TableCell>{row.StartDate}</TableCell>
                                            <TableCell>{row.StartTime}</TableCell>
                                            <TableCell>{row.EndTime}</TableCell>
                                            <TableCell>
                                                <NameField 
                                                    record={row.Studio} 
                                                    endpointToFetch='studios'
                                                    activeTab={props.activeTab}
                                                />
                                            </TableCell>
                                        </TableRow>                                     
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                :
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
                                        <span style={{fontWeight: 'bold'}}>Name: </span> {formState.values.Name}
                                    </Typography>
                                    <NameField 
                                        record={formState.values.Course} 
                                        endpointToFetch='courses'
                                        activeTab={props.activeTab}
                                        label='Course'
                                    />
                                    {
                                        formState.values.Semester ?
                                            <NameField 
                                                record={formState.values.Semester} 
                                                endpointToFetch='semesters'
                                                activeTab={props.activeTab}
                                                label='Semester'
                                            />
                                        : null
                                    }
                                    <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
                                        <span style={{fontWeight: 'bold'}}>Places:</span> {formState.values.Places}
                                    </Typography>                                      
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
                                        <span style={{fontWeight: 'bold'}}>Date: </span> 
                                        {moment(formState.values.StartDateTime).format('MM/DD/YYYY')}
                                    </Typography>
                                    <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
                                        <span style={{fontWeight: 'bold'}}>Starts: </span> 
                                        {formState.values.startHour}
                                    </Typography>
                                    <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
                                        <span style={{fontWeight: 'bold'}}>Ends: </span> 
                                        {formState.values.endHour}
                                    </Typography>
                                    <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
                                        <span style={{fontWeight: 'bold'}}>Price:</span> ${formState.values.Price}
                                    </Typography>                         
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                }   
            </>           
    );
}
 
