import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import {connect} from "react-redux";
import SemesterEdit from '../components/SemesterEdit';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    SemesterEdit
);
