import {connect} from 'react-redux';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';
import ShowClassesDialog from '../components/dialogs/ShowClassesDialog';

const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};

export default connect(null, mapDispatchToProps)(
    ShowClassesDialog
);
