import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField
} from 'react-admin';
import {TeachersListFilters} from '../';
import {formatPhone, exporter} from "../../../helpers";
import TeacherSplitButton from '../containers/TeacherSplitButton';


export const TeachersList = props => (
    <div className="wrapperContent">
        <List 
            {...props} 
            exporter={exporter} 
            filters={<TeachersListFilters />} 
            title='Teachers List' 
            sort={{field: 'id', order: 'DESC'}} 
            bulkActionButtons={false}
            perPage={25}
        >
            <Datagrid>
                <TextField source='Name' />
                <FunctionField label="Cell Phone" render={record => {return `${record.CellPhone}` === "null" ? '' : formatPhone(`${record.CellPhone}`)}} />
                <TextField source='Email' />
                <FunctionField label='Status' source='Active' render={record => record.Active ? 'Active' : 'Inactive'} />
                <TeacherSplitButton cellClassName='splitButtonTd' />
            </Datagrid>
        </List>
    </div>
);
