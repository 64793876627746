import React, {Component, Fragment} from 'react'
import {NeteaScheduler} from 'netea-scheduler'
import {arraySortByKey, fetchIt, getErrorMessage, getWeekdays, inArray} from "../../../helpers";
import {
    GET_LIST,
    UPDATE,
    DELETE,
    CREATE,
    ExportButton,
    ListContextProvider,
    GET_ONE,
    Button as RaButton,
    Title, FormWithRedirect, AutocompleteArrayInput
} from 'react-admin';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import PublishIcon from '@material-ui/icons/Publish';
import AddNewIcon from '@material-ui/icons/Add';
import TeachersIcon from '@material-ui/icons/People';
import DurationIcon from '@material-ui/icons/Alarm';
import DeleteWholeIcon from '@material-ui/icons/DeleteSweep';
import DeleteIcon from '@material-ui/icons/Delete';
import {animation, contextMenu, Item, Menu, theme} from "react-contexify";
import ScheduleForm from '../containers/ScheduleForm';
import {InputLabel, Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from '@material-ui/core/styles';
import * as Moment from 'moment';
import {extendMoment} from 'moment-range';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import {SchedulesListFilters} from './SchedulesListFilters';
import {withResizeDetector} from 'react-resize-detector';
import CopyScheduleDialog from '../containers/CopyScheduleDialog';
import {Grid} from "@material-ui/core";
import OverlappedSchedules from './OverlappedSchedules';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import exporter from '../helpers/exporter';
import OutsideWorkingTimeSchedules from './OutsideWorkingTimeSchedules';
import EditScheduleIcon from '@material-ui/icons/EventNote';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ActiveIcon from '@material-ui/icons/Check';
import SuspendIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconCancel from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import ShowIssuesDialog from './ShowIssuesDioalog';
import ScheduleActionButtons from './ScheduleActionButtons';
import arrayMutators from "final-form-arrays";
import EditScheduleDialog from "../containers/EditScheduleDialog";


const moment = extendMoment(Moment);


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        paddingLeft: 24
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    publishButton: {
        marginLeft: theme.spacing(1),
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});


class SchedulesList extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        const weekdays = getWeekdays();
        const schedulerMinTime = '05:00:00';
        const schedulerMaxTime = '22:00:00';


        this.state = {
            weekdays,
            studios: [],
            schedules: [],
            overlappedSchedules: [],
            filters: {},
            outsideWorkingTimeSchedules: [],
            isLoading: true,
            showDialog: false,
            isDisabled: false,
            tooltip: false,
            event: {},
            tooltipPosition: {
                top: -500,
                left: -1000
            },
            tooltipWidth: 0,
            tooltipHeight: 0,
            slotWidth: 20,
            teachers: [],
            formIndex: 0,
            schoolYears: [],
            isSdwOpened: false,
            isDurationOpened: false,
            isPlacesOpened: false,
            isPriceOpened: false,
            isTeachersOpened: false,
            isChangeStatusOpened: false,
            duration: 0,
            places: 0,
            price: 0,
            isActive: false,
            scheduleTeachers: [],
            selectedSchedule: 0,
            selectedScheduleDataset: {},
            selectedParentSchedule: 0,
            openDeleteSdw: false,
            openDeleteWholeSchedule: false,
            schedulerMinTime,
            schedulerMaxTime,
            currentSchoolYear: moment().format('YYYY'),
            currentSemester: '0',
            schoolYear: moment().format('YYYY'),
            semesters: [],
            refresh: [],
            refreshRows: [],
            refreshSchedules: [],
            withOtherSemesters: true,
            registrationCount: 0,
            registrationIssues: [],
            isRegisrationIssueDialogOpen: false
        };
    }

    onChangeWithOtherSemesters = event => {
        event.persist();

        if (this._isMounted) {
            this.setState({withOtherSemesters: event.target.checked});
            this.setState({isLoading: true});
            setTimeout( () => {
                this.setState({isLoading: false});
            }, 100);
        }
    }


    fetchSchoolYears = () => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        const sort = {field: "Name", order: "DESC"};

        fetchIt(
            GET_LIST,
            'school_years',
            {sort: JSON.stringify(sort)}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let schoolYears = [];
                    let currentSchoolYear = moment.utc().format('YYYY');

                    for (let t = 0; t < response.data.length; t++) {
                        schoolYears.push({id: response.data[t].Name, name: response.data[t].Name});
                        if (response.data[t].CurrentYear) {
                            currentSchoolYear = response.data[t].Name;
                        }
                    }

                    schoolYears = arraySortByKey(schoolYears, 'id', false);

                    if (me._isMounted) {
                        me.setState({
                            schoolYears,
                            schoolYear: currentSchoolYear,
                            filters: {...this.state.filters, SchoolYear: currentSchoolYear}
                        });
                    }

                    this.fetchSemesters({"SchoolYear": currentSchoolYear, "RegistrationView": 1});

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };

    setSchoolYear = schoolYear => {
        if (this._isMounted) {
            this.setState({schoolYear: schoolYear});
        }
    }


    fetchSemesters = (filters, forceCurrentSemester = false) => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_LIST,
            'semesters',
            {filter: filters}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let semesters = [];
                    let currentSemester = '';

                    for (let t = 0; t < response.data.length; t++) {
                        if (response.data[t].IsCurrent) {
                            currentSemester = response.data[t].Id;
                        }
                        if (response.data[t].IsActive) {
                            semesters.push({id: response.data[t].Id, name: response.data[t].Name});
                        }
                    }

                    if (forceCurrentSemester) {
                        currentSemester = forceCurrentSemester;
                    } else {
                        currentSemester = semesters[0].id;
                    }

                    if (me._isMounted) {
                        me.setState({
                            currentSemester,
                            semesters
                        });
                    }

                    if(parseInt(currentSemester)){
                        filters.Semester = parseInt(currentSemester);
                    }

                    this.fetchTheData(filters);

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    fetchTeachers = () => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        const sort = {field: "Name", order: "ASC"};

        fetchIt(
            GET_LIST,
            'teachers',
            {sort: JSON.stringify(sort)}
        ).then(response => {
            switch (response.status) {
                case 200:
                    if (me._isMounted) {
                        me.setState({
                            teachers: response.data
                        });
                    }

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    componentDidMount() {
        this._isMounted = true;

        this.fetchTeachers();
        this.fetchSchoolYears();
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    fetchTheData = filter => {
        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        if(!filter){
            filter = this.state.filters;
        } else {
            let stateFilters = Object.assign({}, this.state.filters);
            filter = Object.assign(stateFilters, filter);
        }

        if (me._isMounted) {
            me.setState({
                isLoading: true,
                filters: filter
            });
        }

        fetchIt(
            GET_ONE,
            'common_settings',
            {}
        ).then(response => {
            switch (response.status) {
                case 200:
                    fetchStart();

                    const earliestHour = response.data.EarliestHour;
                    const latestHour = response.data.LatestHour;

                    let studios = [];

                    const locations = response.data.Locations;

                    for (let l = 0; l < locations.length; l++) {
                        const location = locations[l];
                        for (let s = 0; s < location.Studios.length; s++) {
                            const studio = location.Studios[s];
                            const studioColor = studio.DisplayColor;
                            if (studioColor === '#FAFAFA') {
                                studio.DisplayColor = '#666666';
                            }

                            studios.push(
                                {
                                    id: studio.Id,
                                    title: studio.Name,
                                    scheduleColor: studio.DisplayColor,
                                    color: studioColor,
                                    location: location.Name,
                                    workDays: location.WorkDays
                                }
                            )
                        }
                    }


                    let schedules = {};
                    for (let weekday of this.state.weekdays) {
                        schedules[weekday] = {};

                        for (let studio of studios) {
                            schedules[weekday][studio.id] = [];
                        }
                    }


                    fetchIt(
                        GET_LIST,
                        'schedules',
                        {filter}
                    ).then(response => {
                        switch (response.status) {
                            case 200:
                                let myData = response.data;
                                const total = response.total;
                                let schedulerMinTime = moment.utc(this.state.schedulerMinTime, "HH:mm:ss");
                                let schedulerMaxTime = moment.utc(this.state.schedulerMaxTime, "HH:mm:ss");
                                let overlappedSchedules = [];
                                let outsideWorkingTimeSchedules = [];

                                for (let t = 0; t < myData.length; t++) {

                                    if (
                                        myData[t] &&
                                        myData[t].Id
                                    ) {
                                        if (myData[t].IsOverlapped && !myData[t].BelongsToAnotherSemester) {
                                            overlappedSchedules.push(myData[t]);
                                        }

                                        if (typeof schedules[myData[t].DayOfWeek] === 'undefined') {
                                            schedules[myData[t].DayOfWeek] = {};
                                        }

                                        if (typeof schedules[myData[t].DayOfWeek][myData[t].Studio] === 'undefined') {
                                            schedules[myData[t].DayOfWeek][myData[t].Studio] = [];
                                        }

                                        const startHour = moment.utc(myData[t].StartHour, "H");
                                        const start = moment.utc(myData[t].StartHour, "H").add(myData[t].StartMinutes, 'minutes');
                                        const end = moment.utc(start.format('H:mm'), 'H:mm').add(myData[t].Duration, 'minutes');
                                        const maxEndHour = moment.utc('23:59', 'H:mm');

                                        if (myData[t].IsOutsideWorkingHours && !myData[t].BelongsToAnotherSemester) {
                                            outsideWorkingTimeSchedules.push(myData[t]);
                                        }

                                        myData[t].top = 0;
                                        myData[t].range = moment.range(start, end);
                                        if (!myData[t].IsOutsideWorkingHours){
                                            schedules[myData[t].DayOfWeek][myData[t].Studio].push(myData[t]);

                                            if (schedulerMinTime.isAfter(startHour)) {
                                                schedulerMinTime = moment.utc(startHour.format('H'), 'H');
                                            }

                                            if (end.isAfter(maxEndHour)) {
                                                schedulerMaxTime = moment.utc(maxEndHour.format('H:mm'), 'H:mm');
                                            } else if (schedulerMaxTime.isBefore(end)) {
                                                schedulerMaxTime = moment.utc(end.format('H:mm'), 'H:mm');
                                            }
                                        }
                                    }
                                }

                                if (me._isMounted) {
                                    me.setState({
                                        studios,
                                        schedules,
                                        overlappedSchedules,
                                        outsideWorkingTimeSchedules,
                                        schedulerMinTime: earliestHour,
                                        schedulerMaxTime: latestHour,
                                        isLoading: false,
                                        total
                                    });
                                }

                                break;

                            default:
                                showNotification(
                                    'direct_message',
                                    'warning',
                                    { messageArgs: { _: 'Oops, something went wrong!' } }
                                );
                        }
                    }).catch(error => {

                    }).then(() => {
                        // Dispatch an action letting react-admin know an API call has ended
                        fetchEnd();
                    });

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    fetchBySchedules = newSchedules => {
        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        const filter = Object.assign({}, this.state.filters, {Schedules: newSchedules});
        let schedules = Object.assign({}, this.state.schedules);

        return fetchIt(
            GET_LIST,
            'schedules',
            {filter}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let myData = response.data;
                    const total = response.total;
                    let schedulerMinTime = moment.utc(this.state.schedulerMinTime, "HH:mm:ss");
                    let schedulerMaxTime = moment.utc(this.state.schedulerMaxTime, "HH:mm:ss");
                    let overlappedSchedules = [];
                    let outsideWorkingTimeSchedules = [];

                    for (let t = 0; t < myData.length; t++) {
                        if (
                            myData[t] &&
                            myData[t].Id
                        ) {
                            if (myData[t].IsOverlapped && !myData[t].BelongsToAnotherSemester) {
                                overlappedSchedules.push(myData[t]);
                            }

                            if (typeof schedules[myData[t].DayOfWeek] === 'undefined') {
                                schedules[myData[t].DayOfWeek] = {};
                            }

                            if (typeof schedules[myData[t].DayOfWeek][myData[t].Studio] === 'undefined') {
                                schedules[myData[t].DayOfWeek][myData[t].Studio] = [];
                            }

                            const startHour = moment.utc(myData[t].StartHour, "H");
                            const start = moment.utc(myData[t].StartHour, "H").add(myData[t].StartMinutes, 'minutes');
                            const end = moment.utc(start.format('H:mm'), 'H:mm').add(myData[t].Duration, 'minutes');
                            const maxEndHour = moment.utc('23:59', 'H:mm');

                            if (myData[t].IsOutsideWorkingHours && !myData[t].BelongsToAnotherSemester) {
                                outsideWorkingTimeSchedules.push(myData[t]);
                            }

                            myData[t].top = 0;
                            myData[t].range = moment.range(start, end);
                            if (!myData[t].IsOutsideWorkingHours){
                                schedules[myData[t].DayOfWeek][myData[t].Studio].push(myData[t]);

                                if (schedulerMinTime.isAfter(startHour)) {
                                    schedulerMinTime = moment.utc(startHour.format('H'), 'H');
                                }

                                if (end.isAfter(maxEndHour)) {
                                    schedulerMaxTime = moment.utc(maxEndHour.format('H:mm'), 'H:mm');
                                } else if (schedulerMaxTime.isBefore(end)) {
                                    schedulerMaxTime = moment.utc(end.format('H:mm'), 'H:mm');
                                }
                            }
                        }
                    }

                    if (me._isMounted) {
                        me.setState({
                            schedules,
                            overlappedSchedules,
                            outsideWorkingTimeSchedules,
                            total
                        });
                    }

                    for (let t = 0; t < myData.length; t++) {
                        me.refreshScheduler(
                            [myData[t].DayOfWeek],
                            [myData[t].DayOfWeek + '_' + myData[t].Studio],
                            myData[t].Id
                        );
                    }

                    return myData;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(result => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();

            return result;
        });
    };


    clickOnTimeSlot = (event, time, isTimeSlot, dataSet) => {
        event.persist();

        if (event.target.dataset.isOutside === 'true') {
            return false;
        } else if (isTimeSlot) {
            if (this._isMounted) {
                this.setState(
                    {
                        scheduleTeachers: [],
                        duration: 0,
                        places: 0,
                        price: 0,
                        selectedSchedule: 0,
                        selectedParentSchedule: 0,
                        isActive: false
                    }
                );
            }

            contextMenu.show({
                id: 'ClickOnEmptyTimeSlotContextMenu',
                event: event,
                props: {start: time, dataSet}
            });
        } else {
            let schedule = event.target;
            if (schedule.dataset.getParent && !schedule.dataset.schedule) {
                while (schedule.dataset.getParent && !schedule.dataset.schedule) {
                    schedule = schedule.parentElement;
                }
            }

            const scheduleTeachersJson = JSON.parse(dataSet.Teachers);
            let scheduleTeachers = [];
            for (const teacher of scheduleTeachersJson) {
                scheduleTeachers.push(teacher.Id);
            }

            const duration = parseInt(dataSet.Duration);
            const places = parseInt(dataSet.Places);
            const price = dataSet.Price;
            const isActive = dataSet.IsActive;
            const registrationCount = parseInt(dataSet.Registrations);
            if (this._isMounted) {
                this.setState(
                    {
                        scheduleTeachers,
                        duration,
                        places,
                        price,
                        selectedSchedule: parseInt(schedule.dataset.schedule),
                        selectedScheduleDataset: Object.assign({}, schedule.dataset, dataSet),
                        selectedParentSchedule: parseInt(schedule.dataset.parentSchedule),
                        isActive,
                        registrationCount
                    }
                );
            }

            contextMenu.show({
                id: 'ClickOnScheduleContextMenu',
                event,
                props: {start: time, dataSet, schedule}
            });
        }

        return false;
    };


    onCloseClick = (event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            if (this._isMounted) {
                this.setState({showDialog: false});
            }
        }
    };


    onNewScheduleClick = event => {
        const start = event.props.start;
        const end = moment(event.props.start.format('HH:mm'), 'HH:mm').add(45, 'minutes');

        if (this._isMounted) {
            this.setState({event: {start, end, dataSet: event.props.dataSet}, showDialog: true});
        }
    };


    moveSchedule = data => {
        return fetchIt(
            UPDATE,
            'schedule_move',
            data
        );
    };


    excludeFromOverlapped = item => {
        let overlappedSchedules = this.state.overlappedSchedules;
        const scheduleIndex = overlappedSchedules.findIndex(schedule => schedule && (schedule.Id === item.Id));
        if (scheduleIndex >= 0) {
            overlappedSchedules.splice(scheduleIndex, 1);

            if (this._isMounted) {
                this.setState({
                    overlappedSchedules
                });
            }
        }
    }


    refreshScheduler = (refresh, refreshRows, selectedSchedule) => {
        if (!refreshRows) {
            refreshRows = [];
        }

        let refreshSchedules = this.state.refreshSchedules;
        if (selectedSchedule) {
            if (inArray(selectedSchedule, refreshSchedules)) {
                refreshSchedules[0] = !refreshSchedules[0];
            } else {
                refreshSchedules = [true, selectedSchedule];
            }
        }

        this.setState({refresh, refreshRows, refreshSchedules});
    };


    // onDurationSubmit = event => {
    //     event.preventDefault();
    //
    //     const me = this;
    //     const {fetchStart, fetchEnd, showNotification} = this.props;
    //
    //     if (me.state.duration < 5 || me.state.duration > 720) {
    //         let errorMessage = 'Duration must be between 5 and 720 minutes';
    //
    //         showNotification(
    //             'direct_message',
    //             'warning',
    //             { messageArgs: { _: errorMessage } }
    //         );
    //
    //         return false;
    //     }
    //
    //     fetchStart();
    //
    //     fetchIt(
    //         UPDATE,
    //         'schedule_duration',
    //         {id: me.state.selectedSchedule, Duration: me.state.duration}
    //     ).then(response => {
    //         switch (response.status) {
    //             case 200:
    //                 let schedules = me.state.schedules;
    //                 const scheduleIndex = schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].findIndex(schedule => schedule && (schedule.Id === me.state.selectedSchedule));
    //                 if (scheduleIndex > -1) {
    //                     schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource][scheduleIndex].Duration = me.state.duration;
    //                 }
    //
    //                 if (me._isMounted) {
    //                     me.setState({schedules, isDurationOpened: false, durationAnchorEl: undefined});
    //                 }
    //
    //                 let message = 'Schedule duration changed!';
    //                 if (response.data && response.data.Message) {
    //                     message = response.data.Message;
    //                 }
    //
    //                 showNotification(
    //                     'direct_message',
    //                     'info',
    //                     {messageArgs: {_: message}}
    //                 );
    //
    //                 this.refreshScheduler(
    //                     [me.state.selectedScheduleDataset.weekday],
    //                     [me.state.selectedScheduleDataset.weekday + '_' + [me.state.selectedScheduleDataset.resource]],
    //                     me.state.selectedSchedule
    //                 );
    //
    //                 break;
    //
    //             case 400:
    //             case 404:
    //                 let errorMessage = 'Oops, something went wrong!';
    //                 if (typeof response.data.HasValidationErrors !== 'undefined') {
    //                     errorMessage = getErrorMessage(response.data);
    //                 }
    //
    //                 showNotification(
    //                     'direct_message',
    //                     'warning',
    //                     {messageArgs: {_: errorMessage}}
    //                 );
    //
    //                 break;
    //
    //             default:
    //                 showNotification(
    //                     'direct_message',
    //                     'warning',
    //                     {messageArgs: {_: 'Oops, something went wrong!'}}
    //                 );
    //         }
    //     }).catch(error => {
    //
    //     }).then(() => {
    //         // Dispatch an action letting react-admin know an API call has ended
    //         fetchEnd();
    //     });
    //
    //     return false;
    // };
    //
    //
    // onDurationClose = (event, reason) => {
    //     if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
    //         return false;
    //     }
    // };
    //
    //
    // onDurationCancelClick = event => {
    //     if (this._isMounted) {
    //         this.setState({isDurationOpened: false, durationAnchorEl: undefined});
    //     }
    // };
    //
    //
    // onDurationChange = event => {
    //     if (this._isMounted) {
    //         this.setState({duration: event.target.value});
    //     }
    // };
    //
    //
    // onDurationClick = event => {
    //     if (this._isMounted) {
    //         this.setState({isDurationOpened: true, durationAnchorEl: event.props.schedule});
    //     }
    // };


    onPlacesSubmit = event => {
        event.preventDefault();

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            UPDATE,
            'schedule_places',
            {id: me.state.selectedParentSchedule, Places: me.state.places}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let schedules = me.state.schedules;
                    const scheduleIndex = schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].findIndex(schedule => schedule && (schedule.Id === me.state.selectedSchedule));
                    if (scheduleIndex > -1) {
                        schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource][scheduleIndex].Places = me.state.places;
                    }

                    if (me._isMounted) {
                        me.setState({schedules, isPlacesOpened: false, placesAnchorEl: undefined});
                    }

                    let message = 'Schedule places changed!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    // this.refreshScheduler(
                    //     [me.state.selectedScheduleDataset.weekday],
                    //     [me.state.selectedScheduleDataset.weekday + '_' + [me.state.selectedScheduleDataset.resource]],
                    //     me.state.selectedSchedule
                    // );

                    this.fetchTheData();

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

        return false;
    };


    onPlacesClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    onPlacesCancelClick = event => {
        if (this._isMounted) {
            this.setState({isPlacesOpened: false, placesAnchorEl: undefined});
        }
    };


    onPlacesChange = event => {
        if (this._isMounted) {
            this.setState({places: event.target.value});
        }
    };


    onPlacesClick = event => {
        if (this._isMounted) {
            this.setState({isPlacesOpened: true, placesAnchorEl: event.props.schedule});
        }
    };


    onSdwClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    onSdwClick = event => {
        if (this._isMounted) {
            this.setState({isSdwOpened: true, sdwAnchorEl: event.props.schedule});
        }
    };


    onSdwCancelClick = () => {
        if (this._isMounted) {
            this.setState({isSdwOpened: false, sdwAnchorEl: undefined});
        }
    };



    onPriceSubmit = event => {
        event.preventDefault();

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            UPDATE,
            'schedule_price',
            {id: me.state.selectedParentSchedule, Price: me.state.price}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let schedules = me.state.schedules;
                    const scheduleIndex = schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].findIndex(schedule => schedule && (schedule.Id === me.state.selectedSchedule));
                    if (scheduleIndex > -1) {
                        schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource][scheduleIndex].Price = me.state.price;
                    }

                    if (me._isMounted) {
                        me.setState({schedules, isPriceOpened: false, priceAnchorEl: undefined});
                    }

                    let message = 'Schedule price changed!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    // this.refreshScheduler(
                    //     [me.state.selectedScheduleDataset.weekday],
                    //     [me.state.selectedScheduleDataset.weekday + '_' + [me.state.selectedScheduleDataset.resource]],
                    //     me.state.selectedSchedule
                    // );
                    this.fetchTheData();

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

        return false;
    };


    onPriceClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    onPriceCancelClick = event => {
        if (this._isMounted) {
            this.setState({isPriceOpened: false, priceAnchorEl: undefined});
        }
    };


    onPriceChange = event => {
        if (this._isMounted) {
            this.setState({price: event.target.value});
        }
    };


    onPriceClick = event => {
        if (this._isMounted) {
            this.setState({isPriceOpened: true, priceAnchorEl: event.props.schedule});
        }
    };


    onStatusSubmit = event => {
        event.preventDefault();

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            CREATE,
            'schedule_change_status',
            {id: me.state.selectedParentSchedule, isActive: me.state.isActive}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let schedules = me.state.schedules;
                    // const scheduleIndex = schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].findIndex(schedule => schedule && (schedule.Id === me.state.selectedSchedule));
                    // if (scheduleIndex > -1) {
                    //     schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource][scheduleIndex].isActive = me.state.isActive;
                    // }

                    if (me._isMounted) {
                        me.setState({schedules, isChangeStatusOpened: false, changeStatusAnchorEl: undefined});
                    }

                    let message = 'Schedule status changed!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    // this.refreshScheduler(
                    //     [me.state.selectedScheduleDataset.weekday],
                    //     [me.state.selectedScheduleDataset.weekday + '_' + [me.state.selectedScheduleDataset.resource]],
                    //     me.state.selectedSchedule
                    // );
                    this.fetchTheData();

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

        return false;
    };


    onChangeStatusClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    onChangeStatusCancel = event => {
        if (this._isMounted) {
            this.setState({isChangeStatusOpened: false, changeStatusAnchorEl: undefined});
        }
    };


    onStatusChange = event => {
        event.persist();

        if (this._isMounted) {
            this.setState({isActive: event.target.checked});
        }
    };


    onChangeStatusClick = event => {
        if (this._isMounted) {
            this.setState({isChangeStatusOpened: true, changeStatusAnchorEl: event.props.schedule});
        }
    };

    onTeachersSubmit = event => {
        event.preventDefault();

        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            UPDATE,
            'schedule_teachers',
            {
                id: me.state.selectedSchedule,
                Teachers: me.state.scheduleTeachers.map(teacher => ({Id: teacher, IsPrimary: true}))
            }
        ).then(response => {
            switch (response.status) {
                case 200:
                    const teachers = me.state.scheduleTeachers.map(
                        teacher => me.state.teachers.find(tchr => (teacher === tchr.Id))
                    );

                    let schedules = me.state.schedules;
                    const scheduleIndex = schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].findIndex(schedule => schedule && (schedule.Id === me.state.selectedSchedule));
                    if (scheduleIndex > -1) {
                        schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource][scheduleIndex].Teachers = teachers;
                    }

                    if (me._isMounted) {
                        me.setState({schedules, isTeachersOpened: false});
                    }

                    let message = 'Schedule Teachers changed!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    this.refreshScheduler(
                        [me.state.selectedScheduleDataset.weekday],
                        [me.state.selectedScheduleDataset.weekday + '_' + [me.state.selectedScheduleDataset.resource]],
                        me.state.selectedSchedule
                    );

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });

        return false;
    };


    onTeachersClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    onTeachersCancelClick = () => {
        if (this._isMounted) {
            this.setState({isTeachersOpened: false});
        }
    };


    onTeachersChange = value => {
        if (this._isMounted) {
            this.setState({scheduleTeachers: value});
        }
    };


    onTeachersClick = () => {
        if (this._isMounted) {
            this.setState({isTeachersOpened: true});
        }
    };


    onDeleteScheduleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    openDeleteSchedule = () => {
        if (this._isMounted) {
            this.setState({openDeleteWholeSchedule: true});
        }
    };

    onScheduleCancel = () => {
        if (this._isMounted) {
            this.setState({openDeleteWholeSchedule: false});
        }
    }

    onScheduleDelete = () => {
        const {fetchStart, fetchEnd, showNotification} = this.props;

        const me = this;

        fetchStart();

        fetchIt(
            DELETE,
            'schedules',
            {id: me.state.selectedParentSchedule}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Schedule deleted!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    me.fetchTheData();

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            this.onScheduleCancel();

            fetchEnd();
        });
    };


    onDeleteSdwClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return false;
        }
    };


    openDeleteSdw = () => {
        if (this._isMounted) {
            this.setState({openDeleteSdw: true});
        }
    };


    onSdwCancel = () => {
        if (this._isMounted) {
            this.setState({openDeleteSdw: false});
        }
    };


    onSdwDelete = () => {
        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        fetchStart();

        fetchIt(
            DELETE,
            'schedule_day_of_week',
            {id: this.state.selectedSchedule}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let schedules = me.state.schedules;
                    const scheduleIndex = schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].findIndex(schedule => schedule && (schedule.Id === me.state.selectedSchedule));
                    if (scheduleIndex > -1) {
                        schedules[me.state.selectedScheduleDataset.weekday][me.state.selectedScheduleDataset.resource].splice(scheduleIndex, 1);;
                    }

                    if (me._isMounted) {
                        me.setState({schedules});
                    }

                    let message = 'Schedule Day of week deleted!';
                    if (response.data && response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    me.refreshScheduler(
                        [me.state.selectedScheduleDataset.weekday],
                        [me.state.selectedScheduleDataset.weekday + '_' + [me.state.selectedScheduleDataset.resource]],
                        me.state.selectedSchedule
                    );

                    break;

                case 400:
                case 404:
                    let errorMessage = 'Oops, something went wrong!';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            this.onSdwCancel();

            fetchEnd();
        });
    };


    onPublish = () => {
        if (this.state.filters.Semester) {
            const {fetchStart, fetchEnd, showNotification} = this.props;

            fetchStart();

            fetchIt(
                UPDATE,
                'publish_semester',
                {id: this.state.filters.Semester}
            ).then(response => {
                let errorMessage = 'Oops, something went wrong!';
                switch (response.status) {
                    case 200:
                        let message = 'Semester published!';
                        if (response.data && response.data.Message) {
                            message = response.data.Message;
                        }

                        showNotification(
                            'direct_message',
                            'info',
                            {messageArgs: {_: message}}
                        );

                        break;

                    case 400:

                        if (response.data && response.data.MultiLevelErrorMessage) {
                            this.setState({
                                registrationIssues: response.data.Errors,
                                isRegisrationIssueDialogOpen: true
                            });
                        }

                        if (typeof response.data.HasValidationErrors !== 'undefined') {
                            errorMessage = getErrorMessage(response.data);
                        }

                        if (!response.data.MultiLevelErrorMessage) {
                            showNotification(
                                'direct_message',
                                'warning',
                                {messageArgs: {_: errorMessage}}
                            );
                        }

                        break;

                    case 404:
                        if (typeof response.data.HasValidationErrors !== 'undefined') {
                            errorMessage = getErrorMessage(response.data);
                        }

                        showNotification(
                            'direct_message',
                            'warning',
                            {messageArgs: {_: errorMessage}}
                        );

                        break;

                    default:
                        showNotification(
                            'direct_message',
                            'warning',
                            {messageArgs: {_: 'Oops, something went wrong!'}}
                        );
                }
            }).catch(() => {}).then(() => {
                this.onSdwCancel();

                fetchEnd();
            });
        }
    };


    registrationIssueDialogClose = () => {
        this.setState({isRegisrationIssueDialogOpen: false});
    }


    render() {
        const {fetchStart, fetchEnd, showNotification} = this.props;

        return (
            <Fragment>
                <Title title='Schedules' />
                <SchedulesListFilters
                    schoolYears={this.state.schoolYears}
                    semesters={this.state.semesters}
                    currentSemester={this.state.currentSemester}
                    fetchTheData={this.fetchTheData}
                    fetchSemesters={this.fetchSemesters}
                    schoolYear={this.state.schoolYear}
                />
                <Grid container style={{paddingBottom: 20, paddingLeft: 0}}>
                   <Grid item xs={6}>
                        {
                            this.state.overlappedSchedules.length ?
                                <OverlappedSchedules overlappedSchedules={this.state.overlappedSchedules} />
                            : null
                        }

                        {
                            this.state.outsideWorkingTimeSchedules.length ?
                                <OutsideWorkingTimeSchedules
                                    outsideWorkingTimeSchedules={this.state.outsideWorkingTimeSchedules}
                                    fetchTheData={this.fetchTheData}
                                />
                            : null
                        }
                   </Grid>
                    <Grid item xs={5}>
                        <div style={{textAlign: 'right'}}>
                            <FormControlLabel
                                control={<Switch
                                    checked={this.state.withOtherSemesters}
                                    onChange={this.onChangeWithOtherSemesters}
                                    name='WithOtherSemesters'
                                    color='primary'
                                />}
                                label="Courses from other semesters"
                                labelPlacement="end"
                            />
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <ScheduleActionButtons>
                            <CopyScheduleDialog
                                fetchSemesters={this.fetchSemesters}
                                setSchoolYear={this.setSchoolYear}
                            />
                            <ListContextProvider
                                    resource='schedules'
                                    value={{
                                        resource: 'schedules',
                                        basePath: 'schedules',
                                        selectedIds: [],
                                        filterValues: {...this.state.filters, ExcludeOtherSemesters: !this.state.withOtherSemesters}
                                    }}
                                >
                                    <ExportButton
                                        resource='schedules'
                                        maxResults={this.state.total}
                                        filterValues={{...this.state.filters, ExcludeOtherSemesters: !this.state.withOtherSemesters}}
                                        exporter={exporter}
                                    />
                                </ListContextProvider>
                                <RaButton
                                    label='Publish'
                                    onClick={this.onPublish}
                                    disabled={!this.state.currentSemester}
                                >
                                    <PublishIcon />
                                </RaButton>
                        </ScheduleActionButtons>
                    </Grid>
                </Grid>

                {
                    !this.state.isLoading ?
                        <NeteaScheduler
                            minTime={this.state.schedulerMinTime}
                            maxTime={this.state.schedulerMaxTime}
                            resources={this.state.studios}
                            schedules={this.state.schedules}
                            layoutColor='#fafafa'
                            slotDuration={15} // This can be 5, 10, 15 or 30 minutes
                            clickOnTimeSlot={this.clickOnTimeSlot}
                            fetchStart={fetchStart}
                            fetchEnd={fetchEnd}
                            showNotification={showNotification}
                            moveSchedule={this.moveSchedule}
                            refreshSchedules={this.state.refreshSchedules}
                            refresh={this.state.refresh}
                            refreshRows={this.state.refreshRows}
                            layoutWidth={this.props.width}
                            excludeFromOverlapped={this.excludeFromOverlapped}
                            withOtherSemesters={this.state.withOtherSemesters}
                            weekdays={this.state.weekdays}
                        />
                    : null
                }

                <ShowIssuesDialog
                    registrationIssues={this.state.registrationIssues}
                    isRegisrationIssueDialogOpen={this.state.isRegisrationIssueDialogOpen}
                    registrationIssueDialogClose={this.registrationIssueDialogClose}
                />

                <Dialog
                    maxWidth='xl'
                    fullWidth
                    open={this.state.showDialog}
                    onClose={this.onCloseClick}
                    aria-label='Schedule a course'
                >
                    <DialogTitle disableTypography onClose={this.onCloseClick}>
                        Schedule a course
                    </DialogTitle>

                    <DialogContent>
                        <ScheduleForm
                            schoolYears={this.state.schoolYears}
                            eventInitialData={this.state.event}
                            onClose={this.onCloseClick}
                            fetchTheData={this.fetchBySchedules}
                            scheduleFilters={this.state.filters}
                        />
                    </DialogContent>
                </Dialog>


                <Menu
                    id='ClickOnEmptyTimeSlotContextMenu'
                    theme={theme.light}
                    animation={animation.flip}
                    style={{position: 'fixed', zIndex: 111}}
                >
                    <Item onClick={this.onNewScheduleClick}>
                        <AddNewIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>Create New Class</div>
                    </Item>
                </Menu>


                <Menu
                    id='ClickOnScheduleContextMenu'
                    theme={theme.light}
                    animation={animation.flip}
                    style={{position: 'fixed', zIndex: 111}}
                >
                    <Item onClick={this.onSdwClick}>
                        <EditScheduleIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Edit single day of week (Edit class for the specific day)
                        </div>
                    </Item>
                    <Item onClick={this.onTeachersClick}>
                        <TeachersIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Teachers (Manage teachers for the specific day)
                        </div>
                    </Item>
                    {/*<Item aria-describedby='durationPopper' onClick={this.onDurationClick}>*/}
                    {/*    <DurationIcon />*/}
                    {/*    <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>*/}
                    {/*        Duration (Manage duration for the specific day)*/}
                    {/*    </div>*/}
                    {/*</Item>*/}
                    <Item aria-describedby='placesPopper' onClick={this.onPlacesClick}>
                        <GroupAddIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Places (Manage places for whole schedule)
                        </div>
                    </Item>
                    <Item aria-describedby='pricePopper' onClick={this.onPriceClick}>
                        <AttachMoneyIcon />
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            Price (Manage price for whole schedule)
                        </div>
                    </Item>
                    <Item aria-describedby='changeStatusPopper' onClick={this.onChangeStatusClick}>
                        {this.state.isActive === 'true' || this.state.isActive === true ? <SuspendIcon /> : <ActiveIcon />}
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                            {
                                this.state.isActive === 'true' || this.state.isActive === true ?
                                    'Deactivate (Whole schedule)'
                                :
                                    'Activate (Whole schedule)'
                            }
                        </div>
                    </Item>
                    {
                        this.state.registrationCount === 0 ?
                            <>
                                <Item onClick={this.openDeleteSdw}>
                                    <DeleteIcon />
                                    <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                                        Delete (Schedule Day)
                                    </div>
                                </Item>
                                <Item onClick={this.openDeleteSchedule}>
                                    <DeleteWholeIcon />
                                    <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>
                                        Delete Schedule (Delete whole schedule)
                                    </div>
                                </Item>
                            </>
                        : null
                    }
                </Menu>

                <EditScheduleDialog
                    onClose={this.onSdwClose}
                    scheduleToEdit={this.state.selectedScheduleDataset}
                    isOpened={this.state.isSdwOpened}
                    onCancel={this.onSdwCancelClick}
                    fetchTheData={this.fetchTheData}
                />

                <Dialog
                    onClose={this.onDurationClose}
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.isDurationOpened}
                >
                    <DialogTitle id="confirmation-dialog-title" style={{paddingLeft: 24, paddingRight: 24}}>
                        Manage Duration for the specific day
                    </DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.registrationCount ?
                                <p style={{color: 'red', marginTop: 0}}>This class has registrations!</p>
                                : null
                        }

                        <InputLabel>Duration</InputLabel>
                        <Input
                            type='number'
                            onChange={this.onDurationChange}
                            value={this.state.duration}
                            required
                            inputProps={{
                                min: 0
                            }}
                            style={{width: '100%'}}
                        />
                    </DialogContent>
                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                         <Button
                            color="primary"
                            startIcon={<IconCancel />}
                            onClick={this.onDurationCancelClick}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={this.onDurationSubmit}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.isPlacesOpened}
                    onClose={this.onPlacesClose}
                >
                    <DialogTitle id="confirmation-dialog-title" style={{paddingLeft: 24, paddingRight: 24}}>
                        Manage places for whole schedule
                    </DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.registrationCount ?
                                <p style={{color: 'red', marginTop: 0}}>This class has registrations!</p>
                                : null
                        }

                        <InputLabel>Places</InputLabel>
                        <Input type='number'
                            onChange={this.onPlacesChange}
                            value={this.state.places}
                            required
                            inputProps={{
                                min: 0
                            }}
                            style={{width: '100%'}}
                        />
                    </DialogContent>

                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                         <Button
                            color="primary"
                            startIcon={<IconCancel />}
                            onClick={this.onPlacesCancelClick}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={this.onPlacesSubmit}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={this.onPriceClose}
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.isPriceOpened}
                >
                    <DialogTitle id="confirmation-dialog-title" style={{paddingLeft: 24, paddingRight: 24}}>
                        Manage Price for whole schedule
                    </DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.registrationCount ?
                                <p style={{color: 'red', marginTop: 0}}>This class has registrations!</p>
                                : null
                        }

                        <InputLabel>Price</InputLabel>
                        <Input type='number'
                            onChange={this.onPriceChange}
                            value={this.state.price}
                            required
                            inputProps={{
                                min: 0,
                                step: 0.01
                            }}
                            style={{width: '100%'}}
                            startAdornment={
                                <InputAdornment position="start"><span style={{color: '#000'}}>$</span></InputAdornment>
                            }
                        />
                    </DialogContent>

                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                         <Button
                            color="primary"
                            startIcon={<IconCancel />}
                            onClick={this.onPriceCancelClick}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={this.onPriceSubmit}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={this.onTeachersClose}
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.isTeachersOpened}
                >
                    <DialogTitle id="confirmation-dialog-title">
                       Manage Teachers for the specific day
                    </DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.registrationCount ?
                                <p style={{color: 'red', marginTop: 0}}>This class has registrations!</p>
                            : null
                        }

                        <FormWithRedirect
                            initialValues={{Teachers: this.state.scheduleTeachers}}
                            resource='calendar'
                            mutators={{...arrayMutators}}
                            render={() => (
                                <form>
                                    <AutocompleteArrayInput
                                        source='Teachers'
                                        choices={this.state.teachers}
                                        value={this.state.scheduleTeachers}
                                        optionText='Name'
                                        optionValue='Id'
                                        options={{fullWidth: true, suggestionsContainerProps: {style: {zIndex: 2000}, transition: true}}}
                                        onChange={this.onTeachersChange}
                                    />
                                </form>
                            )}
                        />
                    </DialogContent>

                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                        <Button
                            color="primary"
                            startIcon={<IconCancel />}
                            onClick={this.onTeachersCancelClick}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={this.onTeachersSubmit}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={this.onChangeStatusClose}
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.isChangeStatusOpened}
                >
                    <DialogTitle id="confirmation-dialog-title">
                        {this.state.isActive === 'true' || this.state.isActive === true ? 'Deactivate schedule' : 'Activate schedule'}
                    </DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.registrationCount > 0 ?
                                <p style={{color: 'red', marginTop: 0}}>This class has registrations!</p>
                            : null
                        }

                        {
                            this.state.isActive === 'true' || this.state.isActive === true ?
                                <div>
                                    Active status is used to determine whether the schedule will be published.<br/>
                                    Are you sure you want to deactivate it?
                                </div>
                            :
                            <div>
                                Active status is used to determine whether the schedule will be published.<br/>
                                Are you sure you want to activate it?
                            </div>
                        }
                    </DialogContent>
                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                        <Button
                            onClick={this.onChangeStatusCancel}
                            color="primary"
                        >
                            <IconCancel /> Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.onStatusSubmit}
                        >
                            <DoneIcon /> Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={this.onDeleteSdwClose}
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.openDeleteSdw}
                >
                    <DialogTitle id="confirmation-dialog-title">Delete Schedule day of week</DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.registrationCount > 0 ?
                                <p style={{color: 'red', marginTop: 0}}>This class has registrations!</p>
                            : null
                        }

                        Are you sure you want to delete this Schedule day of week?
                    </DialogContent>
                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                        <Button
                            onClick={this.onSdwCancel}
                            color="primary"
                        >
                            <IconCancel /> Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.onSdwDelete}
                        >
                            <DoneIcon /> Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    onClose={this.onDeleteScheduleClose}
                    maxWidth="md"
                    aria-labelledby="confirmation-dialog-title"
                    open={this.state.openDeleteWholeSchedule}
                >
                    <DialogTitle id="confirmation-dialog-title">Delete Whole Schedule</DialogTitle>
                    <DialogContent style={{paddingLeft: 24, paddingRight: 24, paddingBottom: 24}}>
                        {
                            this.state.registrationCount > 0?
                                <p style={{color: 'red', marginTop: 0}}>This class has registrations!</p>
                            : null
                        }

                        Are you sure you want to delete this Whole Schedule?
                    </DialogContent>
                    <DialogActions style={{backgroundColor: '#f5f5f7'}}>
                        <Button
                            onClick={this.onScheduleCancel}
                            color="primary"
                        >
                            <IconCancel /> Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.onScheduleDelete}
                        >
                            <DoneIcon /> Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

export default withResizeDetector(SchedulesList);
