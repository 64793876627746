import React, {Fragment, Component} from 'react';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {DeleteButton} from '../../common';
import {EditButton, ShowButton} from 'react-admin';


class SplitButton extends Component {
    _isMounted = false;
    anchorRef = null;
    options = [];

    constructor(props) {
        super(props);

        this.anchorRef = React.createRef();
    }

    state = {
        open: false,
        selectedIndex: 0,
        record: {}
    };

    componentDidMount () {
        this._isMounted = true;

        this.setState({
            record: this.props.record
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.record && this._isMounted) {
            this.setState({
                record: nextProps.record
            });
        }
    }

    handleToggle = (event, index) => {
        this.setState({ open: !this.state.open });
    };

    handleClose = (event, index) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({ open: false});
    };

    render() {

        return (
            <Fragment>
                <div ref={this.anchorRef} style={{minWidth: 140}}>
                    <Button
                        color="primary"
                        size="small"
                        aria-owns={this.state.open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleToggle}
                        style={{minWidth: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                    >
                        Actions
                        <ArrowDropDownIcon />
                    </Button>
                </div>
                <Popper
                    open={this.state.open}
                    anchorEl={this.anchorRef.current}
                    transition
                    placement='bottom-start'
                    style={{zIndex: 999, marginLeft: this.props.smallTd ? 46 : 0}}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper id="menu-list-grow">
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                            <MenuItem>
                                            <ShowButton
                                                label="Details"
                                                record={this.props.record}
                                                basePath={this.props.basePath}
                                            />
                                            </MenuItem>
                                            <MenuItem>
                                                <EditButton
                                                    record={this.props.record}
                                                    basePath={this.props.basePath}
                                                />
                                                
                                            </MenuItem>                                              
                                            <MenuItem>
                                                <DeleteButton
                                                    record={this.props.record}
                                                    basePath={this.props.basePath}
                                                    resource={this.props.resource}
                                                    successMessage={this.props.successMessage}
                                                    errorMessage={this.props.errorMessage}
                                                    confirmTitle={this.props.confirmTitle}
                                                    confirmContent={this.props.confirmContent}
                                                />
                                            </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Fragment>
        );
    }
}

export default SplitButton;
