import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import {
    ReferenceInput,
    required,
    NumberInput,
    FormDataConsumer,
    TextInput,
    SelectInput
} from 'react-admin';
import {useForm, useFormState} from 'react-final-form';
import {Grid} from '@material-ui/core';
import {requiredNonNegativeNumberValidation} from '../../../validators';
import CalendarAutoComplete from "../../../components/CalendarAutoComplete";
import CurrencyInput from "../../../components/CurrencyInput";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import EventsDatesInput from './EventsDatesInput';
import {Review} from './Review';


const customBreakpointValues = {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1600,
    },
  }

const breakpoints = createBreakpoints({ ...customBreakpointValues });

const useStyles = makeStyles(theme => ({
    scheduleInnerForm: {
        position: 'relative',
        height: 395,
        width: 800,
        [breakpoints.up('xl')]: {
            width: 1000,
        },
        backgroundColor: theme.palette.background.paper,
    },
    tabPanel: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 800,
        [breakpoints.up('xl')]: {
            width: 1000,
        },
        padding: 20,
        zIndex: 0,
        height: 430,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
        overflowY: 'auto'
    },
    tabPanelActive: {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: 20,
        zIndex: 10,
        height: 430,
        width: 800,
        [breakpoints.up('xl')]: {
            width: 1000,
        },
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
        overflowY: 'auto'
    },
}));

const EventFormView = formProps => {
    const form = useForm();
    const formState = useFormState();

    const errors = formState.errors;
    const classes = useStyles();


    const name = formState.values.Name;
    const course = formState.values.Course;
    const places = formState.values.Places;
    const price = formState.values.Price;
    const semester = formState.values.Semester;


    useEffect(() => {
        formProps.setForm(form);

        if (formProps.activeTab === 0) {
            if (
                (!!errors.Semester) ||
                (!!errors.Course) ||
                (!!errors.Name) ||
                (!!errors.Places) ||
                (!!errors.Price)
            ) {
                formProps.disallowStep(1);
            } else {
                formProps.allowStep(1);
            }
        }
    }, [name, course, places, price, semester, errors, formProps.activeTab]);


    return (
        <form className={classes.scheduleInnerForm}>
            <div
                role="tabpanel"
                aria-labelledby={`vertical-tab-0`}
                className={formProps.activeTab === 0 ? classes.tabPanelActive : classes.tabPanel}
            >
                <Grid container style={{width: '100%', paddingLeft: 0, paddingRight: 0}} >
                    <Grid item xs={6} style={{paddingTop: 0, paddingBottom:0}}>
                        <TextInput source="Name" multiline validate={required('The Name field is required')}/>
                        <FormDataConsumer>
                            {
                                ({formData}) => {
                                    return (
                                        <ReferenceInput
                                            label='Course'
                                            source='Course'
                                            reference='courses'
                                            filter={{IsActive: true}}
                                            perPage={1000}
                                            validate={required('The Course field is required')}
                                        >
                                            <CalendarAutoComplete
                                                source='Course'
                                                id='Course'
                                                myVal={formData.Course}
                                                optionRenderer={choice => choice ? `${choice.Name}` : ''}
                                                validate={required('The Course field is required')}
                                            />
                                        </ReferenceInput>
                                    );
                                }
                            }
                        </FormDataConsumer>

                        <ReferenceInput
                            label='Semester'
                            source='Semester'
                            basePath='/schedules'
                            resource='semesters'
                            reference='semesters'
                            sort={{field: 'Name', order: 'ASC'}}
                            perPage={1000}
                            filter={{RegistrationView: 2}}
                            validate={required('The Course field is required')}
                        >
                            <SelectInput
                                optionText='Name'
                                className="scheduleFormInput"
                            />
                        </ReferenceInput>
                    </Grid>

                    <Grid item xs={6} style={{paddingBottom: 0,  paddingLeft: '7%'}}>
                        <NumberInput
                            source='Places'
                            validate={requiredNonNegativeNumberValidation}
                            className='fieldsWidthExpand'
                        />
                        <CurrencyInput source='Price' validate={requiredNonNegativeNumberValidation} />
                    </Grid>
                    <RichTextInput source='Description' className="fieldsWidthExpand"/>
                </Grid>
            </div>


            <div
                role="tabpanel"
                aria-labelledby={`vertical-tab-1`}
                className={formProps.activeTab === 1 ? classes.tabPanelActive : classes.tabPanel}
            >
                <EventsDatesInput
                    setSsdErrors={formProps.setSsdErrors}
                    ssdErrors={formProps.ssdErrors}
                    record={formProps.record}
                    allowStep={formProps.allowStep}
                    disallowStep={formProps.disallowStep}
                    activeTab={formProps.activeTab}
                    semesters={formProps.semesters}
                />
            </div>

            <div
                role="tabpanel"
                aria-labelledby={`vertical-tab-2`}
                className={formProps.activeTab === 2 ? classes.tabPanelActive : classes.tabPanel}
            >
               <Review activeTab={formProps.activeTab} />
            </div>
        </form>
    );
};

export default EventFormView;
