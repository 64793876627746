import React, {useState, useEffect} from 'react';
import {ImageInput, ImageField, CREATE, GET_ONE, DELETE} from 'react-admin';
import {Form} from 'react-final-form';
import {NtaSaveButton} from '../../common';
import {getErrorMessage, fetchIt} from '../../../helpers';
import {Button} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

const CourseImage = props => {
    const [isDisabled, setIsDisabled] = useState(true);
    const [shouldLoad, setShouldLoad] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const [record, setRecord] = useState({});
    const {fetchStart, fetchEnd, showNotification} = props;
    const [hasImage, setHasImage] = useState(false);


    useEffect(
        () => {
            const convert = async file => {
                await convertFileToBase64(file)
                    .then(transformedImage => {
                        setRecord({ImageFromBackEnd: transformedImage});
                    });
            }

            if (props.record.Id && shouldLoad) {
                fetchStart();

                setRecord({});
                setShouldLoad(false);

                fetchIt(
                    GET_ONE,
                    'course_image',
                    {id: props.record.Id}
                ).then(response => {
                    switch (response.status) {
                        case 200:
                            convert (response.data);
                            setHasImage(true);
                            break;

                        case 400:
                            let errorMessage = 'The image is NOT valid. Please check for errors.';
                            if (typeof response.data.HasValidationErrors !== 'undefined') {
                                errorMessage = getErrorMessage(response.data);
                            }

                            showNotification(
                                'direct_message',
                                'warning',
                                {messageArgs: {_: errorMessage}}
                            );

                            break;
                        case 404:
                            break;

                        default:
                            showNotification(
                                'direct_message',
                                'warning',
                                {messageArgs: {_: 'Oops, something went wrong!'}}
                            );
                    }
                }).catch(error => {

                }).then(() => {
                    fetchEnd();
                });
            }
        }, [props.record.Id, shouldLoad, fetchStart, fetchEnd, showNotification]
    );

    const convertFileToBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });


    const onSubmit = async data => {
        const {fetchStart, fetchEnd, showNotification} = props;

        setIsDisabled(true);
        setIsSaved(true);

        fetchStart();

        if (
            data &&
            data.Image.rawFile &&
            (data.Image.rawFile instanceof File)
        ) {
            let canvas = document.createElement('canvas');
            var img = new Image();
            img.onload = function() {
                const imageType = data.Image.rawFile.type;
                const fileName = data.Image.rawFile.name;
                const width = this.width;
                const height = this.height;
                canvas.setAttribute('width', width);
                canvas.setAttribute('height', height);
                canvas.getContext("2d").drawImage(this, 0, 0, width, height);
                const imgSrc = canvas.toDataURL(imageType,"0.8");

                let image = imgSrc.split(',');
                var img = atob(image[1]);
                var img_buffer = [];
                var i = 0;
                while (i < img.length) {
                    img_buffer.push(img.charCodeAt(i));
                    i++;
                }
                img = new Uint8Array(img_buffer);

                let formData = new FormData();
                formData.append(
                    "image",
                    new File(
                        [new Blob([img], {type: imageType})],
                        fileName,
                        {type: imageType}
                    )
                );

                fetchIt(
                    CREATE,
                    'course_image',
                    {id: props.record.id, formData: formData}
                ).then(response => {
                    switch (response.status) {
                        case 200:
                            setShouldLoad(true);
                            setHasImage(true);
                            let message = 'Image saved.';
                            if (response.data.Message) {
                                message = response.data.Message;
                            }

                            showNotification(
                                'direct_message',
                                'info',
                                {messageArgs: {_: message}}
                            );

                            break;

                        case 400:
                            let errorMessage = 'The image is NOT valid. Please check for errors.';
                            if (typeof response.data.HasValidationErrors !== 'undefined') {
                                errorMessage = getErrorMessage(response.data);
                            }

                            showNotification(
                                'direct_message',
                                'warning',
                                {messageArgs: {_: errorMessage}}
                            );

                            break;

                        default:
                            showNotification(
                                'direct_message',
                                'warning',
                                {messageArgs: {_: 'Oops, something went wrong!'}}
                            );
                    }
                }).catch(error => {

                }).then(() => {
                    fetchEnd();
                });
            };
            img.src = data.Image.src;
        } else {
            showNotification(
                'direct_message',
                'info',
                {messageArgs: {_: 'You should choose an Image first.'}}
            );
        }

        return false;
    }

    const onChange = data => {
        setIsDisabled(!data);
        setIsSaved(false);
    }

    const onDeleteClick = () => {
        fetchStart();

        fetchIt(
            DELETE,
            'course_image',
            {id: props.record.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    setShouldLoad(true);
                    setHasImage(false);
                    let message = 'Image deleted.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        {messageArgs: {_: message}}
                    );

                    break;

                case 400:
                    let errorMessage = 'The image was NOT deleted.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).then(() => {
            fetchEnd();
        });
    }

    return (
        <Form
            onSubmit={onSubmit}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                    <ImageInput label="" onChange={onChange} record={record} source='Image' accept="image/*" >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    {
                        isDisabled && !isSaved ?
                            <ImageField
                                source='ImageFromBackEnd'
                                record={record}
                                style={{marginTop: -25, marginLeft: 'auto', marginRight: 'auto', display: 'table'}}
                            />
                        : null
                    }
                    <NtaSaveButton style={{float: 'right', marginBottom: 10, marginLeft: 15}} label="Save Image" isDisabled={isDisabled} />
                    {
                        hasImage ?
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onDeleteClick}
                                style={{float: 'right', marginBottom: 10}}
                            >
                                <DeleteIcon /> Delete Image
                            </Button>
                        : null
                    }

                </form>
            )}
        />
    );
};

export default CourseImage;
