import React, {useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment";
import Chip from '@material-ui/core/Chip';


const Dates = props => {
    const {record} = props;
    const [expanded, setExpanded] = useState(true);

    const handleChange = () => (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };

    return (
        <Accordion
            expanded={expanded} 
            onChange={handleChange('panel1')}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                style={{paddingRight: 25, paddingLeft: 20}}
            >
                Classes dates
            </AccordionSummary>
            <AccordionDetails>
                <div style={{padding: '0 0 20px 20px'}}>
                    {
                        record.map((date, key) => (
                            <Chip 
                                key={key} 
                                style={{margin: '0 5px 5px 0'}} 
                                label={moment.utc(date).format('MMM.D, YYYY, H:mm A')} 
                            />
                        ))
                    }
                </div>
                
            </AccordionDetails>
        </Accordion>
    );
}

export default Dates;
