import React from 'react';
import Card from "@material-ui/core/Card";
import {Grid, Typography} from "@material-ui/core";
import {FunctionField, SimpleShowLayout, TextField, SelectField} from "react-admin";
import {paymentMethodChoices} from "../../../../../config/constants";
import {formatPhone} from "../../../../../helpers";


const PayeeCard = props => {
    const {record, classes} = props;
    const innerRecord = record.PayeeDetails;

    return (
        <>
            <Grid item xs={2}>
                <Typography variant="h6" component="div" className={classes.leftColumnTitle} gutterBottom>
                    Payee Details
                </Typography>
            </Grid>

            <Grid item xs={10}>
                <Card style={{minHeight: 85}}>
                    <Grid container>
                        <Grid item xs={4}>
                            <SimpleShowLayout basePath='/registrations' record={innerRecord} className='showSections'>
                                <TextField source='Name' />
                                <TextField source='Email' />
                                <FunctionField label='Phone' source='Cellphone' render={record => record.Cellphone ? formatPhone(record.Cellphone) : 'Not specified'} />
                            </SimpleShowLayout>
                        </Grid>
                        <Grid item xs={4}>
                            <SimpleShowLayout basePath='/registrations' record={innerRecord && innerRecord.Address ? innerRecord.Address : {}} className='showSections'>
                                <FunctionField
                                    label='Address'
                                    source='Address'
                                    render={
                                        record => (
                                            record.StreetAddress ?
                                                (
                                                    record.StreetAddressLine2 ?
                                                        record.StreetAddress + ', ' + record.StreetAddressLine2 :
                                                        record.StreetAddress
                                                ) :
                                                'Not specified'
                                        )
                                    }
                                />
                                <FunctionField source='City' render={record => record.City ? record.City : 'Not specified'} />
                                <FunctionField label='State' source='USState' render={record => record.USState ? record.USState : 'Not specified'} />
                            </SimpleShowLayout>
                        </Grid>
                        <Grid item xs={4}>
                            <SimpleShowLayout basePath='/registrations' record={record} className='showSections'>
                                <FunctionField
                                    label='ZIP code'
                                    source='ZIP'
                                    render={
                                        record => (
                                            record.PayeeDetails && record.PayeeDetails.Address && record.PayeeDetails.Address.ZIP ?
                                                record.PayeeDetails.Address.ZIP :
                                                'Not specified')
                                    }
                                />
                                <SelectField source='PaymentMethod' choices={paymentMethodChoices} />
                            </SimpleShowLayout>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
}

export default PayeeCard;
