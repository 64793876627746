import React, { Component, Fragment } from 'react';
import {
    Button,
    SimpleForm,
    TextInput,
    UPDATE,
    FormDataConsumer,
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {emailValidation} from '../../../../validators';
import {fetchIt, getErrorMessage} from "../../../../helpers";
import IconCancel from '@material-ui/icons/Cancel';
import {NtaSaveButton} from '../../../common';
import EditIcon from '@material-ui/icons/Edit';
import DialogContentText from '@material-ui/core/DialogContentText';

const FormActions = props => {
    const {basePath, onCloseClick, isDisabled, showNotification, ...rest} = props;

    return (
        <DialogActions {...rest} className="EmailChangeButtons">
            <NtaSaveButton label="Save" onCloseClick={onCloseClick} showNotification={showNotification} isDisabled={isDisabled} />
            <Button label="Cancel" onClick={onCloseClick}>
                <IconCancel />
            </Button>
        </DialogActions>
    );
};

class ChangeEmailButton extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };


    handleSubmit = values => {
        const {fetchStart, fetchEnd, showNotification} = this.props;

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        values.id = this.props.record.Id;

        fetchIt(
            UPDATE,
            'tenant_users_email',
            { data: values }
        ).then(response => {
            switch (response.status) {
                case 200:
                    
                    this.setState({showDialog: false});

                    let message = 'Email updated.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                     if (this.props.handleRefresh) {
                        this.props.handleRefresh();
                    }

                    break;

                case 400:
                    let errorMessage = 'There was a problem with update the email.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };

    render() {
        const {showDialog} = this.state;
        
        return (
            <Fragment>
                <Button 
                    onClick={this.handleClick} 
                    lable="Update Email" 
                    style= {{
                        float: 'right', 
                        marginRight: '30%',
                        marginTop: 20, 
                        display: "-webkit-box", 
                        background: "white"
                    }}
                    >
                      <EditIcon />
                 </Button>
                <Dialog
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Update email"
                >
                    <DialogTitle>
                        Update user email
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            *Note: User email is also used as login user name!
                        </DialogContentText>
                        <SimpleForm
                            resource="tenant_users_email"
                            save={this.handleSubmit}
                            toolbar={null}
                            className="emailChangeButton"
                            style={{paddingLeft: 25, paddingRight: 25}}
                            record={this.props.record}
                        >
                            <FormDataConsumer>
                                {
                                    ({formData, ...rest }) =>
                                        <TextInput
                                            source='Email'
                                            validate={emailValidation}
                                            fullWidth
                                            style={{marginBottom: 40}}
                                        />
                                }
                            </FormDataConsumer>
                            <FormActions 
                                style={{
                                    backgroundColor: '#f5f5f5', 
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    marginLeft: -25
                                }} 
                                onCloseClick={this.handleCloseClick} 
                                showNotification={this.props.showNotification} 
                                isDisabled={this.state.isDisabled} 
                            />
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

export default ChangeEmailButton;
