import React, {useState, Fragment} from 'react';
import {
    SimpleForm,
    CREATE,
    required,
    GET_MANY
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import {NtaSaveButton} from '../../common';
import {CopyScheduleFormView} from './CopyScheduleFormView';
import Typography from '@material-ui/core/Typography';
import {fetchIt, getErrorMessage} from "../../../helpers";
import DownloadIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';


const FormActions = props => {
    const {basePath, onCloseClick, isDisabled, showNotification, ...rest} = props;

    return (
        <DialogActions {...rest} className="EmailChangeButtons">
            <NtaSaveButton label="Copy Schedule" showNotification={showNotification} isDisabled={isDisabled} />
            <Button color='primary' onClick={onCloseClick}>
                <IconCancel /> Close 
            </Button>
        </DialogActions>
    );
};

const CopyScheduleDialog = props => {

    const [showDialog, setShowDialog] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [message, setMessage] = useState('');


    const handleClick = (values) => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
        setIsSubmit(false);
    };

    const downloadTxtFile = () => {
        const element = document.createElement('a');
        const file = new Blob([document.getElementById('copy_schedule_text').innerText], {type: 'text/plain;charset=utf-8'});
        element.href = URL.createObjectURL(file);
        element.download = "copy_schedule_file.txt";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const handleSubmit = values => {

        const {fetchStart, fetchEnd, showNotification} = props;
        const targetSemesterId = parseInt(values.TargetSemesterIds);
        const data = {
            SourceSemesterId: values.SourceSemesterId,
            TargetSemesterIds: [targetSemesterId]
        };

        //Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        fetchIt(
            CREATE,
            'copy_schedule',
            {data}
        ).then(response => {
            switch (response.status) {
                case 200:

                    setIsSubmit(true);

                    fetchIt(
                        GET_MANY,
                        'semesters',
                        {ids: data.TargetSemesterIds}
                    ).then(response => {
                        const targetSemester = response.data[0];

                        if (targetSemester){
                            props.setSchoolYear(targetSemester.SchoolYear);
                            props.fetchSemesters({"SchoolYear": targetSemester.SchoolYear}, targetSemesterId);
                        }
                    });

                    setMessage(response.data.Message);

                    break;

                case 400:
                    let errorMessage = 'There was a problem.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };

    return (
        <Fragment>
            <Button
                size='small'
                color='primary'
                startIcon={<FileCopyIcon />}
                onClick={handleClick}
                style={{paddingLeft: 8}}
            >
                Copy Schedule
            </Button>
            <Dialog
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Copy Schedule"
                maxWidth='md'
            >
                <DialogTitle style={{paddingLeft: 25, paddingBottom: 0}}>
                    Copy Schedule
                </DialogTitle>
                <DialogContent style={{paddingTop: 0}}>
                    { !isSubmit ?
                        <SimpleForm
                            resource="copy_schedule"
                            save={handleSubmit}
                            toolbar={null}
                            validate={required('Test')}
                        >
                            <CopyScheduleFormView />

                            <FormActions
                                showNotification={props.showNotification}
                                onCloseClick={handleCloseClick}
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    marginLeft: -25
                                }}
                            />
                        </SimpleForm>
                    :
                        <>
                            <Typography id='copy_schedule_text' variant='body1' style={{padding: 15}}>
                                {message}
                            </Typography>
                            <Button  label="Close" onClick={handleCloseClick}><IconCancel /></Button>
                            <Button  label="Export" onClick={downloadTxtFile}><DownloadIcon /></Button>
                        </>
                    }

                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

export default CopyScheduleDialog;
