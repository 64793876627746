import {connect} from 'react-redux';
import CreateNewFamilyDialog from '../components/dialogs/CreateNewFamilyDialog';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification,
    // submit
};


export default connect(null, mapDispatchToProps)(
    CreateNewFamilyDialog
);
