import React, {memo, useMemo} from 'react'
import {hexToRgb} from './helpers'


const getStyles = (resource, schedule, innerSlot, targetSlots, top) => {
    const scheduleLeft = schedule.StartMinutes - (innerSlot * (60 / targetSlots));

    return {
        position: 'absolute',
        top: top,
        left: (180 / 60) * scheduleLeft,
        width: (180 / 60) * schedule.Duration - 1,
        backgroundColor: '#ffffff',
        borderLeft: '3px solid ' + (schedule.BelongsToAnotherSemester ? '#666666' : resource.color),
        boxSizing: 'border-box',
        zIndex: 4,
        opacity: 1,
        height: (schedule.BelongsToAnotherSemester ? 58 : 79),
        cursor: 'default',
        overflow: 'hidden',
        userSelect: 'none',
    }
}


const Schedule = memo(props => {
    const {resource, schedule, timeSlot, innerSlot, targetSlots, backgroundColor} = props;

    const rgbColor = hexToRgb('#666666');
    const scheduleBackgroundColor = schedule.BelongsToAnotherSemester ?
        'rgba(' + rgbColor.r + ', ' + rgbColor.g + ', ' + rgbColor.b + ', 0.15)' :
        backgroundColor;

    const containerStyle = useMemo(
        () => getStyles(resource, schedule, innerSlot, targetSlots, props.top),
        [resource, schedule, innerSlot, targetSlots, props.top]
    );

    return (
        <div data-get-parent={true} style={containerStyle}>
            <div
                style={{
                    width: '100%',
                    height: (schedule.BelongsToAnotherSemester ? 58 : 80),
                    backgroundColor: scheduleBackgroundColor,
                    fontSize: 11,
                    lineHeight: '11px',
                    padding: 5,
                    boxSizing: 'border-box'
                }}
                data-target-slots={targetSlots}
                data-time-slot={timeSlot}
                data-time-subslot={innerSlot}
                data-weekday={schedule.DayOfWeek}
                data-resource={schedule.Studio}
                data-schedule={schedule.Id}
                data-get-parent={true}
            >
                <div
                    data-get-parent={true}
                    style={{
                        paddingBottom: 3,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {schedule.CourseName}
                </div>

                <div
                    data-get-parent={true}
                    style={{
                        paddingBottom: 3,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    Ages: {schedule.AgeTitle}
                </div>

                <div
                    data-get-parent={true}
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {schedule.Semester}
                </div>
            </div>
        </div>
    );
});

export default Schedule;
