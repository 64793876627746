import React, {Component} from 'react';
import {AppBar as RaAppBar, UserMenu, MenuItemLink} from 'ra-ui-materialui';
import SettingsIcon from '@material-ui/icons/Settings';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import {getUserName} from "../helpers";


const MyUserMenu = props => {
    return (
        <UserMenu {...props}>
            {
                props.label ?
                    <Typography component="h5" variant="subtitle2" style={{paddingLeft: 24}}>{props.label}</Typography>
                : null
            }
            {
                props.label ?
                    <MenuItemLink
                        to={'/account/' + props.userId + '/show'}
                        primaryText='My Profile'
                        leftIcon={<SettingsIcon/>}
                    />
                : null
            }
            {
                props.label ?
                    <MenuItemLink
                        to={'/change_password/' + props.userId}
                        primaryText='Change Password'
                        leftIcon={<LockIcon/>}
                    />
                : null
            }
        </UserMenu>
    );
};

class AppBar extends Component {
    _isMounted = false;

    state = {
        userName: '',
        userId: 1
    };

    componentDidMount () {
        this._isMounted = true;
        const me = this;

        getUserName().then(userName => {
            let userId = 1;
            if (localStorage.getItem('account')) {
                const account = JSON.parse(localStorage.getItem('account'));

                userId = account.Id;
            }

            if (me._isMounted) {
                me.setState({userName, userId});
            }
        }).catch(error => {

        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return <RaAppBar style={{border: 'none'}} {...this.props} userMenu={<MyUserMenu label={this.state.userName} userId={this.state.userId} />} />
    }
}
export default AppBar;
