import React from 'react';
import {Route} from 'react-router-dom';
import {Register} from './authorization';
import {TeacherPassword, ChangeForgottenPassword, ForgottenPassword} from './authorization';
import AutoLogin from "./components/AutoLogin";
import CourseDetails from "./components/CourseDetails";


const Routes = [
    <Route exact path="/register" component={Register} noLayout/>,
    <Route exact path="/users/activate/:tenant/:hash" component={TeacherPassword} noLayout/>,
    <Route exact path="/users/activate/:tenant" component={TeacherPassword} noLayout/>,
    <Route exact path="/users/activate" component={TeacherPassword} noLayout/>,
    <Route exact path="/forgotten-password" component={ForgottenPassword} noLayout/>,
    <Route path="/password-reset" component={ChangeForgottenPassword} noLayout/>,
    <Route exact path="/al" component={AutoLogin} noLayout/>,
    <Route exact path="/course-details/:course" component={CourseDetails} noLayout/>,
];

export default Routes;
