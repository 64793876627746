import {connect} from 'react-redux';
import EditScheduleDialog from '../components/EditScheduleDialog';
import {
    fetchStart,
    fetchEnd,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchStart,
    fetchEnd,
    showNotification
};


export default connect(null, mapDispatchToProps)(
    EditScheduleDialog
);
