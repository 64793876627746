import React, {useState, useEffect} from 'react';
import {addField} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';
import {ColorInput} from 'react-admin-color-input';


const styles = {
    numberInput: {
        '& input': {marginLeft: 25},
        '& label': {whiteSpace: 'nowrap', marginLeft: -20},
        '& div' : {marginLeft: -20, marginRight: 20},
    },
};


const NtaColorInput = withStyles(styles)(({input, meta, source, record, id, label, classes, inDialog, val, ...props}) => {
    
    const [color, setColor] = useState(props.color);

    useEffect(() => {
        setColor(input.value);
    }, [input.value]); // eslint-disable-line
    
    
    return (
        <div style={{display: "flex"}}>
            <span 
                style={{
                    alignSelf: "flex-end", 
                    marginBottom: 8, 
                    backgroundColor: color, 
                    height: 20, 
                    width: 20,
                    borderRadius: '50%'
                }}
            />
            <ColorInput source={source} picker='Sketch' className={classes.numberInput} />
        </div>
    );
});

export default addField(NtaColorInput); // decorate with react-final-form's <Field>