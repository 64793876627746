import React, {memo, useMemo, useState, useEffect} from 'react'
import {useDrag} from 'react-dnd'
import {itemTypes} from './config/constants'
import {hexToRgb} from './helpers'
import moment from 'moment'


const getStyles = (resource, schedule, innerSlot, targetSlots, isDragging, wasDragged, top) => {
    const scheduleLeft = schedule.StartMinutes - (innerSlot * (60 / targetSlots));

    return {
        position: 'absolute',
        top: top,
        left: isDragging || wasDragged ? -2000 : (180 / 60) * scheduleLeft,
        width: isDragging || wasDragged ? 0 : (180 / 60) * schedule.Duration - 1,
        backgroundColor: '#ffffff',
        borderLeft: '3px solid ' + (schedule.BelongsToAnotherSemester ? '#666666' : resource.color),
        boxSizing: 'border-box',
        zIndex: 4,
        opacity: isDragging || wasDragged ? 0 : 1,
        height: (schedule.BelongsToAnotherSemester ? 58 : 78),
        cursor: isDragging ? 'drag' : 'pointer',
        overflow: 'hidden',
        userSelect: 'none',
    }
}


const DraggableSchedule = memo(props => {
    const {resource, schedule, timeSlot, innerSlot, targetSlots, backgroundColor, refresh, shouldRefresh} = props;
    const [forceUpdate, setForceUpdate] = useState(false);
    const [wasDragged, setWasDragged] = useState(false);
    const [wasDraggedChanged, setWasDraggedChanged] = useState(false);
    const [item, setItem] = useState({type: itemTypes.SCHEDULE, id: schedule.Id, schedule});

    const rgbColor = hexToRgb('#666666');
    const scheduleBackgroundColor = schedule.BelongsToAnotherSemester ?
        'rgba(' + rgbColor.r + ', ' + rgbColor.g + ', ' + rgbColor.b + ', 0.15)' :
        backgroundColor;

    const [{isDragging}, drag] = useDrag({
        item,
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })


    const onDragStart = (e, sch) => {
        e.persist();

        let newItem = {type: itemTypes.SCHEDULE, id: schedule.Id, schedule: sch};
        newItem.width = e.target.clientWidth;
        newItem.itemX = e.target.getBoundingClientRect().x;
        newItem.mouseX = e.clientX;

        setItem(newItem);
        setWasDragged(false);
    }


    const onDragEnd = e => {
        setWasDragged(true);
    }


    useEffect(() => {
        if (isDragging) {
            setWasDragged(false);
        }

        if (wasDragged) {
            setWasDraggedChanged(true);

            if (wasDraggedChanged) {
                setWasDraggedChanged(false);
                setWasDragged(false);
            }
        }
    }, [isDragging, wasDragged, wasDraggedChanged]); // eslint-disable-line


    useEffect(() => {
        if (refresh && (shouldRefresh !== forceUpdate)) {
            setForceUpdate(shouldRefresh);
        }
    }, [refresh, shouldRefresh]); // eslint-disable-line


    const containerStyle = useMemo(
        () => getStyles(resource, schedule, innerSlot, targetSlots, isDragging, wasDragged, props.top),
        [resource, schedule, innerSlot, targetSlots, isDragging, wasDragged, props.top, forceUpdate]
    );


    const start = moment.utc(schedule.StartHour + ':' + schedule.StartMinutes, 'H:m');
    const end = moment.utc(start.format('H:m'), 'H:m').add(schedule.Duration, 'minutes');


    return (
        <div
            ref={drag}
            style={containerStyle}
            onDragStart={event => onDragStart(event, schedule)}
            onDragEnd={onDragEnd}
            data-get-parent={true}
        >
            <div
                style={{
                    width: '100%',
                    height: (schedule.BelongsToAnotherSemester ? 58 : 80),
                    backgroundColor: scheduleBackgroundColor,
                    fontSize: 11,
                    lineHeight: '11px',
                    padding: 5,
                    boxSizing: 'border-box'
                }}
                data-target-slots={targetSlots}
                data-time-slot={timeSlot}
                data-time-subslot={innerSlot}
                data-start={start.format('h:mmA')}
                data-weekday={schedule.DayOfWeek}
                data-resource={schedule.Studio}
                data-schedule={schedule.Id}
                data-parent-schedule={schedule.Schedule}
                data-get-parent={true}
            >
                <div
                    data-get-parent={true}
                    style={{
                        paddingBottom: 3,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: schedule.IsOverlapped ? 'red' : 'black',
                        fontWeight: schedule.IsOverlapped ? 'bold' : 'normal'
                    }}
                >
                    {schedule.CourseName}
                </div>

                <div
                    data-get-parent={true}
                    style={{
                        paddingBottom: 3,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: schedule.IsOverlapped ? 'red' : 'black',
                        fontWeight: schedule.IsOverlapped ? 'bold' : 'normal'
                    }}
                >
                    Ages: {schedule.AgeTitle}
                </div>

                <div
                    data-get-parent={true}
                    style={{
                        paddingBottom: 3,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {schedule.Semester}
                </div>

                {
                    schedule.Teachers.length > 0 ?
                        <div
                            data-get-parent={true}
                            style={{
                                paddingBottom: 3,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {schedule.Teachers.map(teacher => teacher.Name).join(', ')}
                        </div>
                    : null
                }

                <div
                    data-get-parent={true}
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {start.format('h:mmA')} - {end.format('h:mmA')}
                </div>
            </div>
        </div>
    );
});

export default DraggableSchedule;
