import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import EventForm from '../containers/EventForm';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {Typography} from '@material-ui/core';


const styles = theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>

            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
    );
});

export const NewEventDialog = props => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth='xl'
            style={{paddingTop: 0}}
        >
            <DialogTitle style={{paddingBottom: 0}} onClose={props.onClose}>
                Add New Event
            </DialogTitle>
            <DialogContent style={{paddingTop: 0}}>
                <EventForm
                    closeDialog={props.onClose}
                    goToDate={props.goToDate}
                    record={props.record}
                    fetchTheData={props.fetchTheData}
                    handleSubmit={props.handleSubmit}
                    viewType={props.viewType}
                    activeStart={props.activeStart}
                    activeEnd={props.activeEnd}
                    semesters={props.semesters}
                />
            </DialogContent>
        </Dialog>
    );
}
