import React, {useEffect, useState} from "react";
import {Typography, Grid} from "@material-ui/core";
import {useForm, useFormState} from "react-final-form";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';


const SelectedClasses = props => {
    const form = useForm();
    const formState = useFormState();

    const [schedules, setSchedules] = useState([]);

    const classes = props.classes;


    useEffect(() => {
        if (formState.values.Schedules) {
            const formSchedules = formState.values.Schedules;
            let newSchedules = [];
            let newTotal = 0;
            for (const prop in formSchedules) {
                if (formSchedules.hasOwnProperty(prop)) {
                    newSchedules.push(formSchedules[prop]);
                    newTotal += parseFloat(formSchedules[prop].PriceForRemainingClasses);
                }
            }

            setSchedules(newSchedules);
            
            form.change('SubTotal', newTotal);
            form.change('hasSchedules', (newSchedules.length > 0));
        } else {
            setSchedules([]);
        }
    }, [formState.values.SchedulesChanged]); // eslint-disable-line


    const onDelete = schedule => {
        let schedules = {};
        if (formState.values.Schedules) {
            schedules = formState.values.Schedules;
        }

        delete schedules['sch-' + schedule];

        form.change('Schedules', schedules);
        form.change('SchedulesChanged', !formState.values.SchedulesChanged);
    }

    return (
        <div style={{paddingBottom: formState.values.hasSchedules ? 30 : 0}}>
            {
                schedules && (schedules.length > 0) ?
                    schedules.map((schedule, idx) => {
                        const teachers = schedule.Teachers.map(teacher => teacher.Name).join(', ');
                        let startDate = schedule.StartDate;
                        if (!startDate) {
                            startDate = moment.utc(schedule.StartDateTime, 'M/D/YYYY h:mm:ss A').format('M/D/YYYY h:mm A')
                        }

                        let endDate = schedule.EndDate;
                        if (!endDate) {
                            endDate = moment.utc(schedule.EndDateTime, 'M/D/YYYY h:mm:ss A').format('M/D/YYYY h:mm A')
                        }


                        return (
                            <Grid container key={schedule.Id} style={{paddingLeft: 0, paddingRight: 0}}>
                                <Grid item xs={2} lg={1}>
                                    <IconButton size='small' aria-label="delete" edge="start" onClick={() => onDelete(schedule.Schedule)} >
                                        <CloseIcon />
                                    </IconButton>
                                    <IconButton size='small' edge="start" onClick={() => {props.loadClassDates(schedule.Schedule)}} >
                                        <DateRangeIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={6} lg={8} style={{cursor: 'pointer'}}>
                                    <Typography variant='body1' style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{flexGrow: 1}}>
                                            {idx + 1}. {schedule.Name ? schedule.Name : schedule.CourseName}
                                            {teachers && teachers.length > 0 ? ', ' + teachers : '' }
                                        </span>
                                    </Typography>

                                    <Typography variant='caption' style={{display: 'block', color: 'grey'}}>
                                        {schedule.DaysOfWeek}
                                    </Typography>
                                    <Typography variant='caption' style={{display: 'block', color: 'grey', paddingBottom: 10}}>
                                        {moment.utc(startDate, 'M/D/YYYY h:mm A').format('D MMMM YYYY')}
                                         -
                                        {moment.utc(endDate, 'M/D/YYYY h:mm A').format('D MMMM YYYY')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} lg={3}>
                                    <span style={{float: 'right'}}>${parseFloat(schedule.PriceForRemainingClasses).toFixed(2)}</span>
                                </Grid>
                            </Grid>
                        );
                    })
                :
                    (
                        <Typography variant='body1' className={classes.selectionTitle}>
                            None
                        </Typography>
                    )
            }
        </div>
    );
}

export default SelectedClasses;
