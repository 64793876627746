import React from "react";
import {
    MuiPickersUtilsProvider,
    TimePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {addField, FieldTitle} from "react-admin";

const ScheduleTimePicker = props => {

    const renderLabel = (date) => {
        if (date.isValid()) {
          return date.format(props.dateFormat ? props.dateFormat : 'h:mm A');
        } else {
          return '';
        }
    };

    const {
        label,
        source,
        resource,
        isRequired,
        meta,
        disablePast
    } = props;

    const {touched, error} = meta;

    let className = '';

    if (props.editShedule) {
        className = 'fieldsWidthExpand';
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
            <TimePicker
                margin="normal"
                id={"time_picker_" + source}
                label={
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                }
                value={props.val ? props.val : props.input.value}
                onChange={props.onChange ? value => props.onChange(value, 'Picker') : props.input.onChange}
                format={props.dateFormat ? props.dateFormat : 'h:mm A'}
                style={{marginBottom: 21}}
                error={!!(touched && error)}
                helperText={touched && error}
                disablePast={disablePast}
                labelFunc={renderLabel}
                className={className}
                fullWidth={!!props.fullWidth}
            />
        </MuiPickersUtilsProvider>
    );
}
export default addField(ScheduleTimePicker);
