import React, {Fragment} from 'react';
import {Create} from 'react-admin';
import {SemesterForm} from '..';
import {BackButton} from '../../../../common';


const SemesterCreate = props => {
    return (
        <Fragment>
            <BackButton />
            <Create {...props} title="Create Semester">
                <SemesterForm style={{marginTop: 0}} />
            </Create>
        </Fragment>
    );
};

export default SemesterCreate;
