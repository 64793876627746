import React, {useState, useEffect, Fragment} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useForm, useFormState} from "react-final-form";
import {Typography} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import {formatPhone} from '../../../../../helpers';


export const FamilyMembersList = props => {
    // eslint-disable-next-line
    const [selectedMember, setSelectedMember] = useState(parseInt(props.selectedFamilyMember));
    const form = useForm();
    const formState = useFormState();

    useEffect(() => {
        setSelectedMember(parseInt(formState.values.selectedFamilyMember));
    }, [formState.values.selectedFamilyMember]); // eslint-disable-next-line


    useEffect(() => {
        setSelectedMember(parseInt(props.selectedFamilyMember));
    }, [props.selectedFamilyMember]); // eslint-disable-next-line


    const onChange = event => {
        event.persist();

        form.change('selectedFamilyMember', parseInt(event.target.value));
        form.change('selectedFamilyMemberInfo', props.familyMembers.find(familyMember => familyMember.Id === parseInt(event.target.value)));
        setSelectedMember(parseInt(event.target.value));
        props.setSelectedFamilyMember(parseInt(event.target.value));
        props.setSelectedFamilyMemberInfo(props.familyMembers.find(familyMember => familyMember.Id === parseInt(event.target.value)));
    }

    const onEdit = id => {

        props.setIsEdit(true);
        props.setMemberToEdit(id);
        props.setShowDialog(true);

    }


    return props.familyMembers.length ? (
        <RadioGroup value={formState.values.selectedFamilyMember || ''} onChange={onChange} >
            {
                props.familyMembers.map(familyMember => {
                    return (
                        <Fragment key={familyMember.Id}>
                            <FormControlLabel
                                value={familyMember.Id}
                                control={<Radio color='primary' />}
                                label={
                                    familyMember.FirstName +
                                    " " +
                                    familyMember.LastName +
                                    (familyMember.Email ? ', ' + familyMember.Email : '') +
                                    (familyMember.CellPhone ? ', ' + formatPhone(familyMember.CellPhone) : '')
                                }
                            />
                            <div style={{marginTop: -15}}>
                                <Typography variant="caption" style={{margin: '0px 0px 0px 32px', color: '#9F9E9E'}}>
                                    {familyMember.Role}
                                </Typography>
                                <Button color='primary' onClick={() => onEdit(familyMember.Id)} value={familyMember.Id}>
                                    <Typography style={{textTransform: 'none', textDecoration: 'underline', fontSize: 14}}>Edit</Typography>
                                </Button>
                            </div>
                        </Fragment>
                    );
                })
            }
        </RadioGroup>
    ) : <Typography variant='h6'>No family information</Typography>;
};
