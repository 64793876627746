import React, {Fragment, useState, useEffect} from 'react';
import {
    required,
    email,
    Button,
    TextInput,
    BooleanInput,
    RadioButtonGroupInput,
    CREATE,
    UPDATE,
    FormDataConsumer,
    GET_ONE,
    FormWithRedirect
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {PhoneMaskedInput, DatePicker} from '../../../../../common';
import {fetchIt, getErrorMessage} from '../../../../../helpers';
import {NtaSaveButton} from '../../../../common';
import {Grid} from "@material-ui/core";
import {FamilyMembersSimpleList} from '../lists/FamilyMembersSimpleList';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import arrayMutators from 'final-form-arrays';
import ChangeEmailDialog from '../../containers/ChangeEmailDialog';



const FormActions = props => {
    const {basePath, isDisabled, showNotification, ...rest} = props;
    

    return (

        <FormDataConsumer>
        {
            ({formData, ...rest1}) =>{
                return ( 
                <DialogActions {...rest} className="EmailChangeButtons">
                    <NtaSaveButton label="Save" showNotification={showNotification} isDisabled={isDisabled} />
                </DialogActions>
                )
            }
        }
    </FormDataConsumer>
    );
};

const styles = theme => ({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const CreateNewStudentDialog = props => {
    const [record, setRecord] = useState(false);
    
    useEffect(() => {

        if(props.showDialog && props.isEdit) {
            fetchMemberData(props.memberToEdit);
        }

        if(props.showDialog && !props.isEdit) {
            setRecord({});
        }

        if(!props.showDialog){
            setRecord(false);
        }

    }, [props.showDialog]); // eslint-disable-line

    const fetchMemberData = familyMemberId => {
        const {fetchStart, fetchEnd, showNotification} = props;
        fetchStart();

        fetchIt(
            GET_ONE,
            'tenant_users',
            {id: familyMemberId}
        ).then(response => {
            switch (response.status) {
                case 200:

                    let data = Object.assign({}, response.data);
                    data.IsStudent = (data.Role !== 'Student');

                    setRecord(data);

                    break;

                case 400:

                    let errorMessage = 'There was a problem with creating a new family member.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).finally(() => {
            // Dispatch an action letting react-admin know a API call has ended
            fetchEnd();
        });
    }


    const handleClick = () => {
       props.setIsEdit(false);
       props.setShowDialog(true);
    };

    const handleCloseClick = () => {
        props.setShowDialog(false);
    };

    const handleSubmit = values => {
        const {fetchStart, fetchEnd, showNotification} = props;

        let data = Object.assign({}, values);


        if (data.CellPhone) {
            data.CellPhone = data.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        if (data.IsStudent) {
            data.IsStudent = !data.IsStudent;
        } else {
            data.IsStudent = true;
        }        

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        // As we want to know when the new post has been created in order to close the modal, we use the
        // dataProvider directly

        data.id = (props.isEdit ? props.memberToEdit : props.familyMembers[0].Id);
        const method = (props.isEdit ? UPDATE : CREATE);
        const resource = (props.isEdit ? 'tenant_users' : 'users_family_members');
        fetchIt(
            method,
            resource,
            {data}
        ).then(response => {
            switch (response.status) {
                case 200:

                    props.setShowDialog(false);

                    props.fetchFamilyMembers(response.data.Id);

                    let message = 'New Student is saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:

                    let errorMessage = 'There was a problem with creating a new family member.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).finally(() => {
            // Dispatch an action letting react-admin know a API call has ended
            fetchEnd();
        });
    };

    const SanitizedGrid = ({basePath, ...props}) => {
        return (
            <Grid {...props} />
        );
    };

    return (
        <Fragment>
            <Button
                label="New Student within this family"
                onClick={handleClick}
                style= {{marginBottom: 15, display: "-webkit-box", background: "white", float: 'right'}}
            />
            <Dialog
                open={props.showDialog}
                onClose={handleCloseClick}
                aria-label="Create New Family Member"
                maxWidth='xl'
            >
                <DialogTitle onClose={handleCloseClick} style={{paddingBottom: 0}}>
                    { !props.isEdit ?
                        ' Create New Family Member'
                    :
                        'Edit Family Member'
                    }   
                    
                </DialogTitle>

                <DialogContent style={{paddingTop: 0}}>
                {
                    record || !props.isEdit ?
                        <FormWithRedirect
                            {...props}
                            record={record}
                            resource='tenant_users'
                            save={handleSubmit}
                            mutators={{...arrayMutators}}
                            render={formData => (

                                <form
                                    style={{paddingLeft: 25, paddingRight: 25}}
                                >

                                    <SanitizedGrid container style={{width: '100%', paddingLeft: 0, paddingRight:0}} >
                                        <Grid item xs={6} style={{paddingTop: 0, paddingBottom:0}}>
                                            <TextInput
                                                label='First Name'
                                                className='fieldsWidthExpand'
                                                source='FirstName'
                                                resource='tenant_users'
                                                validate={required('The First Name field is required')}
                                            />

                                            <TextInput
                                                label='Last Name'
                                                className='fieldsWidthExpand'
                                                source='LastName'
                                                resource='tenant_users'
                                                validate={required('The Last Name field is required')}
                                            />
                                            <FormDataConsumer>
                                                {
                                                    ({formData, ...rest}) =>{
                                                        return (<DatePicker
                                                            source='BirthDate'
                                                            resource='tenant_users'
                                                            validate={formData.IsStudent ? null : required('The Birth Date field is required')}
                                                        />)
                                                    }
                                                }
                                            </FormDataConsumer>

                                            { props.isEdit ?

                                                <FormDataConsumer>
                                                {
                                                    ({formData, ...rest}) =>
                                                        <TextInput
                                                            required = {formData.IsStudent ? true : false}  
                                                            source='Email'
                                                            resource='tenant_users'
                                                            className='fieldsWidthExpand'
                                                            inputProps={{ readOnly: true }}
                                                            style={{backgroundColor: "#f5f5f5", borderRadius: 4}}
                                                            validate={email('The Email field is not a valid e-mail address.')}
                                                        />
                                                }
                                                </FormDataConsumer>
                                            :
                                                <FormDataConsumer>
                                                {
                                                    ({formData, ...rest}) =>
                                                        <TextInput
                                                            required = {formData.IsStudent ? true : false}
                                                            source='Email'
                                                            resource='tenant_users'
                                                            className='fieldsWidthExpand'
                                                            inputProps={{readOnly: false}}
                                                            validate={email('The Email field is not a valid e-mail address.')}
                                                        />
                                                }
                                                </FormDataConsumer>
                                            }

                                            { props.isEdit ?
                                                <ChangeEmailDialog
                                                    fetchMemberData={fetchMemberData}
                                                    memberToEdit={props.memberToEdit}
                                                />
                                            : null
                                            }

                                            <FormDataConsumer>
                                                {
                                                    ({formData, ...rest}) =>
                                                        <PhoneMaskedInput
                                                            required = {formData.IsStudent ? true : false}
                                                            source='CellPhone'
                                                            resource='tenant_users'
                                                            label='Mobile Phone'
                                                            className='fieldsWidthExpand'
                                                        />
                                                }
                                            </FormDataConsumer>

                                            { !props.isEdit ?
                                                <BooleanInput source='IsStudent' resource='tenant_users' label='Parent / Guardian' />
                                            : null
                                            }
                                        </Grid>

                                        <Grid item xs={6} style={{paddingTop: 16, paddingBottom:0, paddingLeft: '3%'}}>
                                            <FamilyMembersSimpleList familyMembers={props.familyMembers} />
                                            <hr style={{width: '100%',  marginBottom: 5, opacity: '0.5'}}/>
                                            <RadioButtonGroupInput
                                                source='Gender'
                                                resource='tenant_users'
                                                label=''
                                                row={false}
                                                choices={[
                                                    {id: 'Male', name: 'Male'},
                                                    {id: 'Female', name: 'Female'},
                                                ]}
                                            />

                                        </Grid>

                                        <FormActions
                                            style={{backgroundColor: '#f5f5f5', justifyContent: 'flex-start', width: '100%', marginLeft: -25 }}
                                            showNotification={props.showNotification}
                                        />

                                    </SanitizedGrid>
                                </form>
                            )}
                        />
                    : <div style={{width: 400, height: 500}}> </div>
                }
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

export default CreateNewStudentDialog;
