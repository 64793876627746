import {connect} from 'react-redux';
import ChangeEmailDialog from '../components/dialogs/ChangeEmailDialog';
import {
    fetchEnd,
    fetchStart,
    showNotification
} from 'react-admin';


const mapDispatchToProps = {
    fetchEnd,
    fetchStart,
    showNotification
};


export default connect(null, mapDispatchToProps)(
    ChangeEmailDialog
);
