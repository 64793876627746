export const hostname = window && window.location && window.location.hostname;
let backendUrl = 'https://apilmsdev.azurewebsites.net/';
let frontendUrl = 'localhost:3000';

switch(hostname) {
    case 'localhost':
        backendUrl = 'https://apilmsdev.azurewebsites.net/';

        break;

    default:
        frontendUrl = 'lms.reconart.com';
}


export const FRONTEND_URL = frontendUrl;
export const BACKEND_URL = backendUrl;
export const ENDPOINTS = {
    login: {
        post: {
            url: 'connect/token',
            type: 'POST',
            params: {
                grant_type: "password",
                scope: "admin_access offline_access",
                client_id: "admin.js.client"
            },
            passErrors: true,
            usesUrlEncodedParams: true
        }
    },

    REFRESH: {
        url: 'connect/token',
        params: {
            grant_type: "refresh_token",
            scope: "admin_access offline_access",
            client_id: "admin.js.client"
        }
    },

    logout: {
        post: {
            url: 'connect/revocation',
            type: 'POST',
            params: {
                token_type_hint: "refresh_token",
                client_id: "admin.js.client"
            },
            usesUrlEncodedParams: true
        }
    },

    users_activate: {
        post: {
            url: 'users/activate',
            type: 'POST',
            passErrors: true
        }
    },

    forgotten_password: {
        post: {
            url: 'schools/forgotten-password',
            type: 'POST',
            passErrors: true
        }
    },

    change_forgotten_password: {
        post: {
            url: 'schools/change-forgotten-password',
            type: 'POST',
            passErrors: true
        }
    },

    account: {
        GET_ONE: {
            isSecure: true,
            url: 'users/account',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'users/account',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    change_account_email: {
        UPDATE: {
            isSecure: true,
            url: 'schools/request-email-change',
            type: 'POST',
            passErrors: true
        },
    },


    teacher_account: {
        GET_ONE: {
            isSecure: true,
            url: 'schools/teachers/account/details',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/teachers/account/details',
            type: 'PUT',
            passErrors: true
        }
    },

    change_password: {
        UPDATE: {
            isSecure: true,
            url: 'schools/teachers/change-password',
            type: 'POST',
            passErrors: true
        }
    },

    notifications: {
        GET_LIST: {
            isSecure: true,
            url: 'notifications',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'notifications',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'notifications',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'notifications',
            type: 'POST',
            passErrors: true
        }
    },

    home_address: {
        GET_LIST: {
            isSecure: true,
            url: 'users/address',
            type: 'GET',
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'users/address',
            type: 'GET',
            passErrors: true
        },
        GET_ONE: {
            isSecure: true,
            url: 'users/address',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'users/address',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },


    notifications_locations: {
        GET_LIST: {
            isSecure: true,
            url: 'notifications/locations',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'notifications/locations',
            type: 'GET',
        }
    },

    notifications_sessions: {
        GET_LIST: {
            isSecure: true,
            url: 'notifications/sessions',
            type: 'GET',
            mapFiltersToParams: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'notifications/sessions',
            type: 'GET'
        }
    },

    semesters: {
        GET_LIST: {
            isSecure: true,
            url: 'schedule/semesters',
            type: 'GET'
        },
        GET_MANY: {
            isSecure: true,
            url: 'schedule/semesters',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'schedule/semesters',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schedule/semesters',
            type: 'POST',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schedule/semesters',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schedule/semesters',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        }
    },

    notifications_schedules: {
        GET_LIST: {
            isSecure: true,
            url: 'notifications/schedules',
            type: 'GET',
            mapFiltersToParams: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'notifications/schedules',
            type: 'GET',
            mapFiltersToParams: true
        }
    },

    notifications_created_by: {
        GET_LIST: {
            isSecure: true,
            url: 'notifications/sent-by',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'notifications/sent-by',
            type: 'GET',
        }
    },

    register: {
        CREATE: {
            url: 'tenants',
            type: 'POST',
            passErrors: true
        }
    },

    schools: {
        GET_LIST: {
            isSecure: true,
            url: 'schools',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    locations: {
        GET_LIST: {
            isSecure: true,
            url: 'schools/locations',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools/locations',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'schools/locations',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/locations',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schools/locations',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schools/locations',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
        DELETE_MANY: {
            isSecure: true,
            url: 'schools/locations',
            type: 'DELETE',
            passErrors: true
        }
    },

    common_settings: {
        GET_ONE: {
            isSecure: true,
            url: 'schools/common-settings',
            type: 'GET',
            passErrors: true
        },
    },

    locations_working_time: {
        GET_ONE: {
            isSecure: true,
            url: 'schools/locations/working-time',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/locations/working-time',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    locations_address: {
        GET_ONE: {
            isSecure: true,
            url: 'schools/locations/address',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/locations/address',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    tenant_users: {
        GET_LIST: {
            isSecure: true,
            url: 'schools/tenant-users',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools/tenant-users',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'schools/tenant-users',
            type: 'GET'
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/tenant-users',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schools/tenant-users',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schools/tenant-users',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
        "SEND_ACTIVATION_EMAIL": {
            isSecure: true,
            url: 'schools/tenant-users/resend-activation-email',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        "RESET_PASSWORD": {
            isSecure: true,
            url: 'schools/tenant-users/change-forgotten-password',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        "CHANGE_ACTIVE_STATUS": {
            isSecure: true,
            url: 'schools/tenant-users/change-active-status',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        "CHANGE_NOTIFICATIONS_STATUS": {
            isSecure: true,
            url: 'schools/tenant-users/change-notifications-status',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
    },

    tenant_users_address: {
        GET_ONE: {
            isSecure: true,
            url: 'schools/tenant-users/address',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/tenant-users/address',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    tenant_users_email: {
        UPDATE: {
            isSecure: true,
            url: 'schools/tenant-users/email',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    users_family_members: {
        GET_LIST: {
            isSecure: true,
            url: 'schools/tenant-users/family',
            type: 'GET',
            needsId: true,
            hasParams: true,
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools/tenant-users/family',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        GET_MANY_REFERENCE: {
            isSecure: true,
            url: 'schools/tenant-users/family',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/tenant-users/family',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schools/tenant-users/family-member',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
    },

    users_emergency_contact: {
        GET_ONE: {
            isSecure: true,
            url: 'schools/tenant-users/emergency-contact',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/tenant-users/emergency-contact',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    calendar: {
        GET_LIST: {
            isSecure: true,
            url: 'schools/calendar',
            type: 'GET',
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'schools/calendar',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools/calendar',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/calendar',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schools/calendar',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schools/calendar',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        }
    },

    calendar_teachers: {
        UPDATE: {
            isSecure: true,
            url: 'schools/calendar/event/assign-teachers',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    calendar_common: {
        UPDATE: {
            isSecure: true,
            url: 'schools/calendar/common',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    courses: {
        GET_LIST: {
            isSecure: true,
            url: 'schedule/courses',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'schedule/courses',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'schedule/courses',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schedule/courses',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schedule/courses',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schedule/courses',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
        DELETE_MANY: {
            isSecure: true,
            url: 'schedule/courses',
            type: 'DELETE',
            passErrors: true
        }
    },

    studios: {
        GET_LIST: {
            isSecure: true,
            url: 'schools/studios',
            type: 'GET',
        },
        GET_MANY: {
            isSecure: true,
            url: 'schools/studios',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools/studios',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/studios',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schools/studios',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schools/studios',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
        DELETE_MANY: {
            isSecure: true,
            url: 'schools/studios',
            type: 'DELETE',
            passErrors: true
        }
    },


    course_image: {
        GET_ONE: {
            isSecure: true,
            url: 'schedule/courses/image',
            type: 'GET',
            needsId: true,
            passErrors: true,
            returnsRawData: true,
            options: {responseType: 'blob'}
        },
        CREATE: {
            isSecure: true,
            url: 'schedule/courses/image',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schedule/courses/image',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        }
    },

    teachers: {
        GET_LIST: {
            isSecure: true,
            url: 'schools/teachers',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools/teachers',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        GET_MANY: {
            isSecure: true,
            url: 'schools/teachers',
            type: 'GET',
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/teachers',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schools/teachers',
            type: 'POST',
            passErrors: true
        },
        "SEND": {
            isSecure: true,
            url: 'schools/teachers/resend-invitation',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        "CHANGE_ACTIVE_STATUS": {
            isSecure: true,
            url: 'schools/staff/change-active-status',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
    },

    teachers_assignments: {
        GET_LIST: {
            isSecure: true,
            url: 'schools/teachers/assignments',
            type: 'GET',
            needsId: true
        },
    },

    school_years: {
        GET_LIST: {
            isSecure: true,
            url: 'schedule/school-years',
            type: 'GET'
        },
        GET_MANY: {
            isSecure: true,
            url: 'schedule/school-years',
            type: 'GET',
        }
    },

    holidays: {
        GET_LIST: {
            isSecure: true,
            url: 'schools/holidays',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools/holidays',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/holidays',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schools/holidays',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schools/holidays',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        }
    },

    staff: {
        GET_LIST: {
            isSecure: true,
            url: 'schools/staff',
            type: 'GET',
        },
        GET_ONE: {
            isSecure: true,
            url: 'schools/staff',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schools/staff',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schools/staff',
            type: 'POST',
            passErrors: true
        },
        "CHANGE_ACTIVE_STATUS": {
            isSecure: true,
            url: 'schools/staff/change-active-status',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
        "SEND": {
            isSecure: true,
            url: 'schools/staff/resend-invitation',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
    },

    fees: {
        GET_LIST: {
            isSecure: true,
            url: 'schedule/fees',
            type: 'GET'
        },
        GET_MANY: {
            isSecure: true,
            url: 'schedule/fees',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'schedule/fees',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schedule/fees',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schedule/fees',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schedule/fees',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        }
    },

    schedule_move: {
        GET_ONE: {
            isSecure: true,
            url: 'schedule/day/move',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schedule/day/move',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    schedule_duration: {
        GET_ONE: {
            isSecure: true,
            url: 'schedule/day/change-duration',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schedule/day/change-duration',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    schedule_places: {
        UPDATE: {
            isSecure: true,
            url: 'schedule/change-places',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    schedule_teachers: {
        GET_ONE: {
            isSecure: true,
            url: 'schedule/day/assign-teachers',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        UPDATE: {
            isSecure: true,
            url: 'schedule/day/assign-teachers',
            type: 'PUT',
            needsId: true,
            passErrors: true
        },
    },

    schedule_price: {
        UPDATE: {
            isSecure: true,
            url: 'schedule/change-price',
            type: 'PUT',
            needsId: true,
            passErrors: true
        }
    },

    schedule_change_status: {
        CREATE: {
            isSecure: true,
            url: 'schedule/change-active-status',
            type: 'POST',
            needsId: true,
            passErrors: true
        },
    },

    schedule_day_of_week: {
        DELETE: {
            isSecure: true,
            url: 'schedule/day',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
    },

    price_and_preview: {
        GET_LIST: {
            isSecure: true,
            url: 'schedule/price-and-review',
            type: 'POST',
            passErrors: true
        },
    },

    overlapping_validation: {
        GET_LIST: {
            isSecure: true,
            url: 'schedule/overlapping-validation',
            type: 'POST',
            passErrors: true
        },
    },

    schedules: {
        GET_LIST: {
            isSecure: true,
            url: 'schedule/weekly',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'schedule/weekly',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
        CREATE: {
            isSecure: true,
            url: 'schedule',
            type: 'POST',
            passErrors: true
        },
        DELETE: {
            isSecure: true,
            url: 'schedule',
            type: 'DELETE',
            needsId: true,
            passErrors: true
        },
    },

    // Registrations
    registrations: {
        GET_LIST: {
            isSecure: true,
            url: 'registrations',
            type: 'GET'
        },
        GET_MANY: {
            isSecure: true,
            url: 'registrations',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'registrations',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
    },

    schedule_day_works: {
        GET_LIST: {
            isSecure: true,
            url: 'registrations/schedules/dates',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
    },

    registrations_submit: {
        CREATE: {
            isSecure: true,
            url: 'registrations/submit',
            type: 'POST',
            passErrors: true
        },
    },

    registrations_schedules: {
        GET_LIST: {
            isSecure: true,
            url: 'registrations/schedules',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'registrations/schedules',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
    },

    // Registrations students
    registrations_students: {
        GET_LIST: {
            isSecure: true,
            url: 'registrations/students',
            type: 'GET'
        },
        GET_MANY: {
            isSecure: true,
            url: 'registrations/students',
            type: 'GET'
        },
        GET_ONE: {
            isSecure: true,
            url: 'registrations/students',
            type: 'GET',
            needsId: true,
            passErrors: true
        },
    },

    registration_payees_cards: {
        GET_LIST: {
            isSecure: true,
            url: 'registrations/payees/cards',
            type: 'GET',
            needsId: true,
        }
    },

    registration_payees_list: {
        GET_LIST: {
            isSecure: true,
            url: 'registrations/payees',
            type: 'GET',
            needsId: true,
        }
    },

    registration_family_members_list: {
        GET_LIST: {
            isSecure: true,
            url: 'registrations/family',
            type: 'GET',
            needsId: true,
        }
    },

    stripe_key: {
        GET_ONE: {
            isSecure: true,
            url: 'registrations/stripe-publishable-key',
            type: 'GET',
        }
    },


    registration_price: {
        CREATE: {
            isSecure: true,
            url: 'registrations/price',
            type: 'POST',
            passErrors: true
        }
    },

    registration_cancel: {
        CREATE: {
            isSecure: true,
            url: 'registrations/cancel',
            type: 'POST',
            needsId: true,
            passErrors: true,
        }
    },

    registration_cancel_schedule_student: {
        CREATE: {
            isSecure: true,
            url: 'registrations/cancel/schedule-student',
            type: 'POST',
            needsId: true,
            passErrors: true,
        }
    },

    registration_resend_payment_link: {
        CREATE: {
            isSecure: true,
            url: 'registrations/resend-payment',
            type: 'POST',
            needsId: true,
            passErrors: true,
        }
    },

    publish_semester: {
        UPDATE: {
            isSecure: true,
            url: 'schedule/publish',
            type: 'POST',
            needsId: true,
            passErrors: true
        }
    },

    copy_schedule: {
        CREATE: {
            isSecure: true,
            url: 'schedule/copy-schedule',
            type: 'POST',
            passErrors: true
        }
    },

    copy_teachers: {
        CREATE: {
            isSecure: true,
            url: 'schedule/copy-teachers',
            type: 'POST',
            passErrors: true
        }
    },
};
