import React, {Fragment, Component} from 'react';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {DeleteButton} from '../../../../common';
import {
    EditButton,
    ShowButton
} from 'react-admin';
import ChangeNotificationsStatusButton  from '../containers/ChangeNotificationsStatusButton';
import ChangeActiveStatusButton  from '../containers/ChangeActiveStatusButton';


class UserSplitButton extends Component {
    _isMounted = false;
    anchorRef = null;
    options = [];

    constructor(props) {
        super(props);

        this.anchorRef = React.createRef();
    }

    state = {
        open: false,
        selectedIndex: 0,
        record: {}
    };

    componentDidMount() {
        this._isMounted = true;

        this.setState({
            record: this.props.record
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.record && this._isMounted) {
            this.setState({
                record: nextProps.record
            });
        }
    }

    showTenantUserStatusButtons = record => {
        return record && record.IsActive ?
            <ChangeActiveStatusButton label="Deactivate user" record={record} />
        :
            <Fragment>
                <DeleteButton
                    record={record}
                    resource='tenant_users'
                    successMessage='User deleted'
                    errorMessage='User NOT deleted'
                    confirmTitle='Delete the user'
                    confirmContent='Are you sure you want to delete the user?'
                />
                <ChangeActiveStatusButton label="Activate User" record={record} />
            </Fragment>;
    }

    showTenantUserNotificationsStatusButton = record => {
        return <ChangeNotificationsStatusButton label={record.AreNotificationsEnabled ? "Disable Notifications" : "Enable Notifications"} record={record} />;
    }

    showActionsButtons = () => {
        switch (this.options[this.state.selectedIndex]) {
            case "Edit":
                return <EditButton
                            variant="contained"
                            style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                minHeight: 32,
                                padding: '4px 8px'
                            }}
                            record={this.props.record}
                            basePath={this.props.basePath}
                        />;

            case "Delete":
                return (
                    <DeleteButton
                        record={this.props.record}
                        basePath={this.props.basePath}
                        resource='tenant_users'
                        successMessage='User deleted'
                        errorMessage='User NOT deleted'
                        confirmTitle='Delete the user'
                        confirmContent='Are you sure you want to delete the user?'
                        variant="contained"
                        style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            minHeight: 32,
                            padding: '4px 8px'
                        }}
                    />
                );

            case "Deactivate User":
                return <ChangeActiveStatusButton label="Deactivate User" record={this.props.record} />;

            case "Activate User":
                return <ChangeActiveStatusButton label="Activate User" record={this.props.record} />;

            case "Disable Notifications":
                return <ChangeNotificationsStatusButton label="Disable Notifications" record={this.props.record} />;

            case "Enable Notifications":
                return <ChangeNotificationsStatusButton label="Enable Notifications" record={this.props.record} />;

            default:
                return <ShowButton
                            label="Details"
                            variant="contained"
                            style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                minHeight: 32,
                                padding: '4px 8px'
                            }}
                            record={this.props.record}
                            basePath={this.props.basePath}
                        />;
        }
    }

    handleMenuItemClick = (event, index) => {
        this.setState({
            open: false,
            selectedIndex: index
        });
    };

    handleToggle = (event, index) => {
        this.setState({ open: !this.state.open });
    };

    handleClose = (event, index) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({ open: false});
    };

    render() {
        this.options = ['Details'];

        if (this.state.record && !this.state.record.IsDeleted) {
            this.options.push("Edit", "Delete");

            // if (this.state.record && this.state.record.IsActive) {
            //     this.options.push("Deactivate User");
            // } else {
            //     this.options.push("Delete");
            //     this.options.push("Activate User");
            // }


            if (this.state.record && this.state.record.AreNotificationsEnabled) {
                this.options.push("Disable Notifications");
            } else {
                this.options.push("Enable Notifications");
            }
        }

        return (
            <Fragment>
                <div ref={this.anchorRef}>
                    {this.showActionsButtons()}

                    <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        aria-owns={this.state.open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleToggle}
                        style={{minWidth: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </div>
                <Popper
                    open={this.state.open}
                    anchorEl={this.anchorRef.current}
                    transition
                    placement='bottom-start'
                    style={{zIndex: 999}}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper id="menu-list-grow">
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        {this.options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                // disabled={index === 2}
                                                selected={index === this.state.selectedIndex}
                                                onClick={event => this.handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Fragment>
        );
    }
}

export default UserSplitButton;
