import React, {Fragment, useState, useEffect} from 'react';
import {GET_ONE, Title} from 'react-admin';
import MyAccountForm from '../containers/MyAccountForm';
import MyAccountTeacherForm from '../containers/MyAccountTeacherForm';
import {fetchIt, formatPhone} from "../../../helpers";
import moment from "moment";
import {BackButton} from '../../../common';


const isTeacher = localStorage.getItem('account') ? (JSON.parse(localStorage.getItem('account')).Role === 'Teacher') : false;

const MyAccountEdit = (props) => {
    const [record, setRecord] = useState({Address: {}})

    const fetchTheData = () => {
        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_ONE,
            isTeacher ? 'teacher_account' : 'account',
            {id: props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    response.data.HomePhone = formatPhone(response.data.HomePhone);
                    response.data.CellPhone = formatPhone(response.data.CellPhone);

                    if (isTeacher) {
                        response.data.WorkPhone = formatPhone(response.data.WorkPhone);
                        setRecord(...response.data);
                    } else {
                        response.data.BirthDate = response.data.BirthDate ? moment.utc(response.data.BirthDate, 'MM/DD/YYYY', true) : null;

                        let record = response.data;
                        fetchIt(
                            GET_ONE,
                            'home_address',
                            {id: record.Id}
                        ).then(addressResponse => {
                            if (addressResponse.status === 200) {
                                addressResponse.data.HomePhone = formatPhone(addressResponse.data.HomePhone);

                                record.Address = addressResponse.data;
                            }
                        }).catch(error => {

                        }).then(() => {
                            setRecord(record);

                            // Dispatch an action letting react-admin know an API call has ended
                            fetchEnd();
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }

    useEffect(() => {
       fetchTheData();
    }, [])

    return (
        <Fragment>
            <BackButton />
            <Title title='Edit My Account Data' />
            {
                isTeacher ?
                    <MyAccountTeacherForm record={record} {...props} />
                :
                    <MyAccountForm record={record} {...props} />
            }
        </Fragment>
    );
}

export default MyAccountEdit;
