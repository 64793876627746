import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import moment from 'moment';



const OverlappedSchedulesList = props => {
    
    const createData = (id, name, dayOfWeek, startHour, Studio) => {
        return {id, name, dayOfWeek, startHour, Studio};
    }

    const rows = [];   
    let startHour = '';
    for (let t = 0; t < props.overlappedSchedules.length; t++) { 

        startHour = moment((props.overlappedSchedules[t].StartHour + ':' + props.overlappedSchedules[t].StartMinutes), 'H:m').format('h:mm A');
        rows.push(createData(
            props.overlappedSchedules[t].Id, 
            props.overlappedSchedules[t].Course, 
            props.overlappedSchedules[t].DayOfWeek,
            startHour,
            props.overlappedSchedules[t].StudioName
        ));
    }

    return props.overlappedSchedules.length ? (
        <TableContainer style={{marginBottom: 25}}>
            <Table className='overlapedSchedulesTable' size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Course Name</TableCell>
                        <TableCell>Day of Week</TableCell>
                        <TableCell>Starting time</TableCell>
                        <TableCell>Studio</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (                  
                        <TableRow key={row.id}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.dayOfWeek}</TableCell>
                            <TableCell>{row.startHour}</TableCell>
                            <TableCell>{row.Studio}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>     
    ) : null;
}

export default OverlappedSchedulesList;

