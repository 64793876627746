import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    SelectField
} from 'react-admin';
import {SemesterListFilters} from '../';
import SplitButton from '../../../../common/containers/SplitButton';
import {RegistrationViewChoices} from "../../../../config/constants";


export const SemestersList = props => (
    <div className="wrapperContent">
        <List filters={<SemesterListFilters />} perPage={25} {...props} title='Semesters List' sort={{field: 'id', order: 'DESC'}} bulkActionButtons={false}>
            <Datagrid>
                <TextField source="Name" label="Name"/>
                <TextField source="FromDate" label="From Date" />
                <TextField source="ToDate" label="To Date" />
                <TextField source="SchoolYear" />
                <SelectField source='RegistrationView' choices={RegistrationViewChoices} />
                <FunctionField
                    label='Status'
                    source='IsActive'
                    cellClassName='singleButtonRight'
                    render={record => record.IsActive ? 'Active' : 'Inactive'}
                />
                <SplitButton
                    cellClassName='splitButtonTdSmall'
                    successMessage='Semester deleted'
                    errorMessage='Semester NOT deleted'
                    confirmTitle='Delete the semester'
                    confirmContent='Are you sure you want to delete the semester?'
                    smallTd
                />
            </Datagrid>
        </List>
    </div>
);
