import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    Responsive,
    MenuItemLink,
    DashboardMenuItem,
    getResources,
    GET_LIST,
    showNotification,
    fetchStart,
    fetchEnd,
} from 'react-admin';
import DefaultIcon from '@material-ui/icons/Description';
import classnames from 'classnames';
import compose from 'recompose/compose';
import {createStyles, withStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {fetchIt, inArray} from "../helpers";
import StaffIcon from '@material-ui/icons/People';
import authProvider from '../authProvider';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import StudioIcon from '@material-ui/icons/Domain';
import LocationIcon from '@material-ui/icons/LocationOn';
import {push as pushAction} from 'connected-react-router';
import {approvedRoles} from '../config/constants';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SchoolIcon from '@material-ui/icons/School';
import Teachers from '@material-ui/icons/People';
import HolidayIcon from '@material-ui/icons/WbSunny';
import CoursesIcon from "@material-ui/icons/Class";
import {getUserName} from "../helpers";
import AddIcon from '@material-ui/icons/Add';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';


const useStyles = theme => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    subItem: {
        paddingLeft: 24
    },
    open: {
        width: 215,
    },
    closed: {
        width: 55,
    },
});


class Menu extends Component {
    _isMounted = false;

    constructor (props) {
        super(props);

        this.state = {
            schoolId: null,
            permissions: 'none',
            locationsOpen: false,
            coursesMenuOpen: false,
            registrationsOpen: false,
            userName: '',
            userId: 1
        };
    }


    handleClick = (e, name) => {
        if (this._isMounted) {
            const isOpen = !this.state[name + 'Open'];
            this.setState({[name + 'Open']: isOpen});
        }
    };


    componentDidMount() {
        this._isMounted = true;
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        authProvider.getPermissions().then(permissions => {
            if (me._isMounted) {
                me.setState({
                    permissions
                });
            }


            getUserName().then(userName => {
                let userId = 1;
                if (localStorage.getItem('account')) {
                    const account = JSON.parse(localStorage.getItem('account'));

                    userId = account.Id;
                }

                if (this._isMounted) {
                    this.setState({userName, userId});
                }
            }).catch(error => {

            });


            if (!this.state.schoolId) {
                if (localStorage.getItem('school') !== null) {
                    this.setState({
                        schoolId: JSON.parse(localStorage.getItem('school')).Id
                    });
                } else {
                    // Dispatch an action letting react-admin know an API call is ongoing
                    fetchStart();

                    fetchIt(
                        GET_LIST,
                        'schools'
                    ).then(response => {
                        switch (response.status) {
                            case 200:
                                if (response.data && (response.data.length > 0)) {
                                    localStorage.setItem('school', JSON.stringify(response.data[0]));

                                    if (me._isMounted) {
                                        me.setState({
                                            schoolId: response.data[0].Id
                                        });
                                    }
                                }

                                break;

                            case 401:
                            case 403:
                                localStorage.setItem('school', JSON.stringify({Id: 0}));
                                if (me._isMounted) {
                                    me.setState({
                                        schoolId: 0
                                    });
                                }

                                break;

                            default:
                                showNotification(
                                    'direct_message',
                                    'warning',
                                    { messageArgs: { _: 'Oops, something went wrong!' } }
                                );
                        }
                    }).catch(error => {

                    }).then(() => {
                        // Dispatch an action letting react-admin know an API call has ended
                        fetchEnd();
                    });
                }
            }
        }).catch(error => {
            this.props.push('/login');
        });
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render () {
        const {
            classes,
            className,
            dense,
            hasDashboard,
            onMenuClick,
            open,
            pathname,
            resources,
            logout,
            fetchStart,
            fetchEnd,
            showNotification,
            push,
            ...rest
        } = this.props;


        return inArray(this.state.permissions, approvedRoles) || 1 ? (
            <div className={classnames(classes.root, className, {
                [classes.open]: open,
                [classes.closed]: !open,
            })} {...rest}>
                {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
                {resources
                .filter(r => r.hasList || r.hasEdit)
                .map(resource => (
                    (() => {
                        switch(resource.name) {
                            case 'account':
                                return null;

                            case 'change_password':
                                return null;

                            case 'settings':
                                return null;

                            case 'registrations':
                                return this.state.schoolId ? (
                                    <Fragment key={resource.name}>
                                        <ListItem
                                            button
                                            onClick={e => this.handleClick(e, 'registrations')}
                                            className='labelMenu'
                                            style={{paddingLeft: 15, paddingTop: 0, paddingBottom: 0}}
                                        >
                                            {this.state.registrationsOpen ? <ExpandLess /> : <ExpandMore/>}
                                            <ListItemText
                                                primary="Registrations"
                                            />
                                        </ListItem>

                                        <Collapse
                                            in={this.state.registrationsOpen}
                                            timeout="auto"
                                            unmountOnExit
                                            style={{paddingLeft: 0, backgroundColor: "#efefef", borderBottom: '1px solid #bfbfbf'}}
                                        >
                                            <MenuItemLink
                                                to='/registrations/create'
                                                primaryText='New Registration'
                                                leftIcon={
                                                    <AddIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                                className={classes.subItem}
                                            />

                                            <MenuItemLink
                                                to='/registrations'
                                                primaryText='Registered students'
                                                leftIcon={
                                                    <SubscriptionsIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                                className={classes.subItem}
                                            />
                                        </Collapse>
                                    </Fragment>
                                ) : null;

                            case 'staff':
                                return null;

                            case 'fees':
                                return null;

                            case 'semesters':
                                return null;

                            case 'schools':
                                return null;

                            case 'locations':
                                return this.state.schoolId ? (
                                    <Fragment key={resource.name}>
                                        <ListItem
                                            button
                                            onClick={e => this.handleClick(e, 'locations')}
                                            className='labelMenu'
                                            style={{paddingLeft: 15, paddingTop: 0, paddingBottom: 0, whiteSpace: 'nowrap'}}
                                        >
                                            {this.state.locationsOpen ? <ExpandLess /> : <ExpandMore/>}
                                            <ListItemText
                                                primary="System Preferences"
                                            />
                                        </ListItem>

                                        <Collapse
                                            in={this.state.locationsOpen}
                                            timeout="auto"
                                            unmountOnExit
                                            style={{paddingLeft: 0, backgroundColor: "#efefef", borderBottom: '1px solid #bfbfbf'}}
                                        >
                                            <MenuItemLink
                                                key={resource.name}
                                                to={`/schools/${this.state.schoolId}/show`}
                                                primaryText='Business Profile'
                                                leftIcon={
                                                    <SchoolIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                                className={classes.subItem}
                                            />

                                            <MenuItemLink
                                                to={`/locations`}
                                                primaryText='Locations'
                                                leftIcon={
                                                    <LocationIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                                className={classes.subItem}
                                            />

                                            <MenuItemLink
                                                to={`/studios`}
                                                primaryText='Studios'
                                                leftIcon={
                                                    <StudioIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                                className={classes.subItem}
                                            />

                                            <MenuItemLink
                                                to={`/teachers`}
                                                primaryText='Teachers'
                                                leftIcon={
                                                    <Teachers />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                                className={classes.subItem}
                                            />
                                            {
                                                this.state.permissions === 'School Manager' ?
                                                    (
                                                        <MenuItemLink
                                                            to='/staff'
                                                            primaryText='Staff'
                                                            leftIcon={
                                                                <StaffIcon />
                                                            }
                                                            onClick={onMenuClick}
                                                            dense={dense}
                                                            className={classes.subItem}
                                                        />
                                                    ) : null
                                            }

                                            <MenuItemLink
                                                to='/semesters'
                                                primaryText='Semesters'
                                                leftIcon={
                                                    <ScheduleIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                                className={classes.subItem}
                                            />

                                            <MenuItemLink
                                                to='/courses'
                                                primaryText='Courses'
                                                leftIcon={
                                                    <CoursesIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                                className={classes.subItem}
                                            />

                                            <MenuItemLink
                                                to='/holidays'
                                                primaryText='Holidays'
                                                leftIcon={
                                                    <HolidayIcon />
                                                }
                                                onClick={onMenuClick}
                                                dense={dense}
                                                className={classes.subItem}
                                            />
                                        </Collapse>
                                    </Fragment>
                                ) : null;

                            case 'studios':
                                return null;
                            case 'teachers':
                                return null;
                            case 'holidays':
                                return null;
                            case 'courses':
                                return null;

                            default:
                                return  (
                                    <MenuItemLink
                                        key={resource.name}
                                        to={`/${resource.name}`}
                                        primaryText={resource.options.label ? resource.options.label : resource.name}
                                        leftIcon={
                                            resource.icon ? <resource.icon /> : <DefaultIcon />
                                        }
                                        onClick={onMenuClick}
                                        dense={dense}
                                    />
                                );
                        }
                    })()
                ))}

                <Responsive xsmall={logout} medium={null} />
            </div>
        ) : null;
    }
}


Menu.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
    logout: PropTypes.element,
    onMenuClick: PropTypes.func,
    open: PropTypes.bool,
    pathname: PropTypes.string,
    resources: PropTypes.array.isRequired,
};

Menu.defaultProps = {
    onMenuClick: () => null,
};

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    resources: getResources(state),
    pathname: state.router.location.pathname, // used to force redraw on navigation
});

const mapDispatchToProps = {
    showNotification,
    fetchStart,
    fetchEnd,
    push: pushAction
};

const enhance = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps, // Avoid connect passing dispatch in props,
        null,
        {
            areStatePropsEqual: (prev, next) =>
                prev.resources.every(
                    (value, index) => value === next.resources[index] // shallow compare resources
                ) &&
                prev.pathname === next.pathname &&
                prev.open === next.open,
        }
    ),
    withStyles(useStyles)
);

export default enhance(Menu);
