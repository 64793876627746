import React, {useState, useEffect} from 'react';
import {
    UPDATE,
    CREATE,
    required,
    TextInput,
    Toolbar,
    SimpleForm,
    GET_ONE
} from 'react-admin';
import {fetchIt} from "../../../helpers/fetcher";
import {getErrorMessage} from "../../../helpers";
import {ZipCodeMaskedInput, UsStates} from '../../../common';
import {zipCodeValidation} from '../../../validators';
import {NtaSaveButton} from '../../common';


export const LocationFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} label={props.label}/>
        </Toolbar>
    );
};

export const LocationFormTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};

const LocationContactForm = props => {

    const [record, setRecord] = useState({});

    useEffect(() => {

        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            GET_ONE,
            'locations_address',
            {id: props.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let record = Object.assign({}, response.data);
            
                    setRecord(record);

                    break;

                case 404:
                    let errorMessage = '';
                    
                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );


                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    }, []); // eslint-disable-line



    const handleSubmitAddress = values => {

        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (props.editMode) {
            requestType = UPDATE;
            values.id = props.id;
        }

        fetchIt(
            requestType,
            'locations_address',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Location saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            
        });
    };

    return (
        <SimpleForm
            resource={props.resource}
            record={record}
            save={handleSubmitAddress}
            toolbar={<LocationFormToolbar label='Save Address'/>}
        >
            <TextInput source='City' label='City' className='fieldsWidthExpand' validate={required('The City field is required')} />
            <UsStates source='USState' className='fieldsWidthExpand' validate={required('The State field is required')}/>

            <ZipCodeMaskedInput
                required
                source='ZIP'
                label='ZIP Code'
                validate={zipCodeValidation}
            />
            <TextInput
                multiline
                source='StreetAddress'
                label='Street Address'
                validate={required('The Street Address field is required')}
                className='fieldsWidthExpand'
            />
            <TextInput source='StreetAddressLine2' label='Street Address Line 2' className='fieldsWidthExpand' />                                    
        </SimpleForm>
    );

}

export default LocationContactForm;