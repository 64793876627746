import React, {Fragment, useState, useEffect} from 'react'
import {hexToRgb, inArray} from './helpers'
import DraggableSchedule from './DraggableSchedule'
import Schedule from './Schedule'
import CheckboxedSchedule from './CheckboxedSchedule'


const Schedules = props => {
    const [forceUpdate, setForceUpdate] = useState(false);
    const {resource, timeSlot, targetSlots, timeSubSlot, schedules, refreshSchedules} = props;


    useEffect(() => {
        if (!!refreshSchedules[0] !== forceUpdate) {
            setForceUpdate(!!refreshSchedules[0]);
        }
    }, [refreshSchedules[0]]); // eslint-disable-line


    if (schedules.length > 0) {
        return schedules.map((schedule, key) => {
            const rgbColor = hexToRgb(resource.color);
            const backgroundColor = 'rgba(' + rgbColor.r + ', ' + rgbColor.g + ', ' + rgbColor.b + ', 0.15)';
            

            if (!props.withOtherSemesters && schedule.BelongsToAnotherSemester) {
                return null;
            }

            if (props.withCheckboxes) {
                return (
                    <CheckboxedSchedule
                        key={key}
                        idx={key}
                        resource={resource}
                        schedule={schedule}
                        refresh={inArray(schedule.Id, refreshSchedules)}
                        shouldRefresh={forceUpdate}
                        timeSlot={timeSlot}
                        innerSlot={timeSubSlot}
                        targetSlots={targetSlots}
                        backgroundColor={backgroundColor}
                        top={schedule.top}
                    />
                );
            }

            return (
                <Fragment key={key}>
                    {
                        schedule.BelongsToAnotherSemester ?
                            <Schedule
                                idx={key}
                                resource={resource}
                                schedule={schedule}
                                timeSlot={timeSlot}
                                innerSlot={timeSubSlot}
                                targetSlots={targetSlots}
                                backgroundColor={backgroundColor}
                                top={schedule.top}
                            />
                        :
                            <DraggableSchedule
                                idx={key}
                                resource={resource}
                                schedule={schedule}
                                refresh={inArray(schedule.Id, refreshSchedules)}
                                shouldRefresh={forceUpdate}
                                timeSlot={timeSlot}
                                innerSlot={timeSubSlot}
                                targetSlots={targetSlots}
                                backgroundColor={backgroundColor}
                                top={schedule.top}
                                isReadOnly={props.readOnly}
                                hasCheckbox={props.withCheckboxes}
                            />
                    }
                </Fragment>
            );
        });
    }

    return null;
};

export default Schedules
