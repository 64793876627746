import React, {Component, Fragment} from 'react';
import {
    required,
    email,
    Button,
    SimpleForm,
    TextInput,
    BooleanInput,
    RadioButtonGroupInput,
    CREATE,
    FormDataConsumer,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {PhoneMaskedInput, DatePicker} from '../../../../common';
import {emailValidation, phoneValidation, optionalPhoneValidation} from '../../../../validators';
import {fetchIt, formatPhone, getErrorMessage} from "../../../../helpers";
import IconCancel from '@material-ui/icons/Cancel';
import {NtaSaveButton} from '../../../common';
import {Grid} from "@material-ui/core";

const FormActions = props => {
    const {basePath, onCloseClick, isDisabled, showNotification, ...rest} = props;

    return (
        <DialogActions {...rest} className="EmailChangeButtons">
            <NtaSaveButton label="Save" onCloseClick={onCloseClick} showNotification={showNotification} isDisabled={isDisabled} />
            <Button label="Cancel" onClick={onCloseClick}>
                <IconCancel />
            </Button>
        </DialogActions>
    );
};

class CreateFamilyMemberButton extends Component {
    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        if (this.props.isDeleted) {
            this.props.showNotification(
                'direct_message',
                'warning',
                {messageArgs: {_: 'This customer is deleted!'}}
            );

            this.setState({showDialog: false});
        } else {
            this.setState({showDialog: true});
        }
    };

    handleCloseClick = () => {
        this.setState({showDialog: false});
    };

    handleSubmit = values => {
        const {fetchStart, fetchEnd, showNotification} = this.props;

        if (values.CellPhone) {
            values.CellPhone = values.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        values.id = this.props.record.Id;

        fetchIt(
            CREATE,
            'users_family_members',
            { data: values }
        ).then(response => {
            switch (response.status) {
                case 200:
                    // response.data.CellPhone = formatPhone(response.data.CellPhone)
                    // response.data.BirthDate = response.data.BirthDate ? moment.utc(response.data.BirthDate, 'MM/DD/YYYY', true) : null;

                    this.setState({showDialog: false});

                    if (this.props.handleRefresh) {
                        this.props.handleRefresh();
                    }

                    break;

                case 400:
                    this.setState({showDialog: true});
                    let errorMessage = 'There was a problem with creating a new family member.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }
                    
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: errorMessage}}
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        {messageArgs: {_: 'Oops, something went wrong!'}}
                    );
            }
        }).catch(error => {

        }).finally(() => {
            if (values.CellPhone) {
                values.CellPhone = formatPhone(values.CellPhone);
            }

            // Dispatch an action letting react-admin know a API call has ended
            fetchEnd();
        });
    };

    render() {

        const SanitizedGrid = ({basePath, ...props}) => {
            return (
              <Grid {...props} />
            );
        };

        return (
            <Fragment>
                <Button onClick={this.handleClick} label="ra.action.create" style={{color: '#3B9287'}}>
                    <IconContentAdd />
                </Button>
                <Dialog
                    // fullWidth
                    open={this.state.showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Create Family Member"
                    maxWidth='xl'
                >
                    <DialogTitle style={{paddingBottom: 0}}>Create Family Member</DialogTitle>
                    <DialogContent style={{paddingTop:0}}>
                        <SimpleForm
                            // We override the react-final-form name to avoid collision with the react-admin main form
                            resource="users_family_members"
                            // We override the react-final-form onSubmit prop to handle the submission ourselves
                            save={this.handleSubmit}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                            style={{paddingTop: 0}}
                        >
                            <SanitizedGrid container style={{width: '100%', paddingLeft: 0, paddingRight:0}} >
                                <Grid item xs={6} style={{paddingLeft: 0, marginTop: -25}}>
                                    <TextInput
                                        source='FirstName'
                                        className='fieldsWidthExpand removeBackground'
                                        validate={required('The First Name field is required')}
                                        variant='standard'
                                    />
                                    <TextInput
                                        source='LastName'
                                        className='fieldsWidthExpand removeBackground'
                                        validate={required('The Last Name field is required')}
                                        variant='standard'
                                    />
                                    <FormDataConsumer>
                                        {
                                            ({formData, ...rest}) =>
                                                <DatePicker
                                                    source='BirthDate'
                                                    validate={formData.IsStudent ? required('The Birth Date field is required') : null}
                                                />
                                        }
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {
                                            ({formData, ...rest}) =>
                                                <TextInput
                                                    source='Email'
                                                    required = {!formData.IsStudent ? true : false} 
                                                    className='fieldsWidthExpand'
                                                    variant='standard'
                                                    validate={email('The Email field is not a valid e-mail address.')}
                                                />
                                        }
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {
                                            ({formData, ...rest}) =>
                                                <PhoneMaskedInput
                                                    required = {formData.IsStudent ? false : true}
                                                    source='CellPhone'
                                                    className='fieldsWidthExpand'
                                                />
                                        }
                                    </FormDataConsumer>
                                </Grid>

                                <Grid item xs={6} style={{paddingLeft: '7%'}}>
                                    <RadioButtonGroupInput
                                        source='Gender'
                                        choices={[
                                            {id: 'Male', name: 'Male'},
                                            {id: 'Female', name: 'Female'},
                                        ]}
                                    />
                                    <BooleanInput source='IsStudent' />
                                </Grid>
                            </SanitizedGrid>


                            <FormActions
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    justifyContent: 'flex-start',
                                    width: '100%',
                                    marginLeft: -25
                                }}
                                onCloseClick={this.handleCloseClick}
                                showNotification={this.props.showNotification}
                                isDisabled={this.state.isDisabled}
                            />
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

export default CreateFamilyMemberButton;
