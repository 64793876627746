import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    FunctionField
} from 'react-admin';
import {CoursesListFilters} from '../';
import SplitButton  from '../../../common/containers/SplitButton';
import {exporter} from "../../../helpers";
import {renderYearsFromMonths} from '../../../helpers';

export const CoursesList = props => (
    <div className="wrapperContent">
        <List {...props} filters={<CoursesListFilters />} perPage={25} title='Courses List' exporter={exporter} bulkActionButtons={false} sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField source="Name" />
                <FunctionField source='MinAge' render={record => renderYearsFromMonths(record, 'MinAge') + ' yrs.'} />
                <FunctionField source='MaxAge' render={record => renderYearsFromMonths(record, 'MaxAge') + ' yrs.'} />
                <NumberField source="SingleClassPrice" label="Price" />
                <FunctionField label='Status' source='IsActive' render={record => record.IsActive ? 'Active' : 'Inactive'} />
                <SplitButton 
                    cellClassName='splitButtonTdSmall' 
                    successMessage='Course deleted'
                    errorMessage='Course NOT deleted'
                    confirmTitle='Delete the course'
                    confirmContent='Are you sure you want to delete the course?' 
                    smallTd
                />
            </Datagrid>
        </List>
    </div>
);
