import React, {useState, useEffect} from 'react';
import {
    FormWithRedirect,
    UPDATE,
    fetchStart
} from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {fetchIt, getErrorMessage} from "../../../helpers";
import EditIcon from '@material-ui/icons/Edit';
import EditScheduleFormView from './EditScheduleFormView';
import arrayMutators from 'final-form-arrays';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {Typography} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import moment from "moment";


const EditScheduleDialog = props => {
    const [showDialog, setShowDialog] = useState(props.isOpened);
    const [record, setRecord] = useState({});


    const styles = theme => ({
        root: {
            margin: 0,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });


    const DialogTitle = withStyles(styles)(props => {
        const {classes, onClose, ...other} = props;

        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">Edit single day of week</Typography>
                {
                    onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null
                }
            </MuiDialogTitle>
        );
    });


    useEffect(() => {
        if (props.isOpened) {
            let newRecord = {
                Id: parseInt(props.scheduleToEdit.schedule),
                DayOfWeek: props.scheduleToEdit.weekday,
                StartTime: moment.utc(props.scheduleToEdit.start, 'h:mmA'),
                Duration: parseInt(props.scheduleToEdit.Duration),
                Studio: props.scheduleToEdit.resource,
                HasRegistrations: !!parseInt(props.scheduleToEdit.Registrations),
            };

            setRecord(newRecord);
        }

        setShowDialog(props.isOpened);
    }, [props.isOpened]);


    const handleCloseClick = () => {
        props.onCancel();
    };


    const handleSubmit = values => {
        const {fetchStart, fetchEnd, showNotification} = props;

        fetchStart();

        const data = Object.assign({}, values);
        data.id = record.Id;
        data.StartTime = values.StartTime.format('HH:mm:ss');

        fetchIt(
            UPDATE,
            'schedule_move',
            data
        ).then(response => {
            switch (response.status) {
                case 200:
                    props.onCancel();

                    props.fetchTheData();

                    let message = 'Schedule updated.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:
                    let errorMessage = 'There was a problem with update the email.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(() => {}).then(() => {
            fetchEnd();
        });
    };

    return (
        <>
            <Dialog
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Edit Schedule"
                maxWidth='xs'
            >
                <DialogTitle style={{paddingBottom: 0}} onClose={handleCloseClick} >
                    Edit Schedule
                </DialogTitle>

                <DialogContent style={{paddingTop: 0}}>
                    {
                        record.HasRegistrations ?
                            <p style={{color: 'red', marginTop: 0, paddingLeft: 25, paddingTop: 16}}>This class has registrations!</p>
                            : null
                    }

                    <FormWithRedirect
                        {...props}
                        record={record}
                        mutators={{...arrayMutators}}
                        save={handleSubmit}
                        render={formProps => (
                            <EditScheduleFormView {...formProps} handleCloseClick={handleCloseClick}/>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default EditScheduleDialog;
