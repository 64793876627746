import React, {useEffect, useState} from 'react';
import {Typography} from '@material-ui/core';
import {
    fetchEnd,
    fetchStart,
    showNotification,
    GET_ONE
} from 'react-admin';
import {fetchIt} from "../../../helpers";



const NameField = props => {
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (props.activeTab > 0) {
        fetchStart();

        fetchIt(
            GET_ONE,
            props.endpointToFetch,
            {id: props.record}
        ).then(response => {
            switch (response.status) {
                case 200:
                    setTitle(response.data.Name);

                    break;

                case 404:
                    let errorMessage = '';

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            fetchEnd();
        });
        }
        

    }, [props.record, props.activeTab]); // eslint-disable-line
    
    if (props.label) {
        return (
            <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
                <span style={{fontWeight: 'bold'}}>{props.label}:</span> {title}
            </Typography>
        );
    }
    return(
        <Typography variant="body1" component="div" style={{paddingBottom: 5}}>
            {title}
        </Typography>
    );
    
}

export default NameField;
