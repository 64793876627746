import React, {Component} from 'react'
import Schedules from './Schedules'


class TimeSlot extends Component {
    _isMounted = false;

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            !this.state ||
            (
                nextProps &&
                nextProps.schedules &&
                (
                    (nextProps.schedules.length !== this.props.schedules.length) ||
                    (this.props.schedules.length > 0)
                )
            )
        );
    }


    componentDidMount() {
        this._isMounted = true;

        this.setState({loaded: true});
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        return (
            <div
                id={this.props.id}
                // onClick={timeSlotClick}
                onContextMenu={this.props.timeSlotClick}
                data-target-slots={this.props.targetSlots}
                data-time-slot={this.props.timeSlot}
                data-time-subslot={this.props.inner}
                data-weekday={this.props.weekday}
                data-resource={this.props.resource.id}
                data-is-outside={this.props.isOutsideWorkingHours}
                data-min-time={this.props.minTime}
                data-max-time={this.props.maxTime}
                style={{...this.props.borderStyle}}
                className={this.props.className}
            >
                <Schedules
                    weekday={this.props.weekday}
                    resource={this.props.resource}
                    timeSlot={this.props.timeSlot}
                    targetSlots={this.props.targetSlots}
                    timeSubSlot={this.props.inner}
                    schedules={this.props.schedules}
                    refreshSchedules={this.props.refreshSchedules}
                    readOnly={this.props.readOnly}
                    withCheckboxes={this.props.withCheckboxes}
                    withOtherSemesters={this.props.withOtherSemesters}
                />
            </div>
        )

    }

}

export default TimeSlot;
