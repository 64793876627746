import ExcelJS from 'exceljs';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {inArray} from '../../../helpers';


const exporter = records => {
    const headers = [{
        Id: 'Id',
        Schedule: 'Schedule',
        DayOfWeek: 'Day Of Week',
        StudioId: 'Studio Id',
        Studio: 'Studio',
        Semester: 'Semester',
        Course: 'Course',
        StartsAt: 'Starts At',
        Duration: 'Duration (in minutes)',
        Price: 'Price',
        Places: 'Places',
        BelongsToAnotherSemester: 'Belongs To Another Semester',
        IsOverlapped: 'Is Overlapped',
        Teachers: 'Teachers',
        DaysOfWeek: 'Days Of Week',
    }];


    const rightAligned = ['Id', 'Schedule', 'StudioId', 'StartsAt', 'Duration', 'Price', 'Places'];


    let data = records.map(record => {
        const start = moment.utc(record.StartHour, "H").add(record.StartMinutes, 'minutes').format('h:mm A');

        return {
            Id: record.Id,
            Schedule: record.Schedule,
            DayOfWeek: record.DayOfWeek,
            StudioId: record.Studio,
            Studio: record.StudioName,
            Semester: record.Semester,
            Course: record.Course,
            StartsAt: start,
            Duration: record.Duration,
            Price: record.Price,
            Places: record.Places,
            BelongsToAnotherSemester: (record.BelongsToAnotherSemester ? 'Yes' : 'No'),
            IsOverlapped: (record.IsOverlapped ? 'Yes' : 'No'),
            Teachers: (record.Teachers ? record.Teachers.map(teacher => teacher.Name).join(', ') : ''),
            DaysOfWeek: record.DaysOfWeek,
        }
    });


    data = headers.concat(data);


    let objectMaxLength = [];
    for (let t = 0; t < data.length; t++) {
        for (let key in data[t]) {
            if (data[t].hasOwnProperty(key)) {
                objectMaxLength[key] = objectMaxLength[key] >= ((data[t][key] + '').length + 2) ?
                    objectMaxLength[key] :
                    ((data[t][key] + '').length + 2);
            }
        }
    }

    data.shift();

    let newHeaders = [];
    for (let key in headers[0]) {
        if (headers[0].hasOwnProperty(key)) {
            newHeaders.push({
                key: key,
                header: headers[0][key],
                width: objectMaxLength[key] + (key === 'Price' ? 3 : 0),
                style: (inArray(key, rightAligned) ? {alignment: {vertical: 'middle', horizontal: 'right'}} : {})
            });
        }
    }


    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Schedules');
    sheet.columns = newHeaders;
    for (let t = 0; t < data.length; t++) {
        sheet.addRow(data[t]);
    }

    sheet.getColumn('Price').numFmt = '[$$-409]#,##0.00;-[$$-409]#,##0.00';

    let row = sheet.getRow(1);
    row.font = {bold: true};


    workbook.xlsx.writeBuffer()
    .then(buffer => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const blob = new Blob([buffer], {type: fileType});

        return saveAs(blob, 'Schedules.xlsx')
    })
    .catch(err => console.log('Error writing excel export', err));
};

export default exporter;
