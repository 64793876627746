import React from "react";
import {Typography} from "@material-ui/core";


const SelectedStudent = props => {
    const classes = props.classes;

    return (
        <>
            {
                props.selectedFamilyMemberInfo ?
                    (
                        <Typography variant='body1'>
                            {props.selectedFamilyMemberInfo.FirstName}
                            {' ' + props.selectedFamilyMemberInfo.LastName}
                            {props.selectedFamilyMemberInfo.BirthDate ? ', ' + props.selectedFamilyMemberInfo.BirthDate : ''}
                        </Typography>
                    )
                :
                    (
                        <Typography variant='body1' className={classes.selectionTitle}>
                            None
                        </Typography>
                    )
            }
        </>
    );
}

export default SelectedStudent;
