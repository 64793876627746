import React, {useEffect, useState} from 'react'
import moment from "moment/moment";
import {makeStyles} from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    root: {
        padding: 10,
        background: 'white',
        border: '1px solid #e0e0e3',
        color: '#606060',
        maxWidth: 350,
        lineHeight: 1.8,
        borderRadius: 10
    },
    dot: {
        height: 15,
        width: 15,
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: 5
      },
    bold: {
        fontWeight: 'bold'
    }
}));


const Tooltip = props => {
    const [teachers, setTeachers] = useState(props.teachers || []);
    const [courses, setCourses] = useState(props.courses || []);

    const classes = useStyles();

    useEffect(() => {
        setTeachers(props.teachers);
        setCourses(props.courses);
    }, [props.teachers, props.courses]);

    const event = props.event;
    
    let studio = false;
    if (event.extendedProps) {
        studio = props.resources.find(studio => {
            return studio.id === parseInt(event.extendedProps.Studio);
        });
    }

    let eventTeachers = [];
    if (event.extendedProps && event.extendedProps.Teachers && teachers && (teachers.length > 0)) {
        for (let t = 0; t < event.extendedProps.Teachers.length; t++) {
            const teacher = teachers.find(teacher => {
                return event.extendedProps.Teachers[t] === teacher.id;
            });

            if (teacher) {
                eventTeachers.push(teacher.Name);
            }
        }
    }

    let eventDescription = null;
    if (event.extendedProps && event.extendedProps.DescriptionTagless) {
        eventDescription = event.extendedProps.DescriptionTagless;
    } else if (event.extendedProps && event.extendedProps.Description) {
        eventDescription = event.extendedProps.Description.replace(/<[^>]+>/g, '');
    }

    if (eventDescription && (eventDescription.length > 180)) {
        eventDescription = eventDescription.substring(0, 180) + '...';
    }

    let eventCourse = courses.find(course => {
        return event.extendedProps && (event.extendedProps.Course === course.id);
    });

    return (
        <List className={classes.root}>
            <Typography variant='h6' style={{paddingLeft: 15}}>{event.title}</Typography>

            {
                event.extendedProps && event.extendedProps.IsEvent ?
                    <>
                        <ListItem>
                            {eventTeachers.join(', ')}
                        </ListItem>
                        <Divider/>
                    </>
                : null
            }
           
            {
                event.extendedProps && event.extendedProps.IsEvent ?
                    <>
                        <ListItem>
                            {moment(event.start).format('M/D/YY h:mm a')} - {moment(event.end).format('M/D/YY h:mm a')}
                        </ListItem>
                        <Divider/>
                    </>

                :
                    <>
                        <ListItem alignItems="flex-start">{eventTeachers.join(', ')}</ListItem>
                        <Divider/>
                        <ListItem>{moment(event.start).format('ddd h:mm a')} - {moment(event.end).format('h:mm a')}</ListItem>
                        <Divider/>
                    </>
            }
               
            {
                event.extendedProps && event.extendedProps.Price ?
                    <>
                        <ListItem>
                            Price: ${event.extendedProps.Price}
                        </ListItem>
                        <Divider/>
                    </>
                    
                : null
            }

            {
                event.extendedProps && event.extendedProps.SemesterName ?
                    <>
                        <ListItem>
                            Semester: {event.extendedProps.SemesterName}
                        </ListItem>
                        <Divider/>
                    </>
                    
                : null
            }

            {
                studio ?
                    <>
                        <ListItem>
                            <span style={{background: studio.scheduleColor}} className={classes.dot}></span>
                            {studio.title}
                        </ListItem>
                        <Divider/>
                    </>

                : null
            }

            {
                eventCourse ?
                    <>
                        <ListItem>
                            Age: {eventCourse.MinAgeDisplayName} - {eventCourse.MaxAgeDisplayName}
                        </ListItem>
                        <Divider/>
                    </>
                   
                : null
            }

            {
                eventDescription ?
                    <>
                        <ListItem>
                            {eventDescription}
                        </ListItem>
                        <Divider/>                       
                    </>
                : null
            }

            {/* <ListItem>
                {event.extendedProps && event.extendedProps.Occurrence ? 'Repeats' : 'Does not repeat'}
            </ListItem> */}
            
        </List>
    );
}

export default Tooltip;
