import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {fetchIt} from "../../../../../helpers/fetcher";
import {CREATE} from 'react-admin';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmCancelationDialog from '../dialogs/ConfirmCancelationDialog';

const CancelScheduleStudentButton = props => {

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(true);
    };

    const handleDialogClose = () => {
        setIsOpen(false);
    };


    const onCancel = notice => {
 
        const {fetchStart, fetchEnd, showNotification} = props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();
 
        fetchIt(
            CREATE,
            'registration_cancel_schedule_student',
            {id: props.scheduleStudent.ScheduleStudentId, Notes: notice}
            ).then(response => {
            switch (response.status) {
                case 200:
            
                setIsOpen(false);

                props.fetchTheData();
                let message = 'Schedule Student canceled.';
                if (response.data.Message) {
                    message = response.data.Message;
                }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    break;

                case 400:
                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
 
    }

    return (
        <>
        <Tooltip title="Cancel Schedule Student" placement="right-start">
            <Button
                onClick={handleClick}
                key="button"
                color='secondary'
            >
                <CloseIcon/>
            </Button>
        </Tooltip>
        <ConfirmCancelationDialog 
            isOpen={isOpen} 
            handleDialogClose={handleDialogClose} 
            cancelRegistration={onCancel}
            dialogTitle='Cancel Schedule Student'
            dialogContent='Are you sure you want to cancel Schedule Student?'
        />
        </>
    );  
};

export default CancelScheduleStudentButton;