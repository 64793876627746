import React from "react";
import MaskedInput from 'react-text-mask';
import {addField} from "react-admin";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import {FormHelperText} from "@material-ui/core";


const US = value => {
    value = value.replace(/-/g,"").replace(/\s+/g, "");
    if (value.length > 5) {
        return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }

    return [/\d/, /\d/, /\d/, /\d/, /\d/];
}

const TextMaskCustom = props => {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={US}
            placeholderChar={'\u2000'}
            // showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};


const ZipCodeMaskedInput = props => {
    return (
        <FormControl 
            style={{marginTop: 23, marginBottom: 23, marginLeft: props.userTabbedForm ? 25 : 0}} 
            error={!!(props.meta.modified && props.meta.error)}
        >
            <InputLabel htmlFor={props.source}>{props.label || props.source}{props.required ? ' *' : null}</InputLabel>
            <Input
                value={props.input.value}
                onChange={props.input.onChange}
                inputComponent={TextMaskCustom}
                validate={props.validate}
                id={props.source}
                className="fieldsWidthExpand"
            />
            {
                !!(props.meta.modified && props.meta.error) ? <FormHelperText>{props.meta.error}</FormHelperText> : null
            }
        </FormControl>
    );
}

export default addField(ZipCodeMaskedInput);
