import React, {Component, Fragment} from 'react';
import {
    CREATE,
    UPDATE,
    required,
    TextInput,
    Toolbar,
    BooleanInput,
    RadioButtonGroupInput,
    SimpleForm,
    SelectInput
} from 'react-admin';
import {fetchIt, getErrorMessage, formatPhone} from "../../../../helpers";
import {Redirect} from 'react-router';
import {emailValidation, emailAlternateValidation} from '../../../../validators';
import {PhoneMaskedInput, ZipCodeMaskedInput, UsStates, DatePicker} from '../../../../common';
import {phoneValidation, optionalPhoneValidation, zipCodeValidation} from '../../../../validators';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TenantUserFamilyList from '../containers/TenantUserFamilyList';
import ChangeEmailButton from '../containers/ChangeEmailButton';
import {Grid} from "@material-ui/core";
import {NtaSaveButton} from "../../../common";


export const UserFormToolbar = props => {
    const {isDisabled, ...toolbarProps} = props;

    return (
        <Toolbar {...toolbarProps} >
            <NtaSaveButton isDisabled={isDisabled} label={props.label}/>
        </Toolbar>
    );
};


export const SanitizedGrid = ({basePath, ...props}) => {
    return (
      <Grid {...props} />
    );
};

const styles = () => createStyles({
    errorTabButton: { color: "#f44336 !important" },
});

export const UserTitle = props => {
    if (props.title && document.getElementById("the-title")) {
        document.getElementById("the-title").innerHTML = props.title ? props.title : '';
    }

    return '';
};

export const AddressHeading = () => (
    <h2>
        Address
    </h2>
);

class UserForm extends Component {
    _isMounted = false;

    state = {
        error: false,
        redirectTo: null,
        isDisabled: false,
        value: 0,
        userData: {},
        userAddress: {},
        emergencyContactData: {}
    };

    componentDidMount () {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    };

    handleSubmitUserData = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        if (values.HomePhone) {
            values.HomePhone = values.HomePhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }
        if (values.CellPhone) {
            values.CellPhone = values.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;

        if (this.props.editMode) {
            requestType = UPDATE;
            values.id = this.props.id;
        }

        fetchIt(
            requestType,
            'tenant_users',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'User saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    if (me._isMounted && requestType == CREATE) {
                        me.setState({
                            redirectTo: me.props.basePath + '/' + response.data.Id + '/show'
                        });
                    }

                    break;
                case 400:
                    values.CellPhone = formatPhone(values.CellPhone);
                    values.HomePhone = formatPhone(values.HomePhone);

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    values.CellPhone = formatPhone(values.CellPhone);
                    values.HomePhone = formatPhone(values.HomePhone);

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    handleSubmitUserAddressData = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        if (values.ZIP) {
            values.ZIP = values.ZIP.substring(0, 10);
        }

        const me = this;
        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        values.id = this.props.id;

        fetchIt(
            UPDATE,
            'tenant_users_address',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'User saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );


                    break;
                case 400:

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    handleSubmitEmergencyContactData = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        const me = this;
        if (values.CellPhone) {
            values.CellPhone = values.CellPhone.replace(/\(|\)|\s|-/gi, "").substring(0, 10);
        }

        const { fetchStart, fetchEnd, showNotification } = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        values.id = this.props.id;

        fetchIt(
            UPDATE,
            'users_emergency_contact',
            values
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'User saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );


                    break;
                case 400:
                    values.CellPhone = formatPhone(values.CellPhone);

                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    values.CellPhone = formatPhone(values.CellPhone);
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    render() {
        let props = {};
        props.id = this.props.id;
        props.basePath = this.props.basePath;
        props.resource = this.props.resource;

        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>
        }

        return (
            <Fragment>
                <Card>
                    <CardContent>

                        {
                            this.props.editMode ?
                            <Fragment>
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    scrollButtons="off"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label={this.props.userData.Role + ' Data'} value={0}/>
                                    <Tab label="Address" value={1}/>
                                    <Tab label="Emergency Contact" value={2} style={{marginLeft: 25}}/>
                                    <Tab label="Family" value={3}/>
                                </Tabs>
                            </Fragment>
                        :
                                <Tabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    scrollButtons="off"
                                    indicatorColor="primary"
                                    textColor="primary"
                                >
                                    <Tab label="Organizer Data"  value={0}/>
                                </Tabs>
                        }


                        {
                            this.state.value === 0 &&
                                <SimpleForm
                                    redirect={this.props.editMode ? 'show' : 'edit'}
                                    resource={this.props.resource}
                                    record={this.props.userData}
                                    save={this.handleSubmitUserData}
                                    toolbar={<UserFormToolbar isDisabled={this.state.isDisabled} label="Save User Data"/>}
                                >
                                    <SanitizedGrid container style={{width: '100%', maxWidth: 1640}} >
                                        <Grid item xs={6}>
                                            <TextInput source="FirstName" className='fieldsWidthExpand' validate={required('The First Name field is required')} />
                                            <TextInput source="LastName" className='fieldsWidthExpand' validate={required('The Last Name field is required')} />

                                            <PhoneMaskedInput
                                                required = {this.props.userData && this.props.userData.Role === 'Student' ? false : true}
                                                source='CellPhone'
                                                validate={this.props.userData && this.props.userData.Role === 'Student' ? optionalPhoneValidation : phoneValidation}
                                                className='fieldsWidthExpand'
                                            />

                                            <PhoneMaskedInput
                                                source='HomePhone'
                                                validate={optionalPhoneValidation}
                                                className='fieldsWidthExpand'
                                            />
                                            <DatePicker
                                                source='BirthDate'
                                                validate={this.props.userData && this.props.userData.Role === 'Student' ? required('The Birth Date field is required') : null}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            {
                                                this.props.editMode ?
                                                <ChangeEmailButton handleRefresh={this.props.fetchTenantUserInfo} record={this.props.userData} />
                                                : null
                                            }

                                            {
                                                this.props.editMode ?
                                                <TextInput
                                                    type='email'
                                                    source='Email'
                                                    validate={this.props.userData && this.props.userData.Role === 'Student' ? emailAlternateValidation : emailValidation}
                                                    inputProps={{ readOnly: true }}
                                                    style={{backgroundColor: "#f5f5f5", borderRadius: 4}}
                                                    className='fieldsWidthExpand'
                                                />
                                            :
                                                <TextInput
                                                    type='email'
                                                    source='Email'
                                                    validate={this.props.userData && this.props.userData.Role === 'Student' ? emailAlternateValidation : emailValidation}
                                                    className='fieldsWidthExpand'
                                                />
                                            }
                                            {/* <BooleanInput source='AreNotificationsEnabled' /> */}

                                            {/* {
                                                this.props.editMode ?
                                                    <BooleanInput source='IsActive' style={{paddingTop: 20}}/>
                                                : null
                                            } */}

                                            <RadioButtonGroupInput
                                                source='Gender'
                                                choices={[
                                                    {id: 'Male', name: 'Male'},
                                                    {id: 'Female', name: 'Female'},
                                                ]}
                                            />
                                        </Grid>
                                    </SanitizedGrid>
                                </SimpleForm>
                        }

                        {
                            this.props.editMode ?
                            <Fragment>
                                {
                                    this.state.value === 1 &&
                                        <SimpleForm
                                            resource={this.props.resource}
                                            record={this.props.userAddressData}
                                            save={this.handleSubmitUserAddressData}
                                            toolbar={<UserFormToolbar isDisabled={this.state.isDisabled} label="Save User Address"/>}
                                        >
                                            <TextInput 
                                                style={{marginLeft: 25}} 
                                                source='City' 
                                                label='City' 
                                                className='fieldsWidthExpand' 
                                                validate={required('The City field is required')} 
                                            />
                                            <UsStates 
                                                userTabbedForm
                                                source='USState' 
                                                validate={required('The State field is required')}
                                            />
                                            <ZipCodeMaskedInput
                                                required
                                                source='ZIP'
                                                label='ZIP Code'
                                                validate={zipCodeValidation}
                                                userTabbedForm
                                            />
                                            <TextInput 
                                                style={{marginLeft: 25}} 
                                                multiline 
                                                source='StreetAddress' 
                                                label='Street Address' 
                                                className='fieldsWidthExpand' 
                                                validate={required('The Street Address field is required')} 
                                            />
                                            <TextInput 
                                                style={{marginLeft: 25}} 
                                                source='StreetAddressLine2' 
                                                label='Street Address Line 2' 
                                                className='fieldsWidthExpand' 
                                            />
                                        </SimpleForm>
                                }
                                {
                                    this.state.value === 2 &&
                                        <SimpleForm
                                            resource={this.props.resource}
                                            record={this.props.emergencyContactData}
                                            save={this.handleSubmitEmergencyContactData}
                                            toolbar={<UserFormToolbar isDisabled={this.state.isDisabled} label="Save Emergency Contact"/>}
                                        >
                                             <SanitizedGrid container style={{width: '100%', maxWidth: 1200}} >
                                                <Grid item xs={6}>
                                                    <TextInput
                                                        source="FirstName"
                                                        label="First Name"
                                                        className='fieldsWidthExpand'
                                                        validate={required('The First Name field is required')}
                                                    />
                                                    <TextInput
                                                        source="LastName"
                                                        label="Last Name"
                                                        className='fieldsWidthExpand'
                                                        validate={required('The Last Name field is required')}
                                                    />
                                                    <TextInput 
                                                        source='Email' 
                                                        label="Email" 
                                                        className='fieldsWidthExpand' 
                                                        type='email' 
                                                        validate={emailValidation}
                                                     />
                                                    <DatePicker source='BirthDate'/>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <PhoneMaskedInput
                                                        required
                                                        source='CellPhone'
                                                        validate={phoneValidation}
                                                        label="Cell Phone"
                                                        className='fieldsWidthExpand'
                                                    />
                                                    <SelectInput
                                                        source='Relationship'
                                                        choices={[
                                                            {id: 'Daycare', name: 'Daycare'},
                                                            {id: 'Relative', name: 'Relative'},
                                                            {id: 'Friend', name: 'Friend'},
                                                            {id: 'Other', name: 'Other'},
                                                        ]}
                                                        className='fieldsWidthExpand'
                                                    />
                                                    <TextInput
                                                        source="OtherEmergencyContactRelationship"
                                                        label="Other Relationship"
                                                        className='fieldsWidthExpand'
                                                    />
                                                </Grid>
                                            </SanitizedGrid>
                                        </SimpleForm>
                                }
                                {
                                    this.state.value === 3 && <TenantUserFamilyList source='Id' record={this.props.userData} />
                                }
                            </Fragment>
                            : null
                        }

                    </CardContent>
                 </Card>
            </Fragment>
        );
    }
}

UserForm.propTypes = {
    record: PropTypes.object.isRequired
};

UserForm.defaultProps = {
    record: {}
};

export default withStyles(styles)(UserForm);
