import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import moment from 'moment';
import EditScheduleDialog from '../containers/EditScheduleDialog';



const OutsideWorkingTimeSchedulesList = props => {
    
    const rows = [];   
    let startTime = '';
    for (let t = 0; t < props.outsideWorkingTimeSchedules.length; t++) { 

        startTime = moment.utc((props.outsideWorkingTimeSchedules[t].StartHour + ':' + props.outsideWorkingTimeSchedules[t].StartMinutes), 'H:m');
        const row = {
            Id: props.outsideWorkingTimeSchedules[t].Id, 
            Name: props.outsideWorkingTimeSchedules[t].Course, 
            DayOfWeek: props.outsideWorkingTimeSchedules[t].DayOfWeek,
            StartTime: startTime,
            Duration: props.outsideWorkingTimeSchedules[t].Duration,
            StudioName: props.outsideWorkingTimeSchedules[t].StudioName,
            Studio: props.outsideWorkingTimeSchedules[t].Studio,
            Schedule: props.outsideWorkingTimeSchedules[t].Schedule
        };
        rows.push(row);
    }

    return props.outsideWorkingTimeSchedules.length ? (
        <TableContainer style={{marginBottom: 25}}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Course Name</TableCell>
                        <TableCell>Day of Week</TableCell>
                        <TableCell>Starting time</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Studio</TableCell>
                        <TableCell>Edit</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (                  
                        <TableRow key={row.Id}>
                            <TableCell>{row.Name}</TableCell>
                            <TableCell>{row.DayOfWeek}</TableCell>
                            <TableCell>{row.StartTime.format('h:mm A')}</TableCell>
                            <TableCell>{row.Duration}</TableCell>
                            <TableCell>{row.StudioName}</TableCell>
                            <TableCell><EditScheduleDialog scheduleToEdit={row} fetchTheData={props.fetchTheData}/></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>     
    ) : null;
}

export default OutsideWorkingTimeSchedulesList;

